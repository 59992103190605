import { useFelaEnhanced } from 'hooks';
import { Box } from 'modules/ui';

import { ParcelFilter } from '../../constants';
import { useParcelsFilters } from '../../hooks/useParcelsFilters';
import { CreatedDate } from '../CreatedDate';
import { ParcelStatus } from '../ParcelStatus';
import { Payment } from '../Payment';
import { PaymentStatus } from '../PaymentStatus';

import * as felaRules from './ParcelsFilters.rules';

export const ParcelListFilters = () => {
    const { rules, theme } = useFelaEnhanced(felaRules);
    const { onChange, filters } = useParcelsFilters();

    return (
        <Box
            space={2.5 * theme.metrics.spacing}
            direction="row"
            extend={{ box: rules.container, itemContainer: rules.itemContainer }}
        >
            <ParcelStatus
                value={filters[ParcelFilter.STATE]}
                onChange={status => onChange({ [ParcelFilter.STATE]: status })}
            />
            <CreatedDate
                from={filters[ParcelFilter.CREATED_FROM]}
                to={filters[ParcelFilter.CREATED_TO]}
                onChange={(from, to) => onChange({ [ParcelFilter.CREATED_FROM]: from, [ParcelFilter.CREATED_TO]: to })}
            />
            <Payment
                value={filters[ParcelFilter.PAYMENT_MODE]}
                onChange={mode => onChange({ [ParcelFilter.PAYMENT_MODE]: mode })}
            />
            {filters[ParcelFilter.PAYMENT_MODE] === 'cod' && (
                <PaymentStatus
                    value={filters[ParcelFilter.PAYMENT_STATE]}
                    onChange={state => onChange({ [ParcelFilter.PAYMENT_STATE]: state })}
                />
            )}
        </Box>
    );
};

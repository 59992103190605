import { FormattedMessage } from 'react-intl';

import { useFelaEnhanced } from 'hooks';
import { Headline, LabeledValue, Panel, ValueSize, Button } from 'modules/ui';

import OrderMeta, { OrderMetaProps } from '../OrderMeta';

import * as felaRules from './OrderSummary.rules';
import { ParcelSummary, ParcelSummaryProps } from 'modules/parcels';
import { GetParcelLabel } from 'modules/parcels/modules/parcel-label';

type Item = Pick<
    ParcelSummaryProps,
    'parcelNumber' | 'name' | 'referenceNumber' | 'compartmentSize' | 'value' | 'weight'
> & { parcelLabelUrl?: string };
export interface OrderSummaryProps {
    referenceNumber: string;
    meta: Pick<
        OrderMetaProps,
        | 'collectAmount'
        | 'enableReturnAction'
        | 'invoiceValue'
        | 'notificationEmail'
        | 'paymentMethod'
        | 'returnAllowed'
        | 'typeOfService'
        | 'orderNumber'
        | 'parcelId'
        | 'payment'
    >;
    items: Item[];
    pickupAddress: React.ReactElement;
    deliveryAddress: React.ReactElement;
    orderLabelUrl?: string;
    displayParcelLabelButton?: boolean;
    dark?: boolean;
}

const OrderSummary = ({
    referenceNumber,
    meta,
    items,
    pickupAddress,
    deliveryAddress,
    orderLabelUrl,
    displayParcelLabelButton = false,
    dark = false,
}: OrderSummaryProps) => {
    const { styles, rules } = useFelaEnhanced(felaRules, { orderLabelUrl });

    return (
        <>
            <div className={styles.header}>
                <LabeledValue
                    label={<FormattedMessage id="order.summary" />}
                    value={referenceNumber}
                    size={ValueSize.BIG}
                />

                {displayParcelLabelButton && orderLabelUrl && (
                    <GetParcelLabel url={orderLabelUrl} text={{ id: 'parcel.getLabels' }} />
                )}
            </div>

            <Panel dark={dark}>
                <OrderMeta {...meta} />
            </Panel>

            <Headline level={2} extend={{ headline: rules.itemsHeadline }}>
                <FormattedMessage id="order.items" />
            </Headline>

            <div className={styles.items}>
                {items.map((item, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <ParcelSummary key={index} dark={dark} payment={meta.payment} {...item}>
                        {displayParcelLabelButton && item.parcelLabelUrl && (
                            <GetParcelLabel url={item.parcelLabelUrl} type={Button.Type.TERTIARY} />
                        )}
                    </ParcelSummary>
                ))}
            </div>

            <div className={styles.addresses}>
                <div>
                    <Headline level={2}>
                        <FormattedMessage id="order.pickup.address" />
                    </Headline>
                    {pickupAddress}
                </div>

                <div>
                    <Headline level={2}>
                        <FormattedMessage id="order.delivery.address" />
                    </Headline>
                    {deliveryAddress}
                </div>
            </div>
        </>
    );
};

export default OrderSummary;

import { createApiDetailRequestActions, apiRequestActions } from '@ackee/redux-utils';
import { createAction } from '@reduxjs/toolkit';
import type { ApiResolver } from 'modules/entities';
import type { ParcelFilterApiParams } from 'modules/parcels/modules/parcels-filters';
import type { TokenPaginationApiState } from 'types';
import type { PageType, ParcelsParams } from '../../types';
import * as types from './types';

export { types };

export const fetchParcels = createApiDetailRequestActions<
    string,
    ParcelsParams & ParcelFilterApiParams & { pageNumber: number },
    undefined,
    {
        lastSuccessAt: string;
        totalCount: number;
        [PageType.FIRST]: string | null;
        [PageType.PREVIOUS]: string | null;
        [PageType.CURRENT]: string | null;
        [PageType.NEXT]: string | null;
        [PageType.LAST]: string | null;
        pageNumber: number;
        currentCount: number;
    },
    string
>(types.fetchParcels);

export const requestParcelsFetching = createAction<{
    groupId: string;
    params: ParcelsParams;
    apiResolver: ApiResolver<TokenPaginationApiState>;
    filters: ParcelFilterApiParams;
    pageNumber: number;
    pageType: PageType;
}>(`${types.modulePrefix}/REQUEST_PARCELS_FETCHING`);

export const cancelParcelDelivery = apiRequestActions(types.cancelParcelDelivery, { isDetailRequest: true });

import { createExpirationDate } from '@ackee/petrus';
import type { Credentials, User } from 'modules/auth/types';

export default function processCredentials({ expiresIn, accessToken, refreshToken }: Credentials, user: User) {
    const expiration = createExpirationDate(expiresIn * 1000) as string;

    return {
        accessToken: {
            token: accessToken,
            expiration,
            user,
        },
        refreshToken: {
            token: refreshToken,
        },
    };
}

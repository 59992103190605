import type { TRuleWithTheme } from 'styles/theme';

export const input: TRuleWithTheme = () => ({
    textAlign: 'center',
    letterSpacing: 5,

    '&::-webkit-input-placeholder': {
        letterSpacing: 'normal',
    },

    "&[type='number']::-webkit-inner-spin-button": {
        display: 'none',
    },
});

export const formItem: TRuleWithTheme = () => ({});

import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, Redirect, Route, Switch } from 'react-router-dom';

import config from 'config/index';
import { useFelaEnhanced } from 'hooks';
import { csvImportType } from 'modules/entities/modules/csvImport';
import { Layout, PageHeader } from 'modules/layout';
import { BackButton } from 'modules/ui';

import { CsvImportPage } from '../CsvImportPage';
import { CsvLabelsPage } from '../CsvLabelsPage';
import { OrderFromAnyApmForm } from '../OrderFromAnyApmForm';

import * as felaRules from './OrderFromAnyApmPage.rules';

interface OrderFromAnyApmPageProps {}

export const OrderFromAnyApmPage: FC<OrderFromAnyApmPageProps> = () => {
    const { styles } = useFelaEnhanced(felaRules);

    return (
        <Layout>
            <PageHeader title={<FormattedMessage id="order.fromAnyApm.title" />}>
                <Link to={config.routes.home}>
                    <BackButton text={{ id: 'order.new.back' }} />
                </Link>
            </PageHeader>

            <div className={styles.content}>
                <Switch>
                    <Route path={config.routes.fromAnyApmOrderForm} exact component={OrderFromAnyApmForm} />
                    <Route
                        path={config.routes.fromAnyApmOrderCsvImport}
                        exact
                        render={() => <CsvImportPage type={csvImportType.Apm} />}
                    />
                    <Route
                        path={config.routes.fromAnyApmOrderLabels}
                        exact
                        render={() => <CsvLabelsPage redirectRoute={config.routes.fromAnyApmOrderCsvImport} />}
                    />
                    <Route render={() => <Redirect to={config.routes.fromAnyApmOrderForm} />} />
                </Switch>
            </div>
        </Layout>
    );
};

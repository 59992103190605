import type { FC } from 'react';
import { Field } from 'react-final-form';
import { Input } from 'antd';
import type { TextAreaProps } from 'antd/es/input';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import { useValidationDebounce } from '../../hooks';
import FormItem, { FormItemOwnProps } from '../FormItem';
import type { TextFieldProps } from '../types';

import * as felaRules from './TextAreaField.styles';

export interface TextAreaFieldOwnProps extends TextFieldProps {
    textareaProps?: TextAreaProps;
    fluid?: FormItemOwnProps<string>['fluid'];
    extend?: RulesExtend<typeof felaRules>;
    clear?: () => void;
}

const TextAreaField: FC<TextAreaFieldOwnProps> = ({
    label,
    required = false,
    validate,
    help,
    textareaProps,
    placeholder,
    fluid,
    clear,
    extend,
    ...props
}) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });
    const validateDebounced = useValidationDebounce(validate);

    return (
        <Field<string> validate={validateDebounced} validateFields={[]} {...props}>
            {({ input, meta }) => (
                <FormItem
                    {...{
                        input,
                        meta,
                        required,
                        help,
                        fluid,
                        label,
                    }}
                >
                    <Input.TextArea
                        id={input.name}
                        className={styles.textarea}
                        disabled={props.disabled}
                        placeholder={placeholder}
                        {...textareaProps}
                        {...input}
                    />
                </FormItem>
            )}
        </Field>
    );
};

export default TextAreaField;

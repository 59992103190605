import type { ReactNode } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { generatePath, Link } from 'react-router-dom';
import { toNumber } from 'lodash';

import { useFelaEnhanced } from 'hooks';
import { LabeledValue, Panel, Button } from 'modules/ui';
import config from 'config';
import { useCurrency } from 'modules/currency';
import type { components } from 'api/partnerApiSchema';
import type { ParcelSize } from '../../constants';
import { parcelSizeName } from '../../constants';

import * as felaRules from './ParcelSummary.rules';

export interface ParcelSummaryProps {
    parcelNumber?: string | null;
    name?: string;
    value: string;
    compartmentSize?: ParcelSize;
    weight?: number | string;
    referenceNumber?: string | null;
    children?: ReactNode;
    dark?: boolean;
    payment?: components['schemas']['DeliveryPayment'];
}

const ParcelSummary = ({
    name,
    value,
    compartmentSize,
    weight,
    parcelNumber = null,
    referenceNumber = null,
    children = null,
    dark = false,
    payment,
}: ParcelSummaryProps) => {
    const { styles, rules } = useFelaEnhanced(felaRules);

    const currency = useCurrency(payment);

    return (
        <div>
            {parcelNumber !== null && (
                <Link to={config.routes.parcel.replace(':id', parcelNumber)}>
                    <LabeledValue
                        label={<FormattedMessage id="parcel.parcelNumber" />}
                        value={
                            <Link to={generatePath(config.routes.parcel, { id: parcelNumber })}>
                                <Button type={Button.Type.LINK}>{parcelNumber}</Button>
                            </Link>
                        }
                        extend={{ container: rules.parcelNumber }}
                    />
                </Link>
            )}
            <Panel dark={dark}>
                <div className={styles.panel}>
                    <LabeledValue label={<FormattedMessage id="parcel.name" />} value={name} />
                    <LabeledValue
                        label={<FormattedMessage id="parcel.value" />}
                        // eslint-disable-next-line react/style-prop-object
                        value={<FormattedNumber value={toNumber(value)} style="currency" currency={currency} />}
                    />
                    <LabeledValue
                        label={<FormattedMessage id="parcel.size" />}
                        value={
                            compartmentSize && (
                                <FormattedMessage id={`parcel.size.${parcelSizeName[compartmentSize]}`} />
                            )
                        }
                    />
                    <LabeledValue
                        label={<FormattedMessage id="parcel.weight" />}
                        value={
                            weight && (
                                <FormattedNumber
                                    value={typeof weight === 'string' ? Number.parseFloat(weight) : weight}
                                    // eslint-disable-next-line react/style-prop-object
                                    style="unit"
                                    unit="kilogram"
                                    unitDisplay="narrow"
                                />
                            )
                        }
                    />
                    {referenceNumber !== null && (
                        <LabeledValue
                            label={<FormattedMessage id="parcel.referenceNumber" />}
                            value={referenceNumber}
                        />
                    )}
                    {children}
                </div>
            </Panel>
        </div>
    );
};

export default ParcelSummary;

import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    marginTop: theme.metrics.spacing * 5,
});

export const topMargin: TRuleWithTheme = ({ theme: { metrics } }) => ({
    '&.ant-typography': {
        marginTop: metrics.spacing * 1.5,
    },
});

export const boxnowMapWidget: TRuleWithTheme = () => ({
    width: '70%',
    height: '650px',
    marginBottom: '10px',
});

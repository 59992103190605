import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { EntityKey } from 'modules/entities/constants';
import { resetEntitiesGroup } from 'modules/entities/services/actions';
import { csvImportRequest as apiActions } from '../services/actions';

export const useResetCsvImport = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch([apiActions.reset(), resetEntitiesGroup(EntityKey.CSV_IMPORT)]);
    }, [dispatch]);
};

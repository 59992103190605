import { createSelector } from '@reduxjs/toolkit';

import { locationSelector } from 'services/selectors';
import { isOneOf } from 'types/guards';
import { parcelStates, paymentModes, paymentStates } from '../config';
import { ParcelFilter } from '../constants';

import { parseDate } from './utils';
import type { ParcelFilterApiParams, ParcelFilterValues } from '../types';

const normalizeFilter = <F extends ParcelFilter>(
    filterName: F,
    value: string | null,
): ParcelFilterValues[F] | undefined => {
    switch (filterName) {
        /**
         * We're not able to assure connection between ParcelFilterValues[F] and narrowed filter
         * thus we need to explicitly cast it
         */
        case ParcelFilter.STATE: {
            return isOneOf(parcelStates, value) ? (value as ParcelFilterValues[F]) : undefined;
        }
        case ParcelFilter.PAYMENT_MODE: {
            return isOneOf(paymentModes, value) ? (value as ParcelFilterValues[F]) : undefined;
        }
        case ParcelFilter.PAYMENT_STATE: {
            return isOneOf(paymentStates, value) ? (value as ParcelFilterValues[F]) : undefined;
        }
        case ParcelFilter.CREATED_FROM: {
            const date = parseDate(value);

            return date ? (date as ParcelFilterValues[F]) : undefined;
        }
        case ParcelFilter.CREATED_TO: {
            const date = parseDate(value);

            if (date) {
                return date.hour(23).minute(59).second(59) as ParcelFilterValues[F];
            }
            return undefined;
        }
        case ParcelFilter.QUERY:
            return value ? (value as ParcelFilterValues[F]) : undefined;
    }
};

export const parcelFiltersSelector = createSelector(locationSelector, location => {
    const params = new URLSearchParams(location.search);

    const filters = Object.fromEntries(
        Object.entries(ParcelFilter).map(([_, filterName]) => {
            return [filterName, normalizeFilter(filterName, params.get(filterName))];
        }),
    ) as unknown as ParcelFilterValues;

    return filters;
});

export const parcelFilterApiParamsSelector = createSelector(parcelFiltersSelector, (filters): ParcelFilterApiParams => {
    const createdFrom = filters[ParcelFilter.CREATED_FROM];
    const createdTo = filters[ParcelFilter.CREATED_TO];

    return {
        ...filters,
        [ParcelFilter.CREATED_FROM]: createdFrom ? createdFrom.valueOf() : undefined,
        [ParcelFilter.CREATED_TO]: createdTo ? createdTo.valueOf() : undefined,
    };
});

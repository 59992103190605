import type { TRuleWithTheme } from 'styles/theme';

export const tabs: TRuleWithTheme = ({ theme: { colors, metrics } }) => ({
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',

    background: colors.bgInput,
    borderRadius: 5,

    paddingTop: metrics.spacing * 0.75,
    paddingBottom: metrics.spacing * 0.75,
    paddingLeft: metrics.spacing * 0.875,
    paddingRight: metrics.spacing * 0.875,

    userSelect: 'none',
});

import type { FC } from 'react';
import { useFormState } from 'react-final-form';

import { Address } from 'modules/ui';

import { OrderSummary } from 'modules/order/modules/order-common';

import { DeliveryField, DeliveryType, ItemsField, OrderMetaField } from '../../config';
import { typeOfServiceDefault } from '../../constants';
import type { NewOrderFormValues } from '../../types';

import PickupAddress from '../PickupAddress';
import DestinationAddress from '../DestinationAddress';

export interface OrderSummaryWrapperProps {}

const OrderSummaryWrapper: FC<OrderSummaryWrapperProps> = () => {
    const { values } = useFormState<NewOrderFormValues>({ subscription: { values: true } });

    return (
        <OrderSummary
            referenceNumber={values[DeliveryField.ReferenceNumber]}
            meta={{
                typeOfService: typeOfServiceDefault,
                returnAllowed: values[OrderMetaField.AllowReturn],
                notificationEmail: values[OrderMetaField.NotificationEmail],
                invoiceValue: values[OrderMetaField.InvoiceValue],
                paymentMethod: values[OrderMetaField.PaymentMethod],
                collectAmount: values[OrderMetaField.CollectAmount],
            }}
            items={values[ItemsField.Items]}
            pickupAddress={<PickupAddress fullWidth />}
            deliveryAddress={
                values[DeliveryField.DeliveryType] === DeliveryType.Location ? (
                    <DestinationAddress destinationId={values[DeliveryField.ApmNumber]} fullWidth />
                ) : values[DeliveryField.DeliveryType] === DeliveryType.Warehouse ? (
                    <DestinationAddress destinationId={values[DeliveryField.WarehouseNumber]} fullWidth />
                ) : (
                    <Address
                        location={{
                            id: null,
                            addressLine1: values[DeliveryField.Street],
                            addressLine2: values[DeliveryField.City],
                            country: values[DeliveryField.Country],
                            postalCode: values[DeliveryField.PostalCode],
                        }}
                        fullWidth
                    />
                )
            }
            dark
        />
    );
};

export default OrderSummaryWrapper;

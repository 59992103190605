import { apiSelector } from '@ackee/redux-utils';
import type { ApiState } from '@ackee/redux-utils';
import { createSelector } from 'reselect';

import { ApiReducerKey, DEFAULT_GROUP, EntityKey } from 'modules/entities/constants';
import * as entitiesSelectors from 'modules/entities/services/selectors';
import { denormalizeCsvImport } from 'modules/entities/services/normalizr';
import type { RootState } from 'modules/core/modules/redux';
import type { ErrorIntlMessage } from 'modules/errors';

export const createCsvImportApiSelector = (state: RootState) =>
    apiSelector(state, EntityKey.CSV_IMPORT, ApiReducerKey.CREATE) as ApiState & { error: ErrorIntlMessage | null };

export const selectCsvImport = createSelector(entitiesSelectors.selectCsvImportEntities, csvImportData => {
    const denormalizedCsvImport = denormalizeCsvImport(csvImportData[DEFAULT_GROUP], {
        [EntityKey.CSV_IMPORT]: csvImportData.byId,
    });

    return denormalizedCsvImport;
});

export const selectCsvImportParcelLabelsUrl = (state: RootState) => state.csvImport.parcelLabelsUrl;

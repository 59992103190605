import { useLayoutEffect, useState } from 'react';
import countries from 'i18n-iso-countries';
import { translateSelector } from '@ackee/jerome';

import { useAppSelector } from 'hooks/redux';

export interface CountryProps {
    country: string;
}

const loadTranslations = (locale: string) =>
    import(
        /* webpackInclude: /(en|el)\.json$/, webpackMode: "lazy", webpackChunkName: "i18n-countries-[request]-[index]" */
        `i18n-iso-countries/langs/${locale}.json`
    ).then(localeCountries => {
        countries.registerLocale(localeCountries);
    });

export const Country = ({ country }: CountryProps) => {
    const [displayedCountry, setDisplayedCountry] = useState<string>('');
    const { locale } = useAppSelector(translateSelector);

    useLayoutEffect(() => {
        let countryName = country;
        const loadedLangs = countries.langs();

        const awaitTranslations = !loadedLangs.includes(locale) ? loadTranslations(locale) : Promise.resolve();

        awaitTranslations.then(() => {
            if (country?.length === 2) {
                countryName = countries.getName(country, locale) as string;
            }

            setDisplayedCountry(countryName);
        });
    }, [locale, country]);

    return <>{displayedCountry}</>;
};

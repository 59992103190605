import type { FC } from 'react';

import config from 'config';
import { csvImportType, type CsvImportType } from 'modules/entities/modules/csvImport';

import { CsvImportForm } from '../CsvImportForm';
import { CsvSwitcher } from '../CsvSwitcher';
import { CsvExamplesTextAndModal } from '../CsvExamplesTextAndModal';

interface CsvImportPageProps {
    type: CsvImportType;
}

export const CsvImportPage: FC<CsvImportPageProps> = ({ type }) => {
    return (
        <>
            <CsvSwitcher
                state="csv"
                link={
                    type === csvImportType.Warehouse
                        ? config.routes.fromWarehouseOrderForm
                        : config.routes.fromAnyApmOrderForm
                }
            />

            <CsvExamplesTextAndModal type={type} />

            <CsvImportForm type={type} />
        </>
    );
};

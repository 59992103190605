import { isTruthy } from 'types/guards';
import { PartnerPermission, useUserHasPermission } from 'modules/permissions';
import { DeliveryType } from '../config';

export const useDeliveryTypes = (): DeliveryType[] => {
    const deliveryToAddressAllowed = useUserHasPermission(PartnerPermission.ADDRESS_AS_DESTINATION);

    return (
        [DeliveryType.Location, deliveryToAddressAllowed && DeliveryType.Address, DeliveryType.Warehouse] as const
    ).filter(isTruthy);
};

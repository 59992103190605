import { createApiRequestActions } from '@ackee/redux-utils';
import type { ErrorIntlMessage } from 'modules/errors';
import type { CsvImportValues } from '../../types';
import * as types from './types';

export { types };

export const csvImportRequest = createApiRequestActions<
    CsvImportValues,
    undefined,
    { lastSuccessAt: string },
    ErrorIntlMessage
>({
    REQUEST: types.csvImport.CSV_IMPORT_REQUEST,
    SUCCESS: types.csvImport.CSV_IMPORT_SUCCESS,
    FAILURE: types.csvImport.CSV_IMPORT_FAILURE,
    CANCEL: types.csvImport.CSV_IMPORT_CANCEL,
    RESET: types.csvImport.CSV_IMPORT_RESET,
});

import { takeLatest, put, take } from '@redux-saga/core/effects';
import { push } from 'connected-react-router';

import config from 'config';
import { isFailureAction } from 'types/guards';
import { DEFAULT_GROUP, EntityKey } from 'modules/entities/constants';
import { resetEntitiesGroup } from 'modules/entities/services/actions';
import { createCustomerReturn } from 'modules/entities/modules/customerReturn';
import { actions as parcelsActions, fetchParcel } from 'modules/entities/modules/parcels';
import { showParcelLabelToast } from 'modules/parcels/modules/parcel-label';

import * as actions from '../actions';

function* customerReturnFormSubmitHandler(action: ReturnType<typeof actions.customerReturnForm.submit>) {
    const values = action.payload;

    yield put(
        createCustomerReturn.request({
            sender: {
                contactPhoneNumber: values.phone.phone ?? '',
                contactEmail: values.email,
                contactName: values.fullname,
            },
            destination: {
                locationId: values.destination,
            },
            parcels: [
                {
                    size: values.size,
                },
            ],
        }),
    );

    type SuccessAction = ReturnType<typeof createCustomerReturn.success>;
    type FailureAction = ReturnType<typeof createCustomerReturn.failure>;

    const result: SuccessAction | FailureAction = yield take([
        createCustomerReturn.success.toString(),
        createCustomerReturn.failure.toString(),
    ]);

    if (!isFailureAction<SuccessAction, FailureAction>(result)) {
        yield put([
            actions.customerReturnForm.submitSuccess(),

            parcelsActions.fetchParcels.reset(DEFAULT_GROUP),
            resetEntitiesGroup(EntityKey.PARCELS),
        ]);

        yield put(push(config.routes.home, null));

        const parcel = yield* fetchParcel(result.payload.parcelId);

        if (parcel) {
            yield put(showParcelLabelToast(parcel.id));
        }
    } else {
        yield put(actions.customerReturnForm.submitFailure(result.payload.error));
    }
}

export default function* submitCustomerReturnForm() {
    yield takeLatest(actions.customerReturnForm.submit, customerReturnFormSubmitHandler);
}

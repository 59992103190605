import { createApiRequestType } from '@ackee/redux-utils';
import type { ApiActionTypes } from '@ackee/redux-utils';
import { EntityKey } from 'modules/entities/constants';

export const modulePrefix = EntityKey.PARCELS;

const apiRequestType = createApiRequestType({
    modulePrefix,
});

export const fetchParcels = apiRequestType({}) as unknown as ApiActionTypes;

export const cancelParcelDelivery = apiRequestType({
    types: ['REQUEST', 'SUCCESS', 'FAILURE', 'RESET'] as const,
    typePrefix: 'CANCEL_',
});

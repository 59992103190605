import { combineReducers } from 'redux';
import { ApiReducerKey } from 'modules/entities/constants';

import update from './update';
import create from './create';

export default combineReducers({
    [ApiReducerKey.UPDATE]: update,
    [ApiReducerKey.CREATE]: create,
});

import { CardLayout } from 'modules/layout';
import { Loader } from 'modules/ui';
import { useEntrustedPartners } from 'modules/entities/modules/entrustedPartners/hooks';

import { ChoosePartnerForm } from '../ChoosePartnerForm';
import { NoPartners } from '../NoPartners';
import { ChoosePartnerAutomatically } from '../ChoosePartnerAutomatically';
import { ErrorMessage } from 'modules/errors';

export const ChoosePartnerPage = () => {
    const { entrustedPartners, api } = useEntrustedPartners();

    return (
        <CardLayout>
            <Loader show={api.inProgress}>
                <ErrorMessage error={api.error}>
                    {entrustedPartners.length === 0 && <NoPartners />}
                    {entrustedPartners.length === 1 && <ChoosePartnerAutomatically partner={entrustedPartners[0]} />}
                    {entrustedPartners.length > 1 && <ChoosePartnerForm />}
                </ErrorMessage>
            </Loader>
        </CardLayout>
    );
};

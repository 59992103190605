import type { FC, ReactNode } from 'react';
import { useFelaEnhanced } from 'hooks';

import * as felaRules from './NewOrderSection.rules';

export interface NewOrderSectionProps {
    visible?: boolean;
    children: ReactNode;
}

const NewOrderSection: FC<NewOrderSectionProps> = ({ visible = true, children }) => {
    const { styles } = useFelaEnhanced(felaRules, { visible });

    return <section className={styles.section}>{children}</section>;
};

export default NewOrderSection;

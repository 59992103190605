import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

type State = string | null;

const exportUrlSlice = createSlice({
    name: 'exportUrl',
    initialState: null as State,

    reducers: {
        setExportUrl: (_, action: PayloadAction<State>) => action.payload,
    },
});

export const { reducer } = exportUrlSlice;
export const { setExportUrl } = exportUrlSlice.actions;

import type { TRuleWithTheme } from 'styles/theme';

export const button: TRuleWithTheme = ({ theme }) => ({
    marginBottom: theme.metrics.spacing * 2,
});

export const icon: TRuleWithTheme = () => ({
    width: 20,
    height: 20,
});

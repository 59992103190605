import { useFelaEnhanced } from 'hooks';
import { noop } from 'lodash';
import type { UseFieldConfig } from 'react-final-form';
import { useField } from 'react-final-form';
import type { RulesExtend } from 'styles/theme';
import { FileInput } from '../FileInput';
import FormItem from '../FormItem';

import * as felaRules from './FileField.rules';

export interface FileFieldProps {
    extend?: RulesExtend<typeof felaRules>;
    name: string;
    field?: Omit<UseFieldConfig<File>, 'type' | 'value'>;
}

export const FileField = ({ extend, name, field }: FileFieldProps) => {
    const { rules, styles } = useFelaEnhanced(felaRules, { extend });
    const { input, meta } = useField<File>(name, {
        type: 'file',
    });

    return (
        <FormItem<File> input={input} meta={meta} required className={styles.formItem}>
            <FileInput
                {...field}
                accept={'.csv'}
                multiple={false}
                loading={meta.submitting}
                disabled={meta.submitting}
                /* @ts-expect-error */
                fileList={[input.value].filter(Boolean)}
                // Upload request is made in saga
                customRequest={noop}
                onRemove={() => {
                    input.onChange(null);
                }}
                onChange={e => {
                    input.onChange(e.file.originFileObj);
                }}
                onFileRemove={() => {
                    input.onChange(null);
                }}
                extend={{ dragger: rules.fileInput }}
            />
        </FormItem>
    );
};

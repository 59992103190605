import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import type { IntlMessage } from 'types';

import { Button } from '../Button';
import type { ButtonProps } from '../Button';
import type { ButtonType } from '../Button/types';

export interface DownloadButtonProps extends Pick<ButtonProps, 'extend'> {
    url: string;
    text: IntlMessage;
    type?: ButtonType;
    icon?: ReactNode;
    forceDownload?: boolean;
}

export const DownloadButton = ({
    url,
    type = Button.Type.SECONDARY,
    text,
    icon,
    forceDownload = false,
    extend,
}: DownloadButtonProps) => {
    return (
        <a href={url} target="_blank" rel="noreferrer noopener" download={forceDownload}>
            <Button type={type} icon={icon} extend={extend}>
                <FormattedMessage {...text} />
            </Button>
        </a>
    );
};

import type { TRuleWithTheme } from 'styles/theme';

export const fileInput: TRuleWithTheme = () => ({
    maxWidth: 555,
    width: '100%',
});

export const formItem: TRuleWithTheme = () => ({
    '& .ant-upload-list': {
        display: 'none',
    },
});

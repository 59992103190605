import type { TRuleWithTheme } from 'styles/theme';

export const form: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
});

export const button: TRuleWithTheme = ({ theme }) => ({
    margin: `${theme.metrics.spacing * 2}px 0`,
    alignSelf: 'flex-start',
});

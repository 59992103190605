import { normalize } from 'normalizr';
import type { Schema as NormalizerScheama } from 'normalizr';

import * as schemas from './schemas';
import type { EntityKey } from '../../constants';
import type {
    Entities,
    Location,
    Destination,
    DeliveryPartner,
    Parcel,
    CsvImport,
    EntityId,
    Partner,
} from '../../types';

interface NormalizrResult<Entity extends Entities, Result> {
    result: Result;
    entities: Record<EntityKey, Record<EntityId, Entity>>;
}

const normalizeFactory =
    <Entity extends Entities, Result extends Array<EntityId> | EntityId>(schema: NormalizerScheama) =>
    <Data>(data: Data): NormalizrResult<Entity, Result> =>
        normalize(data, schema);

export const normalizeLocations = normalizeFactory<Location, Array<Location['id']>>(schemas.locationListSchema);

export const normalizeDestinations = normalizeFactory<Location, Array<Destination['id']>>(
    schemas.destinationListSchema,
);

export const normalizeDeliveryPartners = normalizeFactory<Location, Array<DeliveryPartner['id']>>(
    schemas.deliveryPartnerListSchema,
);

export const normalizeEntrustedPartners = normalizeFactory<Partner, Array<Partner['id']>>(
    schemas.entrustedPartnerListSchema,
);

export const normalizeCsvImport = normalizeFactory<CsvImport, Array<CsvImport['id']>>(schemas.csvImportListSchema);

export const normalizeParcels = normalizeFactory<Parcel, Array<Parcel['id']>>(schemas.parcelListSchema);

import type { FieldValidator } from './types';
import type { PhoneFieldValue } from '../../../components/PhoneField';
import { isPossiblePhoneNumber } from 'libphonenumber-js';

export const required: FieldValidator<string | number | boolean | Array<any>> = value => {
    if (
        value === undefined ||
        value === null ||
        String(value).trim() === '' ||
        (Array.isArray(value) && value.length === 0)
    ) {
        return false;
    }

    return true;
};

export const minLength =
    (min: number): FieldValidator =>
    (value = '') =>
        value.length >= min;

export const maxLength =
    (max: number): FieldValidator =>
    (value = '') =>
        value.length < max;

export const phoneNumber: FieldValidator = (value?: PhoneFieldValue) => isPossiblePhoneNumber(value?.phone ?? '');

// Source of the regex: https://stackoverflow.com/a/9204568/12338694
export const email: FieldValidator = (value = '') => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(String(value).toLowerCase());

export const greaterThan =
    (lowerBoundary: number): FieldValidator =>
    (value: number) =>
        value > lowerBoundary;

export const greaterThanOrEqual =
    (lowerBoundary: number): FieldValidator =>
    (value: string) => {
        if (value === undefined) return true;

        const number = Number(value);
        if (Number.isNaN(number)) return false;

        return number >= lowerBoundary;
    };

import { FormattedMessage } from 'react-intl';

import { SelectField } from 'modules/form';
import type { CsvImportValues } from 'modules/entities/modules/csvImport';
import type { FormFieldProps } from 'modules/form/components/types';

type DelimiterValue = NonNullable<CsvImportValues['delimiter']>;

export interface CsvDelimiterSelectProps extends FormFieldProps<DelimiterValue> {
    name: string;
}

const delimiters = [
    { value: ',', label: 'comma' },
    { value: ';', label: 'semicolon' },
] as const satisfies readonly { value: DelimiterValue; label: string }[];

export const CsvDelimiterSelect = ({ name, ...rest }: CsvDelimiterSelectProps) => {
    return (
        <SelectField<DelimiterValue>
            name={name}
            label={<FormattedMessage id="order.csvImport.input.delimiter.label" />}
            defaultValue={','}
            {...rest}
        >
            {delimiters.map(delimiter => (
                <SelectField.Option key={delimiter.value} value={delimiter.value}>
                    <FormattedMessage id={`order.csvImport.input.delimiter.option.${delimiter.label}`} />
                </SelectField.Option>
            ))}
        </SelectField>
    );
};

import type { Parcel } from 'modules/entities';
import { ParcelRowType, ParcelItem } from '../../types';
import { parcelRowHeight } from '../../config';

function calcParcelItemHeight(rowTypes: ParcelRowType[]): number {
    return rowTypes.reduce((total, rowType) => total + parcelRowHeight[rowType], 0);
}

export function figureOutParcelRowTypes(data: Parcel[]): ParcelItem[] {
    let isNewOrderGroup = true;

    return data.map((parcel, index) => {
        const rowTypes = [];

        if (isNewOrderGroup && index > 0) {
            rowTypes.push(ParcelRowType.Separator);
        }

        rowTypes.push(ParcelRowType.Item);

        const orderNumber = parcel.deliveryRequest.orderNumber || null;
        const nextOrderNumber = data[index + 1]?.deliveryRequest.orderNumber || null;
        isNewOrderGroup = orderNumber !== nextOrderNumber;

        return {
            ...parcel,
            rowTypes: new Set(rowTypes),
            height: calcParcelItemHeight(rowTypes),
        };
    });
}

import type { AnyAction } from '@reduxjs/toolkit';
import { PageType } from 'modules/entities/modules/parcels/types';

// TODO: maybe remove this reducer factory in favor of @ackee/redux-utils
export const UNUSED_ACTION_TYPE = Symbol('UNUSED_ACTION_TYPE');

export interface TokenPaginationApiState {
    inProgress: boolean;
    error: null | any;
    success: boolean;
    firstSuccessAt: null | string;
    lastSuccessAt: null | string;
    totalCount: number;
    currentCount: number;
    [PageType.FIRST]: string | null;
    [PageType.PREVIOUS]: string | null;
    [PageType.CURRENT]: string | null;
    [PageType.NEXT]: string | null;
    [PageType.LAST]: string | null;
    pageNumber: number;
    limit: number;
    cancelled: boolean;
}

interface TokenPaginationApiReducerParams {
    actionTypes: {
        REQUEST: string;
        CANCEL?: string;
        SUCCESS: string;
        FAILURE: string;
        RESET?: string;
    };
    selectors: {
        totalCount?: (action: any) => number;
        getPageToken: (action: any, pageType: PageType) => any;
        currentCount?: (action: any) => any;
        hasMore?: (action: any) => boolean;
        lastSuccessAt?: (action: any) => number | string;
    };
    initialState?: Partial<TokenPaginationApiState>;
}

const config = {
    actionTypes: {
        REQUEST: UNUSED_ACTION_TYPE,
        CANCEL: UNUSED_ACTION_TYPE,
        SUCCESS: UNUSED_ACTION_TYPE,
        FAILURE: UNUSED_ACTION_TYPE,
        RESET: UNUSED_ACTION_TYPE,
    },
    initialState: {
        inProgress: false,
        error: null,
        success: false,
        firstSuccessAt: null,
        lastSuccessAt: null,
        currentCount: 0,
        totalCount: 0,
        [PageType.FIRST]: null,
        [PageType.PREVIOUS]: null,
        [PageType.CURRENT]: null,
        [PageType.NEXT]: null,
        [PageType.LAST]: null,
        pageNumber: 1,
        hasMore: true,
        limit: 9,
        cancelled: false,
    } as TokenPaginationApiState,
    selectors: {
        totalCount: (action: AnyAction) => action.meta.totalCount,
        currentCount: (action: AnyAction) => action.meta.currentCount,
        pageNumber: (action: AnyAction) => action.meta.pageNumber,
        getPageToken: (action: AnyAction, pageType: PageType) => action.meta[pageType],
        hasMore: (action: AnyAction) => action.meta.hasMore,
        lastSuccessAt: (action: AnyAction) => action.meta.lastSuccessAt,
    },
};

const getParams = (customParams: TokenPaginationApiReducerParams) => {
    return {
        actionTypes: {
            ...config.actionTypes,
            ...customParams.actionTypes,
        },
        initialState: {
            ...config.initialState,
            ...customParams.initialState,
        },
        selectors: {
            ...config.selectors,
            ...customParams.selectors,
        },
    };
};

export default function tokenPaginationReducerFactory(customParams: TokenPaginationApiReducerParams) {
    const { actionTypes: types, initialState, selectors } = getParams(customParams);

    function tokenPaginationReducer(state = initialState, action: AnyAction) {
        switch (action.type) {
            case types.REQUEST:
                return {
                    ...state,
                    error: initialState.error,
                    inProgress: true,
                    success: false,
                    cancelled: false,
                };

            case types.SUCCESS: {
                const totalCount = selectors.totalCount(action);
                const currentCount = selectors.currentCount(action);
                const pageNumber = selectors.pageNumber(action);
                const firstPageToken = selectors.getPageToken(action, PageType.FIRST);
                const previousPageToken = selectors.getPageToken(action, PageType.PREVIOUS);
                const currentPageToken = selectors.getPageToken(action, PageType.CURRENT);
                const nextPageToken = selectors.getPageToken(action, PageType.NEXT);
                const lastPageToken = selectors.getPageToken(action, PageType.LAST);
                const lastSuccessAt = selectors.lastSuccessAt(action);

                const newState = {
                    ...state,
                    inProgress: false,
                    success: true,
                    lastSuccessAt,
                    currentCount,
                    pageNumber,
                    firstPageToken,
                    previousPageToken,
                    currentPageToken,
                    nextPageToken,
                    lastPageToken,
                    totalCount,
                };

                if (!newState.firstSuccessAt) {
                    newState.firstSuccessAt = lastSuccessAt;
                    return newState;
                }

                return newState;
            }

            case types.FAILURE: {
                const { error = initialState.error } = action;

                return {
                    ...state,
                    inProgress: false,
                    error,
                };
            }

            case types.CANCEL:
                return {
                    ...state,
                    inProgress: false,
                    cancelled: true,
                };

            case types.RESET:
                return initialState;

            default:
                return state;
        }
    }

    tokenPaginationReducer.INITIAL_STATE = initialState;

    return tokenPaginationReducer;
}

import { uniq, difference, isEmpty } from 'lodash';
import { createReducer } from '@reduxjs/toolkit';
import { containerReducer } from '@ackee/redux-utils';

import type { EntityId, EntityState } from '../../types';
import {
    setEntities,
    setEntitiesGroup,
    removeEntity,
    updateEntities,
    unsetEntitiesGroup,
    resetEntitiesGroup,
    resetEntities,
} from '../actions';
import type {
    SetEntitiesAction,
    SetEntitiesGroupAction,
    UpdateEntitiesAction,
    UnsetEntitiesGroupAction,
    ResetEntitiesGroupAction,
} from '../actions';

export const childInitialState: EntityState = {
    byId: {},
    ids: [],
};

const setEntitiesByIdReducer = (state: EntityState['byId'], action: SetEntitiesAction | SetEntitiesGroupAction) => {
    const { byId } = action.payload;

    if (isEmpty(byId)) {
        return state;
    }

    return {
        ...state,
        ...byId,
    };
};

const byIdReducer = createReducer({} as EntityState['byId'], builder =>
    builder
        .addCase(setEntities, setEntitiesByIdReducer)
        .addCase(setEntitiesGroup, setEntitiesByIdReducer)
        .addCase(removeEntity, (state, action) => {
            const { id } = action.payload;
            const nextById = { ...state };

            delete nextById[id];

            return nextById;
        })
        .addCase(updateEntities, (state, action) => {
            const updatedEntries = Object.entries(action.payload.byId)
                .filter(([id]) => state[id] !== undefined)
                .map(([id, update]) => [
                    id,
                    {
                        ...state[id],
                        ...update,
                    },
                ]);

            return {
                ...state,
                ...Object.fromEntries(updatedEntries),
            };
        }),
);

const groupReducer = createReducer([] as Array<EntityId>, builder =>
    builder
        .addCase(setEntitiesGroup, (state, action) => {
            const { ids } = action.payload;
            const { strategy } = action.meta;

            switch (strategy) {
                case 'replace': {
                    return ids;
                }
                case 'append': {
                    if (!ids || isEmpty(ids)) {
                        return state;
                    }

                    return uniq([...state, ...ids]);
                }
                default: {
                    return state;
                }
            }
        })
        .addCase(unsetEntitiesGroup, (state, action) => difference(state, action.payload.ids ?? []))
        .addCase(resetEntitiesGroup, () => []),
);

const resetChildReducer = (state: EntityState, action: UnsetEntitiesGroupAction | ResetEntitiesGroupAction) => {
    const { group } = action.meta;

    if (!state[group]) {
        return state;
    }

    return {
        ...state,
        [group]: groupReducer(state[group], action),
    };
};

function upsertEntities(state: EntityState, action: SetEntitiesAction | UpdateEntitiesAction) {
    if (isEmpty(action.payload.byId)) {
        return state;
    }

    return {
        ...state,
        byId: byIdReducer(state.byId, action),
    };
}

const childReducer = createReducer(childInitialState, builder =>
    builder
        .addCase(setEntities, upsertEntities)
        .addCase(updateEntities, upsertEntities)
        .addCase(setEntitiesGroup, (state, action) => {
            const { group } = action.meta;
            const { byId, ids } = action.payload;

            if (isEmpty(byId) && isEmpty(ids)) {
                return state;
            }

            return {
                ...state,
                byId: byIdReducer(state.byId, action),
                [group]: groupReducer(state[group] as string[], action),
            };
        })
        .addCase(unsetEntitiesGroup, resetChildReducer)
        .addCase(resetEntitiesGroup, resetChildReducer)
        .addCase(removeEntity, (state, action) => {
            const { group } = action.meta;

            return {
                ...state,
                byId: byIdReducer(state.byId, action),
                [group]: state[group].filter((id: string) => id !== action.payload.id),
            };
        })
        .addCase(resetEntities, () => childInitialState),
);

export default containerReducer({
    initialState: {},
    actionTypes: [
        setEntities.toString(),
        setEntitiesGroup.toString(),
        removeEntity.toString(),
        updateEntities.toString(),
        unsetEntitiesGroup.toString(),
        resetEntitiesGroup.toString(),
        resetEntities.toString(),
    ],
    childReducer,
    selectors: {
        itemId: action => action.meta.entityKey,
    },
    options: {
        placeholder: false,
    },
});

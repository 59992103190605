import config from 'config';
import { PartnerPermission } from '../constants';

export const protectedPages: Record<string, ReadonlyArray<PartnerPermission>> = {
    [config.routes.fromAnyApmOrder]: [PartnerPermission.ANY_APM_AS_ORIGIN],
    [config.routes.fromAnyToSameApmOrder]: [
        PartnerPermission.ANY_APM_AS_ORIGIN,
        PartnerPermission.ANY_APM_TO_SAME_APM_DELIVERY,
    ],
    [config.routes.fromWarehouseOrder]: [PartnerPermission.WAREHOUSE_AS_ORIGIN],
    [config.routes.fromWarehouseToWarehouseOrder]: [PartnerPermission.WAREHOUSE_AS_ORIGIN],
    [config.routes.newOrder]: [PartnerPermission.CUSTOM_FORM],
} as const;

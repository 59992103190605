import type { ReactNode } from 'react';

import { ConnectedRouter } from 'connected-react-router';
import { history } from '../config';

interface RouterProps {
    children: ReactNode;
}

function Router({ children }: RouterProps) {
    return <ConnectedRouter history={history}>{children}</ConnectedRouter>;
}

export default Router;

import type { Parcel } from 'modules/entities';
import { Separator } from 'modules/ui';

import Header from './Header';
import History from './History';
import Order from './Order';

export interface ParcelDetailProps {
    data: Parcel;
}

const ParcelDetail = ({ data }: ParcelDetailProps) => {
    const { id, deliveryRequest, state, events, parcelLabelUrl, cancelationRequested } = data;

    return (
        <>
            <Header
                parcelLabelUrl={parcelLabelUrl}
                deliveryRequest={deliveryRequest}
                state={state}
                id={id}
                cancelationRequested={cancelationRequested}
            />
            <Separator spacing={4} />
            <History events={events} />
            <Order data={data} />
        </>
    );
};

export default ParcelDetail;

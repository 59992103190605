import { FormattedMessage } from 'react-intl';

import { useFelaEnhanced } from 'hooks';
import { Select } from 'modules/form';
import { LabeledValue } from 'modules/ui';

import { ALL } from '../../constants';
import type { ParcelState } from '../../types';
import { parcelStates } from '../../config';

import * as felaRules from './ParcelStatus.rules';

interface ParcelStatusProps {
    onChange?: (state: ParcelState | undefined) => void;
    value?: ParcelState;
}

export const ParcelStatus = ({ onChange, value }: ParcelStatusProps) => {
    const { rules } = useFelaEnhanced(felaRules);

    return (
        <LabeledValue
            label={<FormattedMessage id="parcel.filter.status" />}
            value={
                <Select
                    dropdownMatchSelectWidth={false}
                    value={value ?? ALL}
                    extend={{ select: rules.select }}
                    onChange={status => {
                        onChange?.(status === ALL ? undefined : status);
                    }}
                    bordered={false}
                >
                    <Select.Option value={ALL}>
                        <FormattedMessage id={`general.all`} />
                    </Select.Option>

                    {parcelStates.map(state => (
                        <Select.Option value={state} key={state}>
                            <FormattedMessage id={`parcel.state.${state}`} />
                        </Select.Option>
                    ))}
                </Select>
            }
        />
    );
};

import { isTruthy } from 'types/guards';
import { PartnerPermission, useUserHasPermission } from 'modules/permissions';
import type { LocationType } from 'modules/entities/types';

export const useDeliveryLocationTypes = (): LocationType[] => {
    const anyApmAsOriginAllowed = useUserHasPermission(PartnerPermission.ANY_APM_AS_ORIGIN);
    const anyApmToSameApmDeliveryAllowed = useUserHasPermission(PartnerPermission.ANY_APM_TO_SAME_APM_DELIVERY);

    const canDeliverToAnyApm = anyApmAsOriginAllowed && anyApmToSameApmDeliveryAllowed;

    return (['apm', canDeliverToAnyApm && 'any-apm'] as const).filter(isTruthy);
};

import type { ReactNode } from 'react';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles';

import * as felaRules from './PanelHeader.rules';

export interface PanelHeaderProps {
    children: ReactNode;
    extend?: RulesExtend<typeof felaRules>;
}

export const PanelHeader = ({ children, extend }: PanelHeaderProps) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });

    return <div className={styles.header}>{children}</div>;
};

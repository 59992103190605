import type { TRuleWithTheme } from 'styles/theme';

export const link: TRuleWithTheme = ({ theme: { colors } }) => ({
    display: 'block',
    textDecoration: 'underline',
    color: colors.buttonEnabledText,
    fontWeight: 700,
});

export const icon: TRuleWithTheme = ({ theme: { colors, metrics } }) => ({
    width: 48,
    height: 48,
    color: colors.black,
    marginBottom: metrics.spacing * 2,
});

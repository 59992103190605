import type { FC, ReactNode } from 'react';
import { Field } from 'react-final-form';
import { RemoveFields } from '../RemoveFields';

export interface ConditionProps {
    when: string;
    is: any;
    children: ReactNode;
    removeFieldsWhenIsNot?: string[];
}

/**
 *  There is a bug with conditional fields in Final Form, we need to upgrade as soon as it is fixed
 *  https://github.com/final-form/react-final-form/issues/899
 */
const Condition: FC<ConditionProps> = ({ when, is, removeFieldsWhenIsNot, children }) => {
    return (
        <Field name={when} subscription={{ value: true }}>
            {({ input: { value } }) => (value === is ? children : <RemoveFields fields={removeFieldsWhenIsNot} />)}
        </Field>
    );
};

export default Condition;

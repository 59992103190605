import { toast } from 'react-toastify';
import type { TypeOptions, ToastOptions } from 'react-toastify';

import Toast from './components/Toast';
import type { ToastProps } from './components/Toast';
import { DEFAULT_CONTAINER_ID } from './constants';

function createToast(type: TypeOptions) {
    return (params: Omit<ToastProps, 'type'>, options?: ToastOptions) =>
        toast(<Toast {...params} type={type} />, {
            position: 'bottom-left',
            ...options,
            type,
            containerId: DEFAULT_CONTAINER_ID,
        });
}

export const errorToast = createToast('error');

export const successToast = createToast('success');

export const infoToast = createToast('info');

export { toast, Toast };
export type { ToastProps };

export { DEFAULT_CONTAINER_ID };

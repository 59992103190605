import type { TRuleWithTheme, Theme } from 'styles/theme';
import type { ModalProps } from './Modal';

const BORDER_RADIUS = 10;

export const modal: TRuleWithTheme<Required<Pick<ModalProps, 'closeButtonSize'>>> = ({
    theme: { metrics, colors },
    closeButtonSize,
}) => ({
    '& .ant-modal-content': {
        borderRadius: BORDER_RADIUS,

        '& .ant-modal-header': {
            borderTopLeftRadius: BORDER_RADIUS,
            borderTopRightRadius: BORDER_RADIUS,
            borderBottom: 'none',

            paddingTop: metrics.spacing * 4,
            paddingBottom: metrics.spacing * 1.5,
            paddingLeft: metrics.spacing * 3.5,
            paddingRight: metrics.spacing * 3.5,

            fontSize: '1.25rem',
            fontWeight: 700,
            color: colors.fgPrimary,

            '& .ant-modal-title': {
                fontSize: '1em',
            },
        },

        '& .ant-modal-close-x': {
            padding: metrics.spacing * 2,
            width: closeButtonSize + metrics.spacing * 4,
            height: closeButtonSize + metrics.spacing * 4,
            lineHeight: `${closeButtonSize}px`,
        },

        '& .ant-modal-body': {
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: metrics.spacing * 3.5,
            paddingRight: metrics.spacing * 3.5,

            fontSize: '1rem',
            color: colors.fgPrimary,
        },

        '& .ant-modal-footer': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',

            borderTop: 'none',

            paddingTop: metrics.spacing * 4,
            paddingBottom: metrics.spacing * 4,
            paddingLeft: metrics.spacing * 3.5,
            paddingRight: metrics.spacing * 3.5,
        },
    },
});

export const mask = ({ colors }: Theme) => ({
    backgroundColor: colors.accentSecondary30,
});

export const closeIcon: TRuleWithTheme<Required<Pick<ModalProps, 'closeButtonSize'>>> = ({ closeButtonSize }) => ({
    width: closeButtonSize,
    height: closeButtonSize,
});

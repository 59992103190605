export enum ParcelSize {
    Small = 1,
    Medium,
    Large,
}

export const parcelSizeName = {
    [ParcelSize.Small]: 'small',
    [ParcelSize.Medium]: 'medium',
    [ParcelSize.Large]: 'large',
} as const;

import { useMemo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Field, useForm } from 'react-final-form';
import sortBy from 'lodash/sortBy';
import { SelectField } from 'modules/form';
import type { Destination, LocationType } from 'modules/entities/types';
import DestinationAddress from '../DestinationAddress';
import * as felaRules from './DeliveryLocation.rules';
import { useDestinations } from '../../hooks';
import { useFelaEnhanced } from 'hooks';
import type { MessageKey } from 'translations';

export interface DeliveryLocationFieldsWithDropdownProps {
    name: string;
    label: MessageKey;
    placeholder: MessageKey;
    locationTypes: LocationType[];
}

const OPTION_FILTER_PROP = `data-filter-value`;

const DeliveryLocationFieldsWithDropdown = ({
    name,
    label,
    placeholder,
    locationTypes,
}: DeliveryLocationFieldsWithDropdownProps) => {
    const { formatMessage } = useIntl();
    const { rules } = useFelaEnhanced(felaRules);
    const { destinations, api } = useDestinations(locationTypes);
    const form = useForm();

    const sortedDestinations = useMemo(() => sortBy(destinations, ({ id }) => Number(id)), [destinations]);

    // Reset the field value when switching away
    useEffect(() => {
        return () => {
            form.change(name, undefined); // Reset the field value on unmount
        };
    }, [form, name]);

    return (
        <>
            <SelectField<Destination['id']>
                name={name}
                label={formatMessage({ id: label })}
                placeholder={formatMessage({ id: placeholder })}
                loading={api.inProgress}
                extend={{
                    formItem: rules.formItem,
                }}
                showSearch
                optionFilterProp={OPTION_FILTER_PROP}
                required
            >
                {sortedDestinations.map(({ id, displayName, filterValue }) => (
                    <SelectField.Option key={id} {...{ value: id, [OPTION_FILTER_PROP]: filterValue }}>
                        {displayName}
                    </SelectField.Option>
                ))}
            </SelectField>
            <Field name={name} subscription={{ value: true }}>
                {({ input: { value } }) =>
                    value ? <DestinationAddress destinationId={value} extend={{ panel: rules.address }} /> : null
                }
            </Field>
        </>
    );
};

export default DeliveryLocationFieldsWithDropdown;

import { useState } from 'react';
import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { useFelaEnhanced } from 'hooks';
import { Layout, PageHeader } from 'modules/layout';
import { BackButton, Panel, Steps } from 'modules/ui';

import { NewOrderStep } from '../../constants';
import NewOrderForm from '../NewOrderForm';

import * as felaRules from './NewOrderPage.rules';
import config from 'config';

const steps = [
    NewOrderStep.Pickup,
    NewOrderStep.Delivery,
    NewOrderStep.Items,
    NewOrderStep.Order,
    NewOrderStep.Summary,
].map(step => ({
    id: step,
    title: <FormattedMessage id={`order.new.step.${step}`} />,
}));

export interface NewOrderPageProps {}

const NewOrderPage: FC<NewOrderPageProps> = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const isFirstStep = currentStep === 0;

    const { rules } = useFelaEnhanced(felaRules);

    return (
        <Layout>
            <PageHeader title={<FormattedMessage id="order.new.title" />} horizontal>
                {isFirstStep && (
                    <Link to={config.routes.home}>
                        <BackButton text={{ id: 'order.new.back' }} />
                    </Link>
                )}
            </PageHeader>

            <Panel extend={{ panel: rules.panel }} composed>
                <Panel.Header>
                    <Steps
                        steps={steps}
                        current={currentStep}
                        extend={{ step: rules.step }}
                        onFinishedStepClick={stepIndex => setCurrentStep(stepIndex)}
                    />
                </Panel.Header>
                <Panel.Body extend={{ body: rules.panelBody }}>
                    {currentStep > 0 && (
                        <BackButton
                            extend={{ button: rules.backButton }}
                            onClick={() => setCurrentStep(currentStep - 1)}
                        />
                    )}

                    <NewOrderForm step={steps[currentStep]?.id} onNextStep={() => setCurrentStep(currentStep + 1)} />
                </Panel.Body>
            </Panel>
        </Layout>
    );
};

export default NewOrderPage;

import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, Link } from 'react-router-dom';

import { useFelaEnhanced } from 'hooks';
import config from 'config';
import type { Parcel } from 'modules/entities';
import { Headline, Panel, Button } from 'modules/ui';
import type { PaymentMethod } from 'modules/order/modules/order-common/config';
import OrderMeta from 'modules/order/modules/order-common/components/OrderMeta';
import { ParcelRowHeader } from 'modules/parcels/modules/parcel-common';

import { GRID_COLUMNS } from '../../../config';
import { OrderParcels } from '../OrderParcels';

import * as felaRules from './Order.rules';

export interface OrderProps {
    data: Parcel;
}

const Order: FC<OrderProps> = ({ data: { id, payment, deliveryRequest, allowReturn } }) => {
    const { rules, styles } = useFelaEnhanced(felaRules);
    const { typeOfService, paymentMode, invoiceValue, amountToBeCollected, notifyOnAccepted, orderNumber } =
        deliveryRequest;

    return (
        <div className={styles.container}>
            <Headline level={2}>
                <FormattedMessage id="parcel.order.title" />
            </Headline>
            <Panel extend={{ panel: rules.metaPanel }}>
                <OrderMeta
                    {...{
                        orderNumber: deliveryRequest.orderNumber,
                        typeOfService,
                        returnAllowed: allowReturn,
                        paymentMethod: paymentMode as PaymentMethod,
                        invoiceValue: invoiceValue,
                        collectAmount: amountToBeCollected,
                        notificationEmail: notifyOnAccepted,
                        enableReturnAction: !allowReturn,
                        parcelId: id,
                        payment: payment,
                    }}
                />
                <Link to={generatePath(config.routes.orderDetail, { id: orderNumber })}>
                    <Button type={Button.Type.SECONDARY}>
                        <FormattedMessage id="parcel.order.link" />
                    </Button>
                </Link>
            </Panel>

            <Panel composed>
                <Panel.Header>
                    <ParcelRowHeader columnsTemplate={GRID_COLUMNS} showOrder={false} showCreated={false} />
                </Panel.Header>
                <Panel.Body>
                    <OrderParcels orderNumber={orderNumber} withoutParcelId={id} />
                </Panel.Body>
            </Panel>
        </div>
    );
};

export default Order;

import { createApiActions } from '@ackee/redux-utils';
import type { paths } from 'api/partnerApiSchema';
import type { Parcel } from 'modules/entities';
import type { ErrorIntlMessage } from 'modules/errors';

export const checkAddressDelivery = createApiActions<
    'CHECK_ADDRESS_DELIVERY',
    { address: string; radius: number },
    { lastSuccessAt: string; apmDistance: number },
    { error: ErrorIntlMessage }
>('CHECK_ADDRESS_DELIVERY');

type CustomerReturnData =
    paths[`/api/v1/delivery-requests:customerReturns`]['post']['requestBody']['content']['application/json'];

export const createCustomerReturn = createApiActions<
    'CUSTOMER_RETURN',
    CustomerReturnData,
    { lastSuccessAt: string; parcelId: Parcel['id'] },
    { error: ErrorIntlMessage }
>('CUSTOMER_RETURN');

import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({
    theme: {
        metrics: { spacing },
    },
}) => ({
    marginBottom: spacing * 8,
});

export const empty: TRuleWithTheme = ({ theme }) => ({
    paddingTop: theme.metrics.spacing * 2,
    paddingBottom: theme.metrics.spacing * 2,
    textAlign: 'center',
});

export const panelBody: TRuleWithTheme = ({ theme }) => ({
    position: 'relative',
});

export const loader: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    position: 'absolute',
    inset: 0,

    background: 'rgba(255, 255, 255, 0.9)',
    zIndex: 10,
});

import { useAppDispatch, useAppSelector } from 'hooks/redux';

import { labelToastParcelSelector } from '../services/selectors/parcelLabelToast';
import { hideParcelLabelToast } from '../services/slices/parcelLabelToast';

export const useParcelLabelToast = () => {
    const dispatch = useAppDispatch();
    const parcel = useAppSelector(labelToastParcelSelector);

    const visible = Boolean(parcel);
    const hide = () => dispatch(hideParcelLabelToast());

    return {
        visible,
        hide,
        orderLabelUrl: parcel?.orderLabelUrl,
    };
};

import type { TRuleWithTheme } from 'styles/theme';

export const brand: TRuleWithTheme = ({ theme: { colors } }) => ({
    color: colors.foregroundsPrimary80,
    opacity: 0.7,
    fontSize: '1.25rem',
    fontWeight: 700,
});

export const item: TRuleWithTheme = ({ theme: { colors } }) => ({
    color: colors.fgSecondary,
});

export const footer: TRuleWithTheme = ({ theme: { colors, metrics } }) => ({
    display: 'flex',
    // TODO: Responsive design?
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: metrics.spacing * 2,

    height: 'auto',

    fontSize: '1rem',

    background: colors.newBgTertiary,

    paddingTop: metrics.spacing * 2,
    paddingBottom: metrics.spacing * 2,

    paddingRight: metrics.spacing * 2,
    paddingLeft: metrics.spacing * 2,

    isDesktop: {
        flexDirection: 'row',
        gap: metrics.spacing * 8,

        alignItems: 'center',

        height: metrics.height.footer,

        paddingTop: metrics.spacing * 4,
        paddingBottom: metrics.spacing * 4,

        paddingRight: metrics.spacing * 5,
        paddingLeft: metrics.spacing * 5,
    },
});

import React from 'react';
import { useDispatch } from 'react-redux';

import { logout } from '../../../services/actions';

export default function useLogout() {
    const dispatch = useDispatch();
    return React.useCallback(() => {
        dispatch(logout());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}

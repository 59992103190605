import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import * as felaRules from './Home.rules';

export interface HomeProps {
    extend?: RulesExtend<typeof felaRules>;
}

export const Home = ({ extend }: HomeProps) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });

    return (
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.icon}>
            <g clipPath="url(#a)" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
                <path d="M4.167 10H2.5L10 2.5l7.5 7.5h-1.667M4.167 10v5.833A1.666 1.666 0 0 0 5.833 17.5h8.334a1.667 1.667 0 0 0 1.666-1.667V10" />
                <path d="M11.667 10H8.333v3.333h3.334V10Z" />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M0 0h20v20H0z" />
                </clipPath>
            </defs>
        </svg>
    );
};

import type { FC, ReactNode } from 'react';
import { Layout } from 'antd';
import { Link } from 'react-router-dom';

import { useFelaEnhanced } from 'hooks';
import config from 'config';
import { Logo } from 'modules/ui';
import { LogoutButton } from 'modules/auth/components/LogoutButton';
import { PartnerButton } from 'modules/partner/components/PartnerButton';

import * as felaRules from './Header.rules';

export interface HeaderProps {
    children?: ReactNode;
}

const Header: FC<HeaderProps> = ({ children = null }) => {
    const { styles, rules } = useFelaEnhanced(felaRules);

    return (
        <Layout.Header className={styles.header}>
            <Link to={config.routes.home}>
                <Logo />
            </Link>

            <PartnerButton extend={{ button: rules.partnerButton }} />
            {children}
            <LogoutButton extend={{ button: rules.logoutButton }} />
        </Layout.Header>
    );
};

export default Header;

import type { TRuleWithTheme } from 'styles/theme';

import type { SpacingProps } from './Spacing';

export const spacing: TRuleWithTheme<Pick<SpacingProps, 'top' | 'bottom' | 'left' | 'right'>> = ({
    theme: { spacing },
    top,
    right,
    left,
    bottom,
}) => ({
    ...spacing.margin(top, right, bottom, left),
    position: 'relative',
});

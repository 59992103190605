import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    height: '100%',
    width: '100%',

    top: 0,
    left: 0,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 'auto',
});

export const cardContainer: TRuleWithTheme = ({ theme: { metrics } }) => ({
    position: 'relative',

    minHeight: 558,
    width: 666,
    maxWidth: '100%',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    border: 'none',
    borderRadius: 10,

    marginBottom: metrics.spacing * 2,

    overflow: 'hidden',
});

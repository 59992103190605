import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import * as felaRules from './Sad.rules';

export interface SadProps {
    extend?: RulesExtend<typeof felaRules>;
}

export const Sad = ({ extend }: SadProps) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });

    return (
        <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.icon}>
            <g clipPath="url(#a)" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M24 42c9.941 0 18-8.059 18-18S33.941 6 24 6 6 14.059 6 24s8.059 18 18 18ZM18 20h.02M30 20h.02M18 30h12" />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M0 0h48v48H0z" />
                </clipPath>
            </defs>
        </svg>
    );
};

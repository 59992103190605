import type { ReactNode } from 'react';
import { Translatable, translateSelector } from '@ackee/jerome';
import ConfigProvider from 'antd/es/config-provider';

import { useAppSelector } from 'hooks/redux';

import { intlData, antdData } from '../../config';
import type { Language } from '../../constants';

export interface LocalizationsProps {
    children: ReactNode;
}

export const Localizations = ({ children }: LocalizationsProps) => {
    const { locale } = useAppSelector(translateSelector);

    return (
        <ConfigProvider locale={antdData[locale as Language]}>
            <Translatable intlMessages={intlData}>{children}</Translatable>
        </ConfigProvider>
    );
};

import AntdButton from 'antd/es/button';
import type { ButtonProps as AntdButtonProps } from 'antd/es/button';
import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import * as felaRules from './Button.rules';
import { ButtonSize, ButtonType } from './types';

export interface ButtonProps extends Omit<AntdButtonProps, 'className' | 'type' | 'size'> {
    type: ButtonType;
    size?: ButtonSize;
    isIconSuffix?: boolean;
    extend?: RulesExtend<typeof felaRules>;
}

export const Button = ({ extend, type, size = ButtonSize.NORMAL, isIconSuffix = false, ...props }: ButtonProps) => {
    const { styles } = useFelaEnhanced(felaRules, { extend, type, size, isIconSuffix });

    return <AntdButton {...props} className={styles.button} />;
};

Button.Type = ButtonType;
Button.Size = ButtonSize;

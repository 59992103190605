import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({
    theme: {
        metrics: { spacing },
    },
}) => ({
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    columnGap: spacing,
    alignItems: 'center',
    justifyContent: 'flex-start',
});

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

type State = string | null;

const parcelLabelsUrlSlice = createSlice({
    name: 'parcelLabelsUrl',
    initialState: null as State,
    reducers: {
        setParcelLabelsUrl: (_: State, action: PayloadAction<string>) => action.payload,
    },
});

export const { name, reducer } = parcelLabelsUrlSlice;
export const { setParcelLabelsUrl } = parcelLabelsUrlSlice.actions;

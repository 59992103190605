import type { TRule } from 'fela';

export const formItem: TRule = () => ({
    maxWidth: 'unset',
});

export const group: TRule = () => ({
    display: 'flex',
    flexWrap: 'wrap',
    margin: '-0.5rem',
});

export const radioButton: TRule = () => ({
    margin: '0.5rem',
});

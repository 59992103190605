import type { TRuleWithTheme } from 'styles';

export const panel: TRuleWithTheme = ({ theme }) => ({
    marginTop: theme.metrics.spacing * 4,
    marginBottom: theme.metrics.spacing * 5,
});

export const showRecipientInformationSelect: TRuleWithTheme = () => ({
    marginBottom: 0,
});

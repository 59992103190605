import type { ReactNode } from 'react';
import { useFelaEnhanced } from 'hooks';

import * as felaRules from './ErrorMessageContainer.rules';

interface ErrorMessageContainerProps {
    children: ReactNode;
}

const ErrorMessageContainer = ({ children }: ErrorMessageContainerProps) => {
    const { styles } = useFelaEnhanced(felaRules);

    return <div className={styles.container}>{children}</div>;
};

export default ErrorMessageContainer;

import { useFelaEnhanced } from 'hooks';
import { ExportButton } from 'modules/parcels/modules/parcels-export';
import { ParcelListFilters, ParcelsSearch } from 'modules/parcels/modules/parcels-filters';
import { Box } from 'modules/ui';

import * as felaRules from './ParcelsListControls.rules';

export const ParcelsListControls = () => {
    const { styles, rules, theme } = useFelaEnhanced(felaRules);

    return (
        <div className={styles.container}>
            <Box space={2.5 * theme.metrics.spacing} direction="row" extend={{ box: rules.box }}>
                <ParcelListFilters />
                <ExportButton />
            </Box>
            <ParcelsSearch />
        </div>
    );
};

import { ToastContainer } from 'react-toastify';

import { Firewall } from 'modules/auth';
import { ErrorBoundary } from 'modules/errors';
import { DEFAULT_CONTAINER_ID } from 'modules/toast';
import { HasPartner } from 'modules/partner';

import AuthRoutes from './AuthRoutes';

const App = () => (
    <>
        <Firewall>
            <HasPartner>
                <ErrorBoundary>
                    <AuthRoutes />
                </ErrorBoundary>
            </HasPartner>
        </Firewall>
        <ToastContainer enableMultiContainer containerId={DEFAULT_CONTAINER_ID} />
    </>
);

export default App;

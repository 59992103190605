import { put, takeLeading } from 'redux-saga/effects';
import type { RequestResult } from '@ackee/antonio-core';

import config from 'config';
import { authApi } from 'config/antonio';
import * as Log from 'config/loglevel';
import type { paths } from 'api/partnerApiSchema';

import { EntityKey } from 'modules/entities/constants';
import { normalizeLocations } from 'modules/entities/services/normalizr';
import { setEntitiesGroup } from 'modules/entities/services/actions';

import { fetchLocations as apiActions } from '../actions';

type Path = typeof config.api.locations;
type Operation = paths[`/api/v1${Path}`]['get'];
type RequestParams = Operation['parameters']['query'];
type ResponseData = Operation['responses']['200']['content']['application/json'];
type RequestAction = ReturnType<typeof apiActions.request>;

function* handleFetchLocations(action: RequestAction) {
    try {
        const params = {} as RequestParams;

        if (action.payload.locationTypes) {
            params.locationType = action.payload.locationTypes;
        }

        const response: RequestResult<ResponseData> = yield* authApi.get<ResponseData>(config.api.locations, {
            params,
        });

        const { result: ids, entities } = normalizeLocations(response.data.data);

        yield put([
            setEntitiesGroup(EntityKey.LOCATIONS, {
                ids,
                byId: entities[EntityKey.LOCATIONS],
                strategy: 'replace',
                group: action.payload.group,
            }),
            apiActions.success(undefined, {
                lastSuccessAt: new Date().toISOString(),
            }),
        ]);
    } catch (e: unknown) {
        Log.error(e as Error);
        yield put(apiActions.failure((e as Error).message));
    }
}

export default function* fetchLocationsSaga() {
    yield takeLeading(apiActions.request.toString(), handleFetchLocations);
}

import { apiSelector } from '@ackee/redux-utils';
import { createSelector } from 'reselect';

import { selectProp } from 'services/selectors';
import { ApiReducerKey, DEFAULT_GROUP, EntityKey } from 'modules/entities/constants';
import { denormalizeDestinations } from 'modules/entities/services/normalizr';
import * as entitiesSelectors from 'modules/entities/services/selectors';
import { addSelectOptionData } from 'modules/entities/modules/locations';
import type { RootState } from 'modules/core/modules/redux';

export const selectDestinationsApi = (state: RootState) =>
    apiSelector(state, EntityKey.DESTINATIONS, ApiReducerKey.LIST);

export const selectDestinations = createSelector(
    entitiesSelectors.selectDestinationsEntities,
    selectProp<string | undefined>('group'),
    (destinations, group = DEFAULT_GROUP) => {
        const denormalizedDestinations = denormalizeDestinations(destinations[group] ?? [], {
            [EntityKey.DESTINATIONS]: destinations.byId,
        });

        return denormalizedDestinations.map(addSelectOptionData);
    },
);

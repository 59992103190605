import { denormalize } from 'normalizr';
import type { Schema } from 'normalizr';

import * as schemas from './schemas';

import type { EntityKey } from '../../constants';
import type { CsvImport, DeliveryPartner, Entities, EntityId, Location, Parcel, Partner } from '../../types';

const denormalizeFactory =
    <Entity extends Entities = Entities>(schema: Schema) =>
    (input: Array<EntityId> | EntityId, entities: Partial<Record<EntityKey, any>>): Entity[] =>
        denormalize(input, schema, entities);

export const denormalizeLocations = denormalizeFactory<Location>(schemas.locationListSchema);

export const denormalizeDestinations = denormalizeFactory<Location>(schemas.destinationListSchema);

export const denormalizeDeliveryPartners = denormalizeFactory<DeliveryPartner>(schemas.deliveryPartnerListSchema);

export const denormalizeEntrustedPartners = denormalizeFactory<Partner>(schemas.entrustedPartnerListSchema);

export const denormalizeCsvImport = denormalizeFactory<CsvImport>(schemas.csvImportListSchema);

export const denormalizeParcels = denormalizeFactory<Parcel>(schemas.parcelListSchema);

import type { TRuleWithTheme } from 'styles';

export const body: TRuleWithTheme = ({
    theme: {
        metrics: { panelPadding },
    },
}) => ({
    paddingTop: panelPadding.vertical,
    paddingBottom: panelPadding.vertical,
    paddingLeft: panelPadding.horizontal,
    paddingRight: panelPadding.horizontal,
});

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useAppSelector } from 'hooks/redux';
import { DEFAULT_GROUP } from 'modules/entities';
import type { LocationType } from 'modules/entities';
import { apiActions, selectLocations, selectLocationsApi } from 'modules/entities/modules/locations';

const defaultLocationTypes: LocationType[] = [];

export const useLocations = (locationTypes: LocationType[] = defaultLocationTypes) => {
    const dispatch = useDispatch();
    const api = useAppSelector(selectLocationsApi);

    const locationsGroupKey = locationTypes.toString() || DEFAULT_GROUP;
    const locations = useAppSelector(state => selectLocations(state, { group: locationsGroupKey }));

    useEffect(() => {
        dispatch(apiActions.fetchLocations.request({ locationTypes, group: locationsGroupKey }));
    }, [dispatch, locationTypes, locationsGroupKey]);

    return { locations, api };
};

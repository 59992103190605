import type { TRuleWithTheme } from 'styles/theme';

const buttonWidth = 63;

export const container: TRuleWithTheme = ({ theme: { colors, form, font, metrics } }) => ({
    fontFamily: font.family,
    '& .form-control': {
        width: '100%',
        height: form.inputHeightBase,

        border: `1px solid ${colors.borderInput}`,
        borderRadius: form.borderRadius,

        fontSize: '1rem',
        lineHeight: '1.25',

        '&.open': {
            borderColor: colors.accentSecondary,
        },

        '&:focus': {
            borderColor: colors.accentSecondary,

            '& + .flag-dropdown': {
                borderColor: colors.accentSecondary,
            },
        },

        paddingTop: metrics.spacing * 1.5,
        paddingBottom: metrics.spacing * 1.5,
        paddingLeft: metrics.spacing * 1.5 + buttonWidth,
        paddingRight: metrics.spacing * 1.5,
    },
});

export const button: TRuleWithTheme = ({ theme: { colors, metrics } }) => ({
    '&.flag-dropdown': {
        background: colors.fgSeparator,
        border: `1px solid ${colors.borderInput}`,
        borderRadius: '5px 0 0 5px',

        '& .selected-flag': {
            paddingLeft: metrics.spacing * 1.5,
            width: 60,
        },

        '&.open': {
            background: colors.fgSeparator,
            borderRadius: '5px 0 0 5px',
            borderColor: colors.accentSecondary,

            '& .selected-flag': {
                background: colors.fgSeparator,
                borderRadius: '5px 0 0 5px',
            },
        },
    },

    '& .selected-flag:hover': {
        background: colors.fgSeparator,
        borderRadius: '5px 0 0 5px',
    },

    '& .selected-flag:focus': {
        background: colors.fgSeparator,
        borderRadius: '5px 0 0 5px',
    },
});

export const dropdown: TRuleWithTheme = ({ theme: { metrics, shadow, form } }) => ({
    '&.country-list': {
        borderRadius: form.borderRadius,
        paddingTop: metrics.spacing * 1.5,
        paddingBottom: metrics.spacing * 1.5,
        paddingLeft: metrics.spacing,
        paddingRight: metrics.spacing * 1.75,

        boxShadow: shadow.dropdown,

        '& li.country': {
            borderRadius: form.borderRadius,
            marginTop: metrics.spacing * 1.25,

            '&:first-child': {
                margin: 0,
            },

            '& .flag': {
                top: 6,
            },

            '& .dial-code': {
                color: 'inherit',
            },
        },
    },
});

import type { ReactNode } from 'react';

import type { RulesExtend } from 'styles/theme';
import { useFelaEnhanced } from 'hooks';

import { PanelHeader } from './PanelHeader';
import { PanelBody } from './PanelBody';
import { PanelFooter } from './PanelFooter';

import * as felaRules from './Panel.rules';

export interface PanelProps {
    children: ReactNode;
    dark?: boolean;
    extend?: RulesExtend<typeof felaRules>;
    composed?: boolean;
}

export const Panel = ({ children, dark = false, composed = false, extend }: PanelProps) => {
    const { styles } = useFelaEnhanced(felaRules, { extend, dark, composed });

    return <div className={styles.panel}>{children}</div>;
};

Panel.Header = PanelHeader;
Panel.Body = PanelBody;
Panel.Footer = PanelFooter;

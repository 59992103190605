import type { TRuleWithTheme } from 'styles/theme';

export const parcelNumber: TRuleWithTheme = ({ theme }) => ({
    marginBottom: theme.metrics.spacing * 1.5,
});

export const panel: TRuleWithTheme = ({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    columnGap: theme.metrics.spacing * 12,
    rowGap: theme.metrics.spacing * 2,
    marginBottom: theme.metrics.spacing * 2,

    '&:last-child': {
        marginBottom: 0,
    },

    isMobile: {
        gridTemplateColumns: '1fr 1fr',
        columnGap: theme.metrics.spacing * 4,
    },
});

export const labelValue: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.buttonEnabledText,
    textDecoration: 'underline',
    fontWeight: 700,
    marginBottom: theme.metrics.spacing * 1.5,
});

import { put, takeLeading } from 'redux-saga/effects';
import type { AntonioError } from '@ackee/antonio-core';

import config from 'config';
import { authApi } from 'config/antonio';
import * as Log from 'config/loglevel';
import type { paths } from 'api/partnerApiSchema';
import { formatPrice } from 'services/utils/formatPrice';

import { getIntlErrorMessage } from 'modules/errors';
import type { ErrorData } from 'modules/errors';

import { createDeliveryRequest as apiActions } from '../actions';

const apiPath = config.api.deliveryRequests;
type Path = typeof apiPath;
type Operation = paths[`/api/v1${Path}`]['post'];
type RequestAction = ReturnType<typeof apiActions.request>;
type Response = Operation['responses']['200']['content']['application/json'];

function* handleCreateDeliveryRequest({ payload }: RequestAction) {
    try {
        const { data } = yield* authApi.post<Response>(apiPath, {
            ...payload,
            amountToBeCollected: formatPrice(payload.amountToBeCollected),
            invoiceValue: formatPrice(payload.invoiceValue),
            items: payload.items.map(item => ({ ...item, value: formatPrice(item.value) })),
        });

        yield put(
            apiActions.success(
                { parcelId: data.parcels[0].id! },
                {
                    lastSuccessAt: new Date().toISOString(),
                },
            ),
        );
    } catch (e) {
        const errorMessage = getIntlErrorMessage(e as AntonioError<ErrorData> | Error, {
            code: {
                P408: { id: 'error.deliveryRequest.post.p408' },
                P410: { id: 'error.deliveryRequest.post.p410' },
                P411: { id: 'error.deliveryRequest.post.p411' },
                P415: { id: 'error.deliveryRequest.post.p415' },
                P416: { id: 'error.deliveryRequest.post.p416' },
            },
        });

        if (errorMessage.unhandled) {
            Log.error(e as Error);
        }

        yield put(apiActions.failure(errorMessage));
    }
}

export default function* createDeliveryRequestSaga() {
    yield takeLeading(apiActions.request.toString(), handleCreateDeliveryRequest);
}

import { FormattedMessage, useIntl } from 'react-intl';
import { useState } from 'react';
import { useFelaEnhanced } from 'hooks';
import { ErrorMessage } from 'modules/errors';
import { Form, useFormSubmit, validateWithTranslation, validatorsWithMessage } from 'modules/form';
import { Box, Button, Headline } from 'modules/ui';
import { usePartner } from 'modules/partner/hooks';
import { ParcelSize } from 'modules/parcels';
import { FormSpy } from 'react-final-form';
import type { LocationType } from 'modules/entities';
import { Radio } from 'antd';
import { RadioButton } from 'modules/ui';
import { CustomerReturnField, form } from '../../config';
import { customerReturnForm } from '../../services/actions';
import type { CustomerReturnFormValues } from '../../types';
import {
    CustomerDetailsForm,
    getValidators as getCustomerDetailsValidators,
    getInitialValues as getCustomerDetailsInitialValues,
} from '../CustomerDetailsForm';
import { DeliveryLocation, getValidators as getDeliveryLocationValidators } from '../DeliveryLocation';
import { ItemSizeSelect } from '../ItemSizeSelect';

import * as felaRules from './CustomerReturnForm.rules';
import { usePartnerShippingRegions } from 'modules/shipping-regions';

const { required } = validatorsWithMessage;

export const validators = {
    [CustomerReturnField.CompartmentSize]: required,
    ...getDeliveryLocationValidators(CustomerReturnField.Destination),
    ...getCustomerDetailsValidators(),
};

const staticInitialValues = {
    ...getCustomerDetailsInitialValues(),
};

const deliveryLocationTypes: LocationType[] = ['warehouse'];

export const CustomerReturnForm = () => {
    const { formatMessage } = useIntl();
    const onSubmit = useFormSubmit<CustomerReturnFormValues>(customerReturnForm);
    const partnerShippingRegions = usePartnerShippingRegions();

    const [selectedRegionMap, setSelectedRegionMap] = useState<string | null>(
        partnerShippingRegions.length > 0 ? partnerShippingRegions[0].slice(-2) : null,
    );

    const { styles, rules, theme } = useFelaEnhanced(felaRules);

    const validate = (values: CustomerReturnFormValues) =>
        validateWithTranslation<CustomerReturnFormValues>(values, validators, formatMessage);

    const partner = usePartner();

    const filterItemSizeOptions = (size: ParcelSize) =>
        size <= (partner?.customerReturnsMaxAllowedSize ?? ParcelSize.Large);

    const handleMethodChangeRegionMap = (value: string) => {
        setSelectedRegionMap(value);
    };

    return (
        <Form<CustomerReturnFormValues>
            name={form.customerReturn}
            onSubmit={onSubmit}
            subscription={{ submitting: true, submitError: true, hasValidationErrors: true }}
            initialValues={staticInitialValues}
            validate={validate}
        >
            {({ submitting, submitError, dirtySinceLastSubmit }) => (
                <Box space={theme.metrics.spacing * 1} extend={{ box: rules.container }}>
                    <>
                        <ItemSizeSelect
                            name={CustomerReturnField.CompartmentSize}
                            sizeOptionsFilter={filterItemSizeOptions}
                            required
                        />
                        <FormSpy subscription={{ values: true }}>
                            {({ values }) =>
                                values.size && (
                                    <>
                                        <Radio.Group
                                            onChange={e => {
                                                handleMethodChangeRegionMap(e.target.value);
                                            }}
                                            value={selectedRegionMap}
                                        >
                                            {partnerShippingRegions.map(region => (
                                                <RadioButton key={region.slice(-2)} value={region.slice(-2)}>
                                                    {region.slice(-2)}{' '}
                                                    {/* Render the region name or a localized version */}
                                                </RadioButton>
                                            ))}
                                        </Radio.Group>
                                        <br />
                                        <br />
                                        <iframe
                                            title="boxnow-map-widget"
                                            className={styles.boxnowMapWidget}
                                            src={`https://navigate.boxnow.${selectedRegionMap}?size=${values.size}`}
                                            allow="geolocation"
                                            style={{ border: 0, borderRadius: 5 }}
                                        />

                                        <Headline level={2}>
                                            <FormattedMessage id="order.delivery.details" />
                                        </Headline>
                                        <CustomerDetailsForm />

                                        <Headline level={2} extend={{ headline: rules.topMargin }}>
                                            <FormattedMessage id="order.delivery.address" />
                                        </Headline>
                                        <DeliveryLocation
                                            locationTypes={deliveryLocationTypes}
                                            label="order.customerReturn.destination.label"
                                            placeholder="order.customerReturn.destination.placeholder"
                                            name={CustomerReturnField.Destination}
                                        />
                                    </>
                                )
                            }
                        </FormSpy>
                    </>
                    {submitError && !dirtySinceLastSubmit && <ErrorMessage error={submitError} />}

                    <Button type={Button.Type.PRIMARY} size={Button.Size.BIG} htmlType="submit" disabled={submitting}>
                        <FormattedMessage id="order.new.button.create" />
                    </Button>
                </Box>
            )}
        </Form>
    );
};

import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { useFelaEnhanced } from 'hooks';
import { Button, Paragraph } from 'modules/ui';

import * as felaRules from './CsvSwitcher.rules';

export interface CsvSwitcherProps {
    state: 'form' | 'csv';
    link: string;
    children?: ReactNode;
}

export const CsvSwitcher = ({ state, link, children = null }: CsvSwitcherProps) => {
    const { styles, rules } = useFelaEnhanced(felaRules);

    return (
        <div className={styles.container}>
            <span>
                <Paragraph size={Paragraph.Size.BIG} extend={{ paragraph: rules.text }}>
                    <FormattedMessage id={`order.switch.${state === 'csv' ? 'from' : 'to'}Csv.title`} />
                </Paragraph>
                {children}
            </span>
            <Link to={link}>
                <Button type={Button.Type.TERTIARY}>
                    <FormattedMessage id={`order.switch.${state === 'csv' ? 'from' : 'to'}Csv.button`} />
                </Button>
            </Link>
        </div>
    );
};

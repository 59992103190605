import { put } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import config from 'config';
import { api } from 'config/antonio';
import { errorToast } from 'modules/toast';
import { UserRole } from 'modules/auth/types';
import type { User, Credentials, Depot, AuthenticatePayload } from 'modules/auth/types';

import processCredentials from './utils/processCredentials';

export default function* authenticate({ smsCode, challenge }: AuthenticatePayload) {
    const response = yield* api.post<{ credentials: Credentials; user: User; depot: Depot }>(
        config.api.authSession.login,
        {
            smsCode,
            challenge,
        },
    );
    const { credentials, user, depot }: { credentials: Credentials; user: User; depot: Depot } = response.data;

    if (user.role !== UserRole.Partner) {
        yield put(push(config.routes.auth.loginCode));
        errorToast({
            text: { id: 'auth.login.error.role' },
        });
        throw new Error(`You can't use the application with this user's role.`);
    }

    return {
        tokens: processCredentials(credentials, user),
        user,
        depot,
    };
}

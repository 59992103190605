import { FormattedMessage } from 'react-intl';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';
import { Button, Paragraph } from 'modules/ui';
import { Form, useFormSubmit } from 'modules/form';

import type { ChoosePartnerFormValues } from '../../types';
import { choosePartnerForm } from '../../services/actions';
import { ChoosePartnerField, formName } from '../../constants';
import { usePartner, usePartnerModal } from '../../hooks';

import { EntrustedPartnersField } from '../EntrustedPartnersField';
import * as felaRules from './SwitchPartnerForm.rules';

export interface SwitchPartnerFormProps {
    extend?: RulesExtend<typeof felaRules>;
}

export const SwitchPartnerForm = ({ extend }: SwitchPartnerFormProps) => {
    const { styles, rules } = useFelaEnhanced(felaRules, { extend });
    const onSubmit = useFormSubmit<ChoosePartnerFormValues>(choosePartnerForm);
    const { hide } = usePartnerModal();
    const partner = usePartner();

    return (
        <Form<ChoosePartnerFormValues>
            name={formName}
            onSubmit={onSubmit}
            subscription={{
                submitting: true,
            }}
            initialValues={{
                [ChoosePartnerField.PartnerId]: partner?.id,
            }}
        >
            {({ submitting }) => (
                <>
                    <Paragraph size={Paragraph.Size.BIG} extend={{ paragraph: rules.title }}>
                        <FormattedMessage id="switchPartner.title" />
                    </Paragraph>

                    <EntrustedPartnersField />

                    <Paragraph>
                        <FormattedMessage id="switchPartner.description" />
                    </Paragraph>

                    <div className={styles.footer}>
                        <Button key="back" type={Button.Type.LINK} onClick={hide}>
                            <FormattedMessage id="switchPartner.cancel" />
                        </Button>

                        <Button
                            id="submitButton"
                            htmlType="submit"
                            type={Button.Type.PRIMARY}
                            disabled={submitting}
                            extend={{ button: rules.submitButton }}
                        >
                            <FormattedMessage id="switchPartner.confirm" />
                        </Button>
                    </div>
                </>
            )}
        </Form>
    );
};

import type { WheelEvent } from 'react';

import { Input as AntdInput } from 'antd';
import type { InputProps as AntdInputProps } from 'antd';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import * as felaRules from './Input.rules';

// https://stackoverflow.com/a/31059514/7051731
const preventNumberChangeByWheel = (e: WheelEvent<HTMLInputElement>) => {
    const inputElement = e.currentTarget;
    inputElement.blur();
    setTimeout(() => inputElement.focus(), 10);
};

export interface InputProps extends AntdInputProps {
    extend?: RulesExtend<typeof felaRules>;
}

export const Input = ({ type, extend, ...restProps }: InputProps) => {
    const { styles } = useFelaEnhanced(felaRules, { extend, type: type });
    const commonInputProps = {
        id: restProps.name,
        type,
        className: styles.input,
        ...restProps,
    };

    return type === 'password' ? (
        <AntdInput.Password {...commonInputProps} />
    ) : (
        <AntdInput {...commonInputProps} onWheel={type === 'number' ? preventNumberChangeByWheel : undefined} />
    );
};

import React from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { useAppSelector } from 'hooks/redux';
import { DEFAULT_GROUP, EntityKey } from 'modules/entities/constants';
import { resetEntitiesGroup } from 'modules/entities/services/actions';
import { parcelFilterApiParamsSelector } from 'modules/parcels/modules/parcels-filters';
import type { ApiResolver } from 'modules/entities/types';
import type { TokenPaginationApiState } from 'types';

import { PageType, ParcelsParams } from '../types';
import { selectParcelsApi, selectParcels } from '../services/selectors';
import { requestParcelsFetching, fetchParcels as fetchParcelsActions } from '../services/actions';
import { isTruthy } from 'types/guards';

const apiResolver: ApiResolver<TokenPaginationApiState> = api => !api.inProgress;

const defaultOptions = {
    resetOnUnmount: false,
    cancelOnUnmount: true,
};

type Options = typeof defaultOptions;

const useParcels = (
    groupId: string = DEFAULT_GROUP,
    params: ParcelsParams = {} as ParcelsParams,
    customOptions?: Partial<Options>,
) => {
    const dispatch = useDispatch();
    const api = useAppSelector(state => selectParcelsApi(state, groupId));

    const parcels = useAppSelector(state => selectParcels(state, groupId));
    const options = React.useMemo<Options>(
        () => ({
            ...defaultOptions,
            ...customOptions,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const filters = useSelector(parcelFilterApiParamsSelector);

    const fetchParcels = React.useCallback(
        (pageNumber: number = api.pageNumber, pageType: PageType = PageType.CURRENT) => {
            dispatch(requestParcelsFetching({ groupId, params, apiResolver, filters, pageNumber, pageType }));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch, groupId, filters],
    );

    const resetParcels = React.useCallback(() => {
        dispatch(
            [
                groupId !== DEFAULT_GROUP && fetchParcelsActions.reset(groupId),
                resetEntitiesGroup(EntityKey.DELIVERY_REQUESTS),
                resetEntitiesGroup(EntityKey.PARCELS),
            ].filter(isTruthy),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, groupId]);

    React.useEffect(() => {
        fetchParcels();
        return () => {
            batch(() => {
                if (options.cancelOnUnmount) {
                    dispatch(fetchParcelsActions.cancel(groupId));
                }
                if (options.resetOnUnmount) {
                    resetParcels();
                }
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchParcels]);

    return { parcels, api, fetchParcels, resetParcels, filters };
};

export default useParcels;

import type { ItemValues } from '../types';

export enum NewOrderStep {
    Pickup = 'pickup',
    Delivery = 'delivery',
    Items = 'items',
    Order = 'order',
    Summary = 'summary',
}

export const ACTION_PREFIX = 'order';

export const initialOrderItem = {} as ItemValues;

export const typeOfServiceDefault = 'same-day';

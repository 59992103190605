export const ALL = 'all';

export enum ParcelFilter {
    STATE = 'state',
    PAYMENT_MODE = 'paymentMode',
    PAYMENT_STATE = 'paymentState',
    CREATED_FROM = 'createdFrom',
    CREATED_TO = 'createdTo',
    QUERY = 'q',
}

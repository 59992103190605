import { FormattedMessage } from 'react-intl';
import type { MouseEventHandler } from 'react';

import { useFelaEnhanced } from 'hooks';

import { Chevron } from 'modules/ui/modules/icons';
import type { RulesExtend } from 'styles/theme';
import type { IntlMessage } from 'types';

import { Button } from '../Button';
import { ButtonType } from '../Button/types';
import * as felaRules from './BackButton.rules';

interface BackButtonProps {
    text?: IntlMessage;
    extend?: RulesExtend<typeof felaRules>;
    onClick?: MouseEventHandler<HTMLButtonElement>;
}

export const BackButton = ({ extend, onClick, text = { id: 'general.back' } }: BackButtonProps) => {
    const { rules } = useFelaEnhanced(felaRules, { extend });

    return (
        <Button
            type={ButtonType.LINK}
            extend={{ button: rules.button }}
            onClick={onClick}
            icon={<Chevron extend={{ icon: rules.icon }} />}
        >
            <FormattedMessage {...text} />
        </Button>
    );
};

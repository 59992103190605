import { createApiRequestActions } from '@ackee/redux-utils';

import type { LocationType } from 'modules/entities/types';

import * as types from './types';

export { types };

export const fetchLocations = createApiRequestActions<
    { locationTypes?: LocationType[]; group?: string },
    undefined,
    { lastSuccessAt: string },
    string
>(types.fetchLocations);

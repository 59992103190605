import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { useFelaEnhanced } from 'hooks';
import { Button, Modal } from 'modules/ui';
import { useParcelLabelToast } from '../../hooks/useParcelLabelToast';

import { GetParcelLabel } from '../GetParcelLabel';

import * as felaRules from './ParcelLabelToast.rules';

export interface ParcelLabelToastToastProps {}

export const ParcelLabelToast: FC<ParcelLabelToastToastProps> = () => {
    const { rules } = useFelaEnhanced(felaRules);
    const { visible, hide, orderLabelUrl } = useParcelLabelToast();

    return (
        <Modal
            visible={visible}
            title={<FormattedMessage id="order.toastCreated.title" />}
            onCancel={hide}
            footer={[
                orderLabelUrl && (
                    <GetParcelLabel
                        key="get-label"
                        url={orderLabelUrl}
                        type={Button.Type.PRIMARY}
                        text={{ id: 'parcel.getLabels' }}
                    />
                ),
            ]}
            extend={{ modal: rules.modal }}
            destroyOnClose
        >
            <FormattedMessage id="order.toastCreated.text" />
        </Modal>
    );
};

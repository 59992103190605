import type { FC, ReactNode } from 'react';
import { useFelaEnhanced } from 'hooks';

import * as felaRules from './Label.rules';
import type { RulesExtend } from 'styles/theme';

export interface LabelProps {
    children: ReactNode;
    extend?: RulesExtend<typeof felaRules>;
}

export const Label: FC<LabelProps> = ({ children, extend }) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });

    return <span className={styles.label}>{children}</span>;
};

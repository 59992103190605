import error from 'assets/images/error.svg';

import type { TRuleWithTheme } from 'styles/theme';

export const formItem: TRuleWithTheme = ({ theme: { colors, metrics } }) => ({
    '& .ant-form-item-label': {
        color: colors.fgSecondary,
        paddingBlock: metrics.spacing * 0.625,

        '& > label': {
            color: colors.fgSecondary,
        },

        '& > .ant-form-item-required': {
            color: colors.fgSecondary,

            '&:not(.ant-form-item-required-mark-optional)::before': {
                color: colors.fgSecondary,
            },
        },
    },

    '& .ant-form-item-explain': {
        marginTop: metrics.spacing,
        fontSize: '0.75rem',
        lineHeight: '0.875rem',

        '& > .ant-form-item-explain-error': {
            display: 'flex',
            alignItems: 'center',
            gap: metrics.spacing * 0.5,
            color: colors.buttonPrimaryDestructive,

            '&::before': {
                content: `url(${error})`,
                width: 12,
                height: 15,
            },
        },
    },
});

import type { TRuleWithTheme } from 'styles/theme';
import type { NewOrderSectionProps } from './NewOrderSection';

type StyleProps = Required<Pick<NewOrderSectionProps, 'visible'>>;

export const section: TRuleWithTheme<StyleProps> = ({ theme, visible }) => ({
    display: visible ? 'flex' : 'none',
    flexDirection: 'column',
    flexGrow: 1,
    paddingTop: theme.metrics.spacing * 2,
    paddingBottom: theme.metrics.spacing * 2,
});

import type { TRuleWithTheme } from 'styles/theme';

export const dropdown: TRuleWithTheme = ({ theme: { colors, font, metrics } }) => ({
    display: 'inline-flex',
    alignItems: 'center',

    fontFamily: font.family,
    fontSize: '1rem',
    lineHeight: '1.25rem',
    color: colors.foregroundsPrimary80,

    background: colors.white,

    borderRadius: 5,
    border: `2px solid ${colors.white}`,

    paddingTop: metrics.spacing * 1.8,
    paddingBottom: metrics.spacing * 1.8,
    paddingLeft: metrics.spacing * 2,
    paddingRight: metrics.spacing * 2,

    cursor: 'pointer',

    '&.ant-dropdown-open': {
        border: `2px solid ${colors.accentTertiary}`,

        '& > svg': {
            transform: 'rotateX(180deg)',
        },
    },
});

export const icon: TRuleWithTheme = ({ theme: { metrics } }) => ({
    marginLeft: metrics.spacing * 1.25,
    transition: 'transform 0.3s ease-out',
});

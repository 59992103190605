import { cloneElement, ReactElement, ReactNode } from 'react';
import AntdAlert from 'antd/es/alert';
import type { AlertProps as AntdAlertProps } from 'antd/es/alert';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import { Success, Error } from 'modules/ui/modules/icons';

import * as felaRules from './Alert.rules';

export interface AlertProps extends Omit<AntdAlertProps, 'className' | 'type' | 'icon'> {
    type: 'success' | 'error' | 'warning';
    icon?: ReactElement;
    children?: ReactNode;
    extend?: RulesExtend<typeof felaRules>;
    framed?: boolean;
}

const iconByType = {
    success: <Success />,
    error: <Error />,
    warning: <Error />,
};

export const Alert = ({
    children = null,
    showIcon = true,
    type,
    icon: customIcon,
    framed = false,
    extend,
    ...props
}: AlertProps) => {
    const { styles, rules } = useFelaEnhanced(felaRules, { extend, type, framed });
    const icon = cloneElement(customIcon ?? iconByType[type], { extend: { icon: rules.icon } });

    return (
        // @ts-expect-error Remove after antd upgrade to v5
        <AntdAlert className={styles.alert} {...{ showIcon, type, icon }} {...props}>
            {children}
        </AntdAlert>
    );
};

import type { TRuleWithTheme } from 'styles/theme';
import type { SelectOwnProps } from './Select';

export const DROPDOWN_ITEM_HEIGHT = 42;

type StyleProps = Pick<SelectOwnProps<any>, 'bordered'> & { isOpen: boolean };

const select: TRuleWithTheme<StyleProps> = ({ theme: { colors, metrics }, bordered }) => ({
    '&.ant-select:not(.ant-select-customize-input) .ant-select-selector': {
        border: `1px solid ${bordered ? colors.borderInput : colors.white}`,
        borderRadius: 5,
        boxShadow: 'none',

        paddingTop: '0.6875rem',
        paddingBottom: '0.6875rem',

        paddingLeft: '1rem',
        paddingRight: '1rem',

        height: 44,
        lineHeight: '1.25rem',

        '&:hover': {
            lineHeight: '1.125rem',
            border: `2px solid ${colors.borderInputFocus}`,
        },

        '& .ant-select-selection-search-input': {
            height: '100%',
        },

        '& .ant-select-selection-item': {
            lineHeight: 'inherit',
        },
    },

    '&.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused .ant-select-selector':
        {
            boxShadow: 'none',
            borderRightWidth: 2,
        },

    '&.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector':
        {
            borderColor: `${colors.buttonPrimaryDestructive} !important`,
            borderRightWidth: 2,
        },

    '&.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector': {
        border: `2px solid ${colors.borderInputFocus}`,
        borderRightWidth: 2,
        boxShadow: 'none',

        lineHeight: '1.125rem',
    },
});

const dropdown: TRuleWithTheme<StyleProps> = ({ theme: { colors, metrics } }) => ({
    border: `2px solid ${colors.borderInputFocus}`,
    borderRadius: 5,

    '& .ant-select-item.ant-select-item-group': {
        fontSize: '0.875rem',
        color: colors.fgSecondary,

        paddingTop: metrics.spacing * 1.75,
        paddingBottom: metrics.spacing * 0,

        paddingLeft: metrics.spacing * 1.75,
        paddingRight: metrics.spacing * 1.75,
    },

    '& .ant-select-item.ant-select-item-option': {
        fontSize: '1rem',
        color: colors.fgPrimary,

        height: DROPDOWN_ITEM_HEIGHT,

        paddingTop: metrics.spacing * 1.25,
        paddingBottom: metrics.spacing * 1.25,

        paddingLeft: metrics.spacing * 1.75,
        paddingRight: metrics.spacing * 1.75,
    },

    '& .ant-select-item-option-selected': {
        background: colors.borderInputFocus,
        fontWeight: 500,
    },
});

export const felaRules = { select, dropdown };

import config from 'config';
import { goBack, push } from 'connected-react-router';
import { previousLocationSelector } from 'modules/core/modules/router/services/selectors';

import { useAppDispatch, useAppSelector } from './redux';

export const useGoBack = (fallbackRoute = config.routes.home) => {
    const previousLocation = useAppSelector(previousLocationSelector);
    const dispatch = useAppDispatch();

    const goBackWithFallback = () => {
        if (previousLocation.pathname) {
            dispatch(goBack());
        } else {
            dispatch(push(fallbackRoute));
        }
    };

    return goBackWithFallback;
};

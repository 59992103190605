import React from 'react';
import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import * as felaRules from './Loading.rules';

export interface LoadingProps {
    extend?: RulesExtend<typeof felaRules>;
}

export const Loading = ({ extend }: LoadingProps) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });

    return (
        <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.icon}>
            <path d="M24 12V6" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M32.5 15.5L36.8 11.2" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M36 24H42" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M32.5 32.5L36.8 36.8" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M24 36V42" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15.5 32.5L11.2 36.8" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 24H6" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15.5 15.5L11.2 11.2" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

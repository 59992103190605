import type { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { useFelaEnhanced } from 'hooks';
import { Button } from 'modules/ui';

import { ParcelPanelCell } from '../ParcelPanelCell';

import * as felaRules from './ParcelPanelLinkCell.rules';

export interface ParcelPanelLinkCellProps {
    to: string;
    children: ReactNode;
}

export const ParcelPanelLinkCell = ({ to, children }: ParcelPanelLinkCellProps) => {
    const { rules } = useFelaEnhanced(felaRules);

    return (
        <ParcelPanelCell>
            <Link to={to}>
                <Button type={Button.Type.LINK} extend={{ button: rules.linkButton }}>
                    {children}
                </Button>
            </Link>
        </ParcelPanelCell>
    );
};

import { Radio } from 'antd';
import type { RadioGroupProps } from 'antd';
import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import * as felaRules from './Tabs.rules';
import { Tab } from './components/Tab';

export interface TabsProps extends Omit<RadioGroupProps, 'className'> {
    extend?: RulesExtend<typeof felaRules>;
}

export const Tabs = ({ extend, ...props }: TabsProps) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });

    return <Radio.Group className={styles.tabs} {...props} />;
};

Tabs.Tab = Tab;

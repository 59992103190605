import { petrusReducer as petrus, authReducer as auth } from 'modules/auth';
import { reducer as parcels } from 'modules/parcels';
import { reducer as order } from 'modules/order';
import { reducer as csvImport } from 'modules/entities/modules/csvImport';
import { reducer as partner } from 'modules/partner';

import api from './api';
import entities from './entities';

// NOTE:
// Do not use combineReducers() here,
// export reducers as plain object, not as a function.
const reducers = {
    petrus,
    auth,
    api,
    entities,
    parcels,
    csvImport,
    order,
    partner,
};

export default reducers;

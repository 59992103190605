import type { TRuleWithTheme, Theme } from 'styles/theme';
import { BigLogoType } from './types';

const stylesByType = (metrics: Theme['metrics']) => ({
    [BigLogoType.DEFAULT]: {
        width: 93,
        height: 68,

        left: metrics.spacing * 5,
        isMobile: {
            left: metrics.spacing * 2,
        },
    },
    [BigLogoType.LOGIN]: {
        width: 108,
        height: 79,

        left: 0,
    },
});

export const logo: TRuleWithTheme<{ type: BigLogoType }> = ({ type, theme: { metrics } }) => ({
    position: 'absolute',
    top: 0,
    ...stylesByType(metrics)[type],
});

import { combineRules } from 'fela';
import { button } from 'modules/ui/components/Button/Button.rules';
import { ButtonType, StyleProps as ButtonStyleProps } from 'modules/ui/components/Button/types';
import type { ThemeProps, TRuleWithTheme } from 'styles/theme';
import * as dropdownRules from '../Dropdown/Dropdown.rules';

interface StyleProps extends ButtonStyleProps, ThemeProps {}

const borderByType = (colors: any) => ({
    [ButtonType.PRIMARY]: {
        menu: {
            border: `1px solid ${colors.accentPrimary}`,
        },
        dropdownOpen: {
            border: `2px solid ${colors.buttonPrimary}`,
        },
    },
    [ButtonType.SECONDARY]: {
        menu: {
            border: `1px solid ${colors.accentSecondary}`,
        },
        dropdownOpen: {
            border: `2px solid ${colors.buttonSecondary}`,
        },
    },
    [ButtonType.TERTIARY]: {
        menu: {
            border: `1px solid ${colors.accentSecondary}`,
        },
        dropdownOpen: {
            border: `2px solid ${colors.buttonTertiary}`,
        },
    },
    [ButtonType.LINK]: {
        menu: {},
        dropdownOpen: {},
    },
});

export const dropdown = combineRules<ThemeProps, StyleProps, StyleProps>(
    dropdownRules.dropdown,
    button,
    ({ theme: { colors }, type }) => ({
        width: 'fit-content',

        '&.ant-dropdown-open': {
            ...borderByType(colors)[type].dropdownOpen,
        },
    }),
);

export const icon = combineRules<ThemeProps, ThemeProps>(dropdownRules.icon, () => ({}));

export const menu: TRuleWithTheme<{ type: ButtonType }> = ({ theme: { colors }, type }) => ({
    ...borderByType(colors)[type].menu,
});

export const menuItemDescription: TRuleWithTheme<StyleProps> = ({ theme: { colors, metrics } }) => ({
    marginTop: metrics.spacing * 0.5,

    fontSize: '0.875rem',
    lineHeight: '1.0625rem',

    color: colors.fgSecondary,
});

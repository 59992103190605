import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import * as felaRules from './Calendar.rules';

export interface CalendarProps {
    extend?: RulesExtend<typeof felaRules>;
}

export const Calendar = ({ extend }: CalendarProps) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });

    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.icon}>
            <path
                d="M18 5H6C4.89543 5 4 5.89543 4 7V19C4 20.1046 4.89543 21 6 21H18C19.1046 21 20 20.1046 20 19V7C20 5.89543 19.1046 5 18 5Z"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M16 3V7" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 3V7" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4 11H20" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11 15H12" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 15V18" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

import React, { ReactElement, useState } from 'react';
import { noop } from 'lodash';
import { Select as AntdSelect } from 'antd';
import type { SelectProps, SelectValue } from 'antd/es/select';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import { DROPDOWN_ITEM_HEIGHT, felaRules } from './Select.rules';

export interface SelectOwnProps<ValueType> extends SelectProps<ValueType> {
    bordered?: boolean;
    extend?: RulesExtend<typeof felaRules>;
}

const Select = <ValueType extends SelectValue = SelectValue>({
    bordered = true,
    onDropdownVisibleChange = noop,
    extend,
    ...props
}: SelectOwnProps<ValueType>): ReactElement => {
    const [isOpen, setOpen] = useState(false);
    const { styles } = useFelaEnhanced(felaRules, { bordered, isOpen, extend });

    return (
        <AntdSelect
            {...props}
            className={styles.select}
            dropdownClassName={styles.dropdown}
            listHeight={DROPDOWN_ITEM_HEIGHT * 5.5}
            onDropdownVisibleChange={open => {
                setOpen(open);
                onDropdownVisibleChange(open);
            }}
        />
    );
};

Select.Option = AntdSelect.Option;
Select.OptGroup = AntdSelect.OptGroup;

export default Select;

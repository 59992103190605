import { all, put } from 'redux-saga/effects';
import { takeRequest } from '@ackee/antonio-utils';

import config from 'config';
import { authApi } from 'config/antonio';
import * as Log from 'config/loglevel';
import type { paths } from 'api/partnerApiSchema';

import { EntityKey } from 'modules/entities/constants';
import { normalizeParcels } from 'modules/entities/services/normalizr';
import { setEntitiesGroup, setEntities } from 'modules/entities/services/actions';
import { setExportUrl } from 'modules/parcels/modules/parcels-export';

import getValidApiParams from 'services/utils/getValidApiParams';

import { fetchParcels as apiActions } from '../../actions';
import { PageType } from '../../../types';

type Path = typeof config.api.parcels;
type Operation = paths[`/api/v1${Path}`]['get'];
type ResponseData = Operation['responses']['200']['content']['application/json'];
type RequestAction = ReturnType<typeof apiActions.request>;

function* handleFetchParcels(
    { id: groupId }: RequestAction['meta'],
    payload: RequestAction['payload'],
    signal: AbortSignal,
) {
    try {
        const { pageNumber, ...params } = payload;
        const {
            data: { data, pagination, count },
            response: { headers },
        } = yield* authApi.get<ResponseData>(config.api.parcels, {
            params: getValidApiParams(params as Omit<RequestAction['payload'], 'pageNumber'>),
            signal,
        });

        const { result: ids, entities } = normalizeParcels(data);
        const byId = entities[EntityKey.PARCELS];
        const exportUrl = headers.get('x-export-url-csv');

        yield put([
            apiActions.success(groupId, undefined, {
                lastSuccessAt: new Date().toISOString(),
                totalCount: Number(count),
                [PageType.FIRST]: pagination?.first ?? null,
                [PageType.PREVIOUS]: pagination?.prev ?? null,
                [PageType.CURRENT]: payload.pageToken ?? null,
                [PageType.NEXT]: pagination?.next ?? null,
                [PageType.LAST]: pagination?.last ?? null,
                pageNumber,
                currentCount: ids.length,
            }),
            setEntities(EntityKey.DELIVERY_REQUESTS, entities.deliveryRequests),
            setEntitiesGroup(EntityKey.PARCELS, {
                ids,
                byId,
                group: groupId,
                strategy: 'replace',
            }),
            setExportUrl(exportUrl),
        ]);
    } catch (e: unknown) {
        Log.error(e as Error);
        yield put(apiActions.failure(groupId, (e as Error).message));
    }
}

function* handleFetchParcelsForEachParcel(action: RequestAction, signal: AbortSignal) {
    const { parcelIds } = action.payload;

    if (parcelIds && parcelIds.length > 0) {
        const requests = parcelIds.map(parcelId =>
            handleFetchParcels(action.meta, { ...action.payload, parcelId }, signal),
        );

        yield all(requests);
    } else {
        yield* handleFetchParcels(action.meta, action.payload, signal);
    }
}

export default function* fetchParcelsSaga() {
    yield takeRequest(
        {
            REQUEST: apiActions.request.toString(),
            CANCEL: apiActions.cancel.toString(),
        },
        handleFetchParcelsForEachParcel,
    );
}

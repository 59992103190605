import type { TRuleWithTheme } from 'styles';

export const cell: TRuleWithTheme = () => ({
    overflow: 'hidden',
});

export const linkButton: TRuleWithTheme = () => ({
    padding: 0,
    marginRight: 0,
    lineHeight: '1.1875rem',

    maxWidth: '100%',

    // Note: The span is created by Antd button so we have no other way to style it
    '& > span': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
});

import { COUNTRIES_BY_DOMAIN, defaultCountry, Domain, type Country } from '../types';

export const getCountryFromHostname = (hostname: string = document.location.hostname): Country => {
    try {
        const [topLevelDomain] = hostname.split('.').slice(-1);

        const domain = Object.values(Domain).find(domain => domain === topLevelDomain);

        if (!domain) {
            return defaultCountry;
        }

        return COUNTRIES_BY_DOMAIN[domain];
    } catch (err) {
        return defaultCountry;
    }
};

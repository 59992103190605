import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'antd';
import { useUpdateDeliveryRequest } from 'modules/entities/modules/deliveryRequests';

import { useFelaEnhanced } from 'hooks';

import * as felaRules from './OrderReturnButton.rules';

export interface OrderReturnButtonProps {
    orderNumber: string;
    value: boolean;
    parcelId: string;
}

const OrderReturnButton = ({ parcelId, orderNumber, value }: OrderReturnButtonProps) => {
    const { styles } = useFelaEnhanced(felaRules);
    const [returnAllowed, setReturnAllowed] = React.useState(value);
    const { api, updateDeliveryRequest } = useUpdateDeliveryRequest(orderNumber, parcelId);

    return (
        <Button
            type="link"
            className={styles.button}
            onClick={() => {
                const next = !returnAllowed;
                setReturnAllowed(next);
                updateDeliveryRequest(next);
            }}
            disabled={api.inProgress}
        >
            {returnAllowed ? <FormattedMessage id="general.disable" /> : <FormattedMessage id="general.enable" />}
        </Button>
    );
};

export default OrderReturnButton;

import { put, take, takeLatest } from '@redux-saga/core/effects';

import { csvImportRequest } from 'modules/entities/modules/csvImport';
import { isFailureAction } from 'types/guards';

import { cvsImportForm } from '../actions';

function* newCsvImportFormSubmitHandler({ payload }: ReturnType<(typeof csvImportRequest)['request']>) {
    yield put(csvImportRequest.request(payload));

    type SuccessAction = ReturnType<typeof csvImportRequest.success>;
    type FailureAction = ReturnType<typeof csvImportRequest.failure>;

    const result: SuccessAction | FailureAction = yield take([
        csvImportRequest.success.type,
        csvImportRequest.failure.type,
    ]);

    if (!isFailureAction<SuccessAction, FailureAction>(result)) {
        yield put(cvsImportForm.submitSuccess());
    } else {
        yield put(cvsImportForm.submitFailure(result.error));
    }
}

export default function* newCsvImportForm() {
    yield takeLatest(cvsImportForm.submit, newCsvImportFormSubmitHandler);
}

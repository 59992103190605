import type { ReactNode, PropsWithChildren } from 'react';

import { FormattedMessage } from 'react-intl';
import { Spin as AntdSpin } from 'antd';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import * as felaRules from './Loader.rules';

export interface LoaderProps {
    show: boolean;
    loader?: ReactNode;
    text?: ReactNode;
    extend?: RulesExtend<typeof felaRules>;
}

export const Loader = ({
    children = null,
    show,
    loader = <AntdSpin />,
    text = <FormattedMessage id="general.loading" />,
    extend,
}: PropsWithChildren<LoaderProps>) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });

    return show ? (
        <div className={styles.container}>
            {loader}
            <div className={styles.text}>{text}</div>
        </div>
    ) : (
        <>{children}</>
    );
};

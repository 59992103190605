import type { CsvImportValues } from 'modules/entities/modules/csvImport';
import { createFormActions } from 'modules/form';

import { ACTION_PREFIX } from '../../constants';
import type { CustomerReturnFormValues, NewOrderFormValues } from '../../types';

export const newOrderForm = createFormActions<NewOrderFormValues, 'NEW_ORDER_FORM', typeof ACTION_PREFIX>(
    'NEW_ORDER_FORM',
    ACTION_PREFIX,
);

export const customerReturnForm = createFormActions<
    CustomerReturnFormValues,
    'CUSTOMER_RETURN_FORM',
    typeof ACTION_PREFIX
>('CUSTOMER_RETURN_FORM', ACTION_PREFIX);

export const cvsImportForm = createFormActions<CsvImportValues, 'SUBMIT_CVS_IMPORT_FORM', typeof ACTION_PREFIX>(
    'SUBMIT_CVS_IMPORT_FORM',
    ACTION_PREFIX,
);

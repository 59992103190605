import { useMemo } from 'react';
import type { FC } from 'react';
import { useIntl } from 'react-intl';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';

import { useFelaEnhanced } from 'hooks';

import { SelectField, validatorsWithMessage, FormWrapper, validateWithTranslation } from 'modules/form';
import type { LocationType } from 'modules/entities/types';

import { PickupField } from '../../config';
import { useLocations } from '../../hooks';
import PickupAddress from '../PickupAddress';

import type { NewOrderStepFormValidate, PickupFormValues } from '../../types';

import * as felaRules from './PickupForm.rules';

const { required } = validatorsWithMessage;

const validators = {
    [PickupField.Location]: required,
};

const validate: NewOrderStepFormValidate<PickupField> = (values, formatMessage) =>
    validateWithTranslation(values, validators, formatMessage);

export interface PickupFormProps {
    locationTypes?: LocationType[];
    label?: string;
}

const defaultLocationTypes: LocationType[] = [];

const PickupForm: FC<PickupFormProps> & { validate: typeof validate } = ({
    locationTypes = defaultLocationTypes,
    label,
}) => {
    const { formatMessage } = useIntl();
    const { rules } = useFelaEnhanced(felaRules);

    const { locations, api } = useLocations(locationTypes);
    const locationsByType = useMemo(() => {
        const sortedLocations = orderBy(locations, ({ id }) => Number(id));

        return groupBy<typeof sortedLocations[number]>(sortedLocations, 'type');
    }, [locations]);

    return (
        <>
            <FormWrapper withNote={false}>
                <SelectField<PickupFormValues[PickupField.Location]>
                    name={PickupField.Location}
                    label={label ?? formatMessage({ id: 'order.pickup.location.label' })}
                    placeholder={formatMessage({ id: 'order.pickup.location.placeholder' })}
                    loading={api.inProgress}
                    extend={{
                        formItem: rules.formItem,
                    }}
                    required
                >
                    {Object.keys(locationsByType).map(type => (
                        <SelectField.OptGroup
                            key={type}
                            label={formatMessage({ id: `order.location.type.${type as LocationType}` })}
                        >
                            {locationsByType[type].map(location => (
                                <SelectField.Option key={location.id} value={location.id}>
                                    {location.displayName}
                                </SelectField.Option>
                            ))}
                        </SelectField.OptGroup>
                    ))}
                </SelectField>
            </FormWrapper>

            <PickupAddress />
        </>
    );
};

PickupForm.validate = validate;

export default PickupForm;

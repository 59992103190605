import type { TRuleWithTheme, Theme } from 'styles/theme';
import { ButtonSize, ButtonType } from './types';
import type { StyleProps } from './types';
import { generateBorderedButtonColors, generateSolidButtonColors } from '../../utils';

const linkButtonBaseStyle = (colors: Theme['colors']) => ({
    background: 'transparent',
    color: colors.buttonEnabledText,
    borderColor: 'transparent',
    textDecoration: 'underline',
});

const textUnderlined = {
    textDecoration: 'underline',
};

const colorsByType = (colors: Theme['colors']) => ({
    [ButtonType.PRIMARY]: generateSolidButtonColors({
        bg: colors.buttonPrimary,
        text: colors.buttonPrimaryText,
        bgHover: colors.buttonPrimaryHover,
        bgPressed: colors.buttonPrimaryPressed,
    }),
    [ButtonType.SECONDARY]: generateSolidButtonColors({
        bg: colors.buttonSecondary,
        text: colors.buttonSecondaryText,
        bgHover: colors.buttonSecondaryHover,
        bgPressed: colors.buttonSecondaryPressed,
    }),
    [ButtonType.TERTIARY]: generateBorderedButtonColors({
        base: colors.buttonTertiary,
        hover: colors.buttonTertiaryHover,
        pressed: colors.buttonTertiaryPressed,
    }),
    [ButtonType.LINK]: {
        ...linkButtonBaseStyle(colors),
        '&::after': {
            animation: 'none',
        },
        '&:hover': linkButtonBaseStyle(colors),
        '&:focus': linkButtonBaseStyle(colors),
        '&.ant-btn:not([disabled]):hover': textUnderlined,
        // if button children is pure text, not FormattedMessage, ant design buttons wraps it with span
        '& > span': textUnderlined,
    },
});

const paddingBySize = (metrics: Theme['metrics']) => ({
    [ButtonSize.BIG]: {
        paddingTop: metrics.spacing * 1.25,
        paddingBottom: metrics.spacing * 1.25,

        paddingLeft: metrics.spacing * 4.75,
        paddingRight: metrics.spacing * 4.75,
    },
    [ButtonSize.NORMAL]: {
        paddingTop: metrics.spacing * 1.25,
        paddingBottom: metrics.spacing * 1.25,

        paddingLeft: metrics.spacing * 1.75,
        paddingRight: metrics.spacing * 1.75,
    },
});

const noPadding = {
    padding: 0,
};

const iconBySize = {
    [ButtonSize.BIG]: {
        width: 24,
        height: 24,
    },
    [ButtonSize.NORMAL]: {
        width: 20,
        height: 20,
    },
};

const fontBySize = {
    [ButtonSize.BIG]: {
        fontSize: '1.25rem',
        fontWeight: 700,
        lineHeight: '1.5rem',
    },
    [ButtonSize.NORMAL]: {
        fontSize: '1rem',
        fontWeight: 700,
        lineHeight: '1.25rem',
    },
};

export const button: TRuleWithTheme<StyleProps> = ({ theme: { metrics, colors }, type, size, isIconSuffix }) => ({
    height: 'auto',

    display: 'flex',
    alignItems: 'center',
    gap: metrics.spacing,
    flexDirection: isIconSuffix ? 'row-reverse' : 'row',

    borderRadius: 5,
    borderWidth: 2,
    borderStyle: 'solid',

    boxShadow: 'none',

    ...fontBySize[size],
    ...colorsByType(colors)[type],

    ...(type === ButtonType.LINK ? noPadding : paddingBySize(metrics)[size]),

    '& > svg': {
        ...iconBySize[size],
    },
});

import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Alert, Loader, Panel } from 'modules/ui';
import { useFelaEnhanced } from 'hooks';
import { useParcels } from 'modules/entities/modules/parcels';
import { DEFAULT_GROUP } from 'modules/entities';
import { ParcelRowHeader } from 'modules/parcels/modules/parcel-common';

import { figureOutParcelRowTypes } from '../../utils/parcelItem';
import { GRID_COLUMNS } from '../../config';
import ParcelListItem from '../ParcelListItem';

import * as felaRules from './ParcelsList.rules';
import { ParcelsListPaging } from '../ParcelsListPaging';

const ParcelsList = () => {
    const {
        parcels,
        fetchParcels,
        api: { error, inProgress },
    } = useParcels(DEFAULT_GROUP, undefined, { resetOnUnmount: true });

    const data = React.useMemo(() => figureOutParcelRowTypes(parcels), [parcels]);

    const { styles, rules } = useFelaEnhanced(felaRules);

    return (
        <Panel extend={{ panel: rules.container }} composed>
            <Panel.Header>
                <ParcelRowHeader columnsTemplate={GRID_COLUMNS} />
            </Panel.Header>
            <Panel.Body extend={{ body: rules.panelBody }}>
                {error && <Alert message={<FormattedMessage id="parcel.list.error" />} type="error" />}
                <Loader show={inProgress} extend={{ container: rules.loader }} />
                {data.length === 0 ? (
                    <div className={styles.empty}>
                        <FormattedMessage id="parcel.list.empty" />
                    </div>
                ) : (
                    data.map(item => <ParcelListItem key={item.id} item={item} />)
                )}
            </Panel.Body>
            <ParcelsListPaging fetchParcels={fetchParcels} />
        </Panel>
    );
};

export default ParcelsList;

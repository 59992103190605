import type { ReactNode } from 'react';
import { generatePath } from 'react-router-dom';

import { useFelaEnhanced } from 'hooks';
import config from 'config';
import type { Parcel } from 'modules/entities';

import {
    ParcelCreateTime,
    ParcelPanel,
    ParcelPanelCell,
    ParcelPanelLinkCell,
    ParcelState,
} from 'modules/parcels/modules/parcel-common';

import * as felaRules from './ParcelRow.rules';
import { GRID_COLUMNS } from '../../../config';

export interface ParcelRowProps {
    data: Parcel;
    children?: ReactNode;
}

export const ParcelRow = ({
    data: { id, deliveryRequest, updateTime, createTime, state },
    children,
}: ParcelRowProps) => {
    const { rules } = useFelaEnhanced(felaRules);
    const orderId = deliveryRequest.orderNumber;

    return (
        <ParcelPanel columnsTemplate={GRID_COLUMNS}>
            <ParcelPanelLinkCell to={generatePath(config.routes.orderDetail, { id: orderId })}>
                {orderId}
            </ParcelPanelLinkCell>

            <ParcelPanelLinkCell to={generatePath(config.routes.parcel, { id })}>{id}</ParcelPanelLinkCell>

            <ParcelPanelCell>{deliveryRequest.destination.contactName}</ParcelPanelCell>

            <ParcelPanelCell>
                <ParcelCreateTime>{updateTime}</ParcelCreateTime>
            </ParcelPanelCell>

            <ParcelPanelCell>
                <ParcelState>{state}</ParcelState>
            </ParcelPanelCell>

            <ParcelPanelCell>
                <ParcelCreateTime>{createTime}</ParcelCreateTime>
            </ParcelPanelCell>

            <ParcelPanelCell extend={{ cell: rules.extraCell }}>{/* TODO - checkbox */}</ParcelPanelCell>
        </ParcelPanel>
    );
};

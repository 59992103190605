import type { TRuleWithTheme } from 'styles';

export const header: TRuleWithTheme = ({
    theme: {
        colors,
        metrics: { panelPadding },
    },
}) => ({
    paddingTop: panelPadding.vertical,
    paddingBottom: panelPadding.vertical,
    paddingLeft: panelPadding.horizontal,
    paddingRight: panelPadding.horizontal,

    borderBottom: `1px solid ${colors.fgSeparator}`,
});

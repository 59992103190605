import type { DraggerProps } from 'antd/lib/upload';
import { useFelaEnhanced } from 'hooks';
import { DoneCircled } from 'modules/ui/modules/icons';
import type { RulesExtend } from 'styles/theme';

import * as felaRules from './FileInputSelected.rules';
import { UploadItem } from './UploadItem';

export interface FileInputSelectedProps {
    extend?: RulesExtend<typeof felaRules>;
    fileList: NonNullable<DraggerProps['fileList']>;
    onFileRemove: (uid: string) => void;
}

export const FileInputSelected = ({ extend, fileList, onFileRemove }: FileInputSelectedProps) => {
    const { styles, rules } = useFelaEnhanced(felaRules, { extend });

    return (
        <div className={styles.container}>
            <DoneCircled extend={{ icon: rules.icon }} />
            <div className={styles.list}>
                {fileList.map(file => (
                    <UploadItem key={file.uid} uid={file.uid} name={file.name} onRemove={onFileRemove} />
                ))}
            </div>
        </div>
    );
};

import { Typography } from 'antd';
import type { TitleProps as AntdTitleProps } from 'antd/es/typography/Title';
import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import * as felaRules from './Headline.rules';

export interface HeadlineProps extends Omit<AntdTitleProps, 'className' | 'level'> {
    level?: 1 | 2;
    children: React.ReactNode;
    extend?: RulesExtend<typeof felaRules>;
}

const AntdTitle = Typography.Title;

export const Headline = ({ level = 1, children, extend, ...props }: HeadlineProps) => {
    const { styles } = useFelaEnhanced(felaRules, { level, extend });

    return (
        <AntdTitle className={styles.headline} level={level} {...props}>
            {children}
        </AntdTitle>
    );
};

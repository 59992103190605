import type { LoginField, LoginCodeField } from './config';
import type { PhoneFieldValue } from 'modules/form/components/PhoneField';
import type { components } from 'api/apiSchema';
import type { Tokens } from '@ackee/petrus';

export enum UserRole {
    DepotWorker = 'depot-worker',
    Driver = 'driver',
    Partner = 'partner',
    Manager = 'manager',
}

export type User = components['schemas']['User'];

export interface Depot {}
export type PetrusEntitiesState = {
    user: null | User;
    tokens: {
        accessToken: null | {
            token: string;
            expiration: string;
        };
        refreshToken: null | {
            token: string;
        };
    };
};

export interface AuthState {
    challenge: null | string;
    phoneNumber: null | string;
}

export interface LoginCodeFormValues extends Record<string, any> {
    [LoginCodeField.PhoneNumber]: PhoneFieldValue;
    challenge?: string;
}
export interface LoginFormValues {
    [LoginField.SmsCode]: number;
}

export interface Credentials {
    accessToken: string;
    refreshToken: string;
    expiresIn: number;
}

export interface DecodedAccessToken {
    iat: number;
    exp: number;
}

export interface AuthenticatePayload {
    smsCode: number;
    challenge: string;
}

export interface AuthTokens extends Tokens {
    accessToken: {
        token: string;
        expiration?: string | null;
        user: User;
    };
}

export const text = '#10092d';
export const grey = '#f5f5f5';
export const darkGrey = '#5c5c5c';
export const darkGrey2 = '#3f3f3f';
export const primary = '#002a80';
export const secondary = '#77bb56';
export const bgPrimary = '#f3f8fd';
const whiteOld = '#fff';
export const bgSecondary = whiteOld;
export const error = '#d20000';

// ⬆️  Old colors = will be obsolete after redesign is finished ⬆️
// ⬇️  New colors ⬇️

export const white = '#fff';
export const black = '#000';
export const foregroundsPrimary80 = 'rgba(0, 0, 0, 0.8)';
export const accentPrimary = '#6cd04e';
export const accentSecondary = '#513782';
export const accentSecondary30 = '#5137824d';
export const accentTertiary = '#f1bfdf';
export const newBgPrimary = '#f7f7f3';
export const newBgSecondary = white;
export const newBgTertiary = '#f1f1ea';
export const bgSelected = '#f4f2f7';
export const fgPrimary = black;
export const fgSecondary = '#b5bfc3';
export const fgSeparator = '#e8eced';
export const fgOnPrimary = white;
export const buttonPrimary = '#6cd04e';
export const buttonPrimaryHover = '#52bd32';
export const buttonPrimaryPressed = '#5baf41';
export const buttonPrimaryText = white;
export const buttonSecondary = '#260563';
export const buttonSecondaryHover = '#3f1490';
export const buttonSecondaryPressed = '#675092';
export const buttonSecondaryText = white;
export const buttonTertiary = '#260563';
export const buttonTertiaryHover = '#3f1490';
export const buttonTertiaryPressed = '#675092';
export const buttonEnabledText = '#513782';
export const buttonPrimaryDestructive = '#ff4444';
export const bgInput = white;
export const borderInput = '#d0d5d5';
export const borderInputFocus = '#f1bfdf';
export const iconPrimary = black;
export const iconSecondary = white;

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';
import { useAllCountries } from 'modules/countries';

import * as felaRules from './CountriesField.rules';
import SelectField from '../SelectField';
import type { SelectFieldProps } from '../SelectField';
import type { Omit } from 'types';

const OPTION_FILTER_PROP = 'data-filter-value';

export interface CountriesFieldProps<TValue> extends Omit<SelectFieldProps<TValue>, 'extend' | 'children'> {
    extend?: RulesExtend<typeof felaRules>;
}

export const CountriesField = <TValue extends string | number = string>({
    extend,
    ...props
}: CountriesFieldProps<TValue>) => {
    const { rules } = useFelaEnhanced(felaRules, { extend });

    const allCountries = useAllCountries();

    return (
        <SelectField
            extend={{ formItem: rules.formItem, select: rules.select }}
            showSearch
            optionFilterProp={OPTION_FILTER_PROP}
            {...props}
        >
            {allCountries.map(({ id, name }) => (
                <SelectField.Option key={id} value={id} {...{ [OPTION_FILTER_PROP]: name }}>
                    {name}
                </SelectField.Option>
            ))}
        </SelectField>
    );
};

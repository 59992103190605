import { Fragment, useMemo } from 'react';
import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { useFelaEnhanced } from 'hooks';
import { Loader, Separator } from 'modules/ui';
import { useParcels } from 'modules/entities/modules/parcels';
import { ErrorMessage } from 'modules/errors';

import { ParcelRow } from '../../ParcelRow';

import * as felaRules from './OrderParcels.rules';

export interface OrderParcelsProps {
    orderNumber: string;
    withoutParcelId: string;
}

export const OrderParcels: FC<OrderParcelsProps> = ({ orderNumber, withoutParcelId }) => {
    const { parcels, api } = useParcels(orderNumber, { orderNumber });
    const { styles } = useFelaEnhanced(felaRules);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const otherParcels = useMemo(() => parcels.filter(parcel => parcel.id !== withoutParcelId), [
        parcels,
        withoutParcelId,
    ]);

    return (
        <ErrorMessage error={api.error ? { id: 'parcel.list.error' } : null}>
            <Loader show={!api.success}>
                {otherParcels.map((parcel, index) => (
                    <Fragment key={parcel.id}>
                        {index > 0 && <Separator />}
                        <ParcelRow data={parcel} />
                    </Fragment>
                ))}

                {otherParcels.length === 0 && (
                    <div className={styles.listEmpty}>
                        <FormattedMessage id="parcel.order.parcels.empty" />
                    </div>
                )}
            </Loader>
        </ErrorMessage>
    );
};

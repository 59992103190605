import { Children, isValidElement } from 'react';
import type { ReactNode } from 'react';
import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles';

import * as felaRules from './Box.rules';

export interface BoxProps {
    as?: keyof JSX.IntrinsicElements;
    space: number;
    spaceElement?: keyof JSX.IntrinsicElements;
    direction?: 'row' | 'column';
    children: ReactNode;
    extend?: RulesExtend<typeof felaRules>;
}

export const Box = ({
    as: Component = 'div',
    space,
    spaceElement: Container = 'div',
    direction = 'column',
    children,
    extend,
}: BoxProps) => {
    const { styles } = useFelaEnhanced(felaRules, { space, direction, extend });

    return (
        <Component className={styles.box}>
            {Children.toArray(children).map((child, index) => {
                const key = isValidElement(child) ? child.props.key : null;

                return (
                    <Container className={styles.itemContainer} key={key ?? index}>
                        {child}
                    </Container>
                );
            })}
        </Component>
    );
};

import { useCallback } from 'react';
import { push } from 'connected-react-router';
import { useLocation } from 'react-router-dom';
import type { Dayjs } from 'dayjs';
import { useDispatch } from 'react-redux';

import { useAppSelector } from 'hooks/redux';
import { fetchParcels as fetchParcelsActions } from 'modules/entities/modules/parcels/services/actions';
import { DEFAULT_GROUP } from 'modules/entities/constants';
import { PaymentMethod } from 'modules/order/modules/order-common/config';

import { parcelFiltersSelector } from '../services/selectors';
import { ParcelFilter } from '../constants';
import { DATE_PARAM_FORMAT } from '../config';
import type { ParcelFilterValues } from '../types';

const serializeFilter = <F extends keyof ParcelFilterValues>(
    filterName: F,
    value: NonNullable<ParcelFilterValues[F]>,
): string => {
    /**
     * We need to explicitly cast return value type as compiler is not able to narrow it down correctly
     * https://stackoverflow.com/questions/68897217/types-narrowing-with-generics
     *
     * Hopefully we have unit tests to ensure it's working
     */
    switch (filterName) {
        case ParcelFilter.CREATED_FROM:
        case ParcelFilter.CREATED_TO:
            return (value as Dayjs).format(DATE_PARAM_FORMAT);

        default:
            return value as string;
    }
};

export const useParcelsFilters = () => {
    const filters = useAppSelector(parcelFiltersSelector);
    const dispatch = useDispatch();

    const { search } = useLocation();

    const onChange = useCallback(
        (nextFilters: Partial<ParcelFilterValues>) => {
            const params = new URLSearchParams(search);

            if (
                nextFilters[ParcelFilter.PAYMENT_MODE] !== PaymentMethod.CashOnDelivery &&
                filters[ParcelFilter.PAYMENT_STATE]
            ) {
                params.delete(ParcelFilter.PAYMENT_STATE);
            }

            Object.entries(nextFilters).forEach(([name, value]) => {
                if (!value) {
                    params.delete(name);
                } else {
                    params.set(name, serializeFilter(name as ParcelFilter, value));
                }
            });

            dispatch([
                fetchParcelsActions.reset(DEFAULT_GROUP),
                push({
                    search: params.toString(),
                }),
            ]);
        },
        [dispatch, search, filters],
    );

    return { onChange, filters };
};

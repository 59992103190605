import { combineReducers } from 'redux';
import { reducer as routingHistory } from '@ackee/redux-utils/routing-history';

import { reducer as translateReducer } from 'modules/core/modules/localization';
import { connectRouterWithHistory, constants as routerConstants } from 'modules/core/modules/router';
import reducers from 'services/reducers';

export default combineReducers({
    ...reducers,
    translate: translateReducer,
    router: connectRouterWithHistory,
    [routerConstants.ROUTING_HISTORY_MODULE_NAME]: routingHistory,
});

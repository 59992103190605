import { getPartnerId } from '../services/sagas/getPartnerId';
import { invalidatePartner } from '../services/slices/partner';

export function* setPartnerIdHeader(request: Request) {
    const partnerId = yield* getPartnerId();

    if (partnerId) {
        request.headers.set('X-PartnerId', partnerId);
    }

    return request;
}

export { invalidatePartner };

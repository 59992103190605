import { containerReducer, basicResetReducer, basicApiReducer } from '@ackee/redux-utils';
import { types } from '../../actions';

const cancelDeliveryRequestReducer = basicResetReducer(
    basicApiReducer({
        actionTypes: {
            REQUEST: types.cancelParcelDelivery.CANCEL_REQUEST,
            SUCCESS: types.cancelParcelDelivery.CANCEL_SUCCESS,
            FAILURE: types.cancelParcelDelivery.CANCEL_FAILURE,
        },
    }),
    types.cancelParcelDelivery.CANCEL_RESET,
);

export default containerReducer({
    childReducer: cancelDeliveryRequestReducer,
    actionTypes: Object.values(types.cancelParcelDelivery),
    selectors: {
        itemId: action => action.meta.id,
    },
});

import { useMemo } from 'react';
import type { FC } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import type { RouteProps } from 'react-router-dom';
import config from 'config/config';

import { ParcelsListPage } from 'modules/parcels/modules/parcels-list';
import { ParcelDetailPage } from 'modules/parcels/modules/parcel-detail';

const Parcels: FC = () => {
    const routes = useMemo<RouteProps[]>(
        () => [
            {
                path: config.routes.parcel,
                exact: true,
                component: ParcelDetailPage,
            },
            {
                path: config.routes.parcels,
                exact: true,
                component: ParcelsListPage,
            },
            {
                render: () => <Redirect to={config.routes.parcels} />,
            },
        ],
        [],
    );

    return (
        <Switch>
            {routes.map((route, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Route key={index} {...route} />
            ))}
        </Switch>
    );
};

export default Parcels;

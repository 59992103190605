import React from 'react';
import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import * as felaRules from './QrCode.rules';

export interface QrCodeProps {
    extend?: RulesExtend<typeof felaRules>;
}

export const QrCode = ({ extend }: QrCodeProps) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });

    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.icon}>
            <path
                d="M9 4H5C4.44772 4 4 4.44772 4 5V9C4 9.55228 4.44772 10 5 10H9C9.55228 10 10 9.55228 10 9V5C10 4.44772 9.55228 4 9 4Z"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M7 17V17.01" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M19 4H15C14.4477 4 14 4.44772 14 5V9C14 9.55228 14.4477 10 15 10H19C19.5523 10 20 9.55228 20 9V5C20 4.44772 19.5523 4 19 4Z"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M7 7V7.01" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M9 14H5C4.44772 14 4 14.4477 4 15V19C4 19.5523 4.44772 20 5 20H9C9.55228 20 10 19.5523 10 19V15C10 14.4477 9.55228 14 9 14Z"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M17 7V7.01" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14 14H17" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20 14V14.01" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14 14V17" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14 20H17" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M17 17H20" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20 17V20" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

import React from 'react';
import type { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import arrayMutators from 'final-form-arrays';

import { useFelaEnhanced } from 'hooks';
import { useAppSelector } from 'hooks/redux';
import { ErrorMessage } from 'modules/errors';
import { Form, useFormSubmit } from 'modules/form';
import { selectLocationsEntities } from 'modules/entities';
import { Button } from 'modules/ui';
import { Arrow } from 'modules/ui/modules/icons';

import { initialOrderItem, NewOrderStep } from '../../constants';
import {
    PickupField,
    DeliveryField,
    DeliveryType,
    form,
    ItemsField,
    OrderMetaField,
    NewOrderFormField,
    OriginFieldItems,
} from '../../config';
import { newOrderForm } from '../../services/actions';

import { generateReferenceNumber } from '../../utils';
import PickupForm from '../PickupForm';
import DeliveryForm from '../DeliveryForm';
import ItemsForm from '../ItemsForm';
import OrderMetaForm from '../OrderMetaForm';
import NewOrderSection from './NewOrderSection';
import OrderSummaryWrapper from '../OrderSummaryWrapper';
import { getInitialValues as getPaymentMethodSelectValues } from '../PaymentMethodSelect';
import { getInitialValues as getAllowReturnInitialValues } from '../AllowReturnSelect';
import { getInitialValues as getCustomerDetailsInitialValues } from '../CustomerDetailsForm';

import type { NewOrderFormValues, NewOrderStepFormValidateWithMeta } from '../../types';

import * as felaRules from './NewOrderForm.rules';
import { usePartner } from 'modules/partner/hooks';

export interface NewOrderFormProps {
    step: NewOrderStep;
    onNextStep: () => void;
}

const formByStep: Record<NewOrderStep, FC & { validate?: NewOrderStepFormValidateWithMeta<NewOrderFormField> }> = {
    [NewOrderStep.Pickup]: PickupForm,
    [NewOrderStep.Delivery]: DeliveryForm,
    // TODO - solve typing of this in the future
    // @ts-expect-error
    [NewOrderStep.Items]: ItemsForm,
    [NewOrderStep.Order]: OrderMetaForm,
};

const staticInitialValues = {
    [PickupField.Location]: undefined,

    [DeliveryField.DeliveryType]: DeliveryType.Location,
    [DeliveryField.ApmNumber]: undefined,
    [DeliveryField.PartnerId]: undefined,
    [ItemsField.Items]: [initialOrderItem],
    [OrderMetaField.Notify]: false,
    ...getPaymentMethodSelectValues(),
    ...getAllowReturnInitialValues(),
    ...getCustomerDetailsInitialValues(),
};

const NewOrderForm = ({ step, onNextStep }: NewOrderFormProps) => {
    const { rules } = useFelaEnhanced(felaRules);
    const onSubmit = useFormSubmit<NewOrderFormValues>(newOrderForm);
    const partner = usePartner();

    const initialValues = React.useMemo(
        () => ({
            [DeliveryField.ReferenceNumber]: generateReferenceNumber(),
            [DeliveryField.Origin]: {
                [OriginFieldItems.OriginContactEmail]: partner?.email,
                [OriginFieldItems.OriginContactNumber]: partner?.phoneNumber,
            },
            ...staticInitialValues,
        }),
        [partner],
    );

    const { formatMessage } = useIntl();
    const locationsEntities = useAppSelector(selectLocationsEntities);
    const validate = (values: NewOrderFormValues) => {
        const formValidate = formByStep[step]?.validate;

        if (formValidate) {
            return formValidate(values, formatMessage, { locations: locationsEntities });
        }

        return {};
    };

    return (
        <Form<NewOrderFormValues>
            name={form.newOrder}
            mutators={{
                ...arrayMutators,
            }}
            onSubmit={onSubmit}
            subscription={{ submitting: true, submitError: true, hasValidationErrors: true }}
            initialValues={initialValues}
            validate={validate}
            extend={{ form: rules.form }}
        >
            {({ submitting, submitError, form: { mutators }, hasValidationErrors }) => (
                <>
                    <NewOrderSection visible={step === NewOrderStep.Pickup}>
                        <PickupForm />
                    </NewOrderSection>
                    <NewOrderSection visible={step === NewOrderStep.Delivery}>
                        <DeliveryForm />
                    </NewOrderSection>
                    <NewOrderSection visible={step === NewOrderStep.Items}>
                        <ItemsForm addItem={() => mutators.push(ItemsField.Items, initialOrderItem)} />
                    </NewOrderSection>
                    <NewOrderSection visible={step === NewOrderStep.Order}>
                        <OrderMetaForm />
                    </NewOrderSection>
                    <NewOrderSection visible={step === NewOrderStep.Summary}>
                        <OrderSummaryWrapper />
                        {submitError && <ErrorMessage error={submitError} />}
                        <Button
                            type={Button.Type.PRIMARY}
                            size={Button.Size.BIG}
                            htmlType="submit"
                            disabled={submitting}
                            extend={{ button: rules.button }}
                        >
                            <FormattedMessage id="order.new.button.create" />
                        </Button>
                    </NewOrderSection>

                    {step !== NewOrderStep.Summary && (
                        <Button
                            type={Button.Type.PRIMARY}
                            size={Button.Size.BIG}
                            icon={<Arrow />}
                            isIconSuffix
                            onClick={onNextStep}
                            disabled={hasValidationErrors}
                            extend={{ button: rules.button }}
                        >
                            <FormattedMessage id={`order.new.button.continue.${step}.next`} />
                        </Button>
                    )}
                </>
            )}
        </Form>
    );
};

export default NewOrderForm;

import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import type { TypeOptions } from 'react-toastify';
import { Button } from 'antd';

import { useFelaEnhanced } from 'hooks';
import type { Action, IntlMessage } from 'types';

import * as felaRules from './Toast.styles';

interface ButtonProp extends IntlMessage {
    dispatchOnClick?: Action;
    onClick?: () => void;
}

export interface ToastProps {
    text: IntlMessage;
    button?: ButtonProp;
    type: TypeOptions;
}

const Toast: FC<ToastProps> = ({ text, button, type }) => {
    const { styles } = useFelaEnhanced(felaRules, { button, type });
    const dispatch = useDispatch();

    return (
        <div className={styles.container}>
            <FormattedMessage {...text} />
            {button && (
                <Button
                    size="small"
                    onClick={button.dispatchOnClick ? () => dispatch(button.dispatchOnClick) : button.onClick}
                    className={styles.button}
                >
                    <FormattedMessage id={button.id} values={button.values} />
                </Button>
            )}
        </div>
    );
};

export default Toast;

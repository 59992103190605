import React from 'react';
import type { FC, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { useFelaEnhanced } from 'hooks';

import * as rules from './FormWrapper.styles';

export interface FormWrapperProps {
    withNote?: boolean;
    children: ReactNode;
}

const FormWrapper: FC<FormWrapperProps> = ({ children, withNote = true }) => {
    const { styles } = useFelaEnhanced(rules);

    return (
        <div className={styles.container}>
            {withNote && (
                <p className={styles.note}>
                    <span className={styles.requiredMark}>*</span> <FormattedMessage id="general.form.requiredFields" />
                </p>
            )}
            {children}
        </div>
    );
};

export default FormWrapper;

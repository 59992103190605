import { type Country, defaultCountry } from 'modules/localization';
import { usePartner } from 'modules/partner/hooks';

export const usePartnerCountry = () => {
    const partner = usePartner();

    if (!partner?.region) {
        return defaultCountry.toUpperCase() as Uppercase<Country>;
    }

    // Example: el-GR
    const [, country] = partner?.region.split('-');

    return country as Uppercase<Country>;
};

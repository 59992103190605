import type { ReactNode } from 'react';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles';

import * as felaRules from './PanelFooter.rules';

export interface PanelFooterProps {
    children: ReactNode;
    extend?: RulesExtend<typeof felaRules>;
}

export const PanelFooter = ({ children, extend }: PanelFooterProps) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });

    return <div className={styles.footer}>{children}</div>;
};

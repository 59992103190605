import type { TRuleWithTheme } from 'styles/theme';

const counterName = 'step';

export const steps: TRuleWithTheme = ({ theme: { metrics, colors } }) => ({
    width: 'auto',
    display: 'inline-flex',
    justifyContent: 'space-between',

    fontWeight: 400,

    '&.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item': {
        padding: '6px 16px',
    },

    '& .ant-steps-item': {
        flex: '1 0 auto',
        marginRight: metrics.spacing * 10,

        counterIncrement: counterName,

        '::after': {
            content: 'none',
        },
    },

    '& .ant-steps-item-container': {
        display: 'inline-flex',
        flexDirection: 'row-reverse',
    },

    '& .ant-steps-item-icon': {
        display: 'none',
    },

    '& .ant-steps-item-title': {
        paddingRight: 0,
        lineHeight: '20px',

        ':before': {
            content: `counter(${counterName}) ". "`,
        },

        ':after': {
            height: 0,
        },
    },

    '& .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title': {
        color: colors.foregroundsPrimary80,
    },

    '& .ant-steps-item-finish > .ant-steps-item-container': {
        '& > .ant-steps-item-icon': {
            display: 'inline-flex',
            height: metrics.spacing * 2.5,
            marginRight: 0,
            marginLeft: metrics.spacing * 1.5,

            '& .ant-steps-icon': {
                display: 'inline-block',
                width: metrics.spacing * 2.5,
                height: metrics.spacing * 2.5,

                fontSize: 'initial',
                lineHeight: 'initial',
            },
        },

        '& > .ant-steps-item-content > .ant-steps-item-title': {
            color: colors.fgOnPrimary,
        },
    },

    '& .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title': {
        color: colors.buttonEnabledText,
    },
});

export const step: TRuleWithTheme = ({ theme }) => ({
    backgroundColor: 'transparent',

    borderRadius: 5,
    transition: 'ease-in-out 0.3s background-color',

    cursor: 'default',

    '&.ant-steps-item-finish': {
        backgroundColor: theme.colors.accentPrimary,
        cursor: 'pointer',
    },

    '&.ant-steps-item-active': {
        backgroundColor: theme.colors.accentTertiary,
    },
});

export const icon: TRuleWithTheme = ({ theme }) => ({
    width: theme.metrics.spacing * 2.5,
    height: theme.metrics.spacing * 2.5,
});

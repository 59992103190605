import type { IStyle } from 'fela';

const animations = {
    spin: (animationName: string | IStyle) => ({
        animationName,
        animationDuration: '3s',
        animationIterationCount: 'infinite',
        animationTimingFunction: 'linear',
    }),
};

export default animations;

import type { TRuleWithTheme } from 'styles';
import type { HeadlineProps } from './Headline';

type StyleProps = Required<Pick<HeadlineProps, 'level'>>;

const styleBySize = {
    1: {
        fontSize: '1.625rem',
        lineHeight: '1.9375rem',
    },
    2: {
        fontSize: '1.375rem',
        lineHeight: '1.625rem',
    },
};

export const headline: TRuleWithTheme<StyleProps> = ({ theme: { colors, metrics }, level }) => ({
    '&.ant-typography': {
        fontWeight: 700,
        ...styleBySize[level],
        color: colors.fgPrimary,
        marginBottom: metrics.spacing * 1.5,
    },
});

import type { Required as UtilityRequired } from 'utility-types';
import type { ErrorUIMessages } from '../types';

export const defaultErrorUIMessages: UtilityRequired<ErrorUIMessages, 'code' | 'status' | 'fallback'> = {
    code: {
        // e.g.:
        // TOPIC_URL_SLUG_CONFLICT: {
        //     id: 'error.api.topic.urlSlugConflict',
        // },
    },
    status: {
        500: {
            id: 'error.status.500',
        },
    },
    fallback: {
        id: 'error.general',
    },
} as const;

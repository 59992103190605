import type { FC } from 'react';

import { Field } from 'react-final-form';
import PhoneInput from 'react-phone-input-2';
import type { PhoneInputProps } from 'react-phone-input-2';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import FormItem from '../FormItem';
import { useValidationDebounce } from '../../hooks';
import type { TextFieldProps } from '../types';

import * as felaRules from './PhoneField.rules';

// import reactPhoneInputStyles from 'react-phone-input-2/lib/high-res.css';
import('react-phone-input-2/lib/high-res.css');

// react-phone-input-2@2.14.0 doesn't export this interface
interface CountryData {
    name: string;
    dialCode: string;
    countryCode: string;
    format: string;
}

export interface PhoneFieldValue {
    phone: string | undefined;
    dialCode: string;
}
export interface PhoneFieldOwnProps extends TextFieldProps<PhoneFieldValue> {
    extend?: RulesExtend<typeof felaRules>;
    reactPhoneInput?: PhoneInputProps;
}

const PhoneField: FC<PhoneFieldOwnProps> = ({
    label,
    required = false,
    validate,
    help,
    extend,
    reactPhoneInput = {},
    ...props
}) => {
    // const rendered = useRenderStaticStyle(loader);

    const { styles } = useFelaEnhanced(felaRules, { extend, ...props });
    const validateDebounced = useValidationDebounce(validate);

    // if (!rendered) {
    //     return null;
    // }

    return (
        <Field<PhoneFieldValue> validate={validateDebounced} validateFields={[]} {...props}>
            {({ input, meta }) => (
                <FormItem<PhoneFieldValue> {...{ input, meta, required, help, label }}>
                    <PhoneInput
                        disabled={props.disabled}
                        {...input}
                        value={input.value?.phone}
                        onChange={(value, { dialCode }: CountryData) => {
                            input.onChange({
                                phone: value ? `+${value}` : value,
                                dialCode,
                            });
                        }}
                        {...reactPhoneInput}
                        containerClass={styles.container}
                        buttonClass={styles.button}
                        dropdownClass={styles.dropdown}
                        inputProps={{
                            ...reactPhoneInput.inputProps,
                            id: input.name,
                            name: input.name,
                        }}
                    />
                </FormItem>
            )}
        </Field>
    );
};

export default PhoneField;

import type { ParcelState, PaymentMode, PaymentState } from '../types';

export const DATE_PARAM_FORMAT = 'YYYY-MM-DD';

export const SEARCH_DEBOUNCE_TIMEOUT = 700;

export const parcelStates: ParcelState[] = [
    'new',
    'in-transit',
    'in-final-destination',
    'delivered',
    'returned',
    'expired-return',
    'canceled',
    'lost',
    'missing',
];

export const paymentModes: PaymentMode[] = ['cod', 'prepaid'];

export const paymentStates: PaymentState[] = ['pending', 'paid-by-customer', 'transferred-to-partner'];

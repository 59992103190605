import { createSelector } from 'reselect';

import type { RootState } from 'modules/core/modules/redux';
import { selectEntrustedPartnersEntities } from 'modules/entities/services/selectors';

export const partnerIdSelector = (state: RootState) => state.partner?.partnerId;

export const partnerSelector = createSelector(
    partnerIdSelector,
    selectEntrustedPartnersEntities,
    (partnerId, entrustedPartners) => (partnerId && entrustedPartners.byId[partnerId]) || null,
);

export const partnerModalSelector = (state: RootState) => state.partner.modal;

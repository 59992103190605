import { all, takeLatest } from 'redux-saga/effects';

import { invalidatePartner, removePartner, setPartnerId } from '../slices/partner';
import { localStorageKey } from '../../constants';

function syncLocalStorage(action: ReturnType<typeof setPartnerId>) {
    const partnerId = action.payload;

    localStorage.setItem(localStorageKey, partnerId);
}

function removeLocalStorage() {
    localStorage.removeItem(localStorageKey);
}

export default function* syncLocalStorageSaga() {
    yield all([
        takeLatest(setPartnerId.toString(), syncLocalStorage),
        takeLatest(invalidatePartner.toString(), removeLocalStorage),
        takeLatest(removePartner.toString(), removeLocalStorage),
    ]);
}

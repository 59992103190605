import type { TRuleWithTheme } from 'styles';

export const subtitle: TRuleWithTheme = ({ theme }) => ({
    marginTop: theme.metrics.spacing * 2,
});

export const panel: TRuleWithTheme = ({ theme: { metrics } }) => ({
    minHeight: 460,
    marginTop: metrics.spacing * 4,
    marginBottom: metrics.spacing * 4,
});

export const loader: TRuleWithTheme = ({ theme }) => ({
    marginTop: theme.metrics.spacing * 5,
    width: 420,
});

import { useEffect } from 'react';

import { useAppDispatch } from 'hooks/redux';
import type { Partner } from 'modules/entities/types';
import { setPartnerId } from '../../services/slices/partner';

export interface ChoosePartnerAutomaticallyProps {
    partner: Partner;
}

export const ChoosePartnerAutomatically = ({ partner }: ChoosePartnerAutomaticallyProps) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setPartnerId(partner.id));
    }, [dispatch, partner]);

    return null;
};

import { useEffect } from 'react';
import { useForm } from 'react-final-form';

export interface RemoveFieldsProps {
    fields?: string[];
}

export const RemoveFields = ({ fields }: RemoveFieldsProps) => {
    const form = useForm();

    useEffect(() => {
        if (fields) {
            form.batch(() => {
                fields.forEach(field => {
                    form.change(field, undefined);
                });
            });
        }
    }, [form, fields]);

    return null;
};

import { FormattedMessage } from 'react-intl';

import config from 'config';
import { DropdownButton } from 'modules/ui';
import { useRestrictedRoutes } from 'modules/permissions';

const items = [
    {
        link: { pathname: config.routes.newOrder },
        key: 'order.complex.create.button',
        label: <FormattedMessage id="order.complex.create.button" />,
        description: <FormattedMessage id="order.complex.create.button.description" />,
    },
    {
        link: { pathname: config.routes.fromAnyApmOrder },
        key: 'order.fromAnyApm.create.button',
        label: <FormattedMessage id="order.fromAnyApm.create.button" />,
        description: <FormattedMessage id="order.fromAnyApm.create.button.description" />,
    },
    {
        link: { pathname: config.routes.fromAnyToSameApmOrder },
        key: 'order.fromAnyToSameApm.create.button',
        label: <FormattedMessage id="order.fromAnyToSameApm.create.button" />,
        description: <FormattedMessage id="order.fromAnyToSameApm.create.button.description" />,
    },
    {
        link: { pathname: config.routes.fromWarehouseOrder },
        key: 'order.fromWarehouse.create.button',
        label: <FormattedMessage id="order.fromWarehouse.create.button" />,
        description: <FormattedMessage id="order.fromWarehouse.create.button.description" />,
    },
    {
        link: { pathname: config.routes.fromWarehouseToWarehouseOrder },
        key: 'order.fromWarehouseToWarehouse.create.button',
        label: <FormattedMessage id="order.fromWarehouseToWarehouse.create.button" />,
        description: <FormattedMessage id="order.fromWarehouseToWarehouse.create.button.description" />,
    },
    {
        link: { pathname: config.routes.customerReturn },
        key: 'order.customerReturn.create.button',
        label: <FormattedMessage id="order.customerReturn.create.button" />,
        description: <FormattedMessage id="order.customerReturn.create.button.description" />,
    },
];

export const NewOrderDropdownButton = () => {
    const restrictedRoutes = useRestrictedRoutes();
    const filteredItems = items.filter(item => restrictedRoutes && !restrictedRoutes.has(item.link.pathname));

    return (
        <DropdownButton
            type={DropdownButton.Type.PRIMARY}
            label={<FormattedMessage id="order.create.button" />}
            items={filteredItems}
        />
    );
};

import type { ReactNode } from 'react';

import { useFelaEnhanced } from 'hooks';
import { Box, Headline } from 'modules/ui';

import * as felaRules from './PageHeader.rules';

export interface PageHeaderProps {
    title?: ReactNode;
    children?: ReactNode;
    horizontal?: boolean;
}

export const PageHeader = ({ title, horizontal = false, children = null }: PageHeaderProps) => {
    const { theme, rules } = useFelaEnhanced(felaRules, { horizontal });
    const { spacing } = theme.metrics;

    return (
        <Box
            space={spacing * (horizontal ? 5 : 3)}
            direction={horizontal ? 'row' : 'column'}
            extend={{ box: horizontal ? rules.horizontalBox : undefined }}
        >
            {children}
            {title && (
                <Headline level={1} extend={{ headline: rules.title }}>
                    {title}
                </Headline>
            )}
        </Box>
    );
};

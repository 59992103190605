import type { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';
import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import type { LoaderProps as UILoaderProps } from 'modules/ui/components/Loader';
import { Loading } from 'modules/ui/modules/icons';
import * as felaRules from './Loader.rules';

export interface LoaderProps extends Omit<UILoaderProps, 'icon' | 'suffix' | 'extend'> {
    show: boolean;
    extend?: RulesExtend<typeof felaRules>;
}

export const Loader = ({ show, children, extend, ...props }: PropsWithChildren<LoaderProps>) => {
    const { styles, rules } = useFelaEnhanced(felaRules, { extend });

    return show ? (
        <div className={styles.container}>
            <Loading extend={{ icon: rules.loadingIcon }} />
            <div className={styles.text}>
                <FormattedMessage id="general.loading" />
            </div>
            {/* TODO: Use ProgressBar when implementing percentage progress */}
            {/* <ProgressBar percent={percent} /> */}
        </div>
    ) : (
        <>{children}</>
    );
};

import type { FC, ReactNode } from 'react';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import * as felaRules from './ParcelPanelCell.rules';

export interface ParcelPanelCellProps {
    children: ReactNode;
    extend?: RulesExtend<typeof felaRules>;
}

export const ParcelPanelCell: FC<ParcelPanelCellProps> = ({ children, extend }) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });
    return <div className={styles.cell}>{children}</div>;
};

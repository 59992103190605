import type { ReactNode } from 'react';
import { useFelaEnhanced } from 'hooks';
import { Field } from 'react-final-form';

import type { RulesExtend } from 'styles/theme';

import FormItem from '../FormItem';
import Select from '../Select';
import type { FormFieldProps } from '../types';

import * as felaRules from './SelectField.rules';

export interface SelectFieldProps<TValue> extends FormFieldProps<TValue> {
    children: ReactNode;
    extend?: RulesExtend<typeof felaRules>;
}

const SelectField = <TValue extends string | number = string>({
    label,
    required,
    name,
    validate,
    help,
    children,
    value,
    loading,
    placeholder,
    extend,
    defaultValue,
    ...props
}: SelectFieldProps<TValue>) => {
    const { rules } = useFelaEnhanced(felaRules, { extend });

    return (
        <Field<TValue> type="select" {...{ name, value, validate, defaultValue }} validateFields={[]} allowNull>
            {({ input, meta }) => (
                <FormItem<TValue> {...{ input, meta, required, help, label, extend: { formItem: rules.formItem } }}>
                    <Select<TValue>
                        {...input}
                        loading={loading}
                        placeholder={placeholder}
                        extend={{ select: rules.select }}
                        {...props}
                    >
                        {children}
                    </Select>
                </FormItem>
            )}
        </Field>
    );
};

SelectField.Option = Select.Option;
SelectField.OptGroup = Select.OptGroup;

export default SelectField;

import { useEffect, type FC } from 'react';
import type { match, RouteComponentProps } from 'react-router-dom';

import { useFelaEnhanced, useGoBack } from 'hooks';
import { useParcels } from 'modules/entities/modules/parcels';
import { Loader, BackButton } from 'modules/ui';
import { Layout } from 'modules/layout';
import { ErrorMessage } from 'modules/errors';

import ParcelDetail from '../ParcelDetail';
import * as felaRules from './ParcelDetailPage.rules';
import { useDispatch } from 'react-redux';
import { removePartner } from 'modules/partner';
import { apiActions } from 'modules/entities/modules/entrustedPartners';

export interface ParcelDetailPageProps extends RouteComponentProps {
    match: match<{ id: string }>;
}

const ParcelDetailPage: FC<ParcelDetailPageProps> = ({
    match: {
        params: { id },
    },
}) => {
    const { rules } = useFelaEnhanced(felaRules);
    const goBack = useGoBack();
    const { api, parcels } = useParcels(id, { parcelId: id }, { resetOnUnmount: true });
    const dispatch = useDispatch();

    useEffect(() => {
        if (api.error && api.error === '403') {
            dispatch(removePartner());
            dispatch(apiActions.fetchEntrustedPartners.request());
        }
    }, [api.error, dispatch]);

    return (
        <Layout>
            <BackButton extend={{ button: rules.backButton }} text={{ id: 'parcel.detail.back' }} onClick={goBack} />

            <ErrorMessage error={api.error ? { id: 'error.parcel.get', values: { id } } : null}>
                <Loader show={!api.success}>{parcels.length > 0 && <ParcelDetail data={parcels[0]} />}</Loader>
            </ErrorMessage>
        </Layout>
    );
};

export default ParcelDetailPage;

import { useMemo } from 'react';

import { Redirect } from 'react-router-dom';
import type { RouteProps } from 'react-router-dom';

import config from 'config';

import { useRestrictedRoutes } from './useRestrictedRoutes';

type RoutePath = string | readonly string[];

function isRestrictedRoute(restrictedRoutes: Set<string> | null, path?: RoutePath) {
    if (!restrictedRoutes || path === undefined) {
        return false;
    }

    return typeof path === 'string' ? restrictedRoutes.has(path) : path.some(path => restrictedRoutes.has(path));
}

function isAllowedRoute(restrictedRoutes: Set<string> | null, path?: RoutePath) {
    if (!restrictedRoutes || path === undefined) {
        return true;
    }

    return typeof path === 'string' ? !restrictedRoutes.has(path) : path.every(path => !restrictedRoutes.has(path));
}

export function useAllowedRoutes(routes: RouteProps[]) {
    const restrictedRoutes = useRestrictedRoutes();

    return useMemo(() => {
        const restrictedPaths = routes
            .filter(route => isRestrictedRoute(restrictedRoutes, route.path))
            .map(route => route.path)
            .flat(1);

        const allowedRoutes = routes.filter(route => isAllowedRoute(restrictedRoutes, route.path));

        if (restrictedPaths.length > 0) {
            allowedRoutes.unshift({
                // TODO - solve compiler error
                // @ts-expect-error
                path: restrictedPaths,
                exact: true,
                render: () => <Redirect to={config.routes.home} />,
            });
        }

        return allowedRoutes;
    }, [restrictedRoutes, routes]);
}

export const form = {
    newOrder: 'newOrder',
    orderFromWarehouse: 'orderFromWarehouse',
    orderFromWarehouseToWarehouse: 'orderFromWarehouseToWarehouse',
    orderFromAnyApm: 'orderFromAnyApm',
    orderFromCsv: 'orderFromCsv',
    customerReturn: 'customerReturn',
} as const;

export const csvExampleLinks = {
    fromWarehouse: 'https://drive.google.com/file/d/1KkxYnAkBMQXQDU2m7nUzvl3mlUxMhBCi/view?usp=sharing',
    fromAnyApm: 'https://drive.google.com/file/d/1tCBpvMCj_fh6ed9EdXcp6XlNmbSlik9p/view?usp=sharing',
} as const;

export const GRID_COLUMNS = `1fr 1fr 1fr 2fr`;

export enum PickupField {
    Location = 'location',
}

export enum DeliveryType {
    Location = 'location',
    Address = 'address',
    Warehouse = 'warehouse',
}

export enum DeliveryField {
    Fullname = 'fullname',
    PhoneNumber = 'phone',
    Email = 'email',
    ReferenceNumber = 'referenceNumber',
    DeliveryType = 'deliveryType',
    ApmNumber = 'apmNumber',
    WarehouseNumber = 'warehouseNumber',
    Street = 'street',
    City = 'city',
    PostalCode = 'postalCode',
    Country = 'country',
    Note = 'note',
    PartnerId = 'partnerId',
    ShowRecipientInformation = 'showRecipientInformation',
    Origin = 'origin',
}

export enum ItemsField {
    Items = 'items',
}

export enum AnyApmFields {
    CompartmentSize = 'anyApmCompartmentSize',
}

export enum OrderItemField {
    Name = 'name',
    Value = 'value',
    Size = 'compartmentSize',
    Weight = 'weight',
}

export enum OriginFieldItems {
    OriginContactEmail = 'originContactEmail',
    OriginContactNumber = 'originContactNumber',
}

export enum OrderMetaField {
    InvoiceValue = 'invoiceValue',
    PaymentMethod = 'paymentMethod',
    CollectAmount = 'collectAmount',
    AllowReturn = 'allowReturn',
    Notify = 'notify',
    NotificationEmail = 'notificationEmail',
}

export type NewOrderFormField = DeliveryField | ItemsField | OrderMetaField | PickupField;

export enum CustomerReturnField {
    Destination = 'destination',
    CompartmentSize = 'size',
}

import { useState, useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Field, useForm } from 'react-final-form';
import { TextField } from 'modules/form';
import { useAppSelector } from 'hooks/redux';
import { partnerIdSelector } from 'modules/partner';
import DestinationAddress from '../DestinationAddress';
import { useFelaEnhanced } from 'hooks';
import * as felaRules from './DeliveryLocation.rules';
import config from 'config';
import type { MessageKey } from 'translations';
import { isEnvStage } from 'constants/env';
export interface DeliveryLocationFieldsWithWidgetProps {
    name: string;
    label: MessageKey;
    placeholder: MessageKey;
    compSize?: string;
    orderTypePathname: string;
    region: string | null;
}

interface WidgetLockerDetails {
    boxnowLockerId?: string;
    boxnowLockerPostalCode: string;
    boxnowLockerName: string;
    boxnowLockerAddressLine1: string;
}

const DeliveryLocationFieldsWithWidget = ({
    name,
    label,
    placeholder,
    compSize,
    orderTypePathname,
    region,
}: DeliveryLocationFieldsWithWidgetProps) => {
    const { formatMessage } = useIntl();
    const { rules } = useFelaEnhanced(felaRules);
    const [lockerDetails, setLockerDetails] = useState<WidgetLockerDetails>({
        boxnowLockerId: '',
        boxnowLockerPostalCode: '',
        boxnowLockerName: '',
        boxnowLockerAddressLine1: '',
    });

    const form = useForm();
    const partnerSelect = useAppSelector(partnerIdSelector);
    const partnerRegion = region?.toLowerCase();

    const widgetBaseUrl = isEnvStage
        ? `https://widget-v5-stage.boxnow.${partnerRegion}`
        : `https://widget-v5.boxnow.${partnerRegion}`;

    const handleLockerSelection = useCallback(
        (event: MessageEvent<WidgetLockerDetails>) => {
            const data = event.data;
            if (data && data.boxnowLockerId) {
                setLockerDetails({
                    boxnowLockerId: data.boxnowLockerId,
                    boxnowLockerPostalCode: data.boxnowLockerPostalCode,
                    boxnowLockerName: data.boxnowLockerName,
                    boxnowLockerAddressLine1: data.boxnowLockerAddressLine1,
                });
                // Update form field for ID only
                form.change(name, data.boxnowLockerId);
            }
        },
        [form, name],
    );

    // Retrieve widget info from postMessage
    useEffect(() => {
        window.addEventListener('message', handleLockerSelection);

        return () => {
            window.removeEventListener('message', handleLockerSelection);
        };
    }, [handleLockerSelection]);

    // Reset the field value when switching away
    useEffect(() => {
        return () => {
            form.change(name, undefined); // Reset the field value on unmount
        };
    }, [form, name]);

    // Combine address details for display
    const displayValue = lockerDetails.boxnowLockerId
        ? `${lockerDetails.boxnowLockerName}, ${lockerDetails.boxnowLockerAddressLine1}, ${lockerDetails.boxnowLockerPostalCode}`
        : '';

    return (
        <>
            <Field name={name}>
                {({ input, meta }) => (
                    <TextField
                        {...input}
                        value={displayValue} // Use displayValue for the visual display
                        label={formatMessage({ id: label })}
                        placeholder={formatMessage({ id: placeholder })}
                        required
                        autoComplete="off"
                        readOnly
                    />
                )}
            </Field>
            <Field name={name} subscription={{ value: true }}>
                {({ input }) =>
                    input.value ? (
                        <DestinationAddress destinationId={input.value} extend={{ panel: rules.address }} />
                    ) : null
                }
            </Field>
            <iframe
                title="boxnow-map-widget"
                width="100%"
                height="650px"
                src={
                    orderTypePathname === config.routes.newOrder ||
                    orderTypePathname === config.routes.fromWarehouseOrderForm
                        ? `${widgetBaseUrl}?&partnerId=${partnerSelect}`
                        : `${widgetBaseUrl}?&size=${compSize ? compSize : 2}`
                }
                allow="geolocation"
                style={{ border: 0, borderRadius: 5 }}
            />
        </>
    );
};

export default DeliveryLocationFieldsWithWidget;

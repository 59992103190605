import React from 'react';
import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import * as felaRules from './Add.rules';

export interface AddProps {
    extend?: RulesExtend<typeof felaRules>;
}

export const Add = ({ extend }: AddProps) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });

    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.icon}>
            <path
                d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M9 12H15" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 9V15" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

import type { TRuleWithTheme } from 'styles/theme';

export const uploadItem: TRuleWithTheme = ({ theme: { spacing, colors } }) => ({
    display: 'grid',
    gridTemplateColumns: '24px 1fr 24px',
    justifyContent: 'space-between',
    alignItems: 'center',
    columnGap: spacing.unit(0.75),
    borderRadius: 5,
    paddingTop: spacing.unit(1.5),
    paddingRight: spacing.unit(1.5),
    paddingBottom: spacing.unit(1.5),
    paddingLeft: spacing.unit(1.5),
    backgroundColor: colors.newBgPrimary,
});

export const name: TRuleWithTheme = ({ theme: { colors, font } }) => ({
    ...font.sizeAndHeight(16, 20),
    color: colors.text,
    marginLeft: 0,
    marginRight: 'auto',
});

export const buttonRemove: TRuleWithTheme = ({ theme: { colors } }) => ({
    all: 'unset',
    cursor: 'pointer',
    color: colors.buttonPrimaryDestructive,
    height: 24,
});

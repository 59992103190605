import type { TRuleWithTheme } from 'styles/theme';

export const step: TRuleWithTheme = () => ({
    cursor: 'default',
});

export const panel: TRuleWithTheme = () => ({
    marginTop: 20,
    marginBottom: 32,

    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
});

export const panelBody: TRuleWithTheme = () => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
});

export const backButton: TRuleWithTheme = () => ({});

import { useAppSelector } from 'hooks/redux';

import { createCsvImportApiSelector, selectCsvImport, selectCsvImportParcelLabelsUrl } from '../services/selectors';

export const useCsvImport = () => {
    const api = useAppSelector(createCsvImportApiSelector);
    const csvImportData = useAppSelector(selectCsvImport);
    const parcelLabelsUrl = useAppSelector(selectCsvImportParcelLabelsUrl);

    return { api, csvImportData, parcelLabelsUrl } as const;
};

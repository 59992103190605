import type { TRuleWithTheme } from 'styles/theme';

export const successMessage: TRuleWithTheme = ({ theme }) => ({
    marginTop: theme.metrics.spacing,
    marginBottom: theme.metrics.spacing * 5,
});

export const header: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    marginBottom: theme.metrics.spacing * 2.5,
});

export const title: TRuleWithTheme = () => ({
    '&.ant-typography': {
        marginBottom: 0,
    },
});

export const parcel: TRuleWithTheme = ({ theme: { metrics } }) => ({
    paddingTop: metrics.spacing * 1.25,
    paddingBottom: metrics.spacing * 1.25,
});

export const lastCell: TRuleWithTheme = () => ({
    display: 'flex',
    justifyContent: 'flex-end',
});

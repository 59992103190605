import { Fragment } from 'react';
import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { FieldArray } from 'react-final-form-arrays';
import { Divider } from 'antd';

import { useFelaEnhanced } from 'hooks';
import { FormWrapper, validateWithTranslation } from 'modules/form';
import { Button, Headline } from 'modules/ui';
import { Add, Delete } from 'modules/ui/modules/icons';
import type { Location } from 'modules/entities';

import { ItemsField, PickupField } from '../../config';
import { useSelectedOriginLocation } from '../../hooks';
import ItemForm, { getValidators as getItemValidators } from '../ItemForm';

import type { NewOrderStepFormValidateWithMeta } from '../../types';

import * as felaRules from './ItemsForm.rules';

const isSizeMandatory = (origin: Location | null) => origin?.type === 'any-apm';

const validate: NewOrderStepFormValidateWithMeta<ItemsField> = (values, formatMessage, { locations }) => {
    const itemsErrors: ReturnType<typeof validateWithTranslation>[] = [];

    const selectedLocation = locations.byId[values[PickupField.Location]];
    const sizeMandatory = isSizeMandatory(selectedLocation);

    const itemValidators = getItemValidators(sizeMandatory);

    (values[ItemsField.Items] as Record<string, string>[]).forEach((itemValues, index: number) => {
        itemsErrors[index] = validateWithTranslation(itemValues, itemValidators, formatMessage);
    });

    return { [ItemsField.Items]: itemsErrors };
};

export interface ItemsFormProps {
    addItem: () => void;
}

const ItemsForm: FC<ItemsFormProps> & { validate: typeof validate } = ({ addItem }) => {
    const { rules } = useFelaEnhanced(felaRules);
    const origin = useSelectedOriginLocation();
    const sizeMandatory = isSizeMandatory(origin);

    return (
        <FormWrapper withNote={false}>
            <Headline level={2}>
                <FormattedMessage id="order.items" />
            </Headline>

            <FieldArray name={ItemsField.Items}>
                {({ fields }) =>
                    fields.map((name, index) => (
                        <Fragment key={name}>
                            {index !== 0 && <Divider />}
                            <ItemForm itemName={name} isSizeMandatory={sizeMandatory} />
                            {fields.length && fields.length > 1 && (
                                <Button
                                    type={Button.Type.TERTIARY}
                                    icon={<Delete />}
                                    onClick={() => fields.remove(index)}
                                >
                                    <FormattedMessage id="order.items.remove" />
                                </Button>
                            )}
                        </Fragment>
                    ))
                }
            </FieldArray>

            <Button type={Button.Type.SECONDARY} icon={<Add />} extend={{ button: rules.addButton }} onClick={addItem}>
                <FormattedMessage id="order.items.add" />
            </Button>
        </FormWrapper>
    );
};

ItemsForm.validate = validate;

export default ItemsForm;

import config from 'config';
import { authApi } from 'config/antonio';
import * as Log from 'config/loglevel';
import type { paths } from 'api/partnerApiSchema';

type Path = typeof config.api.parcels;
type Operation = paths[`/api/v1${Path}`]['get'];
type ResponseData = Operation['responses']['200']['content']['application/json'];

export function* fetchParcel(parcelId: string) {
    try {
        const {
            data: { data },
        } = yield* authApi.get<ResponseData>(config.api.parcels, {
            params: { parcelId },
        });

        return data ? data[0] : null;
    } catch (e: unknown) {
        Log.error(e as Error);
        return null;
    }
}

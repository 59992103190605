import { configure } from '@ackee/petrus';
import * as Log from 'config/loglevel';

import authenticate from '../services/sagas/handlers/authenticate';
import refreshTokens from '../services/sagas/handlers/refreshTokens';
import getAuthUser from '../services/sagas/handlers/getAuthUser';
import type { AuthenticatePayload, AuthTokens, User } from '../types';
import type { RootState } from 'modules/core/modules/redux';

declare module '@ackee/petrus' {
    interface ConfigurePetrusAppRootState {
        value: RootState;
    }

    interface ConfigurePetrusUser {
        value: User;
    }

    interface ConfigurePetrusCredentials {
        value: AuthenticatePayload;
    }

    interface ConfigurePetrusTokens {
        value: AuthTokens;
    }
}

const { saga, reducer } = configure({
    handlers: {
        authenticate,
        refreshTokens,
        getAuthUser,
    },
    tokens: {
        checkTokenExpirationOnTabFocus: false,
    },
    logger: Log,
    selector: state => state.petrus,
});

export { saga, reducer };

import type { TRuleWithTheme } from 'styles/theme';

export const row: TRuleWithTheme = ({ theme: { metrics } }) => ({
    display: 'grid',
    gridTemplateColumns: '2fr 1fr 1fr 1fr 1fr',
    columnGap: metrics.spacing * 4,
    alignItems: 'flex-start',
});

export const rows: TRuleWithTheme = ({ theme: { metrics } }) => ({
    display: 'grid',
    rowGap: metrics.spacing * 4,
    paddingTop: metrics.spacing * 4,
    paddingBottom: metrics.spacing * 4,
});

export const noEvents: TRuleWithTheme = ({ theme: { metrics } }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: metrics.spacing * 2,
    paddingBottom: metrics.spacing * 2,
});

import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { useFelaEnhanced } from 'hooks';
import config from 'config/index';
import { Layout, PageHeader } from 'modules/layout';
import { BackButton, Panel } from 'modules/ui';

import { CustomerReturnForm } from '../CustomerReturnForm';

import * as felaRules from './CustomerReturnPage.rules';

export const CustomerReturnPage = () => {
    const { rules } = useFelaEnhanced(felaRules);

    return (
        <Layout>
            <PageHeader title={<FormattedMessage id="order.customerReturn.title" />}>
                <Link to={config.routes.home}>
                    <BackButton text={{ id: 'order.new.back' }} />
                </Link>
            </PageHeader>
            <Panel extend={{ panel: rules.panel }}>
                <CustomerReturnForm />
            </Panel>
        </Layout>
    );
};

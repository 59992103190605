import { useEffect } from 'react';
import type { ReactNode } from 'react';
import { useForm } from 'react-final-form';

export interface SetDefaultFieldsProps {
    children: ReactNode;
    fields?: Record<string, any>;
}

export const SetDefaultFields = ({ children, fields }: SetDefaultFieldsProps) => {
    const form = useForm();

    useEffect(() => {
        if (fields) {
            form.batch(() => {
                Object.entries(fields).forEach(([field, value]) => {
                    form.change(field, value);
                });
            });
        }
    }, [form, fields]);

    return <>{children}</>;
};

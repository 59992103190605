import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { Partner } from 'modules/entities';
import { localStorageKey } from 'modules/partner/constants';

type State = {
    partnerId: Partner['id'] | null;
    modal: {
        visible: boolean;
    };
};

const partnerSlice = createSlice({
    name: 'partner',
    initialState: {
        partnerId: localStorage.getItem(localStorageKey) || null,
        modal: {
            visible: false,
        },
    } as State,

    reducers: {
        setPartnerId: (state, action: PayloadAction<Partner['id']>) => ({
            ...state,
            partnerId: action.payload,
            modal: { visible: false },
        }),
        invalidatePartner: state => ({ ...state, partnerId: null }),
        removePartner: state => ({ ...state, partnerId: null }),
        showModal: state => ({ ...state, modal: { visible: true } }),
        hideModal: state => ({ ...state, modal: { visible: false } }),
    },
});

export const { reducer } = partnerSlice;
export const { setPartnerId, invalidatePartner, removePartner, showModal, hideModal } = partnerSlice.actions;

import { combineRules } from 'fela';
import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({});

const spinAnimationStyle: TRuleWithTheme = ({ theme }) => theme.animations.spin(theme.keyframes.spin);

export const loadingIcon: TRuleWithTheme = combineRules(spinAnimationStyle, () => ({
    width: 48,
    height: 48,
}));

export const text = () => ({
    marginTop: '0.5rem',
});

const config = {
    api: {
        base: 'https://api-stage.boxnow.gr/api/v1',
    },
    sentry: {
        dsn: 'https://0d6f0b50d31546b2a0b127f0f4ca63a1@o1006836.ingest.sentry.io/5980660',
    },
    trackBoxNowUrl: 'https://track.snkr.cz/',
    devTools: false,
};

export default config;

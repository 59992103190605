import { createApiRequestType } from '@ackee/redux-utils';
import type { ApiActionTypes } from '@ackee/redux-utils';
import { EntityKey } from 'modules/entities/constants';

const apiRequestType = createApiRequestType({
    modulePrefix: EntityKey.DELIVERY_REQUESTS,
});

export const createDeliveryRequest = apiRequestType({
    typePrefix: 'CREATE_',
});

export const updateDeliveryRequest = apiRequestType({}) as unknown as ApiActionTypes;

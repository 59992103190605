import { Radio } from 'antd';
import type { RadioButtonProps } from 'antd/lib/radio/radioButton';
import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import * as felaRules from './Tab.rules';

export interface TabProps extends Omit<RadioButtonProps, 'className'> {
    extend?: RulesExtend<typeof felaRules>;
}

export const Tab = ({ extend, ...props }: TabProps) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });

    return <Radio.Button className={styles.tab} {...props} />;
};

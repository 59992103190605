import { useEffect, type FC } from 'react';
import { useIntl } from 'react-intl';
import { toString as convertToString } from 'lodash';

import {
    validatorsWithMessage,
    TextField,
    RadioButtonGroupField,
    FormWrapper,
    Condition,
    validateWithTranslation,
    composeValidators,
} from 'modules/form';

import type { NewOrderStepFormValidate } from 'modules/order/modules/order-create/types';
import { useCurrencyPostfix } from 'modules/currency';
import { greaterThanOrEqual } from 'modules/form/services/utils/validation/validatorsWithMessage';

import { DeliveryField, DeliveryType, OrderMetaField } from '../../config';
import { PaymentMethodSelect, getValidators as getPaymentMethodValidators } from '../PaymentMethodSelect';
import { AllowReturnSelect, getValidators as getAllowReturnValidators } from '../AllowReturnSelect';
import { useField, useForm } from 'react-final-form';
import { PaymentMethod } from 'modules/order/modules/order-common';

const { required, email } = validatorsWithMessage;

const validators = {
    [OrderMetaField.InvoiceValue]: composeValidators(required, greaterThanOrEqual(0)),
    [OrderMetaField.Notify]: required,
    ...getAllowReturnValidators(),
};

const notifyAllowedValidators = {
    [OrderMetaField.NotificationEmail]: email,
};

const validate: NewOrderStepFormValidate<OrderMetaField> = (values, formatMessage) => {
    const formValidators = {
        ...validators,
        ...(values[OrderMetaField.Notify] === true ? notifyAllowedValidators : {}),
        ...getPaymentMethodValidators(values),
    };

    return validateWithTranslation(values, formValidators, formatMessage);
};

export interface OrderMetaFormProps {}

const OrderMetaForm: FC<OrderMetaFormProps> & { validate: typeof validate } = () => {
    const { formatMessage } = useIntl();
    const currencyPostfix = useCurrencyPostfix();

    const { change } = useForm();
    const { input } = useField(DeliveryField.DeliveryType);

    useEffect(() => {
        if (input.value === DeliveryType.Warehouse) {
            change(OrderMetaField.PaymentMethod, PaymentMethod.Prepaid);
            change(OrderMetaField.AllowReturn, false);
        }
    }, [input.value, change]);

    return (
        <FormWrapper withNote={false}>
            <TextField<string>
                type="number"
                label={formatMessage({ id: 'order.meta.invoiceValue' }) + currencyPostfix}
                name={OrderMetaField.InvoiceValue}
                parse={convertToString}
                required
            />
            {input.value !== DeliveryType.Warehouse && (
                <>
                    <PaymentMethodSelect />
                    <AllowReturnSelect />
                </>
            )}
            <RadioButtonGroupField<boolean>
                name={OrderMetaField.Notify}
                options={[
                    {
                        value: true,
                        label: formatMessage({ id: `general.yes` }),
                    },
                    {
                        value: false,
                        label: formatMessage({ id: `general.no` }),
                    },
                ]}
                label={formatMessage({ id: 'order.meta.notify' })}
                required
            />

            <Condition when={OrderMetaField.Notify} is={true}>
                <TextField<string>
                    type="email"
                    label={formatMessage({ id: 'order.meta.notificationEmail' })}
                    help={formatMessage({ id: 'order.meta.notificationEmail.note' })}
                    name={OrderMetaField.NotificationEmail}
                    autoComplete="email"
                />
            </Condition>
        </FormWrapper>
    );
};

OrderMetaForm.validate = validate;

export default OrderMetaForm;

import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import debounce from 'lodash/debounce';

import { useFelaEnhanced } from 'hooks';
import { SearchInput } from 'modules/form';

import { SEARCH_DEBOUNCE_TIMEOUT } from '../../config';
import { ParcelFilter } from '../../constants';
import { useParcelsFilters } from '../../hooks/useParcelsFilters';

import * as felaRules from './ParcelsSearch.rules';

export const ParcelsSearch = () => {
    const { formatMessage } = useIntl();
    const [focused, setIsFocused] = useState(false);
    const { rules } = useFelaEnhanced(felaRules);

    const { filters, onChange } = useParcelsFilters();
    const [query, setQueryInputValue] = useState(filters[ParcelFilter.QUERY]);
    const handleChange = useMemo(
        () => debounce(q => onChange({ [ParcelFilter.QUERY]: q }), SEARCH_DEBOUNCE_TIMEOUT),
        [onChange],
    );

    return (
        <SearchInput
            value={query}
            placeholder={formatMessage({ id: `parcel.search.placeholder${focused ? '.focused' : ''}` })}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onChange={e => {
                const q = e.target.value;

                setQueryInputValue(q);
                handleChange(q);
            }}
            extend={{ input: rules.input }}
        />
    );
};

import { createFormActions } from 'modules/form';

import { ACTION_PREFIX } from '../../constants';
import type { ChoosePartnerFormValues } from '../../types';

export const choosePartnerForm = createFormActions<
    ChoosePartnerFormValues,
    'CHOOSE_PARTNER_FORM',
    typeof ACTION_PREFIX
>('CHOOSE_PARTNER_FORM', ACTION_PREFIX);

import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    maxWidth: 440,
    textAlign: 'center',
});

export const icon: TRuleWithTheme = ({ theme }) => ({
    width: 48,
    height: 48,

    color: theme.colors.fgSecondary,
});

export const title: TRuleWithTheme = ({ theme }) => ({
    marginTop: theme.metrics.spacing * 2.5,
    marginBottom: theme.metrics.spacing * 1.5,
});

export const button: TRuleWithTheme = ({ theme }) => ({
    marginTop: theme.metrics.spacing * 6,
});

import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import config from 'config/index';
import { useFelaEnhanced } from 'hooks';
import { Layout, PageHeader } from 'modules/layout';
import { BackButton } from 'modules/ui';

import { OrderFromWarehouseToWarehouseForm } from '../OrderFromWarehouseToWarehouseForm';

import * as felaRules from './OrderFromWarehouseToWarehousePage.rules';

interface OrderFromWareHouseToWarehousePage {}

export const OrderFromWarehouseToWarehousePage: FC<OrderFromWareHouseToWarehousePage> = () => {
    const { styles } = useFelaEnhanced(felaRules);
    return (
        <Layout>
            <PageHeader title={<FormattedMessage id="order.fromWarehouseToWarehouse.title" />}>
                <Link to={config.routes.home}>
                    <BackButton text={{ id: 'order.new.back' }} />
                </Link>
            </PageHeader>

            <div className={styles.content}>
                <OrderFromWarehouseToWarehouseForm />
            </div>
        </Layout>
    );
};

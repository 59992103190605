import type { TRuleWithTheme } from 'styles/theme';

export const title: TRuleWithTheme = ({ theme }) => ({
    marginBottom: theme.metrics.spacing * 1.5,
    marginTop: theme.metrics.spacing * 4,
});

export const submitButton: TRuleWithTheme = ({ theme }) => ({
    margin: '1.5rem auto 0',
});

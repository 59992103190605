import React from 'react';
import type { ReactNode } from 'react';
import { useFelaEnhanced } from 'hooks';
import { Layout as AntdLayout } from 'antd';
import { ToastContainer } from 'react-toastify';

import type { RulesExtend } from 'styles/theme';
import { ErrorBoundary } from 'modules/errors';

import { LAYOUT_TOAST_CONTAINER_ID } from '../../constants';
import Header from '../Header';
import Footer from '../Footer';

import * as felaRules from './Layout.rules';

export interface PageProps {
    children: ReactNode;
    header?: ReactNode;
    footer?: ReactNode;
    extend?: RulesExtend<typeof felaRules>;
}

const defaultHeader = <Header />;
const defaultFooter = <Footer />;

const Layout = ({ children, header = defaultHeader, footer = defaultFooter, extend }: PageProps) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });

    return (
        <AntdLayout className={styles.page}>
            {header}
            <AntdLayout.Content className={styles.content}>
                <ToastContainer enableMultiContainer containerId={LAYOUT_TOAST_CONTAINER_ID} />
                <ErrorBoundary>{children}</ErrorBoundary>
            </AntdLayout.Content>
            {footer}
        </AntdLayout>
    );
};

export default Layout;

export const MODULE_PREFIX = 'entities';
export const DEFAULT_GROUP = 'ids';

export enum EntityKey {
    LOCATIONS = 'locations',
    DESTINATIONS = 'destinations',
    DELIVERY_PARTNERS = 'deliveryPartners',
    ENTRUSTED_PARTNERS = 'entrustedPartners',
    PARCELS = 'parcels',
    DELIVERY_REQUESTS = 'deliveryRequests',
    CSV_IMPORT = 'csvImport',
    CUSTOMER_RETURN = 'customerReturn',
}

export enum ApiReducerKey {
    CREATE = 'create',
    LIST = 'list',
    DETAIL = 'detail',
    UPDATE = 'update',
    DELETE = 'delete',
}

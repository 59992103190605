import { put, takeLeading, select } from 'redux-saga/effects';
import type { TokenPaginationApiState } from 'types';

import { fetchParcels as apiActions, requestParcelsFetching } from '../../actions';
import { selectParcelsApi } from '../../selectors';

type RequestAction = ReturnType<typeof requestParcelsFetching>;

export default function* requestParcelsFetchingSaga() {
    yield takeLeading(
        requestParcelsFetching.toString(),
        function* ({ payload: { params, apiResolver, groupId, filters, pageNumber, pageType } }: RequestAction) {
            const api: TokenPaginationApiState = yield select(selectParcelsApi, groupId);

            if (apiResolver(api)) {
                const { limit, [pageType]: pageToken } = api;

                yield put(
                    apiActions.request(groupId, {
                        limit,
                        pageNumber,
                        pageToken,
                        ...params,
                        ...filters,
                    }),
                );
            }
        },
    );
}

import { useCallback, useState } from 'react';

export const useModalOpenState = (defaultOpen = false) => {
    const [open, setOpen] = useState(defaultOpen);

    const handleOpen = useCallback(() => {
        setOpen(true);
    }, []);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    return [open, handleOpen, handleClose] as const;
};

import type { MouseEventHandler, ReactNode } from 'react';
import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import * as felaRules from './PageNumber.rules';

export interface PageNumberProps {
    onClick?: MouseEventHandler<HTMLButtonElement>;
    children: ReactNode;
    isCurrent?: boolean;
    extend?: RulesExtend<typeof felaRules>;
}

export const PageNumber = ({ onClick, children, isCurrent = false, extend }: PageNumberProps) => {
    const { styles } = useFelaEnhanced(felaRules, { extend, isCurrent });

    return (
        <button
            onClick={onClick}
            className={styles.pageNumber}
            data-testid={isCurrent ? 'page-number-current' : 'page-number'}
        >
            {children}
        </button>
    );
};

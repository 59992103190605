import type { FC } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { toNumber } from 'lodash';

import { useFelaEnhanced } from 'hooks';
import { isTruthy } from 'types/guards';
import { LabeledValue } from 'modules/ui';
import type { components } from 'api/partnerApiSchema';
import { useCurrency } from 'modules/currency';

import { PaymentMethod, TypeOfService } from '../../config';
import OrderReturnButton from '../OrderReturnButton';

import * as felaRules from './OrderMeta.rules';

export interface OrderMetaProps {
    orderNumber?: string;
    typeOfService: TypeOfService;
    returnAllowed?: boolean;
    notificationEmail?: string;
    invoiceValue: string;
    paymentMethod: PaymentMethod;
    collectAmount: string;
    enableReturnAction?: boolean;
    parcelId?: string;
    payment?: components['schemas']['DeliveryPayment'];
}

const OrderMeta: FC<OrderMetaProps> = ({
    orderNumber,
    typeOfService,
    returnAllowed = false,
    notificationEmail = null,
    invoiceValue,
    paymentMethod,
    collectAmount,
    enableReturnAction = false,
    parcelId = null,
    payment,
}) => {
    const { styles, rules } = useFelaEnhanced(felaRules);
    const isCodPaymentMethod = paymentMethod === PaymentMethod.CashOnDelivery;

    const currency = useCurrency(payment);

    return (
        <div className={styles.container}>
            <LabeledValue
                label={<FormattedMessage id="order.meta.typeOfService" />}
                value={
                    typeOfService === 'next-day' ? (
                        <FormattedMessage id="order.meta.typeOfService.nextDay" />
                    ) : (
                        <FormattedMessage id="order.meta.typeOfService.sameDay" />
                    )
                }
                extend={{ container: rules.firstRowItem }}
            />
            <LabeledValue
                label={<FormattedMessage id="order.meta.return" />}
                value={
                    <>
                        {returnAllowed ? (
                            <FormattedMessage id="order.meta.return.allowed" />
                        ) : (
                            <FormattedMessage id="order.meta.return.notAllowed" />
                        )}
                        {enableReturnAction && isTruthy(orderNumber) && isTruthy(parcelId) && (
                            <OrderReturnButton orderNumber={orderNumber} value={returnAllowed} parcelId={parcelId} />
                        )}
                    </>
                }
                extend={{ container: rules.firstRowItem }}
            />
            <LabeledValue
                label={<FormattedMessage id="order.meta.notify" />}
                value={
                    notificationEmail ? (
                        <>
                            <FormattedMessage id="general.yes" /> ({notificationEmail})
                        </>
                    ) : (
                        <FormattedMessage id="general.no" />
                    )
                }
                extend={{ container: rules.firstRowItem }}
            />

            <LabeledValue
                label={<FormattedMessage id="order.meta.invoiceValue" />}
                // eslint-disable-next-line react/style-prop-object
                value={<FormattedNumber value={toNumber(invoiceValue)} style="currency" currency={currency} />}
                extend={{ container: rules.secondRowItem }}
            />
            <LabeledValue
                label={<FormattedMessage id="order.meta.paymentMethod" />}
                value={<FormattedMessage id={`parcel.payment.method.${paymentMethod}`} />}
                extend={{ container: rules.secondRowItem }}
            />
            {isCodPaymentMethod && (
                <LabeledValue
                    label={<FormattedMessage id="order.meta.collectAmount" />}
                    // eslint-disable-next-line react/style-prop-object
                    value={<FormattedNumber value={toNumber(collectAmount)} style="currency" currency={currency} />}
                    extend={{ container: rules.secondRowItem }}
                />
            )}
            {isCodPaymentMethod && payment?.state && (
                <LabeledValue
                    label={<FormattedMessage id="order.meta.payment.state" />}
                    value={<FormattedMessage id={`parcel.payment.state.${payment.state}`} />}
                    extend={{ container: rules.secondRowItem }}
                />
            )}
        </div>
    );
};

export default OrderMeta;

import { FormattedMessage } from 'react-intl';
import type { Dayjs } from 'dayjs';

import { RangePicker, LabeledValue } from 'modules/ui';

export interface CreatedDateProps {
    from?: Dayjs;
    to?: Dayjs;
    onChange?: (from: Dayjs | undefined, to: Dayjs | undefined) => void;
}

export const CreatedDate = ({ from, to, onChange }: CreatedDateProps) => {
    return (
        <LabeledValue
            label={<FormattedMessage id="parcel.filter.created" />}
            value={
                <RangePicker
                    value={[from ?? null, to ?? null]}
                    onChange={dateRange => {
                        const fromDate = dateRange?.[0] ?? undefined;
                        const toDate = dateRange?.[1] ?? undefined;

                        onChange?.(fromDate, toDate);
                    }}
                />
            }
        />
    );
};

import type { FC } from 'react';
import { useIntl } from 'react-intl';
import { toString } from 'lodash';

import { validatorsWithMessage, TextField, composeValidators } from 'modules/form';
import type { FieldValidatorWithMessage } from 'modules/form/services/utils/validation/types';
import { useCurrencyPostfix } from 'modules/currency';

import { OrderItemField } from '../../config';
import type { ItemValues } from '../../types';
import { ItemSizeSelect } from '../ItemSizeSelect';
import { greaterThanOrEqual } from 'modules/form/services/utils/validation/validatorsWithMessage';

const { required } = validatorsWithMessage;

export const getValidators = (isSizeMandatory: boolean) => {
    const validators: Record<string, FieldValidatorWithMessage> = {
        [OrderItemField.Value]: composeValidators(required, greaterThanOrEqual(0)),
        [OrderItemField.Weight]: greaterThanOrEqual(0),
    };

    if (isSizeMandatory) {
        validators[OrderItemField.Size] = required;
    }

    return validators;
};

export interface ItemFormProps {
    itemName: string;
    isSizeMandatory: boolean;
}

const ItemForm: FC<ItemFormProps> = ({ itemName, isSizeMandatory }) => {
    const { formatMessage } = useIntl();

    const currencyPostfix = useCurrencyPostfix();

    return (
        <>
            <TextField<NonNullable<ItemValues[OrderItemField.Name]>>
                type="text"
                label={formatMessage({ id: 'parcel.name' })}
                placeholder={formatMessage({ id: 'order.items.name.placeholder' })}
                name={`${itemName}.${OrderItemField.Name}`}
            />

            <TextField<ItemValues[OrderItemField.Value]>
                type="number"
                label={formatMessage({ id: 'order.items.value' }) + currencyPostfix}
                name={`${itemName}.${OrderItemField.Value}`}
                parse={toString}
                required
            />

            <ItemSizeSelect name={`${itemName}.${OrderItemField.Size}`} required={isSizeMandatory} />

            {/* 
            If we used type="number" for weight, Safari and FF would still allow to enter non-numeric values. 
            In that case – if non-numeric values were inserted, Antd would ignore the value and return undefined.
            That means the value would internally be the same as if no value was entered (and that's valid in case of weight). 
            So there was no way to check this invalid value and display an error. It's not an issue in case of required fields
            because we are sure we do not want undefined values at all. Therefore we use type="text" here, validate that 
            the value is a valid number, and parse the string value to number before submitting the form. 
            */}
            <TextField<NonNullable<ItemValues[OrderItemField.Weight]>>
                type="text"
                label={formatMessage({ id: 'order.items.weight' })}
                name={`${itemName}.${OrderItemField.Weight}`}
                parse={toString}
            />
        </>
    );
};

export default ItemForm;

import type { CsvImportType, CsvImportValues } from './types';

export const csvImportType = {
    Warehouse: 'from-warehouse',
    Apm: 'from-anyapm',
} as const satisfies Record<string, CsvImportType>;

export const csvImportFormKey = {
    type: 'type',
    file: 'file',
    allowReturn: 'allowReturn',
    delimiter: 'delimiter',
    showRecipientInformation: 'showRecipientInformation',
    originContactEmail: 'originContactEmail',
    originContactNumber: 'originContactNumber',
} as const satisfies Record<string, keyof CsvImportValues>;

export const csvProperties = {
    [csvImportType.Warehouse]: [
        { key: 'from_location', required: true },
        { key: 'destination_location', required: true },
        { key: 'customer_phone_number', required: true },
        { key: 'customer_email', required: true },
        { key: 'customer_full_name', required: true },
        { key: 'number_of_parcels', required: false },
        { key: 'payment_mode', required: false },
        { key: 'amount_to_be_collected', required: false },
        { key: 'price_currency', required: false },
    ],
    [csvImportType.Apm]: [
        { key: 'destination_location', required: true },
        { key: 'parcel_size', required: true },
        { key: 'customer_phone_number', required: true },
        { key: 'customer_email', required: true },
        { key: 'customer_full_name', required: true },
        { key: 'number_of_parcels', required: false },
        { key: 'payment_mode', required: false },
        { key: 'amount_to_be_collected', required: false },
        { key: 'price_currency', required: false },
    ],
} as const satisfies Record<CsvImportType, ReadonlyArray<{ key: string; required: boolean }>>;

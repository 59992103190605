import type { FC } from 'react';
import { TimeDate } from 'modules/ui';

export interface ParcelCreateTimeProps {
    children?: string;
}

const ParcelCreateTime: FC<ParcelCreateTimeProps> = ({ children }) => {
    if (!children) {
        return <>–</>;
    }

    return <TimeDate value={children} />;
};

export default ParcelCreateTime;

import type { TRuleWithTheme } from 'styles/theme';

export const dragger: TRuleWithTheme = ({ theme: { colors } }) => ({
    height: 307,

    border: `1px solid ${colors.bgSecondary}`,
    borderRadius: 10,
    boxShadow: 'none',

    textAlign: 'center',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1.25',

    color: colors.fgPrimary,
    background: colors.bgSecondary,
    cursor: 'pointer',

    '& .ant-upload-drag-container': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 'calc(100% - 120px)',
        marginLeft: 'auto',
        marginRight: 'auto',
    },

    '&.ant-upload-drag-hover': {
        border: `1px dashed ${colors.accentSecondary}`,
        background: 'rgba(241, 191, 223, 0.15)',
    },

    '& .ant-upload-btn': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        width: '100%',
        height: '100%',
    },
});

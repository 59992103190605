import { useFelaEnhanced } from 'hooks';
import { Clear } from 'modules/ui/modules/icons';
import type { RulesExtend } from 'styles';

import * as felaRules from './ClearButton.rules';

export interface ClearButtonProps {
    extend?: RulesExtend<typeof felaRules>;
}

export const ClearButton = ({ extend }: ClearButtonProps) => {
    const { rules } = useFelaEnhanced(felaRules, { extend });

    return <Clear extend={{ icon: rules.clearIcon }} />;
};

import { useFelaEnhanced } from 'hooks';

import * as felaRules from './Logo.rules';
import { BigLogoType } from './types';

export interface LogoProps {
    type?: BigLogoType;
}

export const Logo = ({ type = BigLogoType.DEFAULT }: LogoProps) => {
    const { styles } = useFelaEnhanced(felaRules, { type });

    return (
        <svg
            width="93"
            height="68"
            viewBox="0 0 93 68"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={styles.logo}
        >
            <path d="M93 0H0V68H93V0Z" fill="#44D62C" />
            <path
                d="M7.23645 31.6374V7.80659C7.23753 7.68379 7.28725 7.56631 7.3749 7.47947C7.46255 7.39263 7.58111 7.34337 7.70506 7.3423H18.1305C19.7155 7.30293 21.2979 7.48974 22.8291 7.89698C23.8961 8.17955 24.8872 8.69159 25.7319 9.39668C26.3811 9.95885 26.8906 10.6619 27.2207 11.4511C27.5112 12.1898 27.6602 12.9756 27.6603 13.7684C27.6821 14.6334 27.4594 15.4873 27.0175 16.2337C26.315 17.3984 25.256 18.3108 23.9944 18.8386C23.9787 18.8438 23.9652 18.8538 23.9556 18.8671C23.946 18.8803 23.9408 18.8962 23.9408 18.9126C23.9408 18.9289 23.946 18.9448 23.9556 18.9581C23.9652 18.9714 23.9787 18.9813 23.9944 18.9865C24.6997 19.2153 25.376 19.5242 26.0098 19.9069C26.862 20.4077 27.6005 21.0778 28.1787 21.875C28.8194 22.7818 29.1441 23.8709 29.1034 24.9771C29.1034 27.4424 28.1731 29.2393 26.3125 30.3678C24.4519 31.4963 21.7246 32.077 18.1305 32.1099H7.7092C7.58344 32.1088 7.4632 32.0586 7.37466 31.9701C7.28612 31.8816 7.23645 31.762 7.23645 31.6374ZM13.2703 17.306H17.7573C18.54 17.3193 19.3115 17.1204 19.9884 16.7308C20.6436 16.3501 20.9712 15.6886 20.9712 14.7463C20.9712 13.6821 20.6312 12.9672 19.9469 12.5933C19.2765 12.227 18.5229 12.0361 17.7573 12.0386H13.2703V17.306ZM13.2703 27.4382H17.7034C18.3041 27.435 18.9029 27.3717 19.4908 27.2492C20.0964 27.1364 20.6557 26.8508 21.0998 26.4275C21.3391 26.1611 21.5213 25.8492 21.6354 25.5109C21.7495 25.1726 21.793 24.8149 21.7633 24.4594C21.7881 24.1206 21.7419 23.7804 21.6278 23.4601C21.5136 23.1398 21.3338 22.8463 21.0998 22.5981C20.6479 22.1857 20.092 21.9018 19.4908 21.7764C18.9039 21.6462 18.3047 21.5787 17.7034 21.575H13.2703V27.4382Z"
                fill="#EFEFE6"
            />
            <path
                d="M42.7138 32.7427C40.9373 32.7618 39.1743 32.4337 37.5259 31.7771C35.9781 31.1588 34.5761 30.2299 33.408 29.0489C32.2345 27.8627 31.3099 26.4578 30.6876 24.9155C30.0204 23.2645 29.6852 21.5003 29.7006 19.722C29.6841 17.9423 30.0194 16.1765 30.6876 14.5244C31.3078 12.9893 32.2313 11.5924 33.4038 10.4157C34.5771 9.24488 35.978 8.32231 37.5218 7.70388C40.8569 6.42406 44.5541 6.42406 47.8892 7.70388C49.44 8.32511 50.85 9.24709 52.0362 10.4157C53.2142 11.5872 54.1372 12.9856 54.7483 14.5244C55.3952 16.1812 55.7148 17.9457 55.6897 19.722C55.711 21.4974 55.3886 23.2604 54.74 24.9155C54.1301 26.4613 53.2078 27.8672 52.0297 29.0472C50.8516 30.2271 49.4427 31.1561 47.8892 31.7771C46.2445 32.4314 44.4861 32.7594 42.7138 32.7427ZM42.7138 26.5795C43.8888 26.613 45.0462 26.2905 46.0314 25.6551C46.9411 25.041 47.6593 24.1871 48.1049 23.1898C48.5909 22.1037 48.8342 20.9264 48.8181 19.7384C48.8342 18.5505 48.5909 17.3732 48.1049 16.2871C47.6603 15.2837 46.9423 14.4229 46.0314 13.8013C45.0478 13.1603 43.8908 12.8307 42.7138 12.8563C41.5384 12.8196 40.38 13.1424 39.3962 13.7807C38.4811 14.4043 37.7555 15.2639 37.2978 16.2665C36.7956 17.3498 36.5421 18.53 36.5555 19.722C36.5388 20.9117 36.788 22.0904 37.2854 23.1734C37.7439 24.1699 38.4697 25.0227 39.3838 25.6386C40.3679 26.2884 41.5317 26.6187 42.7138 26.5836V26.5795Z"
                fill="#EFEFE6"
            />
            <path
                d="M56.1542 31.3992L63.7514 19.7221C63.8012 19.6471 63.8277 19.5592 63.8277 19.4694C63.8277 19.3796 63.8012 19.2918 63.7514 19.2167L56.1832 8.08199C56.1356 8.0127 56.108 7.93186 56.1034 7.84819C56.0988 7.76451 56.1174 7.68118 56.1571 7.60718C56.1968 7.53318 56.2561 7.47132 56.3287 7.42828C56.4012 7.38524 56.4843 7.36266 56.5689 7.36295H62.1424C62.2243 7.35949 62.3058 7.3774 62.3786 7.4149C62.4513 7.45239 62.5129 7.50815 62.5571 7.57661L68.2302 16.4351C68.2387 16.4487 68.2506 16.4599 68.2648 16.4677C68.2789 16.4754 68.2948 16.4795 68.311 16.4795C68.3272 16.4795 68.3431 16.4754 68.3572 16.4677C68.3714 16.4599 68.3833 16.4487 68.3919 16.4351L74.0857 7.56839C74.1286 7.50346 74.187 7.45 74.2557 7.41274C74.3244 7.37548 74.4013 7.35555 74.4796 7.35474H80.0366C80.1209 7.35509 80.2037 7.37799 80.276 7.42104C80.3483 7.46409 80.4075 7.52568 80.4475 7.59932C80.4874 7.67296 80.5065 7.75591 80.5028 7.83941C80.4991 7.92292 80.4727 8.00389 80.4264 8.07377L72.8789 19.2085C72.8283 19.284 72.8012 19.3726 72.8012 19.4633C72.8012 19.5539 72.8283 19.6425 72.8789 19.718L80.4513 31.3992C80.4968 31.4687 80.5226 31.5491 80.5259 31.6318C80.5291 31.7146 80.5098 31.7968 80.4699 31.8695C80.4299 31.9423 80.3709 32.0031 80.299 32.0454C80.2272 32.0878 80.1451 32.1101 80.0615 32.11H74.4879C74.4052 32.1126 74.3232 32.0935 74.2504 32.0545C74.1775 32.0155 74.1164 31.9581 74.0732 31.8882L68.396 22.5982C68.3877 22.5837 68.3756 22.5716 68.3611 22.5632C68.3465 22.5548 68.3299 22.5504 68.3131 22.5504C68.2962 22.5504 68.2797 22.5548 68.2651 22.5632C68.2505 22.5716 68.2385 22.5837 68.2302 22.5982L62.5364 31.8882C62.4935 31.9584 62.4324 32.0161 62.3595 32.0551C62.2866 32.0941 62.2045 32.1131 62.1217 32.11H56.5481C56.4642 32.1108 56.3815 32.089 56.3091 32.047C56.2366 32.005 56.177 31.9443 56.1366 31.8714C56.0961 31.7984 56.0764 31.716 56.0795 31.6329C56.0826 31.5497 56.1084 31.469 56.1542 31.3992Z"
                fill="#EFEFE6"
            />
            <path
                d="M7.19501 60.2673V36.3543C7.19501 36.2312 7.24438 36.1131 7.33226 36.026C7.42014 35.9389 7.53933 35.89 7.66361 35.89H12.5197C12.5967 35.8895 12.6725 35.9085 12.74 35.9452C12.8075 35.9819 12.8644 36.035 12.9054 36.0996L21.4772 49.49C21.487 49.5063 21.502 49.5188 21.5197 49.5258C21.5375 49.5328 21.5571 49.5338 21.5755 49.5286C21.5939 49.5235 21.61 49.5125 21.6215 49.4974C21.633 49.4822 21.6391 49.4638 21.6389 49.4448V36.3543C21.6389 36.293 21.6512 36.2323 21.675 36.1757C21.6988 36.1191 21.7337 36.0677 21.7776 36.0246C21.8216 35.9814 21.8737 35.9473 21.9311 35.9242C21.9884 35.9011 22.0498 35.8895 22.1117 35.89H27.2332C27.3575 35.89 27.4767 35.9389 27.5645 36.026C27.6524 36.1131 27.7018 36.2312 27.7018 36.3543V60.2673C27.7018 60.3905 27.6524 60.5086 27.5645 60.5956C27.4767 60.6827 27.3575 60.7316 27.2332 60.7316H22.7296C22.6476 60.7351 22.5662 60.7172 22.4934 60.6797C22.4206 60.6422 22.3591 60.5864 22.3149 60.518L13.4196 46.7207C13.4098 46.7045 13.3948 46.6919 13.3771 46.685C13.3593 46.678 13.3397 46.677 13.3213 46.6821C13.3029 46.6873 13.2868 46.6982 13.2753 46.7134C13.2638 46.7285 13.2577 46.747 13.2579 46.7659V60.2673C13.2579 60.3286 13.2456 60.3894 13.2218 60.446C13.198 60.5026 13.1631 60.5539 13.1192 60.5971C13.0752 60.6403 13.0231 60.6744 12.9657 60.6975C12.9084 60.7205 12.847 60.7321 12.7851 60.7316H7.66361C7.53933 60.7316 7.42014 60.6827 7.33226 60.5956C7.24438 60.5086 7.19501 60.3905 7.19501 60.2673Z"
                fill="#EFEFE6"
            />
            <path
                d="M61.4415 60.3741L55.8265 36.457C55.8102 36.389 55.8096 36.3183 55.825 36.25C55.8403 36.1818 55.8711 36.118 55.9151 36.0633C55.9591 36.0087 56.015 35.9647 56.0787 35.9346C56.1424 35.9046 56.2122 35.8893 56.2827 35.89H62.0097C62.119 35.8892 62.2252 35.9267 62.3094 35.9959C62.3936 36.065 62.4505 36.1614 62.47 36.268L65.4185 51.6307C65.423 51.6519 65.4348 51.6709 65.4517 51.6845C65.4687 51.6981 65.4899 51.7056 65.5118 51.7056C65.5337 51.7056 65.5549 51.6981 65.5718 51.6845C65.5888 51.6709 65.6006 51.6519 65.6051 51.6307L68.9766 36.8391C68.9994 36.7362 69.0571 36.6442 69.1401 36.5784C69.2231 36.5126 69.3264 36.477 69.4327 36.4776H74.2972C74.4029 36.4768 74.5057 36.5118 74.5887 36.5768C74.6716 36.6418 74.7297 36.7329 74.7533 36.835L78.158 51.6266C78.158 51.6516 78.168 51.6757 78.1859 51.6934C78.2038 51.7111 78.2281 51.7211 78.2534 51.7211C78.2787 51.7211 78.3029 51.7111 78.3208 51.6934C78.3387 51.6757 78.3487 51.6516 78.3487 51.6266L80.866 36.2762C80.88 36.1636 80.9348 36.0599 81.0203 35.9843C81.1057 35.9088 81.2159 35.8665 81.3304 35.8654H87.0491C87.1194 35.8655 87.1887 35.8813 87.252 35.9115C87.3153 35.9418 87.3709 35.9858 87.4147 36.0402C87.4585 36.0947 87.4894 36.1582 87.5051 36.2261C87.5207 36.294 87.5208 36.3645 87.5053 36.4324L81.9359 60.3495C81.9116 60.4512 81.8533 60.5419 81.7705 60.6068C81.6877 60.6717 81.5853 60.707 81.4797 60.7069H75.7776C75.6719 60.7077 75.569 60.6727 75.4861 60.6077C75.4031 60.5427 75.3451 60.4516 75.3215 60.3495L71.838 45.3936C71.8335 45.3724 71.8217 45.3534 71.8047 45.3397C71.7877 45.3261 71.7666 45.3187 71.7447 45.3187C71.7228 45.3187 71.7016 45.3261 71.6846 45.3397C71.6677 45.3534 71.6559 45.3724 71.6514 45.3936L68.1679 60.3495C68.1436 60.4512 68.0854 60.5419 68.0026 60.6068C67.9198 60.6717 67.8173 60.707 67.7118 60.7069H61.906C61.8023 60.7102 61.7005 60.6793 61.6164 60.6191C61.5324 60.5589 61.4709 60.4727 61.4415 60.3741Z"
                fill="#EFEFE6"
            />
            <path
                d="M42.8341 61.3644C41.0522 61.3834 39.2838 61.0553 37.6296 60.3988C36.0705 59.7799 34.6583 58.8466 33.4826 57.6583C32.3088 56.4652 31.383 55.0552 30.7581 53.5084C29.4589 50.1501 29.4589 46.4345 30.7581 43.0763C31.3802 41.5328 32.3064 40.1277 33.4826 38.9428C34.6648 37.7673 36.0756 36.8419 37.6296 36.2228C40.979 34.9353 44.6933 34.9353 48.0427 36.2228C49.5938 36.8473 51.0036 37.772 52.1896 38.9428C53.3753 40.1209 54.3041 41.5278 54.9183 43.0763C56.1971 46.4382 56.1971 50.1464 54.9183 53.5084C54.3038 55.0589 53.3774 56.4693 52.1954 57.6538C51.0133 58.8384 49.6005 59.7723 48.0427 60.3988C46.3869 61.055 44.6173 61.3831 42.8341 61.3644ZM42.8341 55.2012C44.0287 55.23 45.2032 54.8928 46.1973 54.2357C47.1083 53.6107 47.8262 52.7473 48.2707 51.7416C48.7415 50.6457 48.9841 49.4669 48.9841 48.2759C48.9841 47.0849 48.7415 45.9061 48.2707 44.8102C47.8262 43.8045 47.1083 42.9411 46.1973 42.3161C45.1994 41.6706 44.0252 41.345 42.8341 41.3835C41.6579 41.3503 40.4998 41.6759 39.5165 42.3161C38.5978 42.9415 37.8692 43.804 37.4098 44.8102C36.9262 45.9027 36.6764 47.0828 36.6764 48.2759C36.6764 49.469 36.9262 50.6491 37.4098 51.7416C37.867 52.7468 38.5925 53.6092 39.5082 54.2357C40.4919 54.8833 41.6533 55.2148 42.8341 55.1848V55.2012Z"
                fill="#EFEFE6"
            />
        </svg>
    );
};

Logo.Type = BigLogoType;

const metrics = {
    spacing: 8,

    height: {
        header: 72,
        footer: 64,
        largeButton: 40,
    },

    layoutPadding: 40,
    panelPadding: {
        vertical: 20,
        horizontal: 28,
    },
} as const;

export default metrics;

import { useFelaEnhanced } from 'hooks';

import { FormattedMessage } from 'react-intl';

import * as felaRules from './ParcelSkeleton.rules';

const ParcelSkeleton = () => {
    const { styles } = useFelaEnhanced(felaRules);

    return (
        <div className={styles.panel}>
            <FormattedMessage id="parcel.list.loading" />
        </div>
    );
};

export default ParcelSkeleton;

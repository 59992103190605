import { AntonioError, isAntonioError } from '@ackee/antonio-core';

import { defaultErrorUIMessages } from '../config';
import type { ErrorData, ErrorUIMessages, ErrorIntlMessage } from '../types';

function setUnhandledFlag(msg: ErrorIntlMessage, unhandled: boolean) {
    return {
        ...msg,
        unhandled,
    };
}

function findErrorMessage(
    { status, code }: { status: number; code: string },
    errorUIMessages: Partial<ErrorUIMessages> = {},
): ErrorIntlMessage | null {
    const msg = errorUIMessages.code?.[code] || errorUIMessages.status?.[status];

    if (msg) {
        return setUnhandledFlag(msg, false);
    }

    return null;
}
export function getApiErrorDetails(error: AntonioError<ErrorData> | Error) {
    return isAntonioError<ErrorData>(error) ? error.data.errorData?.error.details : null;
}

export function getIntlErrorMessage(
    error: AntonioError<ErrorData> | Error,
    errorUIMessages: Partial<ErrorUIMessages> = {},
) {
    if (!isAntonioError<ErrorData>(error)) {
        return setUnhandledFlag(errorUIMessages.fallback || defaultErrorUIMessages.fallback, true);
    }

    const params = {
        status: error.response.status,
        code: error.data.errorCode || error.data.code,
    };
    const errorMessage = findErrorMessage(params, errorUIMessages) || findErrorMessage(params, defaultErrorUIMessages);

    return errorMessage || setUnhandledFlag(errorUIMessages.fallback || defaultErrorUIMessages.fallback, true);
}

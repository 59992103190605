import type { TRuleWithTheme } from 'styles/theme';
import type { PanelProps } from './Panel';

type StyleProps = Required<Pick<PanelProps, 'dark' | 'composed'>>;

export const panel: TRuleWithTheme<StyleProps> = ({ theme: { colors, metrics }, dark, composed }) => ({
    borderRadius: 10,
    backgroundColor: dark ? colors.newBgPrimary : colors.white,

    ...(!composed && {
        paddingTop: metrics.panelPadding.vertical,
        paddingBottom: metrics.panelPadding.vertical,
        paddingLeft: metrics.panelPadding.horizontal,
        paddingRight: metrics.panelPadding.horizontal,
    }),
});

import { put } from '@redux-saga/core/effects';
import type { AntonioError, RequestResult } from '@ackee/antonio-core';
import { takeRequest } from '@ackee/antonio-utils';

import config from 'config';
import * as Log from 'config/loglevel';
import type { paths } from 'api/partnerApiSchema';
import { authApi } from 'config/antonio';
import { ErrorData, getIntlErrorMessage } from 'modules/errors';

import { checkAddressDelivery } from '../actions';

const apiPath = config.api.checkAddressDelivery;
type Path = typeof apiPath;
type Operation = paths[`/api/v1${Path}`]['post'];
type Response = Operation['responses']['200']['content']['application/json'];

type RequestAction = ReturnType<typeof checkAddressDelivery.request>;

function* checkAddressDeliveryHandler(action: RequestAction) {
    try {
        // TODO - remove explicit typing of result once https://github.com/AckeeCZ/antonio/issues/70 is resolved
        const { data }: RequestResult<Response> = yield* authApi.post<Response>(apiPath, action.payload);

        yield put(
            checkAddressDelivery.success({
                lastSuccessAt: new Date().toISOString(),
                apmDistance: data.distance,
            }),
        );
    } catch (e) {
        const errorMessage = getIntlErrorMessage(e as AntonioError<ErrorData> | Error, {
            code: {
                P402: { id: 'error.customerReturn.post.p402' },
            },
        });

        if (errorMessage.unhandled) {
            Log.error(e as Error);
        }

        yield put(checkAddressDelivery.failure({ error: errorMessage }));
    }
}

export default function* checkAddressDeliverySaga() {
    yield takeRequest(
        {
            REQUEST: checkAddressDelivery.request.toString(),
            CANCEL: checkAddressDelivery.cancel.toString(),
        },
        checkAddressDeliveryHandler,
    );
}

import { containerReducer } from '@ackee/redux-utils';
import tokenPaginationReducerFactory from 'services/utils/tokenPaginationReducerFactory';
import type { PageType } from '../../../types';
import { types, fetchParcels } from '../../actions';

type ActionSuccess = ReturnType<typeof fetchParcels.success>;
type ActionRequest = ReturnType<typeof fetchParcels.request>;

const tokenPaginationReducer = tokenPaginationReducerFactory({
    actionTypes: types.fetchParcels,
    selectors: {
        totalCount: (action: ActionSuccess) => action.meta.totalCount,
        currentCount: (action: ActionSuccess) => action.meta.currentCount,
        getPageToken: (action: ActionSuccess, pageType: PageType) => action.meta[pageType],
    },
});

export default containerReducer({
    childReducer: tokenPaginationReducer,
    actionTypes: Object.values(types.fetchParcels),
    selectors: {
        // TODO: Redux utils needs more generic for container reducer
        // @ts-expect-error
        itemId: (action: ActionRequest) => action.meta.id,
    },
});

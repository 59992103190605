import type { TRuleWithTheme } from 'styles/theme';

export const input: TRuleWithTheme = ({ theme }) => ({
    width: 210,

    marginBottom: theme.metrics.spacing * 2,

    '&.ant-input-affix-wrapper-focused': {
        width: 380,
    },
});

import type { RadioButtonGroupFieldProps } from 'modules/form';
import { RadioButtonGroupField, validatorsWithMessage } from 'modules/form';
import { useIntl } from 'react-intl';

import { OrderMetaField } from '../../config';

const { required } = validatorsWithMessage;

export const getValidators = () => ({
    [OrderMetaField.AllowReturn]: required,
});

export const getInitialValues = () => ({
    [OrderMetaField.AllowReturn]: false,
});

interface AllowReturnSelectProps extends Omit<RadioButtonGroupFieldProps<boolean>, 'name' | 'options'> {}

export const AllowReturnSelect = (props: AllowReturnSelectProps) => {
    const { formatMessage } = useIntl();

    return (
        <RadioButtonGroupField<boolean>
            name={OrderMetaField.AllowReturn}
            options={[
                {
                    value: false,
                    label: formatMessage({ id: `order.meta.return.notAllowed` }),
                },
                {
                    value: true,
                    label: formatMessage({ id: `order.meta.return.allowed` }),
                },
            ]}
            label={formatMessage({ id: 'order.meta.return' })}
            required
            {...props}
        />
    );
};

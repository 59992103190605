import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import * as felaRules from './DoneCircled.rules';

export interface DoneCircledProps {
    extend?: RulesExtend<typeof felaRules>;
}

export const DoneCircled = ({ extend }: DoneCircledProps) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });

    return (
        <svg viewBox="0 0 38 38" fill="none" className={styles.icon}>
            <path
                d="M13 19L17 23L25 15M37 19C37 28.9411 28.9411 37 19 37C9.05887 37 1 28.9411 1 19C1 9.05887 9.05887 1 19 1C28.9411 1 37 9.05887 37 19Z"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

import type { TRuleWithTheme } from 'styles/theme';

export const input: TRuleWithTheme = ({ theme: { colors, metrics } }) => {
    const highlightInput = {
        boxShadow: 'none',

        borderColor: colors.accentTertiary,
        borderWidth: 2,
        paddingBottom: 11,
    };

    return {
        border: 'none',
        borderBottom: `1px solid ${colors.borderInput}`,
        borderRadius: 0,

        paddingLeft: 2,

        '& .ant-input': {
            background: 'transparent',
        },

        '&.ant-input-affix-wrapper-focused': highlightInput,
        ':not(.ant-input-affix-wrapper-disabled)': {
            '&:hover': highlightInput,
            '&:focus': highlightInput,

            '&:focus-visible': {
                outline: 'none',
            },
        },

        '&.ant-input-affix-wrapper > input.ant-input': {
            '&:hover': {
                borderColor: colors.accentTertiary,
            },

            '&:focus': {
                borderColor: colors.accentTertiary,
                borderWidth: 2,
            },
        },

        '& .ant-input-prefix': {
            marginRight: metrics.spacing,
        },
    };
};

export const icon: TRuleWithTheme = () => ({
    width: 18,
    height: 18,
});

import { basicApiReducer } from '@ackee/redux-utils';
import { types } from '../../actions';

export default basicApiReducer({
    actionTypes: {
        REQUEST: types.createDeliveryRequest.CREATE_REQUEST,
        SUCCESS: types.createDeliveryRequest.CREATE_SUCCESS,
        FAILURE: types.createDeliveryRequest.CREATE_FAILURE,
        CANCEL: types.createDeliveryRequest.CREATE_CANCEL,
    },
});

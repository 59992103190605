import { apiSelector } from '@ackee/redux-utils';
import { createSelector } from 'reselect';

import type { ApiState, TokenPaginationApiState } from 'types';
import { ApiReducerKey, EntityKey } from 'modules/entities/constants';
import { denormalizeParcels } from 'modules/entities/services/normalizr';
import * as entitiesSelectors from 'modules/entities/services/selectors';
import type { ErrorIntlMessage } from 'modules/errors';
import type { RootState } from 'modules/core/modules/redux';

import passArg from 'services/utils/passArg';

export const selectParcelsApi = (state: RootState, groupId: string) =>
    apiSelector(state, EntityKey.PARCELS, ApiReducerKey.LIST, groupId) as TokenPaginationApiState;

export const selectCancelParcelApi = (state: RootState, parcelId: string) =>
    apiSelector(state, EntityKey.PARCELS, 'cancel', parcelId) as ApiState<ErrorIntlMessage>;

export const selectParcels = createSelector(
    [passArg, entitiesSelectors.selectParcelsEntities, entitiesSelectors.selectDeliveryRequestsEntities],
    (groupId, parcels, deliveryRequests) => {
        const denormalizedParcels = denormalizeParcels(parcels[groupId], {
            [EntityKey.PARCELS]: parcels.byId,
            [EntityKey.DELIVERY_REQUESTS]: deliveryRequests.byId,
        });

        return denormalizedParcels || [];
    },
);

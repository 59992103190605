import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';
import { Search } from 'modules/ui/modules/icons';
import { ClearButton } from 'modules/ui';

import { Input } from '../Input';
import type { InputProps } from '../Input';

import * as felaRules from './SearchInput.rules';

export interface SearchInputProps extends Omit<InputProps, 'extend' | 'prefix' | 'allowClear'> {
    extend?: RulesExtend<typeof felaRules>;
}

export const SearchInput = ({ extend, ...props }: SearchInputProps) => {
    const { rules } = useFelaEnhanced(felaRules, { extend });

    const prefix = <Search extend={{ icon: rules.icon }} />;
    const clearIcon = <ClearButton />;

    return <Input extend={rules} {...props} allowClear={{ clearIcon }} prefix={prefix} />;
};

import type { TRuleWithTheme } from 'styles/theme';

export const delimiter: TRuleWithTheme = ({ theme: { colors } }) => ({
    marginLeft: 10,
    marginRight: 10,

    fontWeight: 700,
    fontSize: '1rem',

    opacity: 0.7,
    color: colors.buttonEnabledText,
    cursor: 'default',
});

export enum PageType {
    FIRST = 'firstPageToken',
    PREVIOUS = 'previousPageToken',
    CURRENT = 'currentPageToken',
    NEXT = 'nextPageToken',
    LAST = 'lastPageToken',
}

export type ParcelsParams = Partial<{
    limit: number;
    pageToken: string | null;
    orderNumber: string;
    parcelId: string;
    parcelIds: string[];
}>;

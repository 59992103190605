import { isObject } from 'lodash';
import { FORM_ERROR } from 'final-form';
import type { FormApi } from 'final-form';
import { isErrorIntlMessage, ErrorIntlMessage } from 'modules/errors';

const submitFormFactory =
    <FormValues extends Record<string, any> = Record<string, any>>(
        reduxAsyncHandler: (values: FormValues, form?: FormApi<FormValues>) => Promise<unknown>,
    ) =>
    async (
        values: FormValues,
        form?: FormApi<FormValues>,
    ): Promise<undefined | Record<string, string | ErrorIntlMessage>> => {
        try {
            await reduxAsyncHandler(values, form);
        } catch (payload: unknown) {
            // TODO: Go through usage occurences and type this correctly
            // @ts-expect-error
            const error: Record<string, string> = payload?.error ?? payload;

            if (isObject(error) && !isErrorIntlMessage(error)) {
                return error;
            }

            return {
                [FORM_ERROR]: error,
            };
        }
    };

export default submitFormFactory;

import type { Parcel } from 'modules/entities';
import { OrderSummary, PaymentMethod } from 'modules/order/modules/order-common';
import { Address } from 'modules/ui';
import { isTruthy } from 'types/guards';

export interface OrderDetailProps {
    data: Parcel[];
}

const OrderDetail = ({ data }: OrderDetailProps) => {
    const { id, deliveryRequest, allowReturn, orderLabelUrl, payment } = data[0];
    const { typeOfService, paymentMode, invoiceValue, amountToBeCollected, notifyOnAccepted, origin, destination } =
        deliveryRequest;

    const nameItems = [destination.contactNumber, destination.contactEmail];
    const contactInformation = {
        name: nameItems.filter(isTruthy).join(', '),
        title: destination.contactName,
    };

    return (
        <OrderSummary
            referenceNumber={deliveryRequest.orderNumber}
            meta={{
                orderNumber: deliveryRequest.orderNumber,
                typeOfService,
                returnAllowed: allowReturn,
                paymentMethod: paymentMode as PaymentMethod,
                invoiceValue: invoiceValue,
                collectAmount: amountToBeCollected,
                notificationEmail: notifyOnAccepted,
                enableReturnAction: !allowReturn,
                parcelId: id,
                payment: payment,
            }}
            items={data.map(({ id, itemRefId, name, value, weight, compartmentSize, parcelLabelUrl }) => ({
                parcelNumber: id,
                name,
                value: value ?? '',
                compartmentSize,
                weight,
                referenceNumber: itemRefId,
                parcelLabelUrl,
            }))}
            pickupAddress={<Address location={{ id: origin.locationId ?? null, ...origin }} dark={false} fullWidth />}
            deliveryAddress={
                <Address
                    location={{
                        id: destination.locationId ?? null,
                        ...destination,
                        ...contactInformation,
                    }}
                    dark={false}
                    fullWidth
                />
            }
            orderLabelUrl={orderLabelUrl}
            displayParcelLabelButton
        />
    );
};

export default OrderDetail;

import { takeLatest, put, take } from '@redux-saga/core/effects';
import { push } from 'connected-react-router';
import { toNumber } from 'lodash';

import config from 'config';
import { isFailureAction } from 'types/guards';
import { DEFAULT_GROUP, EntityKey } from 'modules/entities/constants';
import { resetEntitiesGroup } from 'modules/entities/services/actions';
import { createDeliveryRequest } from 'modules/entities/modules/deliveryRequests';
import { actions as parcelsActions, fetchParcel } from 'modules/entities/modules/parcels';
import { showParcelLabelToast } from 'modules/parcels/modules/parcel-label';

import * as actions from '../actions';
import {
    AnyApmFields,
    DeliveryField,
    OrderItemField,
    OrderMetaField,
    OriginFieldItems,
    PickupField,
} from '../../config';

function* newOrderFormSubmitHandler(action: ReturnType<typeof actions.newOrderForm.submit>) {
    const values = action.payload;

    const items = values.items.map(item => ({
        ...item,
        weight: item[OrderItemField.Weight] ? toNumber(item[OrderItemField.Weight]) : undefined,
    }));

    // TODO - figure out accessing comparement size in value
    // @ts-expect-error
    if (values[AnyApmFields.CompartmentSize]) {
        // @ts-expect-error
        items[0][OrderItemField.Size] = values[AnyApmFields.CompartmentSize];
    }

    yield put(
        createDeliveryRequest.request({
            orderNumber: values[DeliveryField.ReferenceNumber],
            invoiceValue: values[OrderMetaField.InvoiceValue],
            paymentMode: values[OrderMetaField.PaymentMethod],
            amountToBeCollected: values[OrderMetaField.CollectAmount],
            allowReturn: values[OrderMetaField.AllowReturn],
            showRecipientInformation: values[DeliveryField.ShowRecipientInformation],
            notifyOnAccepted: values[OrderMetaField.NotificationEmail],
            origin: {
                locationId: values[PickupField.Location],
                // TODO - get values from logged user data
                // https://ackee.slack.com/archives/C022D8LGPNE/p1629194679001600?thread_ts=1629126896.004700&cid=C022D8LGPNE
                contactNumber: values[DeliveryField.Origin][OriginFieldItems.OriginContactNumber],
                contactEmail: values[DeliveryField.Origin][OriginFieldItems.OriginContactEmail],
                contactName: '',
            },
            destination: {
                contactNumber: values[DeliveryField.PhoneNumber].phone ?? '',
                contactEmail: values[DeliveryField.Email],
                contactName: values[DeliveryField.Fullname],
                deliveryPartnerId: values[DeliveryField.PartnerId],
                addressLine1: values[DeliveryField.Street],
                addressLine2: values[DeliveryField.City],
                postalCode: values[DeliveryField.PostalCode],
                country: values[DeliveryField.Country],
                note: values[DeliveryField.Note],
                locationId: values[DeliveryField.ApmNumber] ?? values[DeliveryField.WarehouseNumber],
            },

            items,
        }),
    );

    type SuccessAction = ReturnType<typeof createDeliveryRequest.success>;
    type FailureAction = ReturnType<typeof createDeliveryRequest.failure>;

    const result: SuccessAction | FailureAction = yield take([
        createDeliveryRequest.success.toString(),
        createDeliveryRequest.failure.toString(),
    ]);

    if (!isFailureAction<SuccessAction, FailureAction>(result)) {
        yield put([
            actions.newOrderForm.submitSuccess(),

            parcelsActions.fetchParcels.reset(DEFAULT_GROUP),
            resetEntitiesGroup(EntityKey.PARCELS),
        ]);

        yield put(push(config.routes.home, null));

        const parcel = yield* fetchParcel(result.payload.parcelId);

        if (parcel) {
            yield put(showParcelLabelToast(parcel.id));
        }
    } else {
        yield put(actions.newOrderForm.submitFailure(result.error));
    }
}

export default function* submitPostForm() {
    yield takeLatest(actions.newOrderForm.submit, newOrderFormSubmitHandler);
}

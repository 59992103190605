import { schema } from 'normalizr';

import { EntityKey } from '../../constants';
import type { Parcel } from '../../types';

export const locationSchema = new schema.Entity(EntityKey.LOCATIONS);
export const locationListSchema = [locationSchema];

export const destinationSchema = new schema.Entity(EntityKey.DESTINATIONS);
export const destinationListSchema = [destinationSchema];

export const deliveryPartnerSchema = new schema.Entity(EntityKey.DELIVERY_PARTNERS);
export const deliveryPartnerListSchema = [deliveryPartnerSchema];

export const entrustedPartnerSchema = new schema.Entity(EntityKey.ENTRUSTED_PARTNERS);
export const entrustedPartnerListSchema = [entrustedPartnerSchema];

export const csvImportSchema = new schema.Entity(EntityKey.CSV_IMPORT);
export const csvImportListSchema = [csvImportSchema];

export const deliveryRequestSchema = new schema.Entity(EntityKey.DELIVERY_REQUESTS, undefined, {
    idAttribute: 'orderNumber',
});

export const parcelSchema = new schema.Entity<Parcel>(EntityKey.PARCELS, {
    deliveryRequest: deliveryRequestSchema,
});

export const parcelListSchema = [parcelSchema];

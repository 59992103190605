import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 'fit-content',

    marginBottom: theme.metrics.spacing * 3,
    padding: theme.metrics.spacing * 2,

    backgroundColor: theme.colors.newBgTertiary,
    border: `1px solid ${theme.colors.borderInput}`,
    borderRadius: '0.3125rem',

    isDesktop: {
        minWidth: 555,
    },
});

export const text: TRuleWithTheme = ({ theme }) => ({
    marginBottom: 0,
    marginRight: theme.metrics.spacing * 5,

    color: theme.colors.foregroundsPrimary80,
});

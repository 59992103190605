import { useIntl } from 'react-intl';
import { TextField, RadioButtonGroupField, Condition, validatorsWithMessage, composeValidators } from 'modules/form';
import { toString as convertToString } from 'lodash';

import { useFelaEnhanced } from 'hooks';
import { PaymentMethod } from 'modules/order/modules/order-common';
import { useCurrencyPostfix } from 'modules/currency';

import { OrderMetaField } from '../../config';
import { useAllowedPaymentMethods } from '../../hooks';

import * as felaRules from './PaymentMethodSelect.rules';

const { required, greaterThan } = validatorsWithMessage;

const cashOnDeliveryValidators = {
    [OrderMetaField.CollectAmount]: composeValidators(required, greaterThan(0)),
};

export const getValidators = (values: { [OrderMetaField.PaymentMethod]: PaymentMethod }) => {
    return {
        [OrderMetaField.PaymentMethod]: required,
        ...(values[OrderMetaField.PaymentMethod] === PaymentMethod.CashOnDelivery ? cashOnDeliveryValidators : {}),
    };
};

export const getInitialValues = () => ({
    [OrderMetaField.PaymentMethod]: PaymentMethod.Prepaid,
    [OrderMetaField.CollectAmount]: '0',
});

export const PaymentMethodSelect = () => {
    const { formatMessage } = useIntl();
    const currencyPostfix = useCurrencyPostfix();
    const paymentMethods = useAllowedPaymentMethods();
    const { rules } = useFelaEnhanced(felaRules);

    return (
        <>
            <RadioButtonGroupField<PaymentMethod>
                name={OrderMetaField.PaymentMethod}
                options={paymentMethods.map(method => ({
                    value: method,
                    label: formatMessage({ id: `parcel.payment.method.${method}` }),
                }))}
                label={formatMessage({ id: 'order.meta.paymentMethod' })}
                required
            />

            <Condition when={OrderMetaField.PaymentMethod} is={PaymentMethod.CashOnDelivery}>
                <TextField<string>
                    type="number"
                    label={formatMessage({ id: 'order.meta.collectAmount' }) + currencyPostfix}
                    name={OrderMetaField.CollectAmount}
                    parse={convertToString}
                    extend={{ formItem: rules.textField }}
                    required
                />
            </Condition>
        </>
    );
};

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useAppSelector } from 'hooks/redux';

import { apiActions, selectSortedEntrustedPartners, selectEntrustedPartnersApi } from '../../entrustedPartners';

export const useEntrustedPartners = () => {
    const dispatch = useDispatch();
    const api = useAppSelector(selectEntrustedPartnersApi);
    const entrustedPartners = useAppSelector(selectSortedEntrustedPartners);

    useEffect(() => {
        if (!api.inProgress && !api.success && !api.error) {
            dispatch(apiActions.fetchEntrustedPartners.request());
        }
    }, [api, dispatch]);

    return { entrustedPartners, api };
};

import type { FC } from 'react';
import type { match, RouteComponentProps } from 'react-router-dom';

import { useFelaEnhanced, useGoBack } from 'hooks';
import { useParcels } from 'modules/entities/modules/parcels';
import { Loader, BackButton } from 'modules/ui';
import { Layout, Header } from 'modules/layout';
import { ErrorMessage } from 'modules/errors';

import OrderDetail from '../OrderDetail';
import * as felaRules from './OrderDetail.rules';
import { NewOrderDropdownButton } from 'modules/order/modules/order-create/components/NewOrderDropdownButton';
export interface OrderDetailPageProps extends RouteComponentProps {
    match: match<{ id: string }>;
}

const OrderDetailPage: FC<OrderDetailPageProps> = ({ match: { params } }) => {
    const { rules } = useFelaEnhanced(felaRules);
    const goBack = useGoBack();

    const id = params.id && decodeURIComponent(params.id);
    const { api, parcels } = useParcels(id, { orderNumber: id }, { resetOnUnmount: true });

    return (
        <Layout
            header={
                <Header>
                    <NewOrderDropdownButton />
                </Header>
            }
        >
            <BackButton extend={{ button: rules.backButton }} onClick={goBack} />

            <ErrorMessage error={api.error ? { id: 'error.order.get', values: { id } } : null}>
                <Loader show={!api.success}>
                    <OrderDetail data={parcels || []} />
                </Loader>
            </ErrorMessage>
        </Layout>
    );
};

export default OrderDetailPage;

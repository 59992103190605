import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import * as felaRules from './PageNumberDelimiter.rules';

export interface PageNumberDelimiterProps {
    extend?: RulesExtend<typeof felaRules>;
}

export const PageNumberDelimiter = ({ extend }: PageNumberDelimiterProps) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });

    return <span className={styles.delimiter}>...</span>;
};

import { useFelaEnhanced } from 'hooks';
import { csvImportFormKey, CsvImportValues, useResetCsvImport } from 'modules/entities/modules/csvImport';
import { ErrorMessage } from 'modules/errors';
import { FileField, Form, useFormSubmit, validateWithTranslation, validatorsWithMessage } from 'modules/form';
import { Button, Spacing } from 'modules/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import type { RulesExtend } from 'styles';
import { form } from '../../config';
import { cvsImportForm } from '../../services/actions';
import { AllowReturnSelect } from '../AllowReturnSelect';
import { CsvDelimiterSelect } from '../CsvDelimiterSelect';
import { ShowRecipientInformationSelect } from '../ShowRecipientInformationSelect';
import * as felaRules from './CsvImportForm.rules';
import { usePartner } from 'modules/partner/hooks';

export interface CsvImportFormProps {
    extend?: RulesExtend<typeof felaRules>;
    type: CsvImportValues['type'];
}

const { required } = validatorsWithMessage;

export const CsvImportForm = ({ extend, type }: CsvImportFormProps) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });
    const { formatMessage } = useIntl();
    const onSubmit = useFormSubmit<CsvImportValues>(cvsImportForm);
    const partner = usePartner();

    useResetCsvImport();

    const validate = (values: CsvImportValues) => {
        const validators = {
            [csvImportFormKey.file]: required,
            [csvImportFormKey.delimiter]: required,
            [csvImportFormKey.allowReturn]: required,
            [csvImportFormKey.type]: required,
            [csvImportFormKey.showRecipientInformation]: required,
        } as const satisfies Partial<Record<keyof CsvImportValues, any>>;

        return validateWithTranslation<CsvImportValues>(values, validators, formatMessage);
    };

    return (
        <Form<CsvImportValues>
            name={form.orderFromCsv}
            onSubmit={onSubmit}
            initialValues={
                {
                    allowReturn: true,
                    [csvImportFormKey.originContactEmail]: partner?.email,
                    [csvImportFormKey.originContactNumber]: partner?.phoneNumber,
                    type,
                } as const satisfies Partial<CsvImportValues>
            }
            validate={validate}
        >
            {({ submitting, submitError, dirtySinceLastSubmit }) => (
                <>
                    <FileField name={csvImportFormKey.file} />

                    <Spacing bottom={2.5} />

                    <CsvDelimiterSelect name={csvImportFormKey.delimiter} />

                    <Spacing bottom={2.5} />

                    <AllowReturnSelect
                        className={styles.allowReturnSelect}
                        label={formatMessage({ id: 'order.csvImport.input.return.label' })}
                        required={undefined}
                    />

                    <Spacing bottom={2.5} />

                    <ShowRecipientInformationSelect />

                    {submitError && !dirtySinceLastSubmit && <ErrorMessage error={submitError} />}

                    <Spacing bottom={5} />

                    <Button
                        type={Button.Type.PRIMARY}
                        size={Button.Size.BIG}
                        htmlType="submit"
                        disabled={submitting}
                        loading={submitting}
                    >
                        <FormattedMessage id="order.new.button.create" />
                    </Button>
                </>
            )}
        </Form>
    );
};

import type { TRuleWithTheme } from 'styles';
import type { BoxProps } from './Box';

type StyleProps = Required<Pick<BoxProps, 'direction' | 'space'>>;

export const box: TRuleWithTheme<StyleProps> = ({ direction }) => ({
    display: 'flex',
    flexDirection: direction,
});

export const itemContainer: TRuleWithTheme<StyleProps> = ({ direction, space }) => ({
    ':not(:last-child)': {
        [direction === 'row' ? 'marginRight' : 'marginBottom']: space,
    },
});

import metrics from './metrics';

const unitSpacing = (units: number | null | undefined) => {
    if (units === null || units === undefined) {
        return undefined;
    }

    return units * metrics.spacing;
};

const margin = (top?: number | null, right?: number | null, bottom?: number | null, left?: number | null) =>
    ({
        marginTop: unitSpacing(top),
        marginRight: unitSpacing(right),
        marginBottom: unitSpacing(bottom),
        marginLeft: unitSpacing(left),
    } as const);

export const spacing = {
    unit: (value: number) => unitSpacing(value) as number,
    margin,
} as const;

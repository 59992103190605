import type { FC } from 'react';
import { Radio } from 'antd';
import type { RadioProps } from 'antd';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import * as felaRules from './RadioButton.rules';

export interface RadioButtonProps extends RadioProps {
    extend?: RulesExtend<typeof felaRules>;
}

const RadioButton: FC<RadioButtonProps> = ({ extend, children, checked, disabled, ...rest }) => {
    const { styles } = useFelaEnhanced(felaRules, { extend, checked, disabled });

    return (
        <Radio className={styles.radioButton} checked={checked} disabled={disabled} {...rest}>
            {children}
        </Radio>
    );
};

export default RadioButton;

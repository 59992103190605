import { useIntl } from 'react-intl';

import { RadioButtonGroupField, type RadioButtonGroupFieldProps } from 'modules/form';
import { DeliveryField } from '../../config';
import type { Omit } from 'types';

interface ShowRecipientInformationSelectProps extends Omit<RadioButtonGroupFieldProps<boolean>, 'options' | 'name'> {}

export const ShowRecipientInformationSelect = (props: ShowRecipientInformationSelectProps) => {
    const { formatMessage } = useIntl();

    return (
        <RadioButtonGroupField<boolean>
            name={DeliveryField.ShowRecipientInformation}
            label={formatMessage({ id: 'order.delivery.showRecipientInformation' })}
            defaultValue={true}
            options={[
                {
                    value: false,
                    label: formatMessage({ id: `order.delivery.showRecipientInformation.hide` }),
                },
                {
                    value: true,
                    label: formatMessage({ id: `order.delivery.showRecipientInformation.show` }),
                },
            ]}
            {...props}
        />
    );
};

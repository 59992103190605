import { loginRequest, LOGIN_SUCCESS, LOGIN_FAILURE } from '@ackee/petrus';
import { put, select, take, takeLeading } from 'redux-saga/effects';
import type { AnyAction } from '@reduxjs/toolkit';

import type { AuthState } from 'modules/auth/types';
import { getIntlErrorMessage } from 'modules/errors';

import { loginForm as loginFormActions } from '../../actions';
import { selectAuth } from '../../selectors';

function* handleLoginForm(action: ReturnType<typeof loginFormActions.submit>) {
    const { challenge }: AuthState = yield select(selectAuth);
    const { smsCode } = action.payload;

    if (!challenge) {
        yield put(
            loginFormActions.submitFailure({
                id: 'error.login.401',
            }),
        );
        return;
    }

    yield put(
        loginRequest({
            smsCode,
            challenge,
        }),
    );

    const result: AnyAction = yield take([LOGIN_SUCCESS, LOGIN_FAILURE]);

    if (result.type === LOGIN_SUCCESS) {
        yield put(loginFormActions.submitSuccess());
    } else {
        const errorMessage = getIntlErrorMessage(result.error, {
            status: {
                401: {
                    id: 'error.login.401',
                },
            },
        });
        yield put(loginFormActions.submitFailure(errorMessage));
    }
}

export default function* loginFormSaga() {
    yield takeLeading(loginFormActions.submit, handleLoginForm);
}

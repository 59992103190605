import type { Key, ReactNode } from 'react';
import { push } from 'connected-react-router';
import { Dropdown as AntdDropdown } from 'antd';
import type { LocationDescriptorObject } from 'history';

import { useFelaEnhanced } from 'hooks';
import { useAppDispatch } from 'hooks/redux';
import type { RulesExtend } from 'styles/theme';
import { Chevron } from 'modules/ui/modules/icons';

import type { DropdownProps } from '../Dropdown';
import { ButtonSize, ButtonType } from '../Button/types';
import type { ButtonProps } from '../Button';
import { DropdownMenu } from '../Dropdown/DropdownMenu';

import * as felaRules from './DropdownButton.rules';

interface MenuItem {
    onClick?: () => void;
    key: Key;
    label: string | ReactNode;
    description?: string | ReactNode;
    link?: LocationDescriptorObject;
}

export interface DropdownButtonProps
    extends Omit<DropdownProps, 'extend' | 'items'>,
        Omit<ButtonProps, 'extend' | 'icon' | 'isIconSuffix'> {
    extend?: RulesExtend<typeof felaRules>;
    items: MenuItem[];
}

export const DropdownButton = ({
    extend,
    items,
    label,
    type,
    size = ButtonSize.NORMAL,
    ...props
}: DropdownButtonProps) => {
    const {
        rules: { icon },
    } = useFelaEnhanced({ icon: felaRules.icon });
    const { styles, rules } = useFelaEnhanced(felaRules, { type, size, isIconSuffix: false });
    const dispatch = useAppDispatch();

    const menu = (
        <DropdownMenu
            extend={{ menu: rules.menu }}
            styleProps={{ type }}
            items={items.map(({ key, label, description, link, onClick }) => ({
                key,
                label: (
                    <>
                        <div>{label}</div>
                        {description && <div className={styles.menuItemDescription}>{description}</div>}
                    </>
                ),
                onClick: () => {
                    if (link) {
                        dispatch(push(link));
                    }

                    onClick?.();
                },
            }))}
        />
    );

    return (
        <AntdDropdown overlay={menu} trigger={['click']} className={styles.dropdown} {...props}>
            <span>
                {label} <Chevron extend={{ icon }} />
            </span>
        </AntdDropdown>
    );
};

DropdownButton.Type = ButtonType;
DropdownButton.Size = ButtonSize;

import type { FC } from 'react';
import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useFelaEnhanced } from 'hooks';
import type { LocationType } from 'modules/entities';
import { ErrorMessage } from 'modules/errors';
import { Form, useFormSubmit, validateWithTranslation } from 'modules/form';
import { ParcelSize } from 'modules/parcels';
import { Box, Button, Headline, Panel } from 'modules/ui';

import {
    DeliveryField,
    form,
    ItemsField,
    OrderItemField,
    OrderMetaField,
    OriginFieldItems,
    PickupField,
} from '../../config';
import { useLocations } from '../../hooks';
import { newOrderForm } from '../../services/actions';
import type { NewOrderFormValues } from '../../types';
import { generateReferenceNumber } from '../../utils';
import { getValidators as getAllowReturnValidators } from '../AllowReturnSelect';
import {
    CustomerDetailsForm,
    getInitialValues as getCustomerDetailsInitialValues,
    getValidators as getCustomerDetailsValidators,
} from '../CustomerDetailsForm';
import { DeliveryLocation, getValidators as getDeliveryLocationValidators } from '../DeliveryLocation';
import { getValidators as getPaymentMethodValidators } from '../PaymentMethodSelect';
import PickupForm from '../PickupForm';

import * as felaRules from './OrderFromWarehouseToWarehouseForm.rules';
import { ShowRecipientInformationSelect } from '../ShowRecipientInformationSelect';
import { PaymentMethod } from 'modules/order/modules/order-common';
import { usePartner } from 'modules/partner/hooks';

interface OrderFromWarehouseToWarehouseFormProps {}

export const validators = {
    ...getDeliveryLocationValidators(DeliveryField.WarehouseNumber),
    ...getAllowReturnValidators(),
    ...getCustomerDetailsValidators(),
};

const items = {
    [OrderItemField.Value]: '0',
    [OrderItemField.Size]: ParcelSize.Medium,
};

const locationTypes: LocationType[] = ['warehouse'];

const staticInitialValues = {
    [OrderMetaField.InvoiceValue]: '0',
    [ItemsField.Items]: [items],
    [DeliveryField.ShowRecipientInformation]: true,
    [OrderMetaField.PaymentMethod]: PaymentMethod.Prepaid,
    [OrderMetaField.AllowReturn]: false,
    ...getCustomerDetailsInitialValues(),
};

export const OrderFromWarehouseToWarehouseForm: FC<OrderFromWarehouseToWarehouseFormProps> = () => {
    const { formatMessage } = useIntl();
    const onSubmit = useFormSubmit<NewOrderFormValues>(newOrderForm);
    const { locations } = useLocations(locationTypes);
    const partner = usePartner();

    const initialValues = useMemo(
        () => ({
            [PickupField.Location]: locations?.length > 0 ? locations[0].id : undefined,
            [DeliveryField.ReferenceNumber]: generateReferenceNumber(),
            [DeliveryField.Origin]: {
                [OriginFieldItems.OriginContactEmail]: partner?.email,
                [OriginFieldItems.OriginContactNumber]: partner?.phoneNumber,
            },
            ...staticInitialValues,
        }),
        [locations, partner],
    );
    const { rules, theme } = useFelaEnhanced(felaRules);

    const validate = (values: NewOrderFormValues) => {
        const formValidators = {
            ...validators,
            ...getPaymentMethodValidators(values),
        };
        return validateWithTranslation<NewOrderFormValues>(values, formValidators, formatMessage);
    };

    return (
        <>
            {/* disabled for now (05/2024) */}
            {/* <CsvSwitcher state="form" link={config.routes.fromWarehouseOrderCsvImport} /> */}

            <Panel>
                <Form<NewOrderFormValues>
                    name={form.orderFromWarehouseToWarehouse}
                    onSubmit={onSubmit}
                    initialValues={initialValues}
                    validate={validate}
                >
                    {({ submitting, submitError, dirtySinceLastSubmit }) => {
                        return (
                            <Box space={theme.metrics.spacing * 5}>
                                <PickupForm locationTypes={locationTypes} />

                                <>
                                    <Headline level={2}>
                                        <FormattedMessage id="order.delivery.details" />
                                    </Headline>
                                    <CustomerDetailsForm />
                                </>

                                <>
                                    <Headline level={2}>
                                        <FormattedMessage id="order.delivery.address" />
                                    </Headline>

                                    <DeliveryLocation
                                        name={DeliveryField.WarehouseNumber}
                                        locationTypes={['warehouse']}
                                        label="order.delivery.warehouse.number"
                                        placeholder="order.delivery.warehouse.number.placeholder"
                                        widgetEnabled={false}
                                    />
                                    {/* disabled for now, with prefilled default values */}
                                    {/* <PaymentMethodSelect />
                                <AllowReturnSelect /> */}
                                    <ShowRecipientInformationSelect
                                        extend={{ formItem: rules.showRecipientInformationSelect }}
                                    />
                                </>

                                {submitError && !dirtySinceLastSubmit && <ErrorMessage error={submitError} />}

                                <Button
                                    type={Button.Type.PRIMARY}
                                    size={Button.Size.BIG}
                                    htmlType="submit"
                                    disabled={submitting}
                                >
                                    <FormattedMessage id="order.new.button.create" />
                                </Button>
                            </Box>
                        );
                    }}
                </Form>
            </Panel>
        </>
    );
};

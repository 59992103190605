import type { Parcel } from '../../../entities';

export enum ParcelRowType {
    Item = 'item',
    Skeleton = 'skeleton',
    Separator = 'separator',
}

export type ParcelItem = Parcel & {
    rowTypes: Set<ParcelRowType>;
    height: number;
};

export type ParcelSkeletonItem = Pick<ParcelItem, 'rowTypes' | 'height'>;

import { put, takeLatest } from 'redux-saga/effects';

import { invalidatePartner, setPartnerId } from '../slices/partner';
import { apiActions } from 'modules/entities/modules/entrustedPartners';

function* refetchPartners(action: ReturnType<typeof setPartnerId>) {
    yield put(apiActions.fetchEntrustedPartners.request());
}

export default function* refetchPartnersSaga() {
    yield takeLatest(invalidatePartner.toString(), refetchPartners);
}

import { useAppSelector } from 'hooks/redux';
import { DEFAULT_GROUP } from 'modules/entities';
import { selectParcelsApi } from 'modules/entities/modules/parcels';
import { PageType } from 'modules/entities/modules/parcels';

export const useParcelsListPaging = (fetchParcels: (pageNumber: number, pageType: PageType) => void) => {
    const {
        inProgress: apiInProgress,
        totalCount,
        limit,
        pageNumber: currentPageNumber,
    } = useAppSelector(state => selectParcelsApi(state, DEFAULT_GROUP));

    const firstPageNumber = 1;
    const previousPageNumber = currentPageNumber - 1;
    const nextPageNumber = currentPageNumber + 1;
    const lastPageNumber = Math.ceil(totalCount / limit);

    const getNextPageNumber = (pageType: PageType) => {
        if (pageType === PageType.FIRST) {
            return 1;
        }

        if (pageType === PageType.PREVIOUS) {
            return currentPageNumber - 1;
        }

        if (pageType === PageType.NEXT) {
            return currentPageNumber + 1;
        }

        return lastPageNumber;
    };

    const pageNumberClickHandlerFactory = (pageType: PageType) => () => {
        if (apiInProgress) {
            return;
        }
        const nextPageNumber = getNextPageNumber(pageType);
        fetchParcels(nextPageNumber, pageType);
    };

    return {
        firstPageNumber,
        previousPageNumber,
        currentPageNumber,
        nextPageNumber,
        lastPageNumber,
        pageNumberClickHandlerFactory,
    };
};

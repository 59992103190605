import { FormattedDate, FormattedTime } from 'react-intl';
import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import * as felaRules from './TimeDate.rules';

export interface TimeDateProps {
    value: string;
    extend?: RulesExtend<typeof felaRules>;
}

const TimeDate = ({ value, extend }: TimeDateProps) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });

    return (
        <div className={styles.container}>
            <span>
                <FormattedTime value={value} hour="numeric" minute="numeric" hourCycle="h23" />,
            </span>
            <span>
                <FormattedDate value={value} day="numeric" month="numeric" year="numeric" />
            </span>
        </div>
    );
};

export default TimeDate;

import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    width: '100%',
});

export const list: TRuleWithTheme = ({ theme: { spacing } }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr',
    columnGap: spacing.unit(1),
    marginLeft: 'auto',
    marginRight: 'auto',
});

export const icon: TRuleWithTheme = ({ theme: { colors, metrics } }) => ({
    width: 48,
    height: 48,
    color: colors.text,
    marginBottom: metrics.spacing * 2,
});

import { selectLocationsEntities } from 'modules/entities';
import { useField } from 'react-final-form';

import { useAppSelector } from 'hooks/redux';

import { PickupField } from '../config';

const useSelectedOriginLocation = () => {
    const { input } = useField(PickupField.Location, { subscription: { value: true } });
    const selectedLocation = input.value;

    const locations = useAppSelector(selectLocationsEntities);

    return selectedLocation ? locations.byId[selectedLocation] : null;
};

export default useSelectedOriginLocation;

import type { TRuleWithTheme } from 'styles';
import type { AlertProps } from './Alert';

type StyleProps = Required<Pick<AlertProps, 'type' | 'framed'>>;

export const alert: TRuleWithTheme<StyleProps> = ({ theme: { metrics, colors }, type, framed }) => {
    const colorByType = {
        success: colors.accentPrimary,
        error: colors.buttonPrimaryDestructive,
        warning: undefined, // Not used nor exists in the design
    } as const;

    const bgColorByType = {
        success: `${colors.accentPrimary}26`,
        error: `${colors.buttonPrimaryDestructive}26`,
        warning: colors.newBgTertiary,
    } as const;

    return {
        alignItems: 'flex-start',

        padding: framed ? metrics.spacing * 1.5 : 0,

        color: framed ? colors.foregroundsPrimary80 : colorByType[type],
        backgroundColor: framed ? bgColorByType[type] : undefined,

        border: 0,
        borderRadius: 5,

        '& .ant-alert-message': {
            color: 'inherit',
            fontSize: '1rem',
            lineHeight: '1.25em',
        },

        '& .ant-alert-description': {
            color: colors.foregroundsPrimary80,
            fontSize: '0.875rem',
            lineHeight: '1.2em',
        },
    };
};

export const icon: TRuleWithTheme = ({ theme }) => ({
    width: 18,
    height: 18,

    marginTop: 1,
    marginRight: theme.metrics.spacing,
});

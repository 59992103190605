import type { TRuleWithTheme } from 'styles/theme';

export const link: TRuleWithTheme = () => ({
    display: 'inline',
});

export const paragraph: TRuleWithTheme = ({ theme }) => ({
    paddingTop: theme.metrics.spacing * 0.5,
});

export const modalContent: TRuleWithTheme = ({ theme }) => ({
    paddingBlock: theme.metrics.spacing * 3.5,
});

export const propertiesContainer: TRuleWithTheme = ({ theme }) => ({
    paddingBlock: theme.metrics.spacing * 2.5,
});

export const noMargin: TRuleWithTheme = () => ({
    marginBottom: 0,

    '&.ant-typography': {
        marginBottom: 0,
    },
});

export const required: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.buttonPrimaryDestructive,
});

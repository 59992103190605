import type { Parcel } from 'modules/entities';
import { useIntl } from 'react-intl';

import { useCurrency } from './useCurrency';

export const useCurrencyPostfix = (payment?: Parcel['payment']) => {
    const { formatMessage } = useIntl();
    const currency = useCurrency(payment);

    return ' ' + formatMessage({ id: 'general.inCurrency' }, { currency });
};

import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';
import { Button } from 'modules/ui';

import useLogout from './hooks/useLogout';
import * as felaRules from './LogoutButton.rules';

export interface LogoutButtonProps {
    extend?: RulesExtend<typeof felaRules>;
}

export const LogoutButton: FC<LogoutButtonProps> = ({ extend }) => {
    const { rules } = useFelaEnhanced(felaRules, { extend });
    const logout = useLogout();

    return (
        <Button type={Button.Type.LINK} extend={{ button: rules.button }} onClick={logout}>
            <FormattedMessage id="auth.logout" />
        </Button>
    );
};

import type { ReactNode } from 'react';
import { Field, useField } from 'react-final-form';

import { useFelaEnhanced } from 'hooks';
import { RadioButton } from 'modules/ui';
import type { RulesExtend } from 'styles/theme';

import FormItem, { FormItemOwnProps } from '../FormItem';

import type { FormFieldProps } from '../types';
import * as felaRules from './RadioButtonGroupField.styles';

interface Option<TValue> {
    value: TValue;
    label: ReactNode;
}

export interface RadioButtonGroupFieldProps<TValue> extends FormFieldProps<TValue> {
    options: Option<TValue>[];
    fluid?: FormItemOwnProps<TValue>['fluid'];
    extend?: RulesExtend<typeof felaRules>;
    className?: string;
}

export const RadioButtonGroupField = <TValue extends string | number | boolean = string>({
    label,
    required = false,
    value,
    help,
    options,
    fluid,
    extend,
    disabled,
    className,
    ...props
}: RadioButtonGroupFieldProps<TValue>) => {
    const { styles, rules } = useFelaEnhanced(felaRules, { extend });
    const { input, meta } = useField(props.name);

    return (
        <FormItem<TValue>
            {...{ input, meta, required, help, fluid, label, extend: { formItem: rules.formItem }, className }}
        >
            <div className={styles.group}>
                {options.map(option => (
                    <Field<TValue>
                        type="radio"
                        {...props}
                        value={option.value}
                        disabled={disabled}
                        key={String(option.value)}
                    >
                        {({ input }) => (
                            <RadioButton
                                extend={{ radioButton: felaRules.radioButton }}
                                {...input}
                                disabled={props.disabled}
                            >
                                {option.label}
                            </RadioButton>
                        )}
                    </Field>
                ))}
            </div>
        </FormItem>
    );
};

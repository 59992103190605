import React from 'react';

import { FormattedMessage } from 'react-intl';
import config from 'config';
import { Button } from 'modules/ui';

interface ExtendPickUpPeriodButtonProps {
    parcelNumber: string;
}

const ExtendPickUpPeriodButton = ({ parcelNumber }: ExtendPickUpPeriodButtonProps) => {
    const href = React.useMemo(() => {
        const url = new URL(config.trackBoxNowUrl);

        url.pathname = parcelNumber;

        return url.toString();
    }, [parcelNumber]);

    return (
        <a href={href} target="_blank" rel="noreferrer noopener">
            <Button type={Button.Type.TERTIARY}>
                <FormattedMessage id="parcel.extendPickUpPeriod" />
            </Button>
        </a>
    );
};

export default ExtendPickUpPeriodButton;

import type { ReactNode } from 'react';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';
import { Logo, Card } from 'modules/ui';

import * as felaRules from './CardLayout.rules';
import Layout from '../Layout';

export interface CardLayoutProps {
    children: ReactNode;
    extend?: RulesExtend<typeof felaRules>;
}

export const CardLayout = ({ children, extend }: CardLayoutProps) => {
    const { styles, rules } = useFelaEnhanced(felaRules, { extend });

    return (
        <Layout header={null}>
            <div className={styles.container}>
                <Card extend={{ container: rules.cardContainer }}>
                    <Logo type={Logo.Type.LOGIN} />
                    {children}
                </Card>
            </div>
        </Layout>
    );
};

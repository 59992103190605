import type { TRuleWithTheme } from 'styles/theme';

const CALENDAR_ICON_SIZE = 20;

export const datePicker: TRuleWithTheme = ({ theme: { colors, metrics } }) => ({
    width: 210,

    paddingTop: metrics.spacing * 1.25,
    paddingBottom: metrics.spacing * 1.25,
    paddingLeft: metrics.spacing * 1.75,
    paddingRight: metrics.spacing * 1.75,

    borderRadius: 5,
    border: `2px solid ${colors.bgInput}`,

    background: colors.bgInput,
    color: colors.fgPrimary,

    opacity: 1,

    '&:hover': {
        border: `2px solid ${colors.bgInput}`,

        '&.ant-picker-focused': {
            border: `2px solid ${colors.borderInputFocus}`,
        },
    },

    '&.ant-picker-focused': {
        boxShadow: 'none',
        border: `2px solid ${colors.borderInputFocus}`,
    },

    '& .ant-picker-suffix': {
        color: colors.fgPrimary,
    },

    '& .ant-picker-clear': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        width: CALENDAR_ICON_SIZE,
        height: CALENDAR_ICON_SIZE,

        background: colors.bgInput,
        color: colors.foregroundsPrimary80,

        opacity: 1,
    },

    '& .ant-picker-input > input': {
        lineHeight: '1.25rem',
    },
});

export const dropdown: TRuleWithTheme = ({ theme: { colors, shadow, metrics } }) => ({
    '& .ant-picker-cell-selected': {
        '& .ant-picker-cell-inner': {
            background: colors.accentTertiary,
            color: colors.accentSecondary,
        },
    },

    '& .ant-picker-cell-today': {
        '& .ant-picker-cell-inner::before': {
            borderRadius: '50%',
            borderColor: colors.accentTertiary,
        },
    },

    '& .ant-picker-cell-inner': {
        borderRadius: '50%',
    },

    '& .ant-picker-cell': {
        color: colors.fgSecondary,

        '&.ant-picker-cell-in-view': {
            color: colors.fgPrimary,
        },
    },

    '& .ant-picker-content > thead th': {
        color: colors.fgSecondary,
    },

    '& .ant-picker-panel-container': {
        boxShadow: shadow.dropdown,
        borderRadius: 5,
    },

    '& .ant-picker-header-view': {
        fontSize: '1rem',
        fontWeight: 400,

        '& button': {
            lineHeight: 1.25,
            marginTop: metrics.spacing * 2.25,
        },
    },

    '& .ant-picker-footer': {
        display: 'none',
    },
});

export const prevIcon: TRuleWithTheme = ({ theme: { metrics } }) => ({
    marginTop: metrics.spacing * 2,
});

export const nextIcon: TRuleWithTheme = ({ theme: { metrics } }) => ({
    marginTop: metrics.spacing * 2,
    transform: 'rotateZ(-180deg)',
});

export const calendarIcon: TRuleWithTheme = ({ theme: { metrics } }) => ({
    width: CALENDAR_ICON_SIZE,
    height: CALENDAR_ICON_SIZE,
});

import React from 'react';
import { useFelaEnhanced } from 'hooks';

import * as felaRules from './CodeField.rules';
import TextField from '../TextField';
import type { TextFieldOwnProps } from '../TextField';

export interface CodeFieldProps extends TextFieldOwnProps<number> {}

export const CodeField = ({ extend, ...props }: CodeFieldProps) => {
    const { rules } = useFelaEnhanced(felaRules, { extend });

    return <TextField extend={rules} {...props} />;
};

import { usePartner } from 'modules/partner/hooks';

export const usePartnerPermissions = () => {
    const partner = usePartner();

    if (!partner) {
        throw new Error("Don't use usePartnerPermissions hook when partner is null.");
    }

    return partner.permission;
};

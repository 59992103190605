import type { LocationType } from 'modules/entities';
import { useLocations } from './useLocations';

const locationTypes: LocationType[] = ['any-apm'];

export const useAnyApmLocation = () => {
    const { locations } = useLocations(locationTypes);

    return locations?.length > 0 ? locations[0] : null;
};

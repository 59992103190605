import type { IntlFormatters } from 'react-intl';
import type { FieldValidatorWithMessage, FieldValidator, ErrorDescriptor, TranslatedFieldValidator } from './types';

type ValidationError = ReturnType<FieldValidatorWithMessage>;

export const composeValidators =
    (...validators: Array<FieldValidatorWithMessage>): FieldValidatorWithMessage =>
    value =>
        validators.reduce<ValidationError>(
            (error: ValidationError, validator: FieldValidatorWithMessage) => error || validator(value),
            undefined,
        );

export const setValidatorMessage =
    (validator: FieldValidator, message: ErrorDescriptor): FieldValidatorWithMessage =>
    value => {
        const isValid = validator(value);
        return isValid ? undefined : message;
    };

export const translate =
    <FieldValue = string>(
        formatMessage: IntlFormatters['formatMessage'],
        validator: FieldValidatorWithMessage<FieldValue>,
    ): TranslatedFieldValidator<FieldValue> =>
    value => {
        const message = validator(value);

        if (!message) {
            return undefined;
        }

        if (typeof message === 'string') {
            return formatMessage({ id: message });
        }

        const [messageId, messageValues] = message;

        return formatMessage({ id: messageId }, messageValues);
    };

export const validateWithTranslation = <TFormValues extends Record<string, any> = Record<string, string>>(
    values: TFormValues,
    validators: Record<string, FieldValidatorWithMessage>,
    formatMessage: IntlFormatters['formatMessage'],
) => {
    const errors: Record<string, string> = {};
    Object.entries(validators).forEach(([fieldName, validator]) => {
        const error = translate(formatMessage, validator)(values[fieldName]);

        if (error) {
            errors[fieldName] = error;
        }
    }, {});

    return errors;
};

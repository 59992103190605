import type { Theme } from 'styles';

type Color = Theme['colors'][keyof Theme['colors']];

export const generateSolidButtonColors = (colors: { bg: Color; bgHover: Color; bgPressed: Color; text: Color }) => ({
    background: colors.bg,
    color: colors.text,
    borderColor: colors.bg,
    '&:hover': {
        background: colors.bgHover,
        color: colors.text,
        borderColor: colors.bgHover,
    },
    '&:focus': {
        background: colors.bgPressed,
        color: colors.text,
        borderColor: colors.bgPressed,
    },
});

export const generateBorderedButtonColors = (colors: { base: Color; hover: Color; pressed: Color }) => ({
    background: 'transparent',
    color: colors.base,
    borderColor: colors.base,
    '&:hover': {
        background: 'transparent',
        color: colors.hover,
        borderColor: colors.hover,
    },
    '&:focus': {
        background: 'transparent',
        color: colors.pressed,
        borderColor: colors.pressed,
    },
});

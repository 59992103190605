import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from 'antd';

import type { RulesExtend } from 'styles';
import { useFelaEnhanced } from 'hooks';
import { isTruthy } from 'types/guards';
import type { Location } from 'modules/entities/types';
import { Country } from 'modules/countries';

import { EMPTY } from '../../constants';
import { Paragraph } from '../Paragraph';
import { Panel } from '../Panel';
import LabeledValue from '../LabeledValue';

import * as felaRules from './Address.rules';

export interface AddressProps {
    location: Pick<Location, 'title' | 'name' | 'addressLine1' | 'addressLine2' | 'country' | 'postalCode' | 'note'> & {
        id: Location['id'] | null;
    };
    fullWidth?: boolean;
    dark?: boolean;
    extend?: RulesExtend<typeof felaRules>;
}

export const Address: FC<AddressProps> = ({ location, dark = true, fullWidth = false, extend }) => {
    const { rules, theme } = useFelaEnhanced(felaRules, { extend });
    const { spacing } = theme.metrics;
    const gutter = [spacing * 5, spacing * 2.5] as [number, number];

    const { id, title, name, addressLine1, addressLine2, country, postalCode, note } = location;

    const addressItems = [addressLine1, addressLine2, postalCode];
    const addressString = addressItems.filter(isTruthy).join(', ');
    const address = (
        <>
            {addressString}
            {addressString && country && ', '}
            {country && <Country country={country} />}
        </>
    );

    return (
        <Panel dark={dark} extend={{ panel: fullWidth ? rules.fullWidthPanel : rules.panel }}>
            <Paragraph size={Paragraph.Size.BIG} extend={{ paragraph: rules.title }}>
                {title || name || EMPTY}
            </Paragraph>
            <Paragraph>{title ? name : EMPTY}</Paragraph>

            <Row gutter={gutter}>
                <Col span={8}>
                    <LabeledValue label={<FormattedMessage id="address.address" />} value={address} />
                </Col>
                <Col span={8}>
                    <LabeledValue label={<FormattedMessage id="address.locationId" />} value={id || EMPTY} />
                </Col>
                <Col span={8}>
                    {/* TODO: Provide real value when API is ready */}
                    <LabeledValue label={<FormattedMessage id="address.locationPublicIdentifier" />} value={EMPTY} />
                </Col>
                <Col span={24}>
                    <LabeledValue label={<FormattedMessage id="address.note" />} value={note || EMPTY} />
                </Col>
            </Row>
        </Panel>
    );
};

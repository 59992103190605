import { put, takeLeading } from 'redux-saga/effects';
import type { AntonioError, RequestResult } from '@ackee/antonio-core';

import config from 'config';
import { authApi } from 'config/antonio';
import * as Log from 'config/loglevel';
import type { paths } from 'api/partnerApiSchema';

import { EntityKey } from 'modules/entities/constants';
import { normalizeEntrustedPartners } from 'modules/entities/services/normalizr';
import { setEntitiesGroup } from 'modules/entities/services/actions';

import { fetchEntrustedPartners as apiActions } from '../actions';
import { ErrorData, getIntlErrorMessage } from 'modules/errors';

type Path = typeof config.api.entrustedPartners;
type Operation = paths[`/api/v1${Path}`]['get'];
type ResponseData = Operation['responses']['200']['content']['application/json'];
type RequestAction = ReturnType<typeof apiActions.request>;

function* handleFetchEntrustedPartners(action: RequestAction) {
    try {
        const response: RequestResult<ResponseData> = yield* authApi.get<ResponseData>(config.api.entrustedPartners);

        const { result: ids, entities } = normalizeEntrustedPartners(response.data);

        yield put([
            setEntitiesGroup(EntityKey.ENTRUSTED_PARTNERS, {
                ids,
                byId: entities[EntityKey.ENTRUSTED_PARTNERS],
                strategy: 'replace',
            }),
            apiActions.success(undefined, {
                lastSuccessAt: new Date().toISOString(),
            }),
        ]);
    } catch (e: unknown) {
        const errorMessage = getIntlErrorMessage(e as AntonioError<ErrorData> | Error, {
            status: {
                403: { id: 'error.entrustedPartners.get.403' },
            },
            fallback: { id: 'error.entrustedPartners.get' },
        });

        if (errorMessage.unhandled) {
            Log.error(e as Error);
        }

        yield put(apiActions.failure(errorMessage));
    }
}

export default function* fetchEntrustedPartnersSaga() {
    yield takeLeading(apiActions.request.toString(), handleFetchEntrustedPartners);
}

import { combineReducers } from 'redux';
import { ApiReducerKey } from 'modules/entities/constants';

import list from './list';
import cancel from './cancel';

export default combineReducers({
    [ApiReducerKey.LIST]: list,
    cancel,
});

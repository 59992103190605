import type { TRuleWithTheme } from 'styles';

export const horizontalBox: TRuleWithTheme = () => ({
    alignItems: 'center',
});

export const title: TRuleWithTheme = () => ({
    '&.ant-typography': {
        marginBottom: 0,
    },
});

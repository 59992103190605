import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    display: 'inline-block',
    width: '70%',
});

export const formItem: TRuleWithTheme = () => ({
    minWidth: 200,
});

export const address: TRuleWithTheme = ({ theme }) => ({
    marginTop: theme.metrics.spacing * 2,
    marginBottom: theme.metrics.spacing * 2,
});

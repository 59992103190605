import type { TRule } from 'fela';

import animations from './animations';
import * as colors from './colors';
import * as font from './font';
import form from './form';
import keyframes from './keyframes';
import metrics from './metrics';
import shadow from './shadow';
import { spacing } from './spacing';

export const theme = {
    colors,
    metrics,
    form,
    shadow,
    font,
    animations,
    keyframes,
    spacing,
} as const;

export type Theme = typeof theme;
export type ThemeProps = { theme: Theme };

export type TRuleWithTheme<Props = {}> = TRule<ThemeProps & Props>;

export type RulesExtend<TExtandalbeRules, TProps = {}> = Partial<
    Record<keyof TExtandalbeRules, TRuleWithTheme<TProps>>
>;

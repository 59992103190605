import { useAppSelector } from 'hooks/redux';
import { selectEntrustedPartnersApi } from 'modules/entities/modules/entrustedPartners';
import { usePartner } from './usePartner';

export const useHasValidPartner = () => {
    const partner = usePartner();
    const api = useAppSelector(selectEntrustedPartnersApi);

    return {
        partner,
        hasValidPartner: partner !== null,
        loading: Boolean(api.inProgress),
    };
};

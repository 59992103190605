import type { FC, ReactNode } from 'react';
import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import * as felaRules from './ParcelPanel.rules';

export interface ParcelPanelProps {
    children: ReactNode;
    columnsTemplate: string;
    extend?: RulesExtend<typeof felaRules>;
}

export const ParcelPanel: FC<ParcelPanelProps> = ({ children, columnsTemplate, extend }) => {
    const { styles } = useFelaEnhanced(felaRules, { extend, columnsTemplate });

    return <div className={styles.panel}>{children}</div>;
};

import * as Consts from 'constants/index';

import plugins from './plugins';
import enhancers from './enhancers';

export const rendererConfig = {
    devMode: Consts.isEnvDevelopment,
    enhancers,
    plugins,
};

import type { TRuleWithTheme } from 'styles/theme';
import type { PageNumberProps } from './PageNumber';

type StyleProps = Pick<PageNumberProps, 'isCurrent'>;

export const pageNumber: TRuleWithTheme<StyleProps> = ({ isCurrent, theme: { colors } }) => ({
    padding: 10,
    marginLeft: 10,
    marginRight: 10,

    border: 'none',
    background: 'transparent',

    fontWeight: 700,
    fontSize: '1rem',
    lineHeight: 1.25,
    textDecorationLine: 'underline',

    color: colors.buttonEnabledText,
    opacity: 0.7,
    cursor: 'pointer',

    ...(isCurrent && {
        background: colors.accentTertiary,
        borderRadius: 50,
        padding: '6px 11.5px ',
        cursor: 'default',
        textDecorationLine: 'initial',
    }),
});

import { useIntl } from 'react-intl';

import config from 'config';
import { composeValidators, TextField, validatorsWithMessage } from 'modules/form';
import { useFelaEnhanced } from 'hooks';
import PhoneField from 'modules/form/components/PhoneField';

import { DeliveryField } from '../../config';
import type { DeliveryFormValues } from '../../types';

import * as felaRules from './CustomerDetailsForm.rules';

const { required, email, phoneNumberRequired } = validatorsWithMessage;

export const getValidators = () => ({
    [DeliveryField.PhoneNumber]: phoneNumberRequired,
    [DeliveryField.Email]: composeValidators(required, email),
    [DeliveryField.Fullname]: required,
});

export const getInitialValues = () => ({
    [DeliveryField.PhoneNumber]: {
        phone: undefined,
        dialCode: '',
    },
    [DeliveryField.Email]: '',
    [DeliveryField.Fullname]: '',
});

export const CustomerDetailsForm = () => {
    const { styles, rules } = useFelaEnhanced(felaRules);
    const { formatMessage } = useIntl();

    return (
        <div className={styles.container}>
            <TextField<DeliveryFormValues[DeliveryField.Fullname]>
                label={formatMessage({ id: 'order.delivery.fullname' })}
                name={DeliveryField.Fullname}
                autoComplete="name"
                required
            />
            <PhoneField
                label={formatMessage({ id: 'order.delivery.phoneNumber' })}
                name={DeliveryField.PhoneNumber}
                reactPhoneInput={{
                    country: config.phone.defaultCountry,
                    preferredCountries: config.phone.preferredCountries,
                    placeholder: `+${formatMessage({ id: 'auth.loginCode.phoneNumber.placeholder' })}`,
                    jumpCursorToEnd: false,
                }}
                required
            />
            <TextField<DeliveryFormValues[DeliveryField.Email]>
                type="email"
                label={formatMessage({ id: 'order.delivery.email' })}
                name={DeliveryField.Email}
                autoComplete="email"
                required
                extend={{ formItem: rules.lastFormItem }}
            />
        </div>
    );
};

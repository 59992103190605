import { useFelaEnhanced } from 'hooks';
import { FileUpload } from 'modules/ui/modules/icons';
import { FormattedMessage } from 'react-intl';
import type { RulesExtend } from 'styles/theme';

import * as felaRules from './FileInputEmpty.rules';

export interface FileInputEmptyProps {
    extend?: RulesExtend<typeof felaRules>;
}

export const FileInputEmpty = ({ extend }: FileInputEmptyProps) => {
    const { styles, rules } = useFelaEnhanced(felaRules, { extend });

    return (
        <>
            <FileUpload extend={{ icon: rules.icon }} />
            <span>
                <FormattedMessage id="order.fileInput.dragDropText" />
            </span>
            <span className={styles.link}>
                <FormattedMessage id="order.fileInput.dragDropLink" />
            </span>
        </>
    );
};

import { put, takeLeading } from 'redux-saga/effects';

import config from 'config';
import { authApi } from 'config/antonio';
import * as Log from 'config/loglevel';
import type { paths } from 'api/partnerApiSchema';
import { updateEntities } from 'modules/entities/services/actions';

import { updateDeliveryRequest as apiActions } from '../actions';
import { EntityKey } from 'modules/entities/constants';

type Path = typeof config.api.deliveryRequest;
type Operation = paths[`/api/v1${Path}`]['put'];
type ResponseData = Operation['responses']['200']['content']['application/json'];
type RequestAction = ReturnType<typeof apiActions.request>;

function* handleUpdateDeliveryRequest({ payload: { orderNumber, parcelId, allowReturn } }: RequestAction) {
    try {
        yield* authApi.put<ResponseData>(
            config.api.deliveryRequest,
            { allowReturn },
            {
                uriParams: { orderNumber },
            },
        );
        yield put([
            updateEntities(EntityKey.PARCELS, {
                [parcelId]: {
                    allowReturn,
                },
            }),
            apiActions.success(undefined, {
                lastSuccessAt: new Date().toISOString(),
            }),
        ]);
    } catch (e: unknown) {
        Log.error(e as Error);
        yield put(apiActions.failure((e as Error).message));
    }
}

export default function* updateDeliveryRequestSaga() {
    yield takeLeading(apiActions.request.toString(), handleUpdateDeliveryRequest);
}

import { Layout } from 'antd';
import { FormattedMessage } from 'react-intl';

import { useFelaEnhanced } from 'hooks';
import { EMAIL, PHONE_NUMBER } from '../../config';

import * as felaRules from './Footer.rules';

const Footer = () => {
    const { styles } = useFelaEnhanced(felaRules);

    return (
        <Layout.Footer className={styles.footer}>
            <span className={styles.brand}>
                <FormattedMessage id="general.appName" />
            </span>

            <a href={`mailto:${EMAIL}`} className={styles.item}>
                {EMAIL}
            </a>
            <a href={`tel:${PHONE_NUMBER}`} className={styles.item}>
                {PHONE_NUMBER}
            </a>
            <span className={styles.item}>
                <FormattedMessage id="layout.footer.address" />
            </span>
        </Layout.Footer>
    );
};

export default Footer;

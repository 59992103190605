import { put, takeLeading } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import config from 'config';

import { choosePartnerForm as choosePartnerFormActions } from '../actions';
import { setPartnerId } from '../slices/partner';

function* choosePartnerForm(action: ReturnType<typeof choosePartnerFormActions.submit>) {
    const { partnerId } = action.payload;

    yield put(push(config.routes.home));

    yield put(setPartnerId(partnerId));
    yield put(choosePartnerFormActions.submitSuccess());
}

export default function* choosePartnerFormSaga() {
    yield takeLeading(choosePartnerFormActions.submit, choosePartnerForm);
}

import { generatePath } from 'react-router-dom';

import config from 'config';
import type { Parcel } from 'modules/entities';

import { GRID_COLUMNS } from '../../config';
import {
    ParcelCreateTime,
    ParcelPanel,
    ParcelPanelCell,
    ParcelPanelLinkCell,
    ParcelState,
} from 'modules/parcels/modules/parcel-common';

export interface ParcelRowProps {
    data: Parcel;
}

export const ParcelRow = ({ data: { id, deliveryRequest, state, updateTime } }: ParcelRowProps) => {
    return (
        <ParcelPanel columnsTemplate={GRID_COLUMNS}>
            <ParcelPanelLinkCell to={generatePath(config.routes.parcel, { id })}>{id}</ParcelPanelLinkCell>

            <ParcelPanelCell>{deliveryRequest.destination.contactName}</ParcelPanelCell>

            <ParcelPanelCell>
                <ParcelCreateTime>{updateTime}</ParcelCreateTime>
            </ParcelPanelCell>

            <ParcelPanelCell>
                <ParcelState>{state}</ParcelState>
            </ParcelPanelCell>
        </ParcelPanel>
    );
};

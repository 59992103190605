import { FormattedMessage } from 'react-intl';

import { useFelaEnhanced } from 'hooks';
import { Select } from 'modules/form';
import { LabeledValue } from 'modules/ui';

import { ALL } from '../../constants';
import { paymentStates } from '../../config';
import type { PaymentState } from '../../types';

import * as felaRules from './PaymentStatus.rules';

export interface PaymentStatusProps {
    value?: PaymentState;
    onChange: (state: PaymentState | undefined) => void;
}

export const PaymentStatus = ({ value, onChange }: PaymentStatusProps) => {
    const { rules } = useFelaEnhanced(felaRules);

    return (
        <LabeledValue
            label={<FormattedMessage id="parcel.filter.paymentStatus" />}
            value={
                <Select
                    dropdownMatchSelectWidth={false}
                    value={value ?? ALL}
                    extend={{ select: rules.select }}
                    onChange={state => {
                        onChange?.(state === ALL ? undefined : state);
                    }}
                    bordered={false}
                >
                    <Select.Option value={ALL}>
                        <FormattedMessage id={`general.all`} />
                    </Select.Option>

                    {paymentStates.map(state => (
                        <Select.Option value={state} key={state}>
                            <FormattedMessage id={`parcel.payment.state.${state}`} />
                        </Select.Option>
                    ))}
                </Select>
            }
        />
    );
};

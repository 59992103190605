import React from 'react';
import { useDispatch } from 'react-redux';

import { useAppSelector } from 'hooks/redux';

import { updateDeliveryRequest as apiActions } from '../services/actions';
import { updateDeliveryRequestApiSelector } from '../services/selectors';

export default function useUpdateDeliveryRequest(orderNumber: string, parcelId: string) {
    const dispatch = useDispatch();
    const api = useAppSelector(updateDeliveryRequestApiSelector);

    const updateDeliveryRequest = React.useCallback(
        (allowReturn: boolean) => {
            dispatch(
                apiActions.request({
                    allowReturn,
                    orderNumber,
                    parcelId,
                }),
            );

            return () => {
                dispatch(apiActions.reset());
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [orderNumber],
    );

    return { api, updateDeliveryRequest };
}

import type { FC } from 'react';
import AntdModal from 'antd/lib/modal';
import type { ModalProps as AntdModalProps } from 'antd/lib/modal';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';
import { Cross } from 'modules/ui/modules/icons';

import * as felaRules from './Modal.rules';

const { mask, ...rest } = felaRules;

export interface ModalProps extends Omit<AntdModalProps, 'className' | 'maskStyle'> {
    extend?: RulesExtend<Omit<typeof felaRules, 'mask'>>;
    closeButtonSize?: number;
}

export const Modal: FC<ModalProps> = ({ extend, closeButtonSize = 20, ...props }) => {
    const { styles, rules, theme } = useFelaEnhanced(rest, { extend, closeButtonSize });

    return (
        <AntdModal
            closeIcon={<Cross extend={{ icon: rules.closeIcon }} />}
            className={styles.modal}
            maskStyle={mask(theme)}
            {...props}
        />
    );
};

import { createReducer } from '@reduxjs/toolkit';
import type { AuthState } from 'modules/auth/types';
import * as actions from '../actions';

const initialState: AuthState = {
    challenge: null,
    phoneNumber: null,
};

const authReducer = createReducer(initialState, builder => {
    builder
        .addCase(actions.getLoginCodeForm.submit, (state, action) => {
            const { phoneNumber } = action.payload;

            state.phoneNumber = phoneNumber.phone ?? null;
        })
        .addCase(actions.setLoginCode, (state, action) => {
            state.challenge = action.payload.challenge;
        })
        .addCase(actions.loginForm.submitSuccess, () => initialState);
});

export default authReducer;

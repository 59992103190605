import type { RadioProps } from 'antd';
import type { TRuleWithTheme } from 'styles/theme';

export const radioButton: TRuleWithTheme<Pick<RadioProps, 'checked' | 'disabled'>> = ({ theme: { colors } }) => ({
    '& .ant-radio-inner': {
        borderColor: colors.fgSecondary,
    },

    '& .ant-radio-checked': {
        '& .ant-radio-inner': {
            background: colors.accentSecondary,
            borderColor: colors.accentSecondary,

            '&::after': {
                background: colors.white,
            },
        },
    },
});

import { FormattedMessage } from 'react-intl';
import { useState, type ReactNode } from 'react';
import { Col, Row } from 'antd';

import { useFelaEnhanced } from 'hooks';
import { type CsvImportType, csvImportType, csvProperties } from 'modules/entities/modules/csvImport';
import { Button, Headline, Modal, Paragraph } from 'modules/ui';
import { ParagraphSize } from 'modules/ui/components/Paragraph/types';
import { ButtonType } from 'modules/ui/components/Button/types';
import type { MessageKey } from 'translations';

import { csvExampleLinks } from '../../config';
import * as felaRules from './CsvExamplesTextAndModal.rules';

export interface CsvExamplesTextAndModalProps {
    type: CsvImportType;
}

export const CsvExamplesTextAndModal = ({ type }: CsvExamplesTextAndModalProps) => {
    const { rules, styles } = useFelaEnhanced(felaRules);
    const [modalOpen, setModalOpen] = useState(false);

    const properties = csvProperties[type];

    return (
        <>
            <Paragraph extend={{ paragraph: rules.paragraph }}>
                <FormattedMessage
                    id="order.csvImport.exampleLinks"
                    values={{
                        filelink: (text: ReactNode) => (
                            <a
                                href={
                                    type === csvImportType.Warehouse
                                        ? csvExampleLinks.fromWarehouse
                                        : csvExampleLinks.fromAnyApm
                                }
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Button type={Button.Type.LINK} extend={{ button: rules.link }}>
                                    <>{text}</>
                                </Button>
                            </a>
                        ),
                        examples: (text: ReactNode) => (
                            <Button
                                type={ButtonType.LINK}
                                onClick={() => setModalOpen(true)}
                                extend={{ button: rules.link }}
                            >
                                <>{text}</>
                            </Button>
                        ),
                    }}
                />
            </Paragraph>
            <Modal open={modalOpen} onCancel={() => setModalOpen(false)} width={950} footer={null} closeButtonSize={32}>
                <section className={styles.modalContent}>
                    <Headline extend={{ headline: rules.noMargin }}>
                        <FormattedMessage id="order.csvImport.guide.title" />
                    </Headline>
                    <Paragraph>
                        <FormattedMessage
                            id="order.csvImport.guide.description"
                            values={{ required: (text: ReactNode) => <span className={styles.required}>{text}</span> }}
                        />
                    </Paragraph>

                    <Row gutter={[36, 48]} className={styles.propertiesContainer}>
                        {properties.map(property => (
                            <Col key={property.key} xs={24} sm={12} md={8}>
                                <Paragraph size={ParagraphSize.BIG} extend={{ paragraph: rules.noMargin }}>
                                    {property.key}
                                    {property.required && <span className={styles.required}>*</span>}
                                </Paragraph>
                                <Paragraph extend={{ paragraph: rules.noMargin }}>
                                    <FormattedMessage
                                        id={
                                            `order.csvImport.properties.${type}.${property.key}.description` as MessageKey
                                        }
                                        values={{
                                            br: <br />,
                                        }}
                                    />
                                </Paragraph>
                            </Col>
                        ))}
                    </Row>
                </section>
            </Modal>
        </>
    );
};

import type { TRuleWithTheme } from 'styles/theme';

export const panel: TRuleWithTheme = ({
    theme: {
        metrics: { spacing },
        colors,
    },
}) => ({
    marginBottom: spacing * 2,

    display: 'grid',
    alignItems: 'center',
    padding: spacing * 2,

    fontSize: '1rem',
    fontWeight: 400,
    color: colors.fgPrimary,
    lineHeight: 'normal',
});

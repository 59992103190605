import { FormattedMessage } from 'react-intl';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';
import { Button, Paragraph } from 'modules/ui';
import { Sad } from 'modules/ui/modules/icons';
import { useAppDispatch } from 'hooks/redux';
import { logout } from 'modules/auth/services/actions';

import * as felaRules from './NoPartners.rules';

export interface NoPartnersProps {
    extend?: RulesExtend<typeof felaRules>;
}

export const NoPartners = ({ extend }: NoPartnersProps) => {
    const { styles, rules } = useFelaEnhanced(felaRules, { extend });
    const dispatch = useAppDispatch();

    return (
        <div className={styles.container}>
            <Sad extend={{ icon: rules.icon }} />
            <Paragraph size={Paragraph.Size.BIG} extend={{ paragraph: rules.title }}>
                <FormattedMessage id="choosePartner.notFound.title" />
            </Paragraph>
            <Paragraph>
                <FormattedMessage id="choosePartner.notFound.subtitle" />
            </Paragraph>
            <Button
                type={Button.Type.PRIMARY}
                size={Button.Size.BIG}
                extend={{ button: rules.button }}
                onClick={() => {
                    dispatch(logout());
                }}
            >
                <FormattedMessage id="choosePartner.notFound.logout" />
            </Button>
        </div>
    );
};

import type { FC, ReactNode } from 'react';
import { useFelaEnhanced } from 'hooks';

import type { RulesExtend } from 'styles/theme';
import { ValueSize } from '../types';

import * as felaRules from './Value.rules';
export interface ValueProps {
    children: ReactNode;
    extend?: RulesExtend<typeof felaRules>;
    size?: ValueSize;
}

export const Value: FC<ValueProps> = ({ children, size = ValueSize.NORMAL, extend }) => {
    const { styles } = useFelaEnhanced(felaRules, { extend, size });

    return <span className={styles.value}>{children === null || children === undefined ? '–' : children}</span>;
};

import { combineRules } from 'fela';

import type { ThemeProps, TRuleWithTheme } from 'styles/theme';

import * as datePickerRules from '../DatePicker/DatePicker.rules';

export const { calendarIcon, nextIcon, prevIcon } = datePickerRules;

export const container: TRuleWithTheme = () => ({});

export const picker: TRuleWithTheme = combineRules(datePickerRules.datePicker, () => ({
    width: 250,

    '& .ant-picker-active-bar': {
        display: 'none',
    },

    '& .ant-picker-input > input': {
        textAlign: 'center',
    },

    '& .ant-picker-clear': {
        // we need to shift icon a bit to cover calendar icon
        right: 12,
    },
}));

export const dropdown: TRuleWithTheme = combineRules<ThemeProps, ThemeProps>(
    datePickerRules.dropdown,
    ({ theme: { colors } }) => {
        const rangeCellStyle = {
            background: colors.accentTertiary,
            color: colors.accentSecondary,
        };

        const roundedCellStyle = {
            borderRadius: '50%',
        };

        const rangeHighlightStyle = {
            '::before': {
                background: colors.accentTertiary,
                opacity: 0.4,
            },
        };

        const rangeHoverHighlightStyle = {
            '::after': {
                borderColor: colors.accentTertiary,
            },
        };

        const selectedRangeHoverStyle = {
            color: colors.fgOnPrimary,

            '::after': {
                background: colors.accentSecondary,
            },
        };

        return {
            '& .ant-picker-cell-range-start .ant-picker-cell-inner': rangeCellStyle,
            '& .ant-picker-cell-range-end .ant-picker-cell-inner': rangeCellStyle,

            '& .ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner':
                roundedCellStyle,
            '& .ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner':
                roundedCellStyle,

            '& .ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)': rangeHighlightStyle,
            '& .ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start':
                rangeHighlightStyle,

            '& .ant-picker-cell-in-range': rangeHighlightStyle,

            '& .ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)': rangeHighlightStyle,
            '& .ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end':
                rangeHighlightStyle,

            '& .ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)':
                rangeHoverHighlightStyle,
            '& .ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)': rangeHoverHighlightStyle,
            '& .ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)':
                rangeHoverHighlightStyle,
            '& .ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single': rangeHoverHighlightStyle,
            '& tr > .ant-picker-cell-range-hover:first-child': rangeHoverHighlightStyle,
            '& tr > .ant-picker-cell-range-hover:last-child': rangeHoverHighlightStyle,

            '& .ant-picker-cell-in-range.ant-picker-cell-range-hover::before': {
                background: colors.accentSecondary,
            },

            '& .ant-picker-date-panel .ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner':
                selectedRangeHoverStyle,
            '& .ant-picker-date-panel .ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner':
                selectedRangeHoverStyle,
        };
    },
);

export const separator: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.fgSeparator,
});

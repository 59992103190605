import type { TRuleWithTheme } from 'styles';
import type { SeparatorProps } from './Separator';

type StyleProps = Required<Pick<SeparatorProps, 'spacing'>>;

export const separator: TRuleWithTheme<StyleProps> = ({ theme, spacing }) => ({
    height: 1,
    marginTop: theme.metrics.spacing * spacing,
    marginBottom: theme.metrics.spacing * spacing,

    border: 0,

    background: theme.colors.fgSeparator,
});

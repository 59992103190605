import { useFelaEnhanced } from 'hooks';

import * as felaRules from './Separator.rules';

export interface SeparatorProps {
    spacing?: number;
}

export const Separator = ({ spacing = 2 }: SeparatorProps) => {
    const { styles } = useFelaEnhanced(felaRules, { spacing });

    return <hr className={styles.separator} />;
};

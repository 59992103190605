import { useIntl } from 'react-intl';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import generatePicker from 'antd/es/date-picker/generatePicker';
import type { PickerProps } from 'antd/es/date-picker/generatePicker';
import type { Dayjs } from 'dayjs';

import { useAntdConfig, useFelaEnhanced } from 'hooks';
import { ChevronThin } from 'modules/ui/modules/icons';
import { Calendar } from 'modules/ui/modules/icons';
import type { RulesExtend } from 'styles/theme';
import { ClearButton } from '../ClearButton';

import * as felaRules from './DatePicker.rules';

type DatePickerOmit =
    | 'mode'
    | 'superPrevIcon'
    | 'prevIcon'
    | 'nextIcon'
    | 'superNextIcon'
    | 'suffixIcon'
    | 'allowClear'
    | 'className'
    | 'dropdownClassName';

export interface DatePickerProps extends Omit<PickerProps<Dayjs>, DatePickerOmit> {
    extend?: RulesExtend<typeof felaRules>;
}

export const DayjsDatePicker = generatePicker<Dayjs>(dayjsGenerateConfig);

export const DatePicker = ({ extend, ...props }: DatePickerProps) => {
    const { styles, rules } = useFelaEnhanced(felaRules, { extend });
    const { formatMessage } = useIntl();

    const antdConfig = useAntdConfig();
    const dateFormat = antdConfig.locale?.DatePicker?.lang.dateFormat;

    const clearIcon = <ClearButton />;

    return (
        <DayjsDatePicker
            mode="date"
            format={dateFormat}
            superPrevIcon={null}
            prevIcon={<ChevronThin extend={{ icon: rules.prevIcon }} />}
            nextIcon={<ChevronThin extend={{ icon: rules.nextIcon }} />}
            superNextIcon={null}
            suffixIcon={<Calendar extend={{ icon: rules.calendarIcon }} />}
            className={styles.datePicker}
            dropdownClassName={styles.dropdown}
            {...props}
            placeholder={props.placeholder || formatMessage({ id: 'general.picker.date.placeholder' })}
            clearIcon={clearIcon}
        />
    );
};

import { ErrorBoundary } from '@sentry/react';

import { Fela } from '../modules/fela';
import { Redux } from '../modules/redux';
import { Router } from '../modules/router';

import Enhancers from '../containers/Enhancers';

type CoreProps = { children: React.ReactNode };

function Core({ children }: CoreProps) {
    return (
        <ErrorBoundary fallback={() => <></>}>
            <Redux>
                <Fela>
                    <Router>
                        <Enhancers>{children}</Enhancers>
                    </Router>
                </Fela>
            </Redux>
        </ErrorBoundary>
    );
}

export default Core;

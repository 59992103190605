import type { TRuleWithTheme } from 'styles/theme';

export const page: TRuleWithTheme = ({ theme: { colors } }) => ({
    minHeight: '100vh',
    backgroundColor: colors.newBgPrimary,
});

export const content: TRuleWithTheme = ({ theme: { metrics, colors } }) => ({
    display: 'flex',
    flexDirection: 'column',

    paddingTop: metrics.spacing * 2,
    marginBottom: 0,

    paddingRight: metrics.layoutPadding / 2,
    paddingLeft: metrics.layoutPadding / 2,

    backgroundColor: colors.newBgPrimary,

    // TODO - add just because lacking responsibility of virtualized orders list on Homepage.
    // Consider remove once it is implemented.
    overflow: 'hidden',

    isDesktop: {
        paddingRight: metrics.layoutPadding,
        paddingLeft: metrics.layoutPadding,
    },
});

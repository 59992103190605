// Supported TLD domains
export enum Domain {
    BG = 'bg',
    HR = 'hr',
    GR = 'gr',
    CY = 'cy',
}

export enum Country {
    BULGARIA = 'bg',
    CZECHIA = 'cz',
    CROATIA = 'hr',
    GREECE = 'gr',
    CYPRUS = 'cy',
}

export const COUNTRIES_BY_DOMAIN = {
    [Domain.BG]: Country.BULGARIA,
    [Domain.HR]: Country.CROATIA,
    [Domain.GR]: Country.GREECE,
    [Domain.CY]: Country.CYPRUS,
};

export const defaultCountry = Country.GREECE;

import { createAction } from '@reduxjs/toolkit';

export const createFormActions = <FormValues, FP extends string, MP extends string>(
    formPrefix: FP,
    modulePrefix: MP,
) => {
    const nameBase = `${modulePrefix}/${formPrefix}` as const;

    return {
        submit: createAction(`${nameBase}_SUBMIT`, (values: FormValues, meta?: Record<string, any>) => ({
            payload: values,
            meta,
        })),
        submitSuccess: createAction(`${nameBase}_SUCCESS`),
        submitFailure: createAction(`${nameBase}_FAILURE`, (error: string | object) => ({
            payload: {
                error,
            },
        })),
    };
};

import { configureStore as reduxConfigureStore } from '@reduxjs/toolkit';
import type { Middleware } from '@reduxjs/toolkit';
import { reduxBatch } from '@manaflair/redux-batch';
import createSagaMiddleware from 'redux-saga';

import { isServerEnv } from 'constants/index';
import config from 'config';
import * as log from 'config/loglevel';
import { sentryReduxEnhancer } from 'config/sentry';

import { promiseListener } from './promiseListener';
import reducer from '../services/reducer';
import rootSaga from '../services/saga';
import { routerMiddlewareWithHistory } from '../../router';

export function configureStore() {
    const sagaMiddleware = createSagaMiddleware({
        onError: log.error,
    });

    const store = reduxConfigureStore({
        reducer,
        preloadedState: {},
        devTools: config.devTools && !isServerEnv ? { trace: true } : false,
        middleware: getDefaultMiddleware => {
            // https://redux-toolkit.js.org/api/getDefaultMiddleware#included-default-middleware
            const defaultMiddlewares = getDefaultMiddleware({
                serializableCheck: false,
                thunk: false,
            });

            return defaultMiddlewares.concat([
                routerMiddlewareWithHistory,
                sagaMiddleware,
                // Middleware type in @types/redux-promise-listener seems broken as it
                // uses Middleware<{}, AnyAction> which is incompatible with Middleware type
                promiseListener.middleware as Middleware<{}>,
            ]);
        },
        // To understand the duplication, see https://github.com/manaflair/redux-batch#usage-w-extra-middlewares
        enhancers: defaultEnhancers => [reduxBatch, ...defaultEnhancers, reduxBatch, sentryReduxEnhancer],
    });

    if (!isServerEnv) {
        sagaMiddleware.run(rootSaga);
    }

    return store;
}

export type Store = ReturnType<typeof configureStore>;

export type RootState = ReturnType<Store['getState']>;

export type AppDispatch = Store['dispatch'];

import { takeLeading, put } from 'redux-saga/effects';
import { logoutRequest } from '@ackee/petrus';

import config from 'config';
import { authApi } from 'config/antonio';
import * as Log from 'config/loglevel';
import { removePartner } from 'modules/partner';

import * as actions from '../actions';

function* logout() {
    try {
        yield* authApi.post(config.api.authSession.logout, null);
        yield put(logoutRequest());
        yield put(removePartner());
    } catch (e: unknown) {
        Log.error(e as Error);
    }
}

export default function* logoutSaga() {
    yield takeLeading(actions.logout.toString(), logout);
}

import { useIntl } from 'react-intl';
import type { RangePickerProps as AntdRangePickerProps } from 'antd/es/date-picker/generatePicker';
import type { Dayjs } from 'dayjs';

import { useAntdConfig, useFelaEnhanced } from 'hooks';
import { ChevronThin } from 'modules/ui/modules/icons';
import { Calendar } from 'modules/ui/modules/icons';
import type { RulesExtend } from 'styles/theme';

import { ClearButton } from '../ClearButton';
import { DayjsDatePicker } from '../DatePicker';

import * as felaRules from './RangePicker.rules';

type DatePickerOmit =
    | 'mode'
    | 'superPrevIcon'
    | 'prevIcon'
    | 'nextIcon'
    | 'superNextIcon'
    | 'suffixIcon'
    | 'allowClear'
    | 'className'
    | 'dropdownClassName';

export interface RangePickerProps extends Omit<AntdRangePickerProps<Dayjs>, DatePickerOmit> {
    extend?: RulesExtend<typeof felaRules>;
}

export const RangePicker = ({ extend, ...props }: RangePickerProps) => {
    const { styles, rules } = useFelaEnhanced(felaRules, { extend });
    const { formatMessage } = useIntl();

    const antdConfig = useAntdConfig();
    const dateFormat = antdConfig.locale?.DatePicker?.lang.dateFormat;

    const clearIcon = <ClearButton />;

    return (
        <DayjsDatePicker.RangePicker
            mode={['date', 'date']}
            format={dateFormat}
            superPrevIcon={null}
            prevIcon={<ChevronThin extend={{ icon: rules.prevIcon }} />}
            nextIcon={<ChevronThin extend={{ icon: rules.nextIcon }} />}
            superNextIcon={null}
            suffixIcon={<Calendar extend={{ icon: rules.calendarIcon }} />}
            separator={<span className={styles.separator}>&ndash;</span>}
            className={styles.picker}
            dropdownClassName={styles.dropdown}
            {...props}
            placeholder={
                props.placeholder || [
                    formatMessage({ id: 'general.picker.range.placeholder.start' }),
                    formatMessage({ id: 'general.picker.range.placeholder.end' }),
                ]
            }
            clearIcon={clearIcon}
        />
    );
};

import { Switch, Route, Redirect } from 'react-router-dom';

import config from 'config/config';
import {
    NewOrderPage,
    OrderFromWarehousePage,
    OrderFromWarehouseToWarehousePage,
    OrderFromAnyApmPage,
    OrderFromAnyToSameApmPage,
    CustomerReturnPage,
} from 'modules/order/modules/order-create';
import { OrderDetailPage } from 'modules/order/modules/order-detail';
import { useAllowedRoutes } from 'modules/permissions';

const OrderRoutes = () => {
    const routes = useAllowedRoutes([
        {
            path: config.routes.newOrder,
            component: NewOrderPage,
            exact: true,
        },
        {
            path: config.routes.fromWarehouseOrder,
            component: OrderFromWarehousePage,
            exact: true,
        },
        {
            path: config.routes.fromWarehouseToWarehouseOrder,
            component: OrderFromWarehouseToWarehousePage,
            exact: true,
        },
        {
            path: config.routes.fromAnyApmOrder,
            component: OrderFromAnyApmPage,
            exact: true,
        },
        {
            path: config.routes.fromAnyToSameApmOrder,
            component: OrderFromAnyToSameApmPage,
            exact: true,
        },
        {
            path: config.routes.customerReturn,
            component: CustomerReturnPage,
            exact: true,
        },
        {
            path: config.routes.orderDetail,
            component: OrderDetailPage,
            exact: true,
        },
        {
            render: () => <Redirect to={config.routes.newOrder} />,
        },
    ]);

    return (
        <Switch>
            {routes.map((route, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Route {...route} key={index} />
            ))}
        </Switch>
    );
};

export default OrderRoutes;

import type { TRuleWithTheme } from 'styles/theme';

export const formWrapper: TRuleWithTheme = ({ theme }) => ({
    paddingBottom: theme.metrics.spacing * 2,
});

export const allowReturnSelect: TRuleWithTheme = ({ theme }) => ({
    marginBottom: 'unset',

    '& .ant-form-item-label': {
        paddingBottom: 'unset',
    },

    '& .ant-form-item-row': {
        display: 'block',
    },
});

import type { FC } from 'react';

import { Address, AddressProps } from 'modules/ui';
import { useSelectedOriginLocation } from '../../hooks';

export interface PickupAddressProps extends Omit<AddressProps, 'location'> {}

const PickupAddress: FC<PickupAddressProps> = props => {
    const selectedLocation = useSelectedOriginLocation();

    if (!selectedLocation) {
        return null;
    }

    return <Address location={selectedLocation} {...props} />;
};

export default PickupAddress;

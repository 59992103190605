import { apiSelector } from '@ackee/redux-utils';
import { createSelector } from 'reselect';

import type { RootState } from 'modules/core/modules/redux';
import { ApiReducerKey, DEFAULT_GROUP, EntityKey } from 'modules/entities/constants';
import { denormalizeDeliveryPartners } from 'modules/entities/services/normalizr';
import * as entitiesSelectors from 'modules/entities/services/selectors';

export const selectDeliveryPartnersApi = (state: RootState) =>
    apiSelector(state, EntityKey.DELIVERY_PARTNERS, ApiReducerKey.LIST);

export const selectDeliveryPartners = createSelector(
    entitiesSelectors.selectDeliveryPartnersEntities,
    deliveryPartners => {
        const denormalizedDeliveryPartners = denormalizeDeliveryPartners(deliveryPartners[DEFAULT_GROUP], {
            [EntityKey.DELIVERY_PARTNERS]: deliveryPartners.byId,
        });

        return denormalizedDeliveryPartners;
    },
);

export enum ButtonType {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TERTIARY = 'tertiary',
    LINK = 'link',
}

export enum ButtonSize {
    BIG = 'big',
    NORMAL = 'normal',
}

export interface StyleProps {
    type: ButtonType;
    size: ButtonSize;
    isIconSuffix: boolean;
}

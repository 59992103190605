import type { Location } from 'modules/entities/types';

export const addSelectOptionData = (location: Location) => {
    const { id, name, postalCode } = location;
    const metaInfo = [name, postalCode].filter(Boolean);

    return {
        ...location,
        displayName: [`loc_id: ${id}`, ...metaInfo].join(', '),
        filterValue: [id, ...metaInfo].join(', '),
    };
};

import { useAppDispatch, useAppSelector } from 'hooks/redux';

import { partnerModalSelector } from '../services/selectors/partner';
import { hideModal, showModal } from '../services/slices/partner';

export const usePartnerModal = () => {
    const { visible } = useAppSelector(partnerModalSelector);
    const dispatch = useAppDispatch();

    const show = () => {
        dispatch(showModal());
    };

    const hide = () => {
        dispatch(hideModal());
    };

    return {
        visible,
        show,
        hide,
    };
};

import { useFelaEnhanced } from 'hooks';
import { FormattedMessage } from 'react-intl';

import * as felaRules from './ParcelRowHeader.rules';

export interface ParcelRowHeaderProps {
    columnsTemplate: string;
    showOrder?: boolean;
    showNumber?: boolean;
    showName?: boolean;
    showUpdated?: boolean;
    showStatus?: boolean;
    showCreated?: boolean;
}

const ParcelRowHeader = ({
    columnsTemplate,
    showOrder = true,
    showName = true,
    showNumber = true,
    showStatus = true,
    showUpdated = true,
    showCreated = true,
}: ParcelRowHeaderProps) => {
    const { styles } = useFelaEnhanced(felaRules, { columnsTemplate });

    return (
        <div className={styles.row}>
            {showOrder && (
                <div>
                    <FormattedMessage id="parcel.list.column.order" />
                </div>
            )}
            {showName && (
                <div>
                    <FormattedMessage id="parcel.list.column.number" />
                </div>
            )}
            {showNumber && (
                <div>
                    <FormattedMessage id="parcel.list.column.name" />
                </div>
            )}
            {showStatus && (
                <div>
                    <FormattedMessage id="parcel.list.column.updated" />
                </div>
            )}
            {showUpdated && (
                <div>
                    <FormattedMessage id="parcel.list.column.status" />
                </div>
            )}
            {showCreated && (
                <div>
                    <FormattedMessage id="parcel.list.column.created" />
                </div>
            )}
        </div>
    );
};

export default ParcelRowHeader;

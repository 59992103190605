import { createReducer } from '@reduxjs/toolkit';
import { checkAddressDelivery } from 'modules/entities/modules/customerReturn';

type State = number | null;

export const apmDistanceReducer = createReducer<State>(null, builder =>
    builder
        .addCase(checkAddressDelivery.success, (_: State, action) => action.payload.apmDistance)
        .addCase(checkAddressDelivery.request, () => null)
        .addCase(checkAddressDelivery.reset, () => null),
);

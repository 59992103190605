import type { FC, ReactNode } from 'react';
import { Steps as AntdSteps } from 'antd';
import type { StepsProps as AntdStepsProps } from 'antd';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';
import { Done as DoneIcon } from 'modules/ui/modules/icons';

import * as felaRules from './Steps.rules';

type StepIndex = number;
export interface StepsProps extends AntdStepsProps {
    current: StepIndex;
    steps: { id: string | number; title: ReactNode }[];
    extend?: RulesExtend<typeof felaRules>;
    onFinishedStepClick?: (step: StepIndex, stepId: string | number) => void;
}

const Steps: FC<StepsProps> = ({ current, steps, onFinishedStepClick, extend, ...props }) => {
    const { styles, rules } = useFelaEnhanced(felaRules, { extend });

    return (
        <AntdSteps current={current} className={styles.steps} responsive {...props}>
            {steps.map(({ id, title }, index) => (
                <AntdSteps.Step
                    key={id}
                    title={title}
                    className={styles.step}
                    icon={<DoneIcon extend={{ icon: rules.icon }} />}
                    onClick={() => {
                        const isFinished = index < current;

                        if (isFinished) {
                            onFinishedStepClick?.(index, id);
                        }
                    }}
                />
            ))}
        </AntdSteps>
    );
};

export default Steps;

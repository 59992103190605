import type { Key, ReactNode } from 'react';
import { Menu } from 'antd';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles';

import * as felaRules from './DropdownMenu.rules';

export interface MenuItem {
    onClick?: () => void;
    key: Key;
    label: string | ReactNode;
}

export interface DropdownMenuProps {
    items: MenuItem[];
    extend?: RulesExtend<typeof felaRules>;
    styleProps?: object;
}

export const DropdownMenu = ({ items, extend, styleProps }: DropdownMenuProps) => {
    const { styles } = useFelaEnhanced(felaRules, { extend, ...styleProps });

    return (
        <Menu
            className={styles.menu}
            items={items}
            onClick={({ key }) => {
                const selectedItem = items.find(item => item.key === key);

                if (selectedItem?.onClick) {
                    selectedItem.onClick();
                }
            }}
        />
    );
};

import type { FC } from 'react';
import { Card as AntdCard } from 'antd';
import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import * as felaRules from './Card.styles';

export interface CardProps {
    children: React.ReactNode;
    extend?: RulesExtend<typeof felaRules>;
}

const Card: FC<CardProps> = ({ children, extend }) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });

    return <AntdCard className={styles.container}>{children}</AntdCard>;
};

export default Card;

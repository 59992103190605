import type { TRuleWithTheme } from 'styles';
import { ParagraphSize } from './types';

interface StyleProps {
    size: ParagraphSize;
}

const font = {
    [ParagraphSize.BIG]: {
        fontSize: '1.25rem',
        fontWeight: 700,
    },
    [ParagraphSize.NORMAL]: {
        fontSize: '1rem',
        fontWeight: 400,
    },
};

export const paragraph: TRuleWithTheme<StyleProps> = ({ theme: { colors }, size }) => ({
    ...font[size],
    color: colors.black,
});

import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';

import { DATE_PARAM_FORMAT } from '../config';

export const parseDate = (date?: string | null): Dayjs | undefined => {
    const parsedDate = dayjs(date, DATE_PARAM_FORMAT, true);

    return parsedDate.isValid() ? parsedDate : undefined;
};

import type { ReactNode } from 'react';

import { Localizations } from '../modules/localization';

interface EnhancersProps {
    children: ReactNode;
}

const Enhancers = ({ children }: EnhancersProps) => <Localizations>{children}</Localizations>;

export default Enhancers;

import countries from 'i18n-iso-countries';
import { useMemo } from 'react';
import { translateSelector } from '@ackee/jerome';

import { useAppSelector } from 'hooks/redux';

export const useAllCountries = () => {
    const { locale } = useAppSelector(translateSelector);

    const allCountries = useMemo(
        () =>
            Object.entries(countries.getNames(locale)).map(([code, name]) => ({
                id: code,
                name,
            })),
        [locale],
    );

    return allCountries;
};

import { apiSelector } from '@ackee/redux-utils';
import { createSelector } from 'reselect';

import { selectProp } from 'services/selectors';
import { ApiReducerKey, DEFAULT_GROUP, EntityKey } from 'modules/entities/constants';
import { denormalizeLocations } from 'modules/entities/services/normalizr';
import * as entitiesSelectors from 'modules/entities/services/selectors';
import type { RootState } from 'modules/core/modules/redux';

import { addSelectOptionData } from './utils';

export const selectLocationsApi = (state: RootState) => apiSelector(state, EntityKey.LOCATIONS, ApiReducerKey.LIST);

export const selectLocations = createSelector(
    entitiesSelectors.selectLocationsEntities,
    selectProp<string>('group'),
    (locations, group = DEFAULT_GROUP) => {
        const denormalizedLocations = denormalizeLocations(locations[group] ?? [], {
            [EntityKey.LOCATIONS]: locations.byId,
        });

        return denormalizedLocations.map(addSelectOptionData);
    },
);

import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme: { form } }) => ({
    display: 'inline-block',
    width: form.formWrapperWidth,
    maxWidth: '100%',
});

export const note: TRuleWithTheme = ({ theme }) => ({
    marginBottom: theme.metrics.spacing * 2,
    fontFamily: theme.font.family,
});

export const requiredMark: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.error,
});

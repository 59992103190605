import type { FC } from 'react';
import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import type { IconProps } from '../../types';

import * as felaRules from './Chevron.rules';

interface ChevronProps extends IconProps {
    extend?: RulesExtend<typeof felaRules>;
}

export const Chevron: FC<ChevronProps> = ({ extend }) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });

    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.icon}>
            <path
                d="M6 9L12 15L18 9"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

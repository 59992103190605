import type { FormItemProps } from 'antd';
import { Form } from 'antd';
import type { FieldInputProps, FieldMetaState } from 'react-final-form';
import { useIntl } from 'react-intl';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import * as felaRules from './FormItem.rules';

export interface FormItemOwnProps<TValue> extends FormItemProps<TValue> {
    input: FieldInputProps<TValue, HTMLElement>;
    meta: FieldMetaState<TValue>;
    fluid?: boolean;
    extend?: RulesExtend<typeof felaRules>;
}

const FormItem = <TValue extends any = string>({
    input,
    meta,
    required = false,
    help,
    fluid = false,
    extend,
    ...props
}: FormItemOwnProps<TValue>) => {
    const { styles } = useFelaEnhanced(felaRules, { extend, type: input.type, fluid });
    const intl = useIntl();

    const fieldError = meta.submitError ? intl.formatMessage({ id: meta.submitError }) : meta.error;

    return (
        <Form.Item
            htmlFor={input.name}
            required={required}
            help={(meta.touched && fieldError) || help}
            validateStatus={(meta.touched && fieldError && 'error') || 'validating'}
            {...props}
            className={[styles.formItem, props.className].filter(Boolean).join(' ')}
        />
    );
};

export default FormItem;

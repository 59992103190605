import type { FC } from 'react';
import { Typography } from 'antd';
import type { TextProps as AntdTextProps } from 'antd/es/typography/Text';
import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import * as felaRules from './Text.styles';

export interface TextProps extends AntdTextProps {
    children: React.ReactNode;
    extend?: RulesExtend<typeof felaRules>;
}

const AntdText = Typography.Text;

/**
 * @deprecated Use Paragraph component instead
 */
const Text: FC<TextProps> = ({ children, extend, ...props }) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });

    return (
        <p className={styles.container}>
            <AntdText {...props}>{children}</AntdText>
        </p>
    );
};

export default Text;

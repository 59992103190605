import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useAppSelector } from 'hooks/redux';

import { selectCancelParcelApi } from '../services/selectors';
import { cancelParcelDelivery as apiActions } from '../services/actions';

export const useCancelParcelDelivery = (parcelId: string) => {
    const dispatch = useDispatch();
    const api = useAppSelector(state => selectCancelParcelApi(state, parcelId));

    const cancelParcelDelivery = () => {
        dispatch(apiActions.cancelRequest(parcelId));
    };

    const resetApi = useCallback(() => {
        dispatch(apiActions.cancelReset(parcelId));
    }, [dispatch, parcelId]);

    return [api, cancelParcelDelivery, resetApi] as const;
};

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { Parcel } from 'modules/entities/types';

type State = Parcel['id'] | null;

const parcelLabelToastSlice = createSlice({
    name: 'parcelLabelToast',
    initialState: null as State,

    reducers: {
        showParcelLabelToast: (_, action: PayloadAction<Parcel['id']>) => action.payload,
        hideParcelLabelToast: () => null,
    },
});

export const { reducer } = parcelLabelToastSlice;
export const { showParcelLabelToast, hideParcelLabelToast } = parcelLabelToastSlice.actions;

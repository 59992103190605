import { Layout, Header } from 'modules/layout';
import { ErrorBoundary } from 'modules/errors';
import { ParcelLabelToast } from 'modules/parcels/modules/parcel-label';
import { NewOrderDropdownButton } from 'modules/order/modules/order-create/components/NewOrderDropdownButton';

import { ParcelsListControls } from '../ParcelsListControls';
import ParcelsList from '../ParcelsList';

const ParcelsListPage = () => {
    return (
        <Layout
            header={
                <Header>
                    <NewOrderDropdownButton />
                </Header>
            }
        >
            <ErrorBoundary>
                <ParcelsListControls />
                <ParcelsList />
            </ErrorBoundary>

            <ParcelLabelToast />
        </Layout>
    );
};

export default ParcelsListPage;

import { all } from 'redux-saga/effects';

import { saga as auth } from 'modules/auth';
import { saga as entities } from 'modules/entities';
import { saga as createOrder } from 'modules/order/modules/order-create';
import { saga as partner } from 'modules/partner';

export default function* appSaga() {
    yield all([auth(), entities(), createOrder(), partner()]);
}

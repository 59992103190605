export enum PartnerPermission {
    WAREHOUSE_AS_ORIGIN = 'warehouseAsOrigin',
    ANY_APM_AS_ORIGIN = 'anyApmAsOrigin',
    ANY_APM_TO_SAME_APM_DELIVERY = 'anyApmToSameApmDelivery',
    ANY_APM_TO_SAME_APM_DELIVERY_WITHOUT_CONFIRMATION = 'anyApmToSameApmDeliveryWithoutConfirmation',
    DEPOT_AS_ORIGIN = 'depotAsOrigin',
    CUSTOM_FORM = 'customForm',
    COD_PAYMENT = 'codPayment',
    ADDRESS_AS_DESTINATION = 'addressAsDestination',
    COD_ADDRESS_AS_DESTINATION = 'codAddressAsDestination',
}

import type { TRuleWithTheme } from 'styles/theme';

export const header: TRuleWithTheme = ({ theme: { metrics } }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',

    height: 'auto',
    lineHeight: 'normal',
    background: 'transparent',

    paddingTop: metrics.spacing * 3,
    paddingBottom: metrics.spacing * 3,

    paddingRight: metrics.spacing * 5,
    paddingLeft: metrics.spacing * 5,

    position: 'relative',

    isMobile: {
        flexDirection: 'column-reverse',
        alignItems: 'flex-end',

        paddingTop: metrics.spacing,
        paddingBottom: metrics.spacing,

        paddingRight: metrics.spacing * 2,
        paddingLeft: metrics.spacing * 2,
    },
});

export const partnerButton: TRuleWithTheme = ({ theme: { metrics } }) => ({
    marginRight: metrics.spacing * 3,

    isMobile: {
        marginRight: 0,
        marginBottom: metrics.spacing * 2,
    },
});

export const logoutButton: TRuleWithTheme = ({ theme: { metrics } }) => ({
    marginLeft: metrics.spacing * 4,

    isMobile: {
        marginLeft: 0,
        marginTop: metrics.spacing * 2,
    },
});

import { put, select, takeLeading } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import type { AntonioError } from '@ackee/antonio-core';

import config from 'config';
import { api } from 'config/antonio';
import * as Log from 'config/loglevel';
import { getIntlErrorMessage } from 'modules/errors';
import type { AuthState } from 'modules/auth/types';
import type { ErrorData } from 'modules/errors';

import * as actions from '../actions';
import { selectAuth } from '../selectors';

function* getLoginCode() {
    try {
        const { phoneNumber }: AuthState = yield select(selectAuth);
        const { data } = yield* api.post<{ challenge: string }>(config.api.authSession.codeGet, {
            phoneNumber,
        });

        yield put(actions.setLoginCode(data));
        yield put(actions.getLoginCodeForm.submitSuccess());
        yield put(push(config.routes.auth.login));
    } catch (e: unknown) {
        const errorMessage = getIntlErrorMessage(e as AntonioError<ErrorData> | Error);

        if (errorMessage.unhandled) {
            Log.error(e as Error);
        }

        yield put(actions.getLoginCodeForm.submitFailure(errorMessage));
    }
}

export default function* getLoginCodeSaga() {
    yield takeLeading(actions.getLoginCodeForm.submit, getLoginCode);
}

import { generateBorderedButtonColors, generateSolidButtonColors } from 'modules/ui';
import type { TRuleWithTheme } from 'styles/theme';

export const note: TRuleWithTheme = ({ theme: { metrics } }) => ({
    fontSize: '1.2em',

    height: metrics.height.largeButton,
    lineHeight: `${metrics.height.largeButton}px`,
});

export const triggerButton: TRuleWithTheme = ({ theme: { colors } }) =>
    generateBorderedButtonColors({
        base: colors.buttonPrimaryDestructive,
        hover: colors.buttonPrimaryDestructive,
        pressed: colors.buttonPrimaryDestructive,
    });

export const submitButton: TRuleWithTheme = ({ theme: { colors } }) =>
    generateSolidButtonColors({
        bg: colors.buttonPrimaryDestructive,
        bgHover: colors.buttonPrimaryDestructive,
        bgPressed: colors.buttonPrimaryDestructive,
        text: colors.buttonPrimaryText,
    });

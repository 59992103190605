import { init, createReduxEnhancer, reactRouterV5Instrumentation } from '@sentry/react';
import { BrowserTracing } from '@sentry/browser';

import config from 'config/config';
import { currentEnv, isEnvProduction } from 'constants/index';
import { history } from 'modules/core/modules/router/config';

if (config.sentry.enable) {
    init({
        dsn: config.sentry.dsn,
        debug: false,
        integrations: [
            new BrowserTracing({
                routingInstrumentation: reactRouterV5Instrumentation(history),
            }),
        ],
        environment: currentEnv,
        release: `box-now-customer-portal@${process.env.REACT_APP_VERSION}`,
        denyUrls: [
            // Chrome extensions
            /extensions\//i,
            /^chrome:\/\//i,
        ],
        normalizeDepth: 5,
        tracesSampleRate: isEnvProduction ? 0.25 : 1.0,
    });
}

export const sentryReduxEnhancer = createReduxEnhancer();

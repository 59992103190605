import { FormattedMessage, useIntl } from 'react-intl';

import { ParcelSize, parcelSizeName } from 'modules/parcels';
import { SelectField } from 'modules/form';
import type { FormFieldProps } from 'modules/form/components/types';

import type { ItemValues } from '../../types';
import type { OrderItemField } from '../../config';

interface ItemSizeSelectProps extends FormFieldProps<ItemValues[OrderItemField.Size]> {
    sizeOptionsFilter?: (size: ParcelSize) => boolean;
}

export const ItemSizeSelect = ({ name, sizeOptionsFilter, ...rest }: ItemSizeSelectProps) => {
    const { formatMessage } = useIntl();

    const sizes = [ParcelSize.Small, ParcelSize.Medium, ParcelSize.Large].filter(sizeOptionsFilter ?? (() => true));

    return (
        <SelectField<ItemValues[OrderItemField.Size]>
            name={name}
            label={formatMessage({ id: 'parcel.size' })}
            placeholder={formatMessage({ id: 'order.items.size.placeholder' })}
            {...rest}
        >
            {sizes.map(size => (
                <SelectField.Option key={size} value={size}>
                    <FormattedMessage id={`parcel.size.${parcelSizeName[size]}`} />
                </SelectField.Option>
            ))}
        </SelectField>
    );
};

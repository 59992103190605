import { put, takeLeading } from 'redux-saga/effects';
import type { AntonioError, RequestResult } from '@ackee/antonio-core';

import config from 'config';
import { authApi } from 'config/antonio';
import * as Log from 'config/loglevel';
import type { paths } from 'api/partnerApiSchema';

import { getIntlErrorMessage } from 'modules/errors';
import type { ErrorData } from 'modules/errors';

import { createCustomerReturn } from '../actions';

const apiPath = config.api.customerReturns;
type Path = typeof apiPath;
type Operation = paths[`/api/v1${Path}`]['post'];
type Response = Operation['responses']['200']['content']['application/json'];

type RequestAction = ReturnType<typeof createCustomerReturn.request>;

function* handleCreateCustomerReturn({ payload }: RequestAction) {
    try {
        // TODO - remove explicit typing of result once https://github.com/AckeeCZ/antonio/issues/70 is resolved
        const { data }: RequestResult<Response> = yield* authApi.post<Response>(apiPath, payload);

        yield put(
            createCustomerReturn.success({
                parcelId: data.parcels[0].id,
                lastSuccessAt: new Date().toISOString(),
            }),
        );
    } catch (e) {
        const errorMessage = getIntlErrorMessage(e as AntonioError<ErrorData> | Error);

        if (errorMessage.unhandled) {
            Log.error(e as Error);
        }

        yield put(createCustomerReturn.failure({ error: errorMessage }));
    }
}

export default function* createCustomerReturnSaga() {
    yield takeLeading(createCustomerReturn.request.toString(), handleCreateCustomerReturn);
}

import { FormattedMessage } from 'react-intl';
import type { Parcel } from 'modules/entities';

export interface ParcelStateProps {
    children?: Parcel['state'];
}

const ParcelState = ({ children }: ParcelStateProps) => {
    if (!children) {
        return <>–</>;
    }

    return <FormattedMessage id={`parcel.state.${children}`} />;
};

export default ParcelState;

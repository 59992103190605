import type { TRuleWithTheme } from 'styles/theme';

export const title: TRuleWithTheme = ({ theme }) => ({
    marginBottom: theme.metrics.spacing * 2.5,
    paddingTop: theme.metrics.spacing * 4,
});

export const footer: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',

    marginTop: theme.metrics.spacing * 4,
    paddingBottom: theme.metrics.spacing * 4,
});

export const submitButton: TRuleWithTheme = ({ theme }) => ({
    marginLeft: theme.metrics.spacing * 5,
});

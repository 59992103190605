import React from 'react';
import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';
import { useEntrustedPartners } from 'modules/entities/modules/entrustedPartners/hooks';

import * as felaRules from './EntrustedPartnersField.rules';
import { RadioButtonGroupField, RadioButtonGroupFieldProps } from 'modules/form';
import { ChoosePartnerField } from 'modules/partner/constants';
import type { ChoosePartnerFormValues } from 'modules/partner/types';

type Value = ChoosePartnerFormValues[ChoosePartnerField.PartnerId];

export interface EntrustedPartnersFieldProps
    extends Omit<RadioButtonGroupFieldProps<Value>, 'name' | 'options' | 'required' | 'extend'> {
    extend?: RulesExtend<typeof felaRules>;
}

export const EntrustedPartnersField = ({ extend, ...props }: EntrustedPartnersFieldProps) => {
    const { rules } = useFelaEnhanced(felaRules, { extend });
    const { entrustedPartners } = useEntrustedPartners();

    return (
        <RadioButtonGroupField<Value>
            name={ChoosePartnerField.PartnerId}
            options={entrustedPartners.map(({ id, displayName }) => ({
                value: id,
                label: displayName,
            }))}
            extend={{ formItem: rules.radioFormItem, group: rules.radioGroup }}
            required
            {...props}
        />
    );
};

import enAntd from 'antd/es/locale-provider/en_US';
import elAntd from 'antd/es/locale-provider/el_GR';
import hrAntd from 'antd/es/locale-provider/hr_HR';
import bgAntd from 'antd/es/locale-provider/bg_BG';
import { Languages } from '../constants';

// eslint-disable-next-line compat/compat
export const languages = Object.values(Languages);

export { translations as intlData } from 'translations';

export const antdData = {
    [Languages.EN]: enAntd,
    [Languages.EL]: elAntd,
    [Languages.HR]: hrAntd,
    [Languages.BG]: bgAntd,
};

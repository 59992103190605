import { usePartnerPermissions } from './usePartnerPermissions';
import { protectedPages } from '../config/protectedPages';
import type { PartnerPermission } from '../constants';

export const useRestrictedRoutes = () => {
    const partnerPermissions = usePartnerPermissions();

    if (!partnerPermissions) {
        return null;
    }

    const userHasNotPermission = (permission: PartnerPermission) => !partnerPermissions[permission];

    const restrictedUserRoutes = Object.entries(protectedPages)
        .filter(([_, requiredPermissions]) => requiredPermissions.some(userHasNotPermission))
        .map(([route]) => route);

    return new Set(restrictedUserRoutes);
};

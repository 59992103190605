export const family = "'Effra', 'Helvetica Neue', Helvetica, Arial, sans-serif";

/**
 * @returns font size in `rem`
 */
const size = (pixelSize: number, baseSize = 16) => `${pixelSize / baseSize}rem`;

const lineHeight = (absoluteLineHeight: number, fontSize: number) =>
    Math.round((absoluteLineHeight / fontSize) * 100) / 100; // px

export const sizeAndHeight = (fontSizeInPixels: number, lineHeightInPixels: number) =>
    ({
        fontSize: size(fontSizeInPixels),
        lineHeight: lineHeight(lineHeightInPixels, fontSizeInPixels),
    } as const);

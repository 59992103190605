import type { PartnerPermission } from '../constants';
import { usePartnerPermissions } from './usePartnerPermissions';

export const useUserHasPermission = (permission: PartnerPermission) => {
    const permissions = usePartnerPermissions();

    if (!permissions) {
        return false;
    }

    return Boolean(permissions[permission]);
};

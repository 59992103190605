import type { TRuleWithTheme } from 'styles/theme';

export const button: TRuleWithTheme = ({ theme }) => ({
    borderColor: theme.colors.newBgSecondary,
    background: theme.colors.newBgSecondary,
    color: theme.colors.fgPrimary,
    fontWeight: 400,

    onHover: {
        borderColor: theme.colors.newBgSecondary,
        background: theme.colors.newBgSecondary,
        color: theme.colors.fgPrimary,
    },

    onFocus: {
        borderColor: theme.colors.newBgSecondary,
        background: theme.colors.newBgSecondary,
        color: theme.colors.fgPrimary,
    },

    '&.ant-btn[disabled]': {
        borderColor: theme.colors.newBgSecondary,
        background: theme.colors.newBgSecondary,
        color: theme.colors.fgPrimary,
        cursor: 'default',
    },
});

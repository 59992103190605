import type { TRuleWithTheme } from 'styles/theme';
import type { OrderSummaryProps } from './OrderSummary';

export const header: TRuleWithTheme<Pick<OrderSummaryProps, 'orderLabelUrl'>> = ({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.metrics.spacing * 2.5,
});

export const itemsHeadline: TRuleWithTheme = ({ theme }) => ({
    marginTop: theme.metrics.spacing * 5,

    '&.ant-typography': {
        marginBottom: 0,
    },
});

export const items: TRuleWithTheme = ({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: theme.metrics.spacing * 2.5,

    marginTop: theme.metrics.spacing * 2.5,
});

export const addresses: TRuleWithTheme = ({ theme: { metrics } }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: metrics.spacing * 2.5,

    marginTop: metrics.spacing * 5,
    marginBottom: metrics.spacing * 5,

    isDesktop: {
        gridTemplateColumns: '1fr 1fr',
    },
});

import { DownloadButton } from 'modules/ui';
import type { DownloadButtonProps } from 'modules/ui';
import { QrCode } from 'modules/ui/modules/icons';
import type { IntlMessage } from 'types';

export interface GetParcelLabelsProps extends Pick<DownloadButtonProps, 'url' | 'type' | 'forceDownload'> {
    text?: IntlMessage;
}

const defaultLabel = { id: 'parcel.getLabel' } as const;

export const GetParcelLabel = ({ text = defaultLabel, ...restProps }: GetParcelLabelsProps) => {
    return <DownloadButton {...restProps} text={text} icon={<QrCode />} />;
};

import type { FC } from 'react';

import { useAppSelector } from 'hooks/redux';

import { Address, AddressProps } from 'modules/ui';
import { selectDestinationsEntities } from 'modules/entities';
import type { Destination } from 'modules/entities/types';

export interface DestinationAddressProps extends Omit<AddressProps, 'location'> {
    destinationId: Destination['id'];
}

const DestinationAddress: FC<DestinationAddressProps> = ({ destinationId, ...props }) => {
    const destinations = useAppSelector(selectDestinationsEntities);
    const destination = destinations.byId[destinationId];

    if (!destination) {
        return null;
    }

    return <Address location={destination} {...props} />;
};

export default DestinationAddress;

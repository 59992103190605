import { Typography } from 'antd';
import type { TextProps as AntdTextProps } from 'antd/es/typography/Text';
import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import * as felaRules from './Paragraph.rules';
import { ParagraphSize } from '../Paragraph/types';

export interface ParagraphProps extends AntdTextProps {
    size?: ParagraphSize;
    children: React.ReactNode;
    extend?: RulesExtend<typeof felaRules>;
}

const AntdText = Typography.Text;

export const Paragraph = ({ size = ParagraphSize.NORMAL, children, extend, ...props }: ParagraphProps) => {
    const { styles } = useFelaEnhanced(felaRules, { size, extend });

    return (
        <p className={styles.paragraph}>
            <AntdText {...props}>{children}</AntdText>
        </p>
    );
};

Paragraph.Size = ParagraphSize;

import type { FC } from 'react';
import { Typography } from 'antd';
import type { TitleProps as AntdTitleProps } from 'antd/es/typography/Title';
import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import * as felaRules from './Title.styles';

export interface TitleProps extends AntdTitleProps {
    children: React.ReactNode;
    extend?: RulesExtend<typeof felaRules>;
}

const AntdTitle = Typography.Title;

/**
 * @deprecated Use Headline component instead
 */
const Title: FC<TitleProps> = ({ children, extend, ...props }) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });

    return (
        <AntdTitle className={styles.container} {...props}>
            {children}
        </AntdTitle>
    );
};

export default Title;

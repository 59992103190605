import { usePartner } from 'modules/partner/hooks';

export const usePartnerShippingRegions = () => {
    const partner = usePartner();

    if (!partner) {
        throw new Error("Don't use usePartnerShippingRegions hook when partner is null.");
    }

    return partner.shippingRegions;
};

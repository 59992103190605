import { put, takeLeading } from 'redux-saga/effects';
import type { RequestResult } from '@ackee/antonio-core';

import config from 'config';
import { authApi } from 'config/antonio';
import * as Log from 'config/loglevel';
import type { paths } from 'api/partnerApiSchema';

import { EntityKey } from 'modules/entities/constants';
import { normalizeDeliveryPartners } from 'modules/entities/services/normalizr';
import { setEntitiesGroup } from 'modules/entities/services/actions';

import { fetchDeliveryPartners as apiActions } from '../actions';

type Path = typeof config.api.deliveryPartners;
type Operation = paths[`/api/v1${Path}`]['get'];
type ResponseData = Operation['responses']['200']['content']['application/json'];
type RequestAction = ReturnType<typeof apiActions.request>;

function* handleFetchDeliveryPartners(action: RequestAction) {
    try {
        const response: RequestResult<ResponseData> = yield* authApi.get<ResponseData>(config.api.deliveryPartners);

        const { result: ids, entities } = normalizeDeliveryPartners(response.data.data);

        yield put([
            setEntitiesGroup(EntityKey.DELIVERY_PARTNERS, {
                ids,
                byId: entities[EntityKey.DELIVERY_PARTNERS],
            }),
            apiActions.success(undefined, {
                lastSuccessAt: new Date().toISOString(),
            }),
        ]);
    } catch (e: unknown) {
        Log.error(e as Error);
        yield put(apiActions.failure((e as Error).message));
    }
}

export default function* fetchDeliveryPartnersSaga() {
    yield takeLeading(apiActions.request.toString(), handleFetchDeliveryPartners);
}

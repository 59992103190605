import type { FC } from 'react';
import { useIntl } from 'react-intl';
import { Row, Col } from 'antd';

import { useFelaEnhanced } from 'hooks';
import { SelectField, validatorsWithMessage, TextField, TextAreaField, CountriesField } from 'modules/form';

import { DeliveryField } from '../../config';
import { useDeliveryPartners } from '../../hooks';
import type { DeliveryFormValues } from '../../types';

import * as felaRules from './DeliveryAddressForm.styles';

export interface DeliveryAddressFormProps {}

const { required } = validatorsWithMessage;

export const validators = {
    [DeliveryField.Street]: required,
    [DeliveryField.City]: required,
    [DeliveryField.PostalCode]: required,
    [DeliveryField.Country]: required,
    [DeliveryField.PartnerId]: required,
};

const DeliveryAddressForm: FC<DeliveryAddressFormProps> = () => {
    const { formatMessage } = useIntl();
    const { theme } = useFelaEnhanced(felaRules);
    const { deliveryPartners, api } = useDeliveryPartners();

    return (
        <>
            <TextField<DeliveryFormValues[DeliveryField.Street]>
                label={formatMessage({ id: 'order.delivery.address.street' })}
                placeholder={formatMessage({ id: 'order.delivery.address.street.placeholder' })}
                name={DeliveryField.Street}
                autoComplete="street-address"
                required
            />
            <Row gutter={theme.metrics.spacing}>
                <Col span={16}>
                    <TextField<DeliveryFormValues[DeliveryField.City]>
                        label={formatMessage({ id: 'order.delivery.address.city' })}
                        name={DeliveryField.City}
                        autoComplete="address-level2"
                        required
                    />
                </Col>
                <Col span={8}>
                    <TextField<DeliveryFormValues[DeliveryField.PostalCode]>
                        type="number"
                        label={formatMessage({ id: 'order.delivery.address.postalCode' })}
                        name={DeliveryField.PostalCode}
                        autoComplete="postal-code"
                        required
                    />
                </Col>
            </Row>

            <CountriesField<DeliveryFormValues[DeliveryField.Country]>
                label={formatMessage({ id: 'order.delivery.address.country' })}
                name={DeliveryField.Country}
                autoComplete="country-name"
                required
            />

            <TextAreaField
                label={formatMessage({ id: 'order.delivery.address.note' })}
                placeholder={formatMessage({ id: 'order.delivery.address.note.placeholder' })}
                textareaProps={{
                    rows: 6,
                }}
                name={DeliveryField.Note}
            />

            <SelectField<DeliveryFormValues[DeliveryField.PartnerId]>
                name={DeliveryField.PartnerId}
                label={formatMessage({ id: 'order.delivery.address.partnerId' })}
                placeholder={formatMessage({ id: 'order.delivery.address.partnerId.placeholder' })}
                loading={api.inProgress}
                required
            >
                {deliveryPartners.map(partner => (
                    <SelectField.Option key={partner.id} value={partner.id}>
                        {partner.name}
                    </SelectField.Option>
                ))}
            </SelectField>
        </>
    );
};

export default DeliveryAddressForm;

import { FormattedMessage } from 'react-intl';

import { LabeledValue, Tabs } from 'modules/ui';

import { paymentModes } from '../../config';
import { ALL } from '../../constants';
import type { PaymentMode } from '../../types';

export interface PaymentProps {
    value?: PaymentMode;
    onChange: (mode: PaymentMode | undefined) => void;
}

export const Payment = ({ value, onChange }: PaymentProps) => {
    return (
        <>
            <LabeledValue
                label={<FormattedMessage id="parcel.filter.paymentMode" />}
                value={
                    <Tabs
                        value={value ?? ALL}
                        onChange={event => {
                            const mode = event.target.value;
                            onChange?.(mode === ALL ? undefined : mode);
                        }}
                    >
                        <Tabs.Tab value={ALL}>
                            <FormattedMessage id="general.all" />
                        </Tabs.Tab>
                        {paymentModes.map(mode => (
                            <Tabs.Tab key={mode} value={mode}>
                                <FormattedMessage id={`parcel.payment.method.${mode}`} />
                            </Tabs.Tab>
                        ))}
                    </Tabs>
                }
            />
        </>
    );
};

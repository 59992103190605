import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
});

export const box: TRuleWithTheme = ({ theme }) => ({
    flexWrap: 'wrap',
    alignItems: 'flex-end',
});

import * as validators from './validators';
import { setValidatorMessage } from './helpers';
import type { FieldValidatorWithMessage } from './types';

export const required: FieldValidatorWithMessage<string | number | boolean | Array<any>> = setValidatorMessage(
    validators.required,
    'error.form.required',
);

export const phoneNumberRequired = setValidatorMessage(validators.phoneNumber, 'error.form.invalidPhoneNumber');

export const email: FieldValidatorWithMessage = setValidatorMessage(validators.email, 'error.form.email');

export const greaterThan = (min: number): FieldValidatorWithMessage =>
    setValidatorMessage(validators.greaterThan(min), ['error.form.greaterThan', { min }]);

export const greaterThanOrEqual = (min: number): FieldValidatorWithMessage =>
    setValidatorMessage(validators.greaterThanOrEqual(min), ['error.form.greaterThanOrEqual', { min }]);

import { requestAuthHeaderInterceptor } from '@ackee/antonio-auth';
import { Antonio, isAntonioError } from '@ackee/antonio-core';
import { logoutRequest } from '@ackee/petrus';
import { put } from 'redux-saga/effects';

import config from 'config';
import { invalidatePartner, setPartnerIdHeader } from 'modules/partner/api';

const api = new Antonio({
    baseURL: config.api.base,
});

const authApi = new Antonio({
    baseURL: config.api.base,
});

function setAppInfoHeader(request: Request) {
    request.headers.set('x-app-info', `BoxNow Partner portal ${process.env.REACT_APP_VERSION}`);
    return request;
}

api.interceptors.request.use(undefined, setAppInfoHeader);
authApi.interceptors.request.use(undefined, setAppInfoHeader);

// Set the Authorization header before each req. with valid access token obtained from @ackee/petrus
authApi.interceptors.request.use(undefined, requestAuthHeaderInterceptor);

authApi.interceptors.request.use(undefined, setPartnerIdHeader);

/* @ts-ignore */
authApi.interceptors.response.use(null, function* (error) {
    if (isAntonioError(error)) {
        if (error.response.status === 401) {
            yield put(logoutRequest());
        }

        if (error.response.status === 400 && ['P440', 'P441'].includes((error.data as any).code)) {
            yield put(invalidatePartner());
        }
    }

    return error;
});

export { api, authApi };

import { createAction } from '@reduxjs/toolkit';
import { createFormActions } from 'modules/form';
import type { LoginCodeFormValues, LoginFormValues } from 'modules/auth/types';

import { ACTION_PREFIX } from '../../constants';

export const loginForm = createFormActions<LoginFormValues, 'LOGIN_FORM', typeof ACTION_PREFIX>(
    'LOGIN_FORM',
    ACTION_PREFIX,
);

export const getLoginCodeForm = createFormActions<LoginCodeFormValues, 'LOGIN_CODE_FORM', typeof ACTION_PREFIX>(
    'LOGIN_CODE_FORM',
    ACTION_PREFIX,
);

export const setLoginCode = createAction<{ challenge: string }>(`${ACTION_PREFIX}/SET_LOGIN_CODE`);

export const logout = createAction(`${ACTION_PREFIX}/LOGOUT`);

export const resendLoginCode = createAction(`${ACTION_PREFIX}/RESEND_LOGIN_CODE`);

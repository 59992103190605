import type { TRuleWithTheme } from 'styles/theme';
import type { ParcelPanelProps } from './ParcelPanel';

type StyleProps = Pick<ParcelPanelProps, 'columnsTemplate'>;

export const panel: TRuleWithTheme<StyleProps> = ({
    theme: {
        metrics: { spacing },
        colors,
    },
    columnsTemplate,
}) => ({
    display: 'grid',

    gridTemplateColumns: columnsTemplate,
    columnGap: spacing * 3,
    alignItems: 'center',

    paddingTop: spacing * 2,
    paddingBottom: spacing * 2,

    fontSize: '1rem',
    fontWeight: 400,
    color: colors.fgPrimary,
    lineHeight: '1.25rem',

    overflowY: 'hidden',
    position: 'relative',
});

import { useState } from 'react';
import { useFelaEnhanced } from 'hooks';
import { DeliveryField } from '../../config';
import * as felaRules from './DeliveryLocation.rules';
import config from 'config';
import type { LocationType } from 'modules/entities';
import type { MessageKey } from 'translations';
import { validatorsWithMessage } from 'modules/form';
import CustomerReturnDeliveryLocationFields from './CustomerReturnDeliveryLocationFields';
import DeliveryLocationFieldsWithWidget from './DeliveryLocationFieldsWithWidget';
import DeliveryLocationFieldsWithDropdown from './DeliveryLocationFieldsWithDropdown';
import { useLocation } from 'react-router-dom';
import { Field } from 'react-final-form';
import { Radio } from 'antd';
import { useForm } from 'react-final-form';
import { RadioButton } from 'modules/ui';
import { FormattedMessage } from 'react-intl';
import { usePartnerShippingRegions } from 'modules/shipping-regions';

export interface DeliveryLocationProps {
    name?: string;
    compSize?: string;
    locationTypes?: LocationType[];
    label?: MessageKey;
    placeholder?: MessageKey;
    widgetEnabled?: boolean;
}

export enum ApmPickMethod {
    Widget = 'widget',
    Dropdown = 'dropdown',
}

const { required } = validatorsWithMessage;

const DEFAULT_NAME = DeliveryField.ApmNumber;

export const getValidators = (name: string = DEFAULT_NAME) => ({
    [name]: required,
});

const defaultLocationTypes: LocationType[] = ['apm'];
const defaultLabel: MessageKey = 'order.delivery.apm.number';
const defaultMapLabel: MessageKey = 'delivery.type.location';
const defaultPlaceholder: MessageKey = 'order.delivery.apm.number.placeholder';
const defaultMapPlaceholder: MessageKey = 'order.delivery.apm.choose';

export const DeliveryLocation = ({
    name = DEFAULT_NAME,
    locationTypes = defaultLocationTypes,
    label,
    placeholder,
    compSize,
    widgetEnabled = true,
}: DeliveryLocationProps) => {
    const partnerShippingRegions = usePartnerShippingRegions();
    const { styles } = useFelaEnhanced(felaRules);
    const orderType = useLocation();
    const [selectedMethod, setSelectedMethod] = useState<ApmPickMethod>(ApmPickMethod.Widget);
    const [selectedRegionMap, setSelectedRegionMap] = useState<string | null>(
        partnerShippingRegions.length > 0 ? partnerShippingRegions[0].slice(-2) : null,
    );
    const form = useForm();

    const handleMethodChange = (newValue: ApmPickMethod) => {
        setSelectedMethod(newValue);
        form.change(name, undefined);
    };

    const handleMethodChangeRegionMap = (value: string) => {
        setSelectedRegionMap(value);
    };

    return (
        <div className={styles.container}>
            {orderType.pathname === config.routes.customerReturn ? (
                <CustomerReturnDeliveryLocationFields
                    key="customerReturn"
                    name={name}
                    label={label ?? defaultLabel}
                    placeholder={placeholder ?? defaultPlaceholder}
                    locationTypes={locationTypes}
                />
            ) : (
                <>
                    {widgetEnabled && (
                        <Field name="apmWidget" subscription={{ value: true }} type="radio">
                            {({ input }) => (
                                <Radio.Group
                                    {...input}
                                    onChange={e => {
                                        input.onChange(e);
                                        handleMethodChange(e.target.value);
                                    }}
                                    value={selectedMethod}
                                >
                                    <RadioButton value={ApmPickMethod.Dropdown}>
                                        <FormattedMessage id="order.delivery.location.method.dropdown" />
                                    </RadioButton>

                                    <RadioButton value={ApmPickMethod.Widget}>
                                        <FormattedMessage id="order.delivery.location.method.widget" />
                                    </RadioButton>
                                </Radio.Group>
                            )}
                        </Field>
                    )}
                    {selectedMethod === ApmPickMethod.Widget && widgetEnabled && partnerShippingRegions?.length > 0 ? (
                        <>
                            <br />
                            <br />
                            <Field name="regionMap" subscription={{ value: true }} type="radio">
                                {({ input }) => (
                                    <Radio.Group
                                        {...input}
                                        onChange={e => {
                                            input.onChange(e);
                                            handleMethodChangeRegionMap(e.target.value);
                                        }}
                                        value={selectedRegionMap}
                                    >
                                        {partnerShippingRegions.map(region => (
                                            <RadioButton key={region.slice(-2)} value={region.slice(-2)}>
                                                {region.slice(-2)} {/* Render the region name or a localized version */}
                                            </RadioButton>
                                        ))}
                                    </Radio.Group>
                                )}
                            </Field>
                            <br />
                            <DeliveryLocationFieldsWithWidget
                                key={selectedRegionMap} // Unique key for each region
                                name={name}
                                label={label ?? defaultMapLabel}
                                placeholder={placeholder ?? defaultMapPlaceholder}
                                compSize={compSize}
                                orderTypePathname={orderType.pathname}
                                region={selectedRegionMap} // Passing the region as a prop (if needed)
                            />
                        </>
                    ) : (
                        <DeliveryLocationFieldsWithDropdown
                            key="dropdown"
                            name={name}
                            label={label ?? defaultLabel}
                            placeholder={placeholder ?? defaultPlaceholder}
                            locationTypes={locationTypes}
                        />
                    )}
                </>
            )}
        </div>
    );
};

import type { Parcel } from 'modules/entities';
import { usePartner } from 'modules/partner/hooks';

const usePartnerCurrency = (): string => {
    const partner = usePartner();

    if (partner === null) {
        throw new Error('Partner should not be null');
    }

    return partner.assignedCurrency;
};

// When showing parcel detail / order details - the currency should be in the payment object
export const useCurrency = (payment?: Parcel['payment']): string => {
    const partnerCurrency = usePartnerCurrency();

    if (payment) {
        return payment.price.currency;
    }

    return partnerCurrency;
};

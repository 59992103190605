import { all } from 'redux-saga/effects';

import choosePartnerFormSaga from './choosePartnerForm';
import refetchPartnersSaga from './refetchPartners';
import syncLocalStorageSaga from './syncLocalStorage';

export { getPartnerId } from './getPartnerId';

export default function* rootPartnerSaga() {
    yield all([choosePartnerFormSaga(), syncLocalStorageSaga(), refetchPartnersSaga()]);
}

import type { TRuleWithTheme } from 'styles/theme';

export const parcelNumber: TRuleWithTheme = ({ theme }) => ({
    gap: theme.metrics.spacing * 0.5,
});

export const labeledValue: TRuleWithTheme = ({ theme }) => ({
    gap: theme.metrics.spacing * 1.5,
});

export const sectionTop: TRuleWithTheme = ({ theme: { metrics } }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 2fr',
    rowGap: metrics.spacing * 4,
    alignItems: 'flex-start',

    isMobile: {
        gridTemplateColumns: '1fr 1fr',
    },
});

export const actions: TRuleWithTheme = ({ theme: { metrics } }) => ({
    display: 'inline-flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    gap: metrics.spacing * 3,
});

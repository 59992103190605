import type { FC } from 'react';
import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useFelaEnhanced } from 'hooks';
import { Form, useFormSubmit, validateWithTranslation, validatorsWithMessage } from 'modules/form';
import { ErrorMessage } from 'modules/errors';
import { Box, Headline, Panel, Button } from 'modules/ui';
import { ParcelSize } from 'modules/parcels';
import type { LocationType } from 'modules/entities';

import type { NewOrderFormValues } from '../../types';
import { newOrderForm } from '../../services/actions';
import { useAnyApmLocation, useDestinations } from '../../hooks';
import {
    AnyApmFields,
    DeliveryField,
    form,
    ItemsField,
    OrderItemField,
    OrderMetaField,
    OriginFieldItems,
    PickupField,
} from '../../config';
import { generateReferenceNumber } from '../../utils';
import { ItemSizeSelect } from '../ItemSizeSelect';
import {
    PaymentMethodSelect,
    getValidators as getPaymentMethodValidators,
    getInitialValues as getPaymentMethodInitialValues,
} from '../PaymentMethodSelect';
import {
    CustomerDetailsForm,
    getValidators as getCustomerDetailsValidators,
    getInitialValues as getCustomerDetailsInitialValues,
} from '../CustomerDetailsForm';
import {
    AllowReturnSelect,
    getValidators as getAllowReturnValidators,
    getInitialValues as getAllowReturnInitialValues,
} from '../AllowReturnSelect';

import * as felaRules from './OrderFromAnyToSameApmForm.rules';
import { ShowRecipientInformationSelect } from '../ShowRecipientInformationSelect';
import { usePartner } from 'modules/partner/hooks';

interface OrderFromAnyToSameApmFormProps {}

const { required } = validatorsWithMessage;

export const validators = {
    [AnyApmFields.CompartmentSize]: required,
    ...getAllowReturnValidators(),
    ...getCustomerDetailsValidators(),
};

const staticInitialValues = {
    [OrderMetaField.InvoiceValue]: '0',
    [ItemsField.Items]: [
        {
            [OrderItemField.Value]: '0',
            [OrderItemField.Size]: ParcelSize.Medium,
        },
    ],
    [DeliveryField.ShowRecipientInformation]: true,
    ...getPaymentMethodInitialValues(),
    ...getAllowReturnInitialValues(),
    ...getCustomerDetailsInitialValues,
};

const locationTypes: LocationType[] = ['any-apm'];

export const OrderFromAnyToSameApmForm: FC<OrderFromAnyToSameApmFormProps> = () => {
    const { formatMessage } = useIntl();
    const onSubmit = useFormSubmit<NewOrderFormValues>(newOrderForm);
    const anyApmLocation = useAnyApmLocation();
    const { destinations } = useDestinations(locationTypes);
    const partner = usePartner();

    const initialValues = useMemo(
        () => ({
            [DeliveryField.ReferenceNumber]: generateReferenceNumber(),
            [PickupField.Location]: anyApmLocation?.id ?? undefined,
            [DeliveryField.ApmNumber]: destinations?.length > 0 ? destinations[0].id : undefined,
            [DeliveryField.Origin]: {
                [OriginFieldItems.OriginContactEmail]: partner?.email,
                [OriginFieldItems.OriginContactNumber]: partner?.phoneNumber,
            },
            ...staticInitialValues,
        }),
        [anyApmLocation, destinations, partner],
    );
    const { rules, theme } = useFelaEnhanced(felaRules);

    const validate = (values: NewOrderFormValues) => {
        const formValidators = {
            ...validators,
            ...getPaymentMethodValidators(values),
        };

        return validateWithTranslation<NewOrderFormValues>(values, formValidators, formatMessage);
    };

    return (
        <Panel extend={{ panel: rules.panel }}>
            <Form<NewOrderFormValues>
                name={form.orderFromAnyApm}
                onSubmit={onSubmit}
                initialValues={initialValues}
                validate={validate}
            >
                {({ submitting, submitError, dirtySinceLastSubmit }) => (
                    <Box space={theme.metrics.spacing * 5}>
                        <>
                            <Headline level={2}>
                                <FormattedMessage id="order.delivery.details" />
                            </Headline>
                            <CustomerDetailsForm />
                        </>

                        <>
                            <Headline level={2}>
                                <FormattedMessage id="order.delivery.address" />
                            </Headline>

                            <ItemSizeSelect name={AnyApmFields.CompartmentSize} required />
                            <PaymentMethodSelect />
                            <AllowReturnSelect />
                            <ShowRecipientInformationSelect
                                extend={{ formItem: rules.showRecipientInformationSelect }}
                            />
                        </>

                        {submitError && !dirtySinceLastSubmit && <ErrorMessage error={submitError} />}

                        <Button
                            type={Button.Type.PRIMARY}
                            size={Button.Size.BIG}
                            htmlType="submit"
                            disabled={submitting}
                        >
                            <FormattedMessage id="order.new.button.create" />
                        </Button>
                    </Box>
                )}
            </Form>
        </Panel>
    );
};

import type { TRuleWithTheme } from 'styles/theme';

import type { ToastProps } from './Toast';

type StyleProps = Pick<ToastProps, 'button'>;

export const container: TRuleWithTheme<StyleProps> = ({ button }) => ({
    display: 'grid',
    gridTemplateColumns: button ? '1fr auto' : '1fr',
    columnGap: 12,
});

export const button: TRuleWithTheme = ({ theme: { colors } }) => ({
    width: 'auto',

    padding: '6px 12px',
    marginTop: '1.25rem',
    marginBottom: '0.5rem',

    fontSize: '0.937rem',
    color: colors.white,
    backgroundColor: 'transparent',

    borderColor: colors.white,

    ':hover': {
        color: colors.white,
        backgroundColor: 'transparent',
        borderColor: 'transparent',
    },

    ':focus': {
        color: colors.white,
        backgroundColor: 'transparent',
        borderColor: 'transparent',
    },
});

import type { RequestSearchParams } from '@ackee/antonio-core';
import { isDefined } from 'types/guards';

type NullableRequestSearchParams =
    | URLSearchParams
    | {
          [key: string]: string | number | boolean | null | (string | number | boolean)[];
      };

export default function getValidApiParams(params: NullableRequestSearchParams): RequestSearchParams {
    const validEntries = Object.entries(params).filter(([_, value]) => isDefined(value));

    return Object.fromEntries(validEntries);
}

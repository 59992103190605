import type { IStyle } from 'fela';
import type { TRuleWithTheme } from 'styles/theme';
import type { TextFieldProps } from '../types';

type StyleProps = Pick<TextFieldProps, 'type'>;

const spinButtonStyle: IStyle = {
    WebkitAppearance: 'none',
    margin: '0',
};

export const input: TRuleWithTheme<StyleProps> = ({ theme: { colors, metrics, form }, type }) => ({
    padding: metrics.spacing * 1.5,
    fontSize: '1rem',
    lineHeight: 1.25,

    border: `1px solid ${colors.borderInput}`,
    borderRadius: form.borderRadius,

    background: 'transparent',
    color: colors.fgPrimary,

    '&:hover': {
        borderColor: colors.accentSecondary,
    },

    '&:focus': {
        borderColor: colors.accentSecondary,
        boxShadow: 'none',
    },

    '&::placeholder': {
        color: colors.fgSecondary,
        opacity: 1,
    },

    '&.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input': {
        borderColor: colors.buttonPrimaryDestructive,
        background: 'transparent',
    },

    extend: {
        condition: type === 'number',
        style: {
            '::-webkit-outer-spin-button': spinButtonStyle,
            '::-webkit-inner-spin-button': spinButtonStyle,
            touchAction: 'none',
            MozAppearance: 'textfield',
        },
    },
});

import type { ReactNode } from 'react';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles';
import { Loader } from 'modules/ui';

import { ChoosePartnerPage } from '../ChoosePartnerPage';
import { useHasValidPartner } from '../../hooks/useHasValidPartner';
import * as felaRules from './HasPartner.rules';

export interface HasPartnerProps {
    children: ReactNode;
    extend?: RulesExtend<typeof felaRules>;
}

export const HasPartner = ({ children, extend }: HasPartnerProps) => {
    const { rules } = useFelaEnhanced(felaRules, { extend });
    const { loading, hasValidPartner } = useHasValidPartner();

    return (
        <Loader
            show={loading}
            children={hasValidPartner ? children : <ChoosePartnerPage />}
            extend={{ container: rules.container }}
        />
    );
};

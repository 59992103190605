import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import { Button } from 'modules/ui';
import { Home } from 'modules/ui/modules/icons';
import { useEntrustedPartners } from 'modules/entities/modules/entrustedPartners/hooks';

import { usePartner, usePartnerModal } from '../../hooks';
import { SwitchPartnersModal } from '../SwitchPartnersModal';

import * as felaRules from './PartnerButton.rules';

export interface PartnerButtonProps {
    extend?: RulesExtend<typeof felaRules>;
}

export const PartnerButton = ({ extend }: PartnerButtonProps) => {
    const { rules } = useFelaEnhanced(felaRules, { extend });
    const partner = usePartner();
    const { entrustedPartners } = useEntrustedPartners();
    const { show } = usePartnerModal();

    const isOnlyOneEntrustedPartner = entrustedPartners.length === 1;

    const onClick = () => {
        show();
    };

    return (
        <>
            <SwitchPartnersModal />
            <Button
                type={Button.Type.TERTIARY}
                extend={{ button: rules.button }}
                icon={<Home />}
                onClick={onClick}
                disabled={isOnlyOneEntrustedPartner}
            >
                {partner?.displayName || '-'}
            </Button>
        </>
    );
};

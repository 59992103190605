import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { useFelaEnhanced } from 'hooks';
import { Alert, AlertProps } from 'modules/ui';
import type { ErrorIntlMessage } from '../../types';

import * as felaRules from './ErrorMessage.rules';

export interface ErrorMessageProps extends Omit<AlertProps, 'message' | 'type'> {
    error?: ErrorIntlMessage | null;
    children?: ReactNode;
}

const ErrorMessage = ({ error, description, children = null }: ErrorMessageProps) => {
    const { rules } = useFelaEnhanced(felaRules);

    return error ? (
        <Alert
            message={<FormattedMessage {...error} />}
            type="error"
            extend={{ alert: rules.alert }}
            description={description}
        />
    ) : (
        <>{children}</>
    );
};

export default ErrorMessage;

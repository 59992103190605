import type { ReactNode } from 'react';
import { type FallbackRender, ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import ErrorMessageContainer from '../ErrorMessageContainer';
import ErrorMessage from '../ErrorMessage';

type ErrorBoundaryProps = {
    fallback?: FallbackRender;
    children: ReactNode;
};

const ErrorBoundary = ({
    children,
    fallback = () => (
        <ErrorMessageContainer>
            <ErrorMessage error={{ id: 'error.general' }} />
        </ErrorMessageContainer>
    ),
}: ErrorBoundaryProps) => <SentryErrorBoundary fallback={fallback}>{children}</SentryErrorBoundary>;

export default ErrorBoundary;

import { Modal } from 'modules/ui';
import { usePartnerModal } from 'modules/partner/hooks';
import { SwitchPartnerForm } from '../SwitchPartnerForm';

export interface SwitchPartnersModalProps {}

export const SwitchPartnersModal = () => {
    const { visible } = usePartnerModal();

    return (
        <Modal visible={visible} closable={false} footer={null}>
            <SwitchPartnerForm />
        </Modal>
    );
};

import { memo } from 'react';
import type { FC } from 'react';

import { isTruthy } from 'types/guards';
import { ParcelSkeleton } from 'modules/parcels/modules/parcel-common';
import { Separator } from 'modules/ui';

import { ParcelItem, ParcelRowType } from '../../types';
import { ParcelRow } from '../ParcelsList/ParcelRow';

export interface ParcelListItemProps {
    item: ParcelItem;
}

const ParcelListItem: FC<ParcelListItemProps> = ({ item }) => {
    return (
        <>
            {[
                item.rowTypes.has(ParcelRowType.Skeleton) && <ParcelSkeleton key="skeleton" />,
                item.rowTypes.has(ParcelRowType.Separator) && <Separator key="separator" />,
                item.rowTypes.has(ParcelRowType.Item) && <ParcelRow key="item" data={item} />,
            ].filter(isTruthy)}
        </>
    );
};

export default memo(ParcelListItem);

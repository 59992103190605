import { put, takeEvery } from 'redux-saga/effects';
import type { AnyAction } from '@reduxjs/toolkit';
import type { AntonioError } from '@ackee/antonio-core';

import config from 'config';
import { authApi } from 'config/antonio';
import * as Log from 'config/loglevel';

import { getIntlErrorMessage } from 'modules/errors';
import { setEntities } from 'modules/entities/services/actions';
import { normalizeParcels } from 'modules/entities/services/normalizr';
import { EntityKey } from 'modules/entities/constants';
import type { ErrorData } from 'modules/errors';

import { types, cancelParcelDelivery as apiActions } from '../actions';
import { fetchParcel } from './fetchParcel';

function* cancelParcelDelivery(action: AnyAction) {
    const { id: parcelId } = action.meta;

    try {
        const cancelApiUrl = config.api.cancelParcelDelivery.replace(':id', parcelId);
        yield* authApi.post(cancelApiUrl, null);

        const parcel = yield* fetchParcel(parcelId);

        const { entities } = normalizeParcels([parcel]);

        yield put([
            setEntities(EntityKey.PARCELS, entities[EntityKey.PARCELS]),
            setEntities(EntityKey.DELIVERY_REQUESTS, entities[EntityKey.DELIVERY_REQUESTS]),
            apiActions.cancelSuccess(parcelId, undefined, {
                lastSuccessAt: new Date().toISOString(),
            }),
        ]);
    } catch (e: unknown) {
        const errorMessage = getIntlErrorMessage(e as AntonioError<ErrorData> | Error, {
            code: {
                P420: { id: 'error.deliveryRequest.post.p420' },
            },
        });

        if (errorMessage.unhandled) {
            Log.error(e as Error);
        }

        yield put(apiActions.cancelFailure(parcelId, errorMessage));
    }
}

export default function* cancelParcelDeliverySaga() {
    yield takeEvery(types.cancelParcelDelivery.CANCEL_REQUEST, cancelParcelDelivery);
}

import { createAction } from '@reduxjs/toolkit';

import { MODULE_PREFIX, DEFAULT_GROUP, EntityKey } from '../constants';
import type { EntityId, Entities, SetStrategy } from '../types';

type ById = Record<string, Entities>;

type PrepareSetEntitiesAction = (
    entityKey: EntityKey,
    byId: ById,
) => {
    meta: {
        entityKey: EntityKey;
        group?: never;
        strategy?: never;
    };
    payload: {
        byId: ById;
        ids?: never;
        id?: never;
    };
};

export const setEntities = createAction<PrepareSetEntitiesAction>(
    `${MODULE_PREFIX}/SET_ENTITIES`,
    (entityKey, byId) => ({
        meta: { entityKey },
        payload: { byId },
    }),
);

type PrepareRemoveEntityAction = (
    entityKey: EntityKey,
    id: EntityId,
) => {
    meta: {
        entityKey: EntityKey;
        group: string;
    };
    payload: {
        id: EntityId;
    };
};

export const removeEntity = createAction<PrepareRemoveEntityAction>(
    `${MODULE_PREFIX}/REMOVE_ENTITY`,
    (entityKey, id, group = DEFAULT_GROUP) => ({
        meta: { entityKey, group },
        payload: { id },
    }),
);

type PrepareSetEntitiesGroupAction = (
    entityKey: EntityKey,
    payload?: {
        group?: string;
        strategy?: SetStrategy;
        ids?: Array<EntityId>;
        byId: ById;
    },
) => {
    meta: {
        entityKey: EntityKey;
        group: string;
        strategy: SetStrategy;
    };
    payload: {
        ids?: Array<EntityId>;
        byId: ById;
        id?: EntityId;
    };
};

export const setEntitiesGroup = createAction<PrepareSetEntitiesGroupAction>(
    `${MODULE_PREFIX}/SET_ENTITIES_GROUP`,
    (entityKey, payload = { byId: {} as ById, ids: [] }) => ({
        meta: {
            entityKey,
            group: payload.group ?? DEFAULT_GROUP,
            strategy: payload.strategy ?? 'append',
        },
        payload,
    }),
);

type PrepareResetEntitiesGroupAction = (
    entityKey: EntityKey,
    group?: string,
) => {
    meta: {
        entityKey: EntityKey;
        group: string;
    };
    payload: undefined;
};

export const resetEntitiesGroup = createAction<PrepareResetEntitiesGroupAction>(
    `${MODULE_PREFIX}/RESET_ENTITIES_GROUP`,
    (entityKey, group = DEFAULT_GROUP) => ({
        meta: {
            entityKey,
            group,
        },
        payload: undefined,
    }),
);

type PrepareResetEntitiesAction = (entityKey: EntityKey) => {
    meta: {
        entityKey: EntityKey;
    };
    payload: undefined;
};

export const resetEntities = createAction<PrepareResetEntitiesAction>(`${MODULE_PREFIX}/RESET_ENTITIES`, entityKey => ({
    meta: { entityKey },
    payload: undefined,
}));

type PrepareUnsetEntitiesGroupAction = (
    entityKey: EntityKey,
    group?: string,
    ids?: Array<EntityId>,
) => {
    meta: {
        entityKey: EntityKey;
        group: string;
    };
    payload: {
        ids?: Array<EntityId>;
    };
};

export const unsetEntitiesGroup = createAction<PrepareUnsetEntitiesGroupAction>(
    `${MODULE_PREFIX}/UNSET_ENTITIES_GROUP`,
    (entityKey, group = DEFAULT_GROUP, ids = []) => ({
        meta: {
            entityKey,
            group,
        },
        payload: {
            ids,
        },
    }),
);

type PrepareUpdateEntitiesAction = (
    entityKey: EntityKey,
    byId: Record<string, Partial<Entities>>,
) => {
    meta: {
        group?: string;
        entityKey: EntityKey;
    };
    payload: {
        byId: Record<string, Partial<Entities>>;
    };
};

export const updateEntities = createAction<PrepareUpdateEntitiesAction>(
    `${MODULE_PREFIX}/UPDATE_ENTITIES`,
    (entityKey, byId) => ({
        meta: { entityKey },
        payload: {
            byId,
        },
    }),
);

export type SetEntitiesAction = ReturnType<typeof setEntities>;
export type RemoveEntityAction = ReturnType<typeof removeEntity>;
export type SetEntitiesGroupAction = ReturnType<typeof setEntitiesGroup>;
export type ResetEntitiesGroupAction = ReturnType<typeof resetEntitiesGroup>;
export type ResetEntitiesAction = ReturnType<typeof resetEntities>;
export type UnsetEntitiesGroupAction = ReturnType<typeof unsetEntitiesGroup>;
export type UpdateEntitiesAction = ReturnType<typeof updateEntities>;

export type EntitiesActions =
    | SetEntitiesAction
    | RemoveEntityAction
    | SetEntitiesGroupAction
    | ResetEntitiesGroupAction
    | ResetEntitiesAction
    | UnsetEntitiesGroupAction
    | UpdateEntitiesAction;

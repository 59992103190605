import { useFelaEnhanced } from 'hooks';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';

import type { Parcel } from 'modules/entities';
import { Panel, Label, Value, Headline } from 'modules/ui';

import * as felaRules from './History.rules';

export interface HistoryProps {
    events: Parcel['events'];
}

const History = ({ events }: HistoryProps) => {
    const { styles, rules } = useFelaEnhanced(felaRules);

    return (
        <>
            <Headline level={2}>
                <FormattedMessage id="parcel.history.title" />
            </Headline>
            <Panel composed>
                <Panel.Header extend={{ header: rules.row }}>
                    <Label>
                        <FormattedMessage id="parcel.history.event" />
                    </Label>
                    <Label>
                        <FormattedMessage id="parcel.history.location" />
                    </Label>
                    <Label>
                        <FormattedMessage id="parcel.history.postalCode" />
                    </Label>
                    <Label>
                        <FormattedMessage id="parcel.history.time" />
                    </Label>
                    <Label>
                        <FormattedMessage id="parcel.history.date" />
                    </Label>
                </Panel.Header>
                <Panel.Body extend={{ body: rules.rows }}>
                    {events?.map(({ type, locationDisplayName, postalCode, createTime }, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <div className={styles.row} key={index}>
                            <Value>
                                <FormattedMessage id={`parcel.history.event.type.${type}`} />
                            </Value>
                            <Value>{locationDisplayName}</Value>
                            <Value>{postalCode}</Value>
                            <Value>
                                <FormattedTime value={createTime} hour="numeric" minute="numeric" hour12={false} />
                            </Value>
                            <Value>
                                <FormattedDate value={createTime} day="numeric" month="numeric" year="numeric" />
                            </Value>
                        </div>
                    ))}
                    {events?.length === 0 && (
                        <div className={styles.noEvents}>
                            <FormattedMessage id="parcel.history.empty" />
                        </div>
                    )}
                </Panel.Body>
            </Panel>
        </>
    );
};

export default History;

import type { ReactNode } from 'react';
import { pick } from 'lodash';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend, TRuleWithTheme } from 'styles/theme';

import { Label, LabelProps } from './Label';
import { Value, ValueProps } from './Value';
import type { ValueSize } from './types';

import * as felaRules from './LabeledValue.rules';

export interface LabeledValueProps {
    label: ReactNode;
    value?: ReactNode;
    extend?: RulesExtend<typeof felaRules> & LabelProps['extend'] & ValueProps['extend'];
    size?: ValueSize;
}

const LabeledValue = ({ label, value, size, extend }: LabeledValueProps) => {
    const { styles } = useFelaEnhanced(felaRules, {
        extend: pick(extend, 'container') as { container: TRuleWithTheme },
    });

    return (
        <div className={styles.container}>
            <Label extend={pick(extend, 'label')}>{label}</Label>
            <Value extend={pick(extend, 'value')} size={size}>
                {value}
            </Value>
        </div>
    );
};
export default LabeledValue;

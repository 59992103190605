import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import config from 'config';
import { Layout, PageHeader } from 'modules/layout';
import { BackButton } from 'modules/ui';

import { OrderFromAnyToSameApmForm } from '../OrderFromAnyToSameApmForm';

export const OrderFromAnyToSameApmPage = () => {
    return (
        <Layout>
            <PageHeader title={<FormattedMessage id="order.fromAnyToSameApm.title" />}>
                <Link to={config.routes.home}>
                    <BackButton text={{ id: 'order.new.back' }} />
                </Link>
            </PageHeader>

            <OrderFromAnyToSameApmForm />
        </Layout>
    );
};

import { useFormState } from 'react-final-form';

import { isTruthy } from 'types/guards';
import { PaymentMethod } from 'modules/order/modules/order-common';
import { useUserHasPermission, PartnerPermission } from 'modules/permissions';

import type { NewOrderFormValues } from '../types';
import { DeliveryField, DeliveryType } from '../config';

const useAllowedPaymentMethods = () => {
    const codPaymentAllowed = useUserHasPermission(PartnerPermission.COD_PAYMENT);
    const codAddressAsDestinationAllowed = useUserHasPermission(PartnerPermission.COD_ADDRESS_AS_DESTINATION);

    const { values } = useFormState<Partial<NewOrderFormValues>>({ subscription: { values: true } });

    const deliveryType = values[DeliveryField.DeliveryType];
    const isAddressDeliveryType = deliveryType === DeliveryType.Address;

    const codAllowed = codPaymentAllowed && (isAddressDeliveryType ? codAddressAsDestinationAllowed : true);

    return [PaymentMethod.Prepaid, codAllowed && PaymentMethod.CashOnDelivery].filter(isTruthy);
};
export default useAllowedPaymentMethods;

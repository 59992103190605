import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles';

import * as felaRules from './Success.rules';

export interface SuccessProps {
    extend?: RulesExtend<typeof felaRules>;
}

export const Success = ({ extend }: SuccessProps) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });

    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={styles.icon}
            stroke="currentColor"
        >
            <path
                d="M9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75Z"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M6.75 9L8.25 10.5L11.25 7.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

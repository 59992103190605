import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router-dom';

import { useFelaEnhanced } from 'hooks';
import type { AppRoute } from 'types';

import { Loader, Panel, Button, Headline, Separator, Alert } from 'modules/ui';
import { ParcelPanel, ParcelPanelCell, ParcelRowHeader } from 'modules/parcels/modules/parcel-common';
import { GetParcelLabel } from 'modules/parcels/modules/parcel-label';
import { useCsvImport } from 'modules/entities/modules/csvImport';
import { GRID_COLUMNS } from '../../config';

import * as felaRules from './CsvLabelsPage.rules';

interface CsvLabelsPageProps {
    redirectRoute: AppRoute;
}

export const CsvLabelsPage = ({ redirectRoute }: CsvLabelsPageProps) => {
    const { styles, rules } = useFelaEnhanced(felaRules);
    const { api, csvImportData, parcelLabelsUrl } = useCsvImport();

    if (csvImportData.length === 0) {
        return <Redirect to={redirectRoute} />;
    }

    return (
        <>
            <Alert
                type="success"
                message={<FormattedMessage id="order.csvImport.successUpload" />}
                extend={{ alert: rules.successMessage }}
            />

            <div className={styles.header}>
                <Headline level={2} extend={{ headline: rules.title }}>
                    <FormattedMessage id="order.csvImport.downloadLabels" />
                </Headline>

                {parcelLabelsUrl && (
                    <GetParcelLabel url={parcelLabelsUrl} text={{ id: 'parcel.getLabels' }} forceDownload />
                )}
            </div>

            <Panel composed>
                <Panel.Header>
                    <ParcelRowHeader
                        showUpdated={false}
                        showStatus={false}
                        showCreated={false}
                        columnsTemplate={GRID_COLUMNS}
                    />
                </Panel.Header>
                <Panel.Body>
                    {api.inProgress ? (
                        <Loader show />
                    ) : (
                        csvImportData.map((deliveryRequest, index) => (
                            <Fragment key={deliveryRequest.id}>
                                {index > 0 && <Separator spacing={1.25} />}

                                {deliveryRequest.parcels.map(parcel => (
                                    <ParcelPanel
                                        key={parcel.id}
                                        columnsTemplate={GRID_COLUMNS}
                                        extend={{ panel: rules.parcel }}
                                    >
                                        <ParcelPanelCell>{deliveryRequest.id}</ParcelPanelCell>

                                        <ParcelPanelCell>{parcel.id}</ParcelPanelCell>
                                        <ParcelPanelCell>{deliveryRequest.destination?.contactName}</ParcelPanelCell>

                                        <ParcelPanelCell extend={{ cell: rules.lastCell }}>
                                            <GetParcelLabel url={parcel.labelUrl} type={Button.Type.TERTIARY} />
                                        </ParcelPanelCell>
                                    </ParcelPanel>
                                ))}
                            </Fragment>
                        ))
                    )}
                </Panel.Body>
            </Panel>
        </>
    );
};

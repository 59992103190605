import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import { Delete, File } from 'modules/ui/modules/icons';

import * as felaRules from './UploadItem.rules';

export interface UploadItemProps {
    extend?: RulesExtend<typeof felaRules>;
    uid: string;
    name: string;
    onRemove: (uid: string) => void;
}

export const UploadItem = ({ extend, name, uid, onRemove }: UploadItemProps) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });

    return (
        <div className={styles.uploadItem}>
            <File />

            <span className={styles.name}>{name}</span>

            <button
                type="button"
                onClick={e => {
                    e.stopPropagation();
                    onRemove(uid);
                }}
                className={styles.buttonRemove}
            >
                <Delete />
            </button>
        </div>
    );
};

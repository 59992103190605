import { basicApiReducer } from '@ackee/redux-utils';
import { types } from '../../actions';

export default basicApiReducer({
    actionTypes: {
        REQUEST: types.csvImport.CSV_IMPORT_REQUEST,
        SUCCESS: types.csvImport.CSV_IMPORT_SUCCESS,
        FAILURE: types.csvImport.CSV_IMPORT_FAILURE,
        CANCEL: types.csvImport.CSV_IMPORT_CANCEL,
        RESET: types.csvImport.CSV_IMPORT_RESET,
    },
});

import type { TRuleWithTheme } from 'styles/theme';

export const radioFormItem: TRuleWithTheme = ({ theme }) => ({
    marginBottom: theme.metrics.spacing * 1.5,
});

export const radioGroup: TRuleWithTheme = ({ theme }) => ({
    background: theme.colors.newBgPrimary,
    borderRadius: 10,

    flexDirection: 'column',
    flexWrap: 'nowrap',

    padding: '1rem',
    margin: 0,

    maxHeight: 200,
    overflowY: 'scroll',
});

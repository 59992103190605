import type { TRuleWithTheme } from 'styles/theme';

export const tab: TRuleWithTheme = ({ theme: { colors, metrics } }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    paddingTop: metrics.spacing * 0.75,
    paddingBottom: metrics.spacing * 0.75,
    paddingLeft: metrics.spacing * 2,
    paddingRight: metrics.spacing * 2,

    border: 'none',
    borderRadius: 5,
    boxShadow: 'none',

    fontSize: '1rem',
    fontWeight: 400,
    color: colors.foregroundsPrimary80,

    background: 'transparent',

    cursor: 'pointer',

    '&:first-child': {
        border: 'none',
    },

    '&.ant-radio-button-wrapper:not(:first-child)::before': {
        display: 'none',
    },

    '&.ant-radio-button-wrapper-checked:focus-within': {
        boxShadow: 'none',
    },

    '& > .ant-radio-button-checked': {
        color: colors.buttonEnabledText,
        background: colors.accentTertiary,
        borderRadius: 5,
    },
});

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useAppSelector } from 'hooks/redux';

import {
    apiActions,
    selectDeliveryPartners,
    selectDeliveryPartnersApi,
} from 'modules/entities/modules/deliveryPartners';

const useDeliveryPartners = () => {
    const dispatch = useDispatch();
    const api = useAppSelector(selectDeliveryPartnersApi);
    const deliveryPartners = useAppSelector(selectDeliveryPartners);

    useEffect(() => {
        if (!api.inProgress && !api.success) {
            dispatch(apiActions.fetchDeliveryPartners.request());
        }
    }, [api, dispatch]);

    return { deliveryPartners, api };
};

export default useDeliveryPartners;

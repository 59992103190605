import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';
import { Button, DownloadButton, DownloadButtonProps } from 'modules/ui';
import { Export } from 'modules/ui/modules/icons';

import * as felaRules from './ExportButton.rules';
import { useExportUrl } from '../../hooks';

export interface ExportButtonProps extends Pick<DownloadButtonProps, 'type' | 'forceDownload'> {
    extend?: RulesExtend<typeof felaRules>;
}

export const ExportButton = ({ extend, ...props }: ExportButtonProps) => {
    const { rules } = useFelaEnhanced(felaRules, { extend });

    const url = useExportUrl();

    if (!url) {
        return null;
    }

    return (
        <DownloadButton
            {...props}
            url={url}
            type={Button.Type.TERTIARY}
            icon={<Export extend={{ icon: rules.icon }} />}
            text={{ id: 'parcel.export.button' }}
            extend={{ button: rules.button }}
        />
    );
};

import { Languages } from 'modules/core/modules/localization/constants';
import type { Language } from 'modules/core/modules/localization/constants';

import en from './en.json';
import el from './el.json';
import hr from './hr.json';
import bg from './bg.json';

type Translations = typeof en;

export type MessageKey = keyof Translations;

declare global {
    namespace FormatjsIntl {
        interface Message {
            ids: MessageKey;
        }

        interface IntlConfig {
            locale: Language;
        }
    }
}

export const translations: Record<Language, Translations> = {
    [Languages.EN]: en,
    [Languages.EL]: el,
    [Languages.HR]: hr,
    [Languages.BG]: bg,
} as const;

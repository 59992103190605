import { combineRules } from 'fela';
import type { TRuleWithTheme } from 'styles/theme';

export const panel: TRuleWithTheme = () => ({
    width: 670,
    maxWidth: '100%',
});

export const fullWidthPanel: TRuleWithTheme = combineRules(panel, () => ({
    width: '100%',
}));

export const title: TRuleWithTheme = ({ theme: { metrics } }) => ({
    marginBottom: metrics.spacing,
});

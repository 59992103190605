import { all } from 'redux-saga/effects';

import { saga as petrus } from '../../config/petrus';
import getLoginCode from './getLoginCode';
import singles from './singles';
import logout from './logout';

export default function* rootAuthSaga() {
    yield all([petrus(), getLoginCode(), singles(), logout()]);
}

import { createApiRequestActions } from '@ackee/redux-utils';
import type { DeliveryRequest } from 'modules/entities/types';
import type { ErrorIntlMessage } from 'modules/errors';

import * as types from './types';

export { types };

export const createDeliveryRequest = createApiRequestActions<
    DeliveryRequest,
    {
        parcelId: string;
    },
    { lastSuccessAt: string },
    ErrorIntlMessage
>({
    REQUEST: types.createDeliveryRequest.CREATE_REQUEST,
    SUCCESS: types.createDeliveryRequest.CREATE_SUCCESS,
    FAILURE: types.createDeliveryRequest.CREATE_FAILURE,
    CANCEL: types.createDeliveryRequest.CREATE_CANCEL,
    RESET: types.createDeliveryRequest.CREATE_RESET,
});

export const updateDeliveryRequest = createApiRequestActions<
    { orderNumber: string; allowReturn: boolean; parcelId: string },
    undefined,
    { lastSuccessAt: string },
    string
>(types.updateDeliveryRequest);

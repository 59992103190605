import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import type { IconProps } from '../../types';

import * as felaRules from './ChevronThin.rules';

interface ChevronThinProps extends IconProps {
    extend?: RulesExtend<typeof felaRules>;
}

export const ChevronThin = ({ extend }: ChevronThinProps) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });

    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.icon}>
            <path d="M15 6L9 12L15 18" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

import { childInitialState } from './reducers/entities';
import { EntityKey } from '../constants';
import type { EntitiesState } from '../types';

export interface RootState {
    entities: EntitiesState;
}

export const selectEntities = (state: RootState, { entityKey }: { entityKey: EntityKey }) => {
    const entity = state.entities[entityKey];
    return (entity ?? childInitialState) as typeof entity;
};

export const selectLocationsEntities = (state: RootState) => {
    const locations = state.entities[EntityKey.LOCATIONS];
    return (locations ?? childInitialState) as typeof locations;
};

export const selectDestinationsEntities = (state: RootState) => {
    const destinations = state.entities[EntityKey.DESTINATIONS];
    return (destinations ?? childInitialState) as typeof destinations;
};

export const selectDeliveryPartnersEntities = (state: RootState) => {
    const deliveryPartners = state.entities[EntityKey.DELIVERY_PARTNERS];
    return (deliveryPartners ?? childInitialState) as typeof deliveryPartners;
};

export const selectEntrustedPartnersEntities = (state: RootState) => {
    const entrustedPartners = state.entities[EntityKey.ENTRUSTED_PARTNERS];
    return (entrustedPartners ?? childInitialState) as typeof entrustedPartners;
};

export const selectParcelsEntities = (state: RootState) => {
    const parcels = state.entities[EntityKey.PARCELS];
    return (parcels ?? childInitialState) as typeof parcels;
};

export const selectDeliveryRequestsEntities = (state: RootState) => {
    const data = state.entities[EntityKey.DELIVERY_REQUESTS];
    return (data ?? childInitialState) as typeof data;
};

export const selectCsvImportEntities = (state: RootState) => {
    const data = state.entities[EntityKey.CSV_IMPORT];
    return (data ?? childInitialState) as typeof data;
};

import { FormattedMessage, useIntl } from 'react-intl';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';
import { Button, Paragraph } from 'modules/ui';
import { Form, setValidatorMessage, translate, useFormSubmit, validators } from 'modules/form';
import type { ChoosePartnerFormValues } from 'modules/partner/types';

import * as felaRules from './ChoosePartnerForm.rules';
import { EntrustedPartnersField } from '../EntrustedPartnersField';

import { formName } from '../../constants';
import { choosePartnerForm } from '../../services/actions';

const partnerRequired = setValidatorMessage(validators.required, 'error.form.noPartner');

export interface ChoosePartnerFormProps {
    extend?: RulesExtend<typeof felaRules>;
}

export const ChoosePartnerForm = ({ extend }: ChoosePartnerFormProps) => {
    const { rules } = useFelaEnhanced(felaRules, { extend });
    const { formatMessage } = useIntl();
    const onSubmit = useFormSubmit<ChoosePartnerFormValues>(choosePartnerForm);

    return (
        <Form<ChoosePartnerFormValues>
            name={formName}
            onSubmit={onSubmit}
            subscription={{
                submitting: true,
            }}
        >
            {({ submitting }) => (
                <>
                    <Paragraph size={Paragraph.Size.BIG} extend={{ paragraph: rules.title }}>
                        <FormattedMessage id="choosePartner.title" />
                    </Paragraph>
                    <Paragraph>
                        <FormattedMessage id="choosePartner.subtitle" />
                    </Paragraph>

                    <EntrustedPartnersField validate={translate(formatMessage, partnerRequired)} />

                    <Paragraph>
                        <FormattedMessage id="choosePartner.description" />
                    </Paragraph>

                    <Button
                        id="submitButton"
                        htmlType="submit"
                        type={Button.Type.PRIMARY}
                        size={Button.Size.BIG}
                        loading={submitting}
                        extend={{ button: rules.submitButton }}
                    >
                        <FormattedMessage id="choosePartner.continue" />
                    </Button>
                </>
            )}
        </Form>
    );
};

import React from 'react';
import type { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { promiseListener } from 'modules/core/modules/redux/config/promiseListener';

import { submitFormFactory } from '../services/utils';

interface FormActions {
    submit: ActionCreatorWithPayload<any>;
    submitSuccess: ActionCreatorWithPayload<any>;
    submitFailure: ActionCreatorWithPayload<any>;
}

const useFormSubmit = <FV extends Record<string, any> = Record<string, any>, FA extends FormActions = any>(
    actions: FA,
    meta?: Record<string, any>,
) => {
    const onSubmit = React.useMemo(
        () =>
            promiseListener.createAsyncFunction({
                start: actions.submit.toString(),
                resolve: actions.submitSuccess.toString(),
                reject: actions.submitFailure.toString(),
                setPayload: (action, payload) => ({
                    ...action,
                    payload,
                    meta,
                }),
            }),
        [actions, meta],
    );

    React.useEffect(() => {
        return () => onSubmit.unsubscribe();
    }, [onSubmit]);

    return submitFormFactory<FV>(onSubmit.asyncFunction);
};

export default useFormSubmit;

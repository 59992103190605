import { FormattedMessage } from 'react-intl';

import { useFelaEnhanced } from 'hooks';

import { LabeledValue, ValueSize } from 'modules/ui';
import type { Parcel } from 'modules/entities';
import { GetParcelLabel } from 'modules/parcels/modules/parcel-label';

import ExtendPickUpPeriodButton from '../ExtendPickUpPeriodButton';
import { CancelDeliveryButton } from '../CancelDeliveryButton';

import * as felaRules from './Header.rules';

export type HeaderProps = Pick<Parcel, 'state' | 'parcelLabelUrl' | 'deliveryRequest' | 'id' | 'cancelationRequested'>;

const forbiddenCancelStates = ['delivered', 'lost', 'returned', 'expired-return', 'canceled', 'canceled-return'];

const Header = ({ id, state, parcelLabelUrl, deliveryRequest, cancelationRequested }: HeaderProps) => {
    const { styles, rules } = useFelaEnhanced(felaRules);
    const cancelDeliveryAlowed = !forbiddenCancelStates.includes(state);

    const labeledValueExtend = { container: rules.labeledValue };

    return (
        <section className={styles.sectionTop}>
            <LabeledValue
                label={<FormattedMessage id="parcel.summary.orderNumber" />}
                value={id}
                extend={{ container: rules.parcelNumber }}
                size={ValueSize.BIG}
            />
            <LabeledValue
                label={<FormattedMessage id="parcel.summary.name" />}
                value={deliveryRequest.destination.contactName}
                extend={labeledValueExtend}
            />
            <LabeledValue
                label={<FormattedMessage id="parcel.summary.status" />}
                value={<FormattedMessage id={`parcel.state.${state}`} />}
                extend={labeledValueExtend}
            />
            <div className={styles.actions}>
                {cancelDeliveryAlowed && (
                    <CancelDeliveryButton parcelNumber={id} cancelationRequested={cancelationRequested} />
                )}

                {state === 'in-final-destination' && <ExtendPickUpPeriodButton parcelNumber={id} />}
                {parcelLabelUrl && <GetParcelLabel url={parcelLabelUrl} />}
            </div>
        </section>
    );
};

export default Header;

import { createElement, ReactElement } from 'react';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import * as felaRules from './Spacing.rules';

export interface SpacingProps {
    extend?: RulesExtend<typeof felaRules>;
    top?: number;
    bottom?: number;
    left?: number;
    right?: number;
    children?: ReactElement | ReactElement[];
    elementType?: 'div';
}

export const Spacing = ({ extend, top, right, left, bottom, children, elementType = 'div' }: SpacingProps) => {
    const { styles } = useFelaEnhanced(felaRules, { extend, top, right, left, bottom });

    return createElement(elementType, { className: styles.spacing }, children);
};

import type { FC } from 'react';
import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import config from 'config';
import { useFelaEnhanced } from 'hooks';
import { Form, useFormSubmit, validateWithTranslation, validatorsWithMessage } from 'modules/form';
import { ErrorMessage } from 'modules/errors';
import { Box, Headline, Panel, Button } from 'modules/ui';
import { ParcelSize } from 'modules/parcels';

import type { NewOrderFormValues } from '../../types';
import { newOrderForm } from '../../services/actions';
import { useAnyApmLocation, useDeliveryLocationTypes } from '../../hooks';
import {
    AnyApmFields,
    DeliveryField,
    form,
    ItemsField,
    OrderItemField,
    OrderMetaField,
    OriginFieldItems,
    PickupField,
} from '../../config';
import { DeliveryLocation, getValidators as geDeliveryApmValidators } from '../DeliveryLocation';
import { generateReferenceNumber } from '../../utils';
import { ItemSizeSelect } from '../ItemSizeSelect';
import {
    PaymentMethodSelect,
    getValidators as getPaymentMethodValidators,
    getInitialValues as getPaymentMethodInitialValues,
} from '../PaymentMethodSelect';
import {
    CustomerDetailsForm,
    getValidators as getCustomerDetailsValidators,
    getInitialValues as getCustomerDetailsInitialValues,
} from '../CustomerDetailsForm';
import { ShowRecipientInformationSelect } from '../ShowRecipientInformationSelect';
import {
    AllowReturnSelect,
    getValidators as getAllowReturnValidators,
    getInitialValues as getAllowReturnInitialValues,
} from '../AllowReturnSelect';
import { CsvSwitcher } from '../CsvSwitcher';
import { FormSpy } from 'react-final-form';
import * as felaRules from './OrderFromAnyApmForm.rules';
import { usePartner } from 'modules/partner/hooks';

interface OrderFromAnyApmFormProps {}

const { required } = validatorsWithMessage;

export const validators = {
    [AnyApmFields.CompartmentSize]: required,
    ...geDeliveryApmValidators(),
    ...getAllowReturnValidators(),
    ...getCustomerDetailsValidators(),
};

const items = {
    [OrderItemField.Value]: '0',
    [OrderItemField.Size]: ParcelSize.Medium,
};

const staticInitialValues = {
    [OrderMetaField.InvoiceValue]: '0',
    [ItemsField.Items]: [items],
    [DeliveryField.ShowRecipientInformation]: true,
    ...getPaymentMethodInitialValues(),
    ...getCustomerDetailsInitialValues(),
    ...getAllowReturnInitialValues(),
};

export const OrderFromAnyApmForm: FC<OrderFromAnyApmFormProps> = () => {
    const { formatMessage } = useIntl();
    const onSubmit = useFormSubmit<NewOrderFormValues>(newOrderForm);
    const anyApmLocation = useAnyApmLocation();
    const deliveryLocationTypes = useDeliveryLocationTypes();
    const partner = usePartner();

    const initialValues = useMemo(
        () => ({
            [PickupField.Location]: anyApmLocation?.id ?? undefined,
            [DeliveryField.ReferenceNumber]: generateReferenceNumber(),
            [DeliveryField.Origin]: {
                [OriginFieldItems.OriginContactEmail]: partner?.email,
                [OriginFieldItems.OriginContactNumber]: partner?.phoneNumber,
            },
            ...staticInitialValues,
        }),
        [anyApmLocation, partner],
    );
    const { rules, theme } = useFelaEnhanced(felaRules);

    const validate = (values: NewOrderFormValues) => {
        const formValidators = {
            ...validators,
            ...getPaymentMethodValidators(values),
        };

        return validateWithTranslation<NewOrderFormValues>(values, formValidators, formatMessage);
    };

    return (
        <>
            <CsvSwitcher state="form" link={config.routes.fromAnyApmOrderCsvImport} />

            <Panel>
                <Form<NewOrderFormValues>
                    name={form.orderFromAnyApm}
                    onSubmit={onSubmit}
                    initialValues={initialValues}
                    validate={validate}
                >
                    {({ submitting, submitError, dirtySinceLastSubmit }) => (
                        <Box space={theme.metrics.spacing * 5}>
                            <>
                                <Headline level={2}>
                                    <FormattedMessage id="order.delivery.details" />
                                </Headline>

                                <CustomerDetailsForm />
                            </>

                            <>
                                <Headline level={2}>
                                    <FormattedMessage id="order.delivery" />
                                </Headline>

                                <ItemSizeSelect name={AnyApmFields.CompartmentSize} required />
                                <FormSpy subscription={{ values: true }}>
                                    {({ values }) =>
                                        values.anyApmCompartmentSize && (
                                            <DeliveryLocation
                                                locationTypes={deliveryLocationTypes}
                                                compSize={values.anyApmCompartmentSize}
                                            />
                                        )
                                    }
                                </FormSpy>

                                <PaymentMethodSelect />
                                <AllowReturnSelect />
                                <ShowRecipientInformationSelect
                                    extend={{ formItem: rules.showRecipientInformationSelect }}
                                />
                            </>

                            {submitError && !dirtySinceLastSubmit && <ErrorMessage error={submitError} />}

                            <Button
                                type={Button.Type.PRIMARY}
                                size={Button.Size.BIG}
                                htmlType="submit"
                                disabled={submitting}
                            >
                                <FormattedMessage id="order.new.button.create" />
                            </Button>
                        </Box>
                    )}
                </Form>
            </Panel>
        </>
    );
};

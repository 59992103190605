import { useCallback, useEffect } from 'react';
import { Alert } from 'antd';
import { FormattedMessage } from 'react-intl';

import { useFelaEnhanced } from 'hooks';
import { useCancelParcelDelivery } from 'modules/entities/modules/parcels';
import { Button, Modal, useModalOpenState } from 'modules/ui';

import * as felaRules from './CancelDeliveryButton.rules';

export interface CancelDeliveryButtonProps {
    parcelNumber: string;
    cancelationRequested: boolean;
}

const texts = {
    cancelDelivery: <FormattedMessage id="parcel.cancel.button" />,
    confirmTitle: <FormattedMessage id="parcel.cancel.confirm.title" />,
    confirmText: <FormattedMessage id="parcel.cancel.confirm.text" />,
    confirmGoBack: <FormattedMessage id="parcel.cancel.confirm.cancel" />,
};

export const CancelDeliveryButton = ({ cancelationRequested, parcelNumber }: CancelDeliveryButtonProps) => {
    const { styles, rules } = useFelaEnhanced(felaRules);

    const [confirmVisible, showConfirmModal, hideConfirmModal] = useModalOpenState();

    const [api, cancel, resetApi] = useCancelParcelDelivery(parcelNumber);
    const hideConfirm = useCallback(() => {
        hideConfirmModal();
        resetApi();
    }, [resetApi, hideConfirmModal]);

    useEffect(() => {
        if (confirmVisible && api.lastSuccessAt) {
            hideConfirm();
        }
    }, [confirmVisible, api.lastSuccessAt, hideConfirm]);

    if (cancelationRequested) {
        return (
            <span className={styles.note}>
                <FormattedMessage id="parcel.cancel.requested" />
            </span>
        );
    }

    return (
        <>
            <Button type={Button.Type.TERTIARY} onClick={showConfirmModal} extend={{ button: rules.triggerButton }}>
                {texts.cancelDelivery}
            </Button>

            <Modal
                visible={confirmVisible}
                title={texts.confirmTitle}
                onOk={cancel}
                onCancel={hideConfirm}
                closable={false}
                footer={[
                    <Button key="back" type={Button.Type.LINK} onClick={hideConfirm}>
                        {texts.confirmGoBack}
                    </Button>,
                    <Button
                        key="submit"
                        type={Button.Type.PRIMARY}
                        loading={api.inProgress}
                        onClick={cancel}
                        extend={{ button: rules.submitButton }}
                    >
                        {texts.cancelDelivery}
                    </Button>,
                ]}
            >
                <p>{texts.confirmText}</p>
                {api.error && <Alert type="error" showIcon message={<FormattedMessage id={api.error.id} />} />}
            </Modal>
        </>
    );
};

import { createApiRequestActions } from '@ackee/redux-utils';

import type { ErrorIntlMessage } from 'modules/errors';
import * as types from './types';

export { types };

export const fetchEntrustedPartners = createApiRequestActions<
    undefined,
    undefined,
    { lastSuccessAt: string },
    ErrorIntlMessage
>(types.fetchEntrustedPartners);

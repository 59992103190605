import { ParcelRowType } from '../types';

export const GRID_COLUMNS = 'minmax(0, 2fr) minmax(0, 1fr) 3fr 1fr 1fr 1fr';

export const parcelRowHeight = {
    [ParcelRowType.Item]: 55,
    [ParcelRowType.Skeleton]: 55,
    [ParcelRowType.Separator]: 33,
} as const;

export const SKELETON_COUNT = 3;

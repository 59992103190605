import type { FieldValidator } from 'final-form';
import React from 'react';
import { DEBOUNCE_INTERVAL } from '../constants';

const useValidationDebounce = <FieldValue = any>(validate?: FieldValidator<FieldValue>) => {
    const clearTimeoutRef = React.useRef<null | Function>(null);

    const validateDebounced = React.useCallback(
        (value: any, allValues: any, meta: any) =>
            validate
                ? new Promise<void>(resolve => {
                      if (clearTimeoutRef.current) {
                          clearTimeoutRef.current();
                      }

                      const timer = setTimeout(() => {
                          resolve(validate(value, allValues, meta));
                      }, DEBOUNCE_INTERVAL);

                      clearTimeoutRef.current = () => {
                          clearTimeout(timer);
                          resolve();
                      };
                  })
                : undefined,
        [validate],
    );

    return validateDebounced;
};

export default useValidationDebounce;

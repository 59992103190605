import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from 'modules/core/modules/redux';
import { selectParcelsEntities } from 'modules/entities';

export const labelToastParcelIdSelector = (state: RootState) => state.parcels.parcelLabelToast;

export const labelToastParcelSelector = createSelector(
    labelToastParcelIdSelector,
    selectParcelsEntities,
    (parcelId, parcels) => (parcelId ? parcels.byId[parcelId] : undefined),
);

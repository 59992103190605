import { api } from 'config/antonio';
import config from 'config';

import processCredentials from './utils/processCredentials';
import type { AuthTokens, Credentials } from 'modules/auth/types';

// TODO - check if refreshToken can be undefind in refreshTokens
export default function* refreshTokens({ refreshToken: { token }, accessToken: { user } }: Required<AuthTokens>) {
    const { data } = yield* api.post<Credentials>(config.api.authSession.refresh, {
        refreshToken: token,
    });

    return processCredentials(data, user);
}

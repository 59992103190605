import type { ReactNode } from 'react';
import { Field } from 'react-final-form';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import { useValidationDebounce } from '../../hooks';
import FormItem from '../FormItem';
import { Input } from '../Input';
import type { TextFieldProps } from '../types';
import type { InputProps } from '../Input';

import * as felaRules from './TextField.rules';

export interface TextFieldOwnProps<TValue>
    extends TextFieldProps<TValue>,
        Omit<InputProps, 'name' | 'type' | 'value' | 'defaultValue' | 'children' | 'extend'> {
    children?: ReactNode;
    extend?: RulesExtend<typeof felaRules>;
    inputExtend?: InputProps['extend'];
}

const TextField = <TValue extends string | number = string>({
    label,
    required = false,
    validate,
    help,
    extend,
    inputExtend,
    parse,
    ...restProps
}: TextFieldOwnProps<TValue>) => {
    const { rules } = useFelaEnhanced(felaRules, { extend, type: restProps.type });
    const validateDebounced = useValidationDebounce(validate);

    return (
        <Field<TValue> validate={validateDebounced} validateFields={[]} {...{ parse }} {...restProps}>
            {({ input, meta }) => (
                <FormItem<TValue> {...{ input, meta, required, help, label }} extend={{ formItem: rules.formItem }}>
                    <Input {...input} {...restProps} extend={inputExtend} />
                </FormItem>
            )}
        </Field>
    );
};

export default TextField;

import { all } from 'redux-saga/effects';

import { saga as locations } from '../modules/locations';
import { saga as destinations } from '../modules/destinations';
import { saga as deliveryPartners } from '../modules/deliveryPartners';
import { saga as entrustedPartners } from '../modules/entrustedPartners';
import { saga as parcels } from '../modules/parcels';
import { saga as deliveryRequests } from '../modules/deliveryRequests';
import { saga as csvImport } from '../modules/csvImport';
import { saga as customerReturn } from '../modules/customerReturn';

export default function* entitiesSaga() {
    yield all([
        locations(),
        destinations(),
        deliveryPartners(),
        entrustedPartners(),
        parcels(),
        deliveryRequests(),
        csvImport(),
        customerReturn(),
    ]);
}

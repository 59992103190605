import type { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { toString } from 'lodash';

import {
    validatorsWithMessage,
    TextField,
    RadioButtonGroupField,
    Condition,
    validateWithTranslation,
    FormWrapper,
    SetDefaultFields,
} from 'modules/form';
import { Headline } from 'modules/ui';

import { DeliveryField, DeliveryType } from '../../config';
import { CustomerDetailsForm, getValidators as getCustomerDetailsValidators } from '../CustomerDetailsForm';
import DeliveryAddressForm, { validators as deliveryAddressValidators } from '../DeliveryAddressForm';
import { DeliveryLocation, getValidators as getDeliveryValidators } from '../DeliveryLocation';
import { ShowRecipientInformationSelect } from '../ShowRecipientInformationSelect';
import type { DeliveryFormValues, NewOrderStepFormValidate } from '../../types';
import { useDeliveryTypes, useDeliveryLocationTypes, usePartnerCountry } from '../../hooks';

const { required } = validatorsWithMessage;

export const validators = {
    [DeliveryField.ReferenceNumber]: required,
    [DeliveryField.DeliveryType]: required,
    ...getCustomerDetailsValidators(),
};

const validate: NewOrderStepFormValidate<DeliveryField> = (values, formatMessage) => {
    const deliveryValidators =
        values[DeliveryField.DeliveryType] === DeliveryType.Location
            ? getDeliveryValidators()
            : values[DeliveryField.DeliveryType] === DeliveryType.Warehouse
            ? getDeliveryValidators(DeliveryField.WarehouseNumber)
            : deliveryAddressValidators;
    const formValidators = {
        ...validators,
        ...deliveryValidators,
    };

    return validateWithTranslation(values, formValidators, formatMessage);
};

export interface DeliveryFormProps {}

const DeliveryForm: FC<DeliveryFormProps> & { validate: typeof validate } = () => {
    const { formatMessage } = useIntl();
    const deliveryTypes = useDeliveryTypes();
    const locationTypes = useDeliveryLocationTypes();
    const defaultCountry = usePartnerCountry();

    return (
        <>
            <FormWrapper withNote={false}>
                <Headline level={2}>
                    <FormattedMessage id="order.delivery.details" />
                </Headline>

                <CustomerDetailsForm />
                <TextField<DeliveryFormValues[DeliveryField.ReferenceNumber]>
                    label={formatMessage({ id: 'order.delivery.referenceNumber' })}
                    placeholder={formatMessage({ id: 'order.delivery.referenceNumber.placeholder' })}
                    name={DeliveryField.ReferenceNumber}
                    parse={toString}
                    required
                />

                <Headline level={2}>
                    <FormattedMessage id="order.delivery.address" />
                </Headline>

                <RadioButtonGroupField<DeliveryFormValues[DeliveryField.DeliveryType]>
                    name={DeliveryField.DeliveryType}
                    options={deliveryTypes.map(type => ({
                        value: type,
                        label: <FormattedMessage id={`delivery.type.${type}`} />,
                    }))}
                    label={formatMessage({ id: 'delivery.type' })}
                    required
                />

                <Condition
                    when={DeliveryField.DeliveryType}
                    is={DeliveryType.Address}
                    removeFieldsWhenIsNot={[
                        DeliveryField.Street,
                        DeliveryField.City,
                        DeliveryField.PostalCode,
                        DeliveryField.Country,
                        DeliveryField.Note,
                        DeliveryField.PartnerId,
                    ]}
                >
                    <SetDefaultFields fields={{ [DeliveryField.Country]: defaultCountry }}>
                        <DeliveryAddressForm />
                    </SetDefaultFields>
                </Condition>
            </FormWrapper>

            <Condition
                when={DeliveryField.DeliveryType}
                is={DeliveryType.Location}
                removeFieldsWhenIsNot={[DeliveryField.ApmNumber]}
            >
                <DeliveryLocation locationTypes={locationTypes} />
            </Condition>

            <Condition
                when={DeliveryField.DeliveryType}
                is={DeliveryType.Warehouse}
                removeFieldsWhenIsNot={[DeliveryField.WarehouseNumber]}
            >
                <DeliveryLocation
                    name={DeliveryField.WarehouseNumber}
                    label="order.delivery.warehouse.number"
                    placeholder="order.delivery.warehouse.number.placeholder"
                    locationTypes={['warehouse']}
                    widgetEnabled={false}
                />
            </Condition>

            <ShowRecipientInformationSelect />
        </>
    );
};

DeliveryForm.validate = validate;

export default DeliveryForm;

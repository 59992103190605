import type { TRuleWithTheme } from 'styles/theme';

export const panel: TRuleWithTheme = () => ({
    display: 'flex',
    justifyContent: 'space-between',
});

export const container: TRuleWithTheme = ({ theme: { metrics } }) => ({
    display: 'inline-grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: metrics.spacing * 6,
    rowGap: metrics.spacing * 4,

    isDesktop: {
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
    },
});

export const firstRowItem: TRuleWithTheme = () => ({
    gridRow: '1 / 2',
});

export const secondRowItem: TRuleWithTheme = () => ({
    gridRow: '2 / 3',
});

import React from 'react';
import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import * as felaRules from './Clear.rules';

export interface ClearProps {
    extend?: RulesExtend<typeof felaRules>;
}

export const Clear = ({ extend }: ClearProps) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });

    return (
        <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.icon}>
            <path
                d="M13.5 3.00002H4.5C3.67157 3.00002 3 3.67159 3 4.50002V13.5C3 14.3284 3.67157 15 4.5 15H13.5C14.3284 15 15 14.3284 15 13.5V4.50002C15 3.67159 14.3284 3.00002 13.5 3.00002Z"
                fill="currentColor"
            />
            <path
                d="M10.5 7.50002L7.5 10.5M7.5 7.50002L10.5 10.5L7.5 7.50002Z"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

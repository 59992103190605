import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles';

import * as felaRules from './Error.rules';

export interface ErrorProps {
    extend?: RulesExtend<typeof felaRules>;
}

export const Error = ({ extend }: ErrorProps) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });

    return (
        <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={styles.icon}
        >
            <path
                d="M6 10.5a4.5 4.5 0 100-9 4.5 4.5 0 000 9zM6 4v2m0 2h.007"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

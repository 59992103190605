import { combineReducers } from 'redux';
import { ApiReducerKey } from 'modules/entities/constants';

import create from './create';
import checkAddressDelivery from './checkAddressDelivery';
import { CHECK_ADDRESS_DELIVERY_REDUCER_KEY } from '../../../constants';

export default combineReducers({
    [ApiReducerKey.CREATE]: create,
    [CHECK_ADDRESS_DELIVERY_REDUCER_KEY]: checkAddressDelivery,
});

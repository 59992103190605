import type { TRuleWithTheme } from 'styles/theme';

export const menu: TRuleWithTheme = ({ theme: { colors, font, shadow, metrics } }) => ({
    background: colors.white,
    color: colors.foregroundsPrimary80,
    boxShadow: shadow.dropdown,
    borderRadius: 5,
    fontFamily: font.family,

    '& .ant-dropdown-menu-item': {
        paddingLeft: metrics.spacing * 2,
        paddingRight: metrics.spacing * 2,
        paddingTop: metrics.spacing * 1.25,
        paddingBottom: metrics.spacing * 1.25,

        fontSize: '1rem',
        lineHeight: '1.25rem',
    },
});

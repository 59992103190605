import type { AntonioError } from '@ackee/antonio-core';
import { push } from 'connected-react-router';
import { put, takeLeading } from 'redux-saga/effects';

import type { components, paths } from 'api/partnerApiSchema';
import { authApi } from 'config/antonio';
import type { RoutePaths, Routes } from 'config/config';
import config from 'config/index';
import * as Log from 'config/loglevel';
import { EntityKey } from 'modules/entities/constants';
import { setEntitiesGroup } from 'modules/entities/services/actions';
import { normalizeCsvImport } from 'modules/entities/services/normalizr';
import { ErrorData, getIntlErrorMessage } from 'modules/errors';

import { csvImportType } from '../../constants';
import type { CsvImportType } from '../../types';
import { csvImportRequest as apiActions } from '../actions';
import { setParcelLabelsUrl } from '../slices/parcelLabelsUrl';

const apiPath = config.api.fromCsvRequest;
type Path = typeof apiPath;
type Operation = paths[`/api/v1${Path}`]['post'];
type RequestBody = Operation['requestBody']['content']['multipart/form-data'];
type RequestAction = ReturnType<typeof apiActions.request>;

type ErrorP404 = AntonioError<components['schemas']['E_P404']>;

const redirects = {
    [csvImportType.Warehouse]: config.routes.fromWarehouseOrderLabels,
    [csvImportType.Apm]: config.routes.fromAnyApmOrderLabels,
} as const satisfies Record<CsvImportType, RoutePaths<Routes>>;

function* handleCreateCsvImport({ payload }: RequestAction) {
    try {
        const blob = new Blob([payload.file], { type: 'text/csv' });

        const formData = new FormData();
        formData.append('file', blob);
        formData.append('type', payload.type);
        formData.append('allowReturn', String(payload.allowReturn));
        formData.append('delimiter', payload.delimiter ?? ',');
        formData.append('showRecipientInformation', String(payload.showRecipientInformation));
        if (payload.originContactEmail) formData.append('originContactEmail', payload.originContactEmail);
        if (payload.originContactNumber) formData.append('originContactNumber', payload.originContactNumber);

        const {
            data,
            response: { headers },
        } = yield* authApi.post<RequestBody>(apiPath, formData);
        const { result: ids, entities } = normalizeCsvImport(data);
        const labelsUrlPdf = headers.get('x-labels-url-pdf');

        yield put([
            setEntitiesGroup(EntityKey.CSV_IMPORT, {
                ids,
                byId: entities[EntityKey.CSV_IMPORT],
            }),
            setParcelLabelsUrl(labelsUrlPdf!),
            apiActions.success(undefined, {
                lastSuccessAt: new Date().toISOString(),
            }),
        ]);

        yield put(push(redirects[payload.type]));
    } catch (e) {
        const errorMessage = getIntlErrorMessage(e as AntonioError<ErrorData> | Error, {
            code: {
                P400: { id: 'error.csvImportRequest.post.p400' },
                P401: { id: 'error.csvImportRequest.post.p401' },
                P402: { id: 'error.csvImportRequest.post.p402' },
                P403: { id: 'error.csvImportRequest.post.p403' },
                P404: {
                    id: 'error.csvImportRequest.post.p404',
                    values: { line: (e as ErrorP404).data.line, column: (e as ErrorP404).data.column },
                },
                P405: { id: 'error.csvImportRequest.post.p405' },
            },
            fallback: {
                id: 'order.csvImport.failedUpload',
            },
        });

        if (errorMessage.unhandled) {
            Log.error(e as Error);
        }

        yield put(apiActions.failure(errorMessage));
    }
}

export default function* createCsvImportSaga() {
    yield takeLeading(apiActions.request.toString(), handleCreateCsvImport);
}

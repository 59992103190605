import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useAppSelector } from 'hooks/redux';
import { DEFAULT_GROUP } from 'modules/entities';
import { apiActions, selectDestinations, selectDestinationsApi } from 'modules/entities/modules/destinations';
import type { LocationType } from 'modules/entities/types';

const useDestinations = (locationTypes: LocationType[]) => {
    const dispatch = useDispatch();
    const api = useAppSelector(selectDestinationsApi);

    const destinationsGroupKey = locationTypes.toString() || DEFAULT_GROUP;
    const destinations = useAppSelector(state => selectDestinations(state, { group: destinationsGroupKey }));

    useEffect(() => {
        dispatch(apiActions.fetchDestinations.request({ locationTypes, group: destinationsGroupKey }));
    }, [locationTypes, destinationsGroupKey, dispatch]);

    return { destinations, api };
};

export default useDestinations;

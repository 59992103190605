import { Upload } from 'antd';
import type { DraggerProps } from 'antd/es/upload';
import { useFelaEnhanced } from 'hooks';
import type { FC } from 'react';

import type { RulesExtend } from 'styles/theme';

import * as felaRules from './FileInput.rules';
import { FileInputEmpty } from './FileInputEmpty';
import { FileInputSelected } from './FileInputSelected';
import { Loader } from './Loader';

interface FileInputProps extends Omit<DraggerProps, 'className'> {
    loading?: boolean;
    extend?: RulesExtend<typeof felaRules>;
    onFileRemove: (uid: string) => void;
}

const { Dragger } = Upload;

export const FileInput: FC<FileInputProps> = ({
    extend,
    loading = false,
    onChange,
    onFileRemove,
    fileList = [],
    ...rest
}) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });
    const selected = (fileList?.length ?? 0) > 0;

    return (
        <Dragger className={styles.dragger} onChange={onChange} {...rest}>
            <Loader show={loading}>
                {selected ? <FileInputSelected fileList={fileList} onFileRemove={onFileRemove} /> : <FileInputEmpty />}
            </Loader>
        </Dragger>
    );
};

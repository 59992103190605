import { merge } from 'lodash';
import { Country, getCountryFromHostname } from 'modules/localization';

import type { ConfigDevelopment } from './config.development';

// `currentEnv` must be imported with CommonJS require, otherwise production build throws
// see https://github.com/webpack/webpack/issues/5271
const { isLocalhost, currentEnv } = require('constants/index');
const envConfig = require(`./config.${currentEnv}.ts`).default;

const defaults = {
    sentry: {
        enable: !isLocalhost,
    },
    api: {
        authSession: {
            codeGet: '/auth-sessions:codeGet',
            login: '/auth-sessions',
            refresh: '/auth-sessions:refresh',
            logout: '/auth-sessions:logout',
        },
        locations: '/origins',
        destinations: '/destinations',
        deliveryPartners: '/delivery-partners',
        entrustedPartners: '/entrusted-partners',
        deliveryRequests: '/delivery-requests',
        deliveryRequest: '/delivery-requests/:orderNumber',
        fromCsvRequest: '/delivery-requests:fromCsv',
        checkAddressDelivery: '/delivery-requests:checkAddressDelivery',
        customerReturns: '/delivery-requests:customerReturns',
        parcels: '/parcels',
        cancelParcelDelivery: '/parcels/:id:cancel',
    } as const,
    routes: {
        auth: {
            loginCode: '/login-code',
            login: '/login',
        },
        home: '/',
        order: '/orders',
        newOrder: '/orders/new',
        fromAnyApmOrder: '/orders/from-any-apm/:step',
        fromAnyApmOrderForm: '/orders/from-any-apm/form',
        fromAnyApmOrderCsvImport: '/orders/from-any-apm/csv-import',
        fromAnyApmOrderLabels: '/orders/from-any-apm/labels',
        fromWarehouseOrder: '/orders/from-warehouse/:step',
        fromWarehouseToWarehouseOrder: '/orders/from-warehouse-to-warehouse/:step',
        fromAnyToSameApmOrder: '/orders/any-apm-to-same-apm',
        fromWarehouseOrderForm: '/orders/from-warehouse/form',
        fromWarehouseToWarehouseOrderForm: '/orders/from-warehouse-to-warehouse/form',
        fromWarehouseOrderCsvImport: '/orders/from-warehouse/csv-import',
        fromWarehouseOrderLabels: '/orders/from-warehouse/labels',
        customerReturn: '/orders/customer-return',
        orderDetail: '/orders/:id',
        parcels: '/parcels',
        parcel: '/parcels/:id',
    } as const,
    forms: {
        login: 'loginForm',
    },
    phone: {
        defaultCountry: getCountryFromHostname(),
        preferredCountries: Object.values(Country),
    },
};

const config = merge(defaults, envConfig) as typeof defaults & ConfigDevelopment;

export type Routes = (typeof config)['routes'];

// Flatten values of the `routes`
export type RoutePaths<R extends Record<string, string | any>> = R extends Record<string, infer V>
    ? V extends string
        ? V
        : V extends Record<string, any>
        ? RoutePaths<V>
        : never
    : never;

export default config;

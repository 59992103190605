import { combineReducers } from 'redux';

import { EntityKey } from '../../constants';
import { apiReducer as locations } from '../../modules/locations';
import { apiReducer as destinations } from '../../modules/destinations';
import { apiReducer as deliveryPartners } from '../../modules/deliveryPartners';
import { apiReducer as entrustedPartners } from '../../modules/entrustedPartners';
import { apiReducer as parcels } from '../../modules/parcels';
import { apiReducer as deliveryRequests } from '../../modules/deliveryRequests';
import { apiReducer as csvImport } from '../../modules/csvImport';
import { apiReducer as customerReturn } from '../../modules/customerReturn';

export default combineReducers({
    [EntityKey.LOCATIONS]: locations,
    [EntityKey.DESTINATIONS]: destinations,
    [EntityKey.DELIVERY_PARTNERS]: deliveryPartners,
    [EntityKey.ENTRUSTED_PARTNERS]: entrustedPartners,
    [EntityKey.PARCELS]: parcels,
    [EntityKey.DELIVERY_REQUESTS]: deliveryRequests,
    [EntityKey.CSV_IMPORT]: csvImport,
    [EntityKey.CUSTOMER_RETURN]: customerReturn,
});

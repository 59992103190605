import { apiSelector } from '@ackee/redux-utils';
import { createSelector } from 'reselect';

import type { RootState } from 'modules/core/modules/redux';
import { ApiReducerKey, DEFAULT_GROUP, EntityKey } from 'modules/entities/constants';
import { denormalizeEntrustedPartners } from 'modules/entities/services/normalizr';
import * as entitiesSelectors from 'modules/entities/services/selectors';
import type { ApiState } from 'types';
import type { ErrorIntlMessage } from 'modules/errors';

export const selectEntrustedPartnersApi = (state: RootState) =>
    apiSelector(state, EntityKey.ENTRUSTED_PARTNERS, ApiReducerKey.LIST) as ApiState<ErrorIntlMessage>;

export const selectEntrustedPartners = createSelector(
    entitiesSelectors.selectEntrustedPartnersEntities,
    entrustedPartners => {
        const denormalizedEntrustedPartners = denormalizeEntrustedPartners(entrustedPartners[DEFAULT_GROUP], {
            [EntityKey.ENTRUSTED_PARTNERS]: entrustedPartners.byId,
        });

        return denormalizedEntrustedPartners;
    },
);

export const selectSortedEntrustedPartners = createSelector(selectEntrustedPartners, partners =>
    partners.sort((a, b) => a.displayName.localeCompare(b.displayName)),
);

import React, { Suspense } from 'react';
import { Authenticated } from '@ackee/petrus';

import { Loader } from 'modules/ui';

const Auth = React.lazy(() => import('../Auth'));

interface FirewallProps {
    children: React.ReactNode;
}

const Firewall = ({ children }: FirewallProps) => (
    <Authenticated
        FallbackComponent={() => (
            <Suspense fallback={null}>
                <Auth />
            </Suspense>
        )}
        LoaderComponent={Loader as () => JSX.Element}
    >
        {children}
    </Authenticated>
);

export default Firewall;

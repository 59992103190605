import type { TRuleWithTheme } from 'styles/theme';
import type { ValueProps } from '../..';
import { ValueSize } from '../types';

type StyleProps = Required<Pick<ValueProps, 'size'>>;

export const value: TRuleWithTheme<StyleProps> = ({ theme, size }) => ({
    flexGrow: 1,
    display: 'inline-flex',
    alignItems: 'center',

    color: theme.colors.foregroundsPrimary80,
    fontSize: size === ValueSize.BIG ? '1.625rem' : '1rem',
    fontWeight: size === ValueSize.BIG ? 700 : 400,
    lineHeight: size === ValueSize.BIG ? '1.9375rem' : '1.25rem',

    isMobile: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
});

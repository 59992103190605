import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import type { RouteProps } from 'react-router-dom';

import config from 'config';
import { OrderRoutes } from 'modules/order';
import { Parcels } from 'modules/parcels';
import { ParcelsListPage } from 'modules/parcels/modules/parcels-list';

const AuthRoutes = () => {
    const routes = React.useMemo<RouteProps[]>(
        () => [
            {
                path: config.routes.home,
                component: ParcelsListPage,
                exact: true,
            },
            {
                path: config.routes.order,
                component: OrderRoutes,
                exact: false,
            },
            {
                path: config.routes.parcels,
                component: Parcels,
                exact: false,
            },
            {
                render: () => <Redirect to={config.routes.home} />,
            },
        ],
        [],
    );

    return (
        <Switch>
            {routes.map((route, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Route {...route} key={index} />
            ))}
        </Switch>
    );
};

export default AuthRoutes;

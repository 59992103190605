import { put, select, take, takeLeading } from 'redux-saga/effects';

import { isFailureAction } from 'types/guards';
import { errorToast, infoToast } from 'modules/toast';
import type { AuthState } from 'modules/auth/types';

import * as actions from '../../actions';
import { selectAuth } from '../../selectors';

function* resendLoginCode() {
    const { challenge, phoneNumber }: AuthState = yield select(selectAuth);

    yield put(
        actions.getLoginCodeForm.submit({
            phoneNumber: {
                phone: phoneNumber ?? undefined,
                dialCode: '',
            },
            challenge: challenge ?? undefined,
        }),
    );

    const { submitSuccess, submitFailure } = actions.getLoginCodeForm;

    type SuccessAction = ReturnType<typeof submitSuccess>;
    type FailureAction = ReturnType<typeof submitFailure>;

    const result: SuccessAction | FailureAction = yield take([submitSuccess, submitFailure]);

    if (!isFailureAction<SuccessAction, FailureAction>(result)) {
        infoToast({
            text: { id: 'auth.resend.success' },
        });
    } else {
        errorToast({
            text: { id: 'auth.resend.failure' },
        });
    }
}

export default function* resendLoginCodeSaga() {
    yield takeLeading(actions.resendLoginCode.toString(), resendLoginCode);
}

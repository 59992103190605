import ReactDOM from 'react-dom/client';

import Core from 'modules/core';
import Application from 'modules/application';

import 'styles/index.less';

const container = document.getElementById('root')!;
const root = ReactDOM.createRoot(container);

root.render(
    <Core>
        <Application />
    </Core>,
);

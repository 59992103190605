import type { TRuleWithTheme } from 'styles/theme';
import type { ParcelRowHeaderProps } from './ParcelRowHeader';

type StyleProps = Pick<ParcelRowHeaderProps, 'columnsTemplate'>;

export const row: TRuleWithTheme<StyleProps> = ({
    theme: {
        metrics: { spacing },
        colors,
    },
    columnsTemplate,
}) => ({
    display: 'grid',
    gridTemplateColumns: columnsTemplate,
    columnGap: spacing * 3,
    alignItems: 'center',

    paddingTop: 2,
    paddingBottom: 2,

    fontSize: '0.875rem',
    fontWeight: 400,
    color: colors.fgSecondary,
    lineHeight: '1.0625rem',
});

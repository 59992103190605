import type { ReactNode } from 'react';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles';

import * as felaRules from './PanelBody.rules';

export interface PanelBodyProps {
    children: ReactNode;
    extend?: RulesExtend<typeof felaRules>;
}

export const PanelBody = ({ children, extend }: PanelBodyProps) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });

    return <div className={styles.body}>{children}</div>;
};

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';
import { Panel } from 'modules/ui';

import { useParcelsListPaging } from '../../hooks/useParcelsListPaging';

import * as felaRules from './ParcelsListPaging.rules';
import { PageNumber, PageNumberDelimiter } from './components';
import { PageType } from 'modules/entities/modules/parcels';

export interface ParcelsListPagingProps {
    fetchParcels: (pageNumber: number, pageType: PageType) => void;
    extend?: RulesExtend<typeof felaRules>;
}

export const ParcelsListPaging = ({ fetchParcels, extend }: ParcelsListPagingProps) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });

    const {
        firstPageNumber,
        previousPageNumber,
        currentPageNumber,
        nextPageNumber,
        lastPageNumber,
        pageNumberClickHandlerFactory,
    } = useParcelsListPaging(fetchParcels);

    return (
        <Panel.Footer>
            <div className={styles.box}>
                {currentPageNumber !== firstPageNumber && (
                    <PageNumber onClick={pageNumberClickHandlerFactory(PageType.FIRST)}>{firstPageNumber}</PageNumber>
                )}
                {currentPageNumber > 3 && <PageNumberDelimiter />}
                {previousPageNumber > 0 && previousPageNumber !== firstPageNumber && (
                    <PageNumber onClick={pageNumberClickHandlerFactory(PageType.PREVIOUS)}>
                        {previousPageNumber}
                    </PageNumber>
                )}
                <PageNumber isCurrent>{currentPageNumber}</PageNumber>
                {nextPageNumber < lastPageNumber && nextPageNumber !== lastPageNumber && (
                    <PageNumber onClick={pageNumberClickHandlerFactory(PageType.NEXT)}>{nextPageNumber}</PageNumber>
                )}
                {currentPageNumber < lastPageNumber - 2 && <PageNumberDelimiter />}
                {lastPageNumber !== 0 && currentPageNumber !== lastPageNumber && (
                    <PageNumber onClick={pageNumberClickHandlerFactory(PageType.LAST)}>{lastPageNumber}</PageNumber>
                )}
            </div>
        </Panel.Footer>
    );
};

import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme: { metrics } }) => ({
    marginTop: metrics.spacing * 5,
    marginBottom: metrics.spacing * 5,
});

export const metaPanel: TRuleWithTheme = ({ theme: { metrics } }) => ({
    display: 'flex',
    justifyContent: 'space-between',

    paddingBottom: metrics.spacing * 3.5,
    marginBottom: metrics.spacing * 1.5,
});
